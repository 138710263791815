
import React, { useEffect, useRef, useState } from 'react';
import { Tabs, Tab, Grid, Typography, List, ListItem, ListItemText, Divider } from "@mui/material";
import { styled } from '@mui/material/styles';

const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
}));

export const DetailEmployee = (props: any): JSX.Element => {

    const { data } = props;

    return (
        <>
        <Grid display='flex'>

            <Grid item xs={12} md={6}>
                <Demo>
                    <List>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Nombre Completo </Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: ${data.surname +' '+ data.second_surname +' '+ data.name}`}</Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold'}}>RUT</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: ${data.n_document}`}</Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold'}}>Cargo</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: ${data.charge}`}</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Demo>
            </Grid>

            <Grid item xs={12} md={6}>
                <Demo>
                    <List>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>UF</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: ${data.UF}`}</Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold'}}>UTM</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: ${data.UTM}`}</Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold'}}>Tope Max Imp</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: ${data.Tope_Max_Imp}`}</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Demo>
            </Grid>
        </Grid>

        <Divider></Divider>

        <Grid display='flex'>
            <Grid item xs={12} md={6}>
                <Demo>
                    <List>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Fondo de pensiones</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: ${data.AFP + ' ' + data.AFP_percentage}% `}</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Demo>
            </Grid>

            <Grid item xs={12} md={6}>
                <Demo>
                    <List>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Sistema de salud</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: ${data.salud + ' ' + data.salud_percentage}% `}</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Demo>
            </Grid>
        </Grid>

        <Divider></Divider>

        <Grid display='flex'>
            <Grid item xs={12} md={6}>
                <Demo>
                    <List>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Días trabajados</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: ${data.work_days} días`}</Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold'}}>Inasistencias</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: ${data.absences}`}</Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold'}}>Base tributable</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: $ ${data.tax_base}`}</Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold'}}>Horas extras</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: ${data.extra_hours}`}</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Demo>
            </Grid>

            <Grid item xs={12} md={6}>
                <Demo>
                    <List>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Sueldo base Cto.</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: $ ${data.desc_base_salary}`}</Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold'}}>Atrasos</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: ${data.delay} horas`}</Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold'}}>Total salud</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: $ ${data.total_health}`}</Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold'}}>Cargas familiares</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: ${data.family_allowance}`}</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Demo>
            </Grid>
        </Grid>
        </>
    )
}

import { useEffect, useState } from "react"
import { Protected } from "@/components/layout/Protected"
import { Box, Button, ButtonGroup, Card, Container, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { Loading } from "@/components/common/Loading"
import { WorkerValidationService } from "@/service/services/WorkerValidation.service"
import { ModalWorkerValidation } from "@/components/Modal/ModalWorkerValidation"
import EditIcon from '@mui/icons-material/Edit';
import { API_URL_BASE } from "@/toolbox/defaults/app"
import { Props } from "./AdminValidation.type"
import { AdminValidationService } from "@/service/services/AdminValidation.service"
import { ModalAdminValidation } from "@/components/Modal/ModalAdminValidation"

export const AdminValidationView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [validations, setValidations] = useState([]);
    const [showValidationModal, setShowValidationModal] = useState<boolean>(false);
    const [showValidationDeleteModal, setShowValidationDeleteModal] = useState<boolean>(false);
    const [validationSelected, setValidationSelected] = useState<any>('');
    const [actionSelected, setActionSelected] = useState<any>('');

    useEffect(() => {
        getAdminValidations();
    }, []);

    const getAdminValidations = async() => {
        setLoading(true);
        await AdminValidationService.getAdminValidations('[0,1,2,3]').then((data) => {
            const oldData = data.data || [];
            let newData = [];
            oldData.length > 0 && oldData.map((item) => {
                data = {
                    id: item.id,
                    iduser: item.iduser,
                    userName: item.userName,
                    userEmail: item.userEmail,
                    userN_document: item.userN_document,
                    idcompany: item.idcompany,
                    idrole: item.idrole,
                    idemployee: item.idemployee,
                    employeeName: item.employeeName,
                    EmployeeN_document: item.EmployeeN_document,
                    date_assignment: item.date_assignment,
                    assign_ip: item.assign_ip,
                    idvalidationidentity: item.idvalidationidentity,
                    VIid: item.VIid,
                    VIface_img: `${API_URL_BASE}/${item.VIface_img}`,
                    VIdoc_img: `${API_URL_BASE}/${item.VIdoc_img}`,
                    VIface_matching: item.VIface_matching,
                    VIobservations: item.VIobservations,
                    VIstatus: item.VIstatus,
                    status_validation_identity: item.status_validation_identity,
                    status: item.status,
                    // review_status: item.review_status == '0' ? 'pendiente' : (item.review_status == '1' ? 'aceptada' : 'rechazada'),
                }
                newData.push(data);
            });

            setValidations(newData|| []);
            setLoading(false);
        })
    }

    function RecuperarData(value){
        setValidationSelected(value)
        console.log(value)
    }

    const showDocument = (image) => {
        setLoading(true);

        if(image){
            window.open(image, '_blank');
        }

        setLoading(false);
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Container>
                <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    m: -1,
                    py: 3
                }}
                >
                    <Typography variant='h6' component='h1' gutterBottom className="tittle"
                    sx={{ m: 1 , fontWeight:600}}
                    >
                        {'VALIDACIÓN DE IDENTIDAD'}
                    </Typography>
                    
                </Box>
            <Card sx={{width:"100%"}}>
                <TableContainer>
                    <Table>
                        <TableHead sx={{ bgcolor: '#F4F6F8' }}>
                            <TableRow >
                                <TableCell sx={{minWidth:'180px'}}>
                                    <Typography>Nombre Empleado</Typography>
                                </TableCell>
                                <TableCell sx={{minWidth:'180px'}}>
                                    <Typography>N° Documento</Typography>
                                </TableCell>
                                <TableCell sx={{minWidth:'180px'}}>
                                    <Typography>Nombre de Usuario</Typography>
                                </TableCell>
                                <TableCell sx={{minWidth:'180px'}}>
                                    <Typography>Correo</Typography>
                                </TableCell>
                                <TableCell sx={{minWidth:'180px'}}>
                                    <Typography>Imagen Rostro</Typography>
                                </TableCell>
                                <TableCell sx={{minWidth:'180px'}}>
                                    <Typography>Imagen Documento</Typography>
                                </TableCell>
                                <TableCell sx={{minWidth:'180px'}}>
                                    <Typography>Coincidencias de rostros</Typography>
                                </TableCell>
                                <TableCell sx={{minWidth:'180px'}}>
                                    <Typography>Observaciones</Typography>
                                </TableCell>
                                <TableCell sx={{minWidth:'180px'}}  align="center" >
                                    <Typography>Acciones</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                validations.length > 0 && validations.map((item) => (
                                    <TableRow sx={{maxHeight:'50px'}}>
                                        <TableCell size={'small'}>
                                            {item.employeeName}
                                        </TableCell>
                                        <TableCell size={'small'}>
                                            {item.EmployeeN_document}
                                        </TableCell>
                                        <TableCell size={'small'}>
                                            {item.userName}
                                        </TableCell>
                                        <TableCell size={'small'}>
                                            {item.userEmail}
                                        </TableCell>
                                        <TableCell size={'small'} sx={{maxHeight:'50px'}}>
                                            <img src={item.VIface_img} style={{maxHeight:'100px', maxWidth:'100px', cursor: 'pointer'}} onClick={()=>{showDocument(item.VIface_img)}}/>
                                        </TableCell>
                                        <TableCell size={'small'}>
                                        <img src={item.VIdoc_img} style={{maxHeight:'100px', maxWidth:'100px', cursor: 'pointer'}} onClick={()=>{showDocument(item.VIdoc_img)}}/>
                                        </TableCell>
                                        <TableCell size={'small'}>
                                            {item.VIface_matching}
                                        </TableCell>
                                        <TableCell size={'small'}>
                                            {item.VIobservations}
                                        </TableCell>
                                        <TableCell size={'small'}  align="center" >
                                        <ButtonGroup variant="contained" color="inherit" aria-label="button-group-custom-table" style={{padding: "0px 5px"}}>
                                            <IconButton size="small" color="success" aria-label="view" 
                                                   onClick={() => {
                                                    setValidationSelected(item);
                                                    setShowValidationModal(true);
                                                }}
                                                >
                                                   <EditIcon fontSize='small' />
                                                </IconButton>
                                        </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
            </Container>

            { 
                showValidationModal && (
                    <ModalAdminValidation
                        open={showValidationModal}
                        closeModal={()=>{setShowValidationModal(false)}}
                        actionSelected={actionSelected}
                        onListValidations={getAdminValidations}
                        validation={validationSelected}
                        clearState={()=>{setValidationSelected('')}}
                    />
                )
            }
        </Protected>
    )
}
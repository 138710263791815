import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Autocomplete, Divider, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { PhasesService } from "@/service/services/Phases.service";
import { Loading } from "@/components/common/Loading";
import { AssistanceService } from "@/service/services/Assistance.service";
import { Props } from "./ModalGroupCompany.type";
import { requestTypeService } from "@/service/services/RequestType.service";
import { companiesService } from "@/service/services/Companies.service";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";

export const ModalGroupCompany: React.FC<Props> = (props: any): JSX.Element => {

    const { open, closeModal, onList, groupCompany, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [companies, setCompanies] = useState<any>([]);
    const [newCompanySelected, setNewCompanySelected] = useState<any>(null)
    const userData = readLocalStorage(KEY_USER_DATA);
    const [data, setData] = useState({
        idcompany: null,
    });

    const validateForm = (values) => {
        let errors: any = {};
        if (!newCompanySelected) errors.company = "empresa es requerido";
        return errors;
    }

    const onSubmit = async (values, { resetForm }) => {

        const formData = {
            idcompany: newCompanySelected.id,
            idadmin: userData.user.iduser,
        }

        setLoading(true);

        await companiesService.createRequestGroupCompany(formData).then((data) => {
            onList && onList();
            closeModal && closeModal();
        })

        setLoading(false);
    }

    const handleDecision = async(e,decision) =>{
        decision==1 && e.preventDefault();
        setLoading(true);

        await companiesService.editDecisionGroupCompany(groupCompany.id, decision).then((data) => {
            onList && onList();
            closeModal && closeModal();
        })

        setLoading(false);
    }

    const getMyCompanies = async () => {
        const resp = await companiesService.getCompanies();
        setCompanies(resp.data)
    }

    const onClose = () =>{
        closeModal();
        clearState();
    }

    useEffect(() => {
        getMyCompanies();
    }, [])
    return (
        <>
            {loading && <Loading />}

            <Modal open={open} handleClose={closeModal} handle size="sm">
                <ModalHeader
                    text={ groupCompany? "Grupo de Empresas" : "Agregar a Grupo de Compañias"}
                    className='positionElements'
                    onCancel={onClose}
                >
                </ModalHeader>
                <ModalBody>

                    {
                        groupCompany && <Typography variant="subtitle1" className="textColor"> 
                        ¿Desea Aceptar o Rechazar la solicitud?
                      </Typography>
                    }
                    <Formik initialValues={data} enableReinitialize validate={(values) => validateForm(values)} onSubmit={onSubmit}>
                        {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                            return (
                                <form onSubmit={ !groupCompany &&handleSubmit}>
                                    <Grid container >

                                        {!groupCompany && <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                                <Typography variant="body1"><b>Empresa:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Autocomplete
                                                    id="disable-portal"
                                                    disablePortal
                                                    value={newCompanySelected}
                                                    options={companies || []}
                                                    getOptionLabel={(option) => `${option.business_name} - ${option.registry_number}` || ""}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} variant="standard" label="Escriba para buscar" />}
                                                    onChange={(e, newValue) => {
                                                        setNewCompanySelected(newValue);
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        }                                
                                    </Grid>
                                    {!groupCompany && <Divider />}
                                    <ModalFooter
                                        buttonType="submit"
                                        confirmText={ groupCompany ? "ACEPTAR": "SOLICITAR"}
                                        cancelText={ groupCompany ? "RECHAZAR": "CANCELAR"}
                                        onCancel={groupCompany ? (e) => {handleDecision(e,3)} :handleSubmit}
                                        onConfirm={groupCompany ? (e) => {handleDecision(e,1)}:handleSubmit}
                                    />
                                </form>
                            )
                        }}
                    </Formik>
                </ModalBody>
            </Modal>
        </>
    );
}
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useRef, useState } from "react";
import { Alert, Autocomplete, Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import { Loading } from "@/components/common/Loading";

import { PeriodService } from "@/service/services/Period.service";
import { RemunerationService } from "@/service/services/Remuneration.service";
import { ROUTE_CUSTOM_VISUALIZER_PDF } from "@/toolbox/constants/route-map";
import { saveLocalStorage } from "@/toolbox/helpers/local-storage-helper";

export const ModalBills = (props:any): JSX.Element => {

   const { open, closeModal, workerSelected, setShowLiquitacion, setView, setLiquitacionExists } = props;
   

   const [loading, setLoading] = useState<boolean>(false);
   const [periods, setPeriods] = useState<any>([]);
   const [years, setYears] = useState<any>([]);
   const [yearSelected, setYearSelected] = useState<any>('');
   const [monthSelected, setMonthSelected] = useState<any>('');
   const [btnLiquitadion, setBtnLiquitadion] = useState<boolean>(false);
   const [liquidation, setLiquidation] = useState<any>('');
   
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   });

   useEffect(()=> {
      getPeriods();
  },[]);

   const getPeriods = async() => {
      setLoading(true);
      const resp:any = await PeriodService.getPeriods();
      const newData = [];
      const arrayYears = [];
      resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
         newData.push({
            id: item.id,
            month: item.month,
            year: item.year,
            alias_name: item.month + '-' +  item.year,
         })
         const found = arrayYears.find(element => element == item.year);
         if(!found) { arrayYears.push(item.year); }
         
      });
      setYears(arrayYears);
      setPeriods(newData || []);
      setLoading(false);
   }

   const showLiquidation = async() => {
      setLoading(true);
      const dataPdf = {
         idperiod: liquidation.idperiod,
         idemployee: liquidation.idemployee,
      }
      const pdfResp:any = await RemunerationService.generatePDFPreRemuneration(dataPdf);
      if(pdfResp){
         const blob = new Blob([pdfResp], {type: 'application/pdf'});
         const blobURL = URL.createObjectURL(blob);
         window.open(blobURL, '_blank');
      }
      setLoading(false);
      closeModal && closeModal();
   }

   const onSubmit = async() => {
      setLoading(true);
      if(!monthSelected || !yearSelected){
         setLoading(false);
         return (
            setSnackBarConfig(prev => ({ ...prev, open: true, 
               severity:'warning', 
               message: `Debe llenar todos los campos` }
            ))
         );
      }

      const periodSelected = monthSelected + '-' + yearSelected;
      const period = periods.find(element => element.alias_name == periodSelected);

      const dataPre = {
         idperiod: period.id,
         month: period.month,
         year: period.year
      }
      const dataPdf = {
         idperiod: period.id,
         idemployee: workerSelected.id,
      }
      
      const verifyResp:any = await RemunerationService.verfifyRemuneration(workerSelected.id, period.id);
      
      if((verifyResp.data.message).includes('Existe')){
         const detail = verifyResp.data?.detail[0];
         setBtnLiquitadion(true);
         setLoading(false);
         setLiquidation(detail)
         return (
            setSnackBarConfig(prev => ({ ...prev, open: true, 
               severity:'warning', 
               message: `${verifyResp.data.message}` }
            ))
         );
      }

      const preResp:any = await RemunerationService.preRemuneration(dataPre, workerSelected.id);
      if(preResp.data.status != true){
         setLoading(false);
         return (
            setSnackBarConfig(prev => ({ ...prev, open: true, 
               severity:'error', 
               message: `${preResp.data.message || 'Ocurrió algun error inesperado'}` }
            ))
         );
      }

      const pdfResp:any = await RemunerationService.generatePDFPreRemuneration(dataPdf);
      if(pdfResp){
         
         const blob = new Blob([pdfResp], {type: 'application/pdf'});
         const blobURL = URL.createObjectURL(blob);

         const dataRemu = {
            pdf: blobURL, 
            idperiod: period.id,
            month: period.month,
            year: period.year,
            idemployee: workerSelected.id,
         }

         saveLocalStorage('dataRemu', dataRemu);
         window.open(ROUTE_CUSTOM_VISUALIZER_PDF, '_blank');

      }
      setLoading(false);
      closeModal && closeModal();
   }
   
   return(
      <>
      {loading && <Loading/>}
      <Snackbar
         open={snackBarConfig.open} 
         autoHideDuration={snackBarConfig.autoHideDuration} 
         onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
         anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
         <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
               {snackBarConfig.message || ""}
         </Alert>
      </Snackbar>

      <Modal open={open} handleClose={closeModal} handle size="sm">
         <ModalHeader
               text={"Generar Liquidación"}
               className='positionElements'
               onCancel={closeModal}
         >
         </ModalHeader>
         <ModalBody>
            <Grid container >
               <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                  <Grid item xs={4}>
                     <Typography variant="body1"><b>Período:</b> </Typography>
                  </Grid>
                  <Grid item xs={8}>
                     <Select
                        id="id_education_level"
                        variant="standard"
                        onChange={(event) => {
                           setYearSelected(event.target.value);
                        }}
                        fullWidth
                     >
                        {
                           years.map((year, i) => {
                              return <MenuItem key={i} value={year}>{year}</MenuItem>;
                           })
                        }
                     </Select>
                  </Grid>
               </Grid>

               <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                  <Grid item xs={4}>
                     <Typography variant="body1"><b>Mes:</b> </Typography>
                  </Grid>
                  <Grid item xs={8}>
                     <Select
                        id="id_education_level"
                        variant="standard"
                        onChange={(event) => {setMonthSelected(event.target.value)}}
                        fullWidth
                     >
                        <MenuItem value={'01'}>Enero</MenuItem>
                        <MenuItem value={'02'}>Febrero</MenuItem>
                        <MenuItem value={'03'}>Marzo</MenuItem>
                        <MenuItem value={'04'}>Abril</MenuItem>
                        <MenuItem value={'05'}>Mayo</MenuItem>
                        <MenuItem value={'06'}>Junio</MenuItem>
                        <MenuItem value={'07'}>Julio</MenuItem>
                        <MenuItem value={'08'}>Agosto</MenuItem>
                        <MenuItem value={'09'}>Septiembre</MenuItem>
                        <MenuItem value={'10'}>Octubre</MenuItem>
                        <MenuItem value={'11'}>Noviembre</MenuItem>
                        <MenuItem value={'12'}>Diciembre</MenuItem>
                     </Select>
                  </Grid>
               </Grid>

               {
                  btnLiquitadion && (
                     <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                        <Button onClick={showLiquidation} type={'submit'} size="small" color={"primary"} variant="contained" >
                           {`ver liquidación`}
                        </Button>
                     </Grid>
                  )
               }
            </Grid>
         </ModalBody>
         <ModalFooter 
            buttonType="submit"
            confirmText={" CREAR NUEVA" }
            cancelText="CANCELAR" 
            onCancel={closeModal}
            onConfirm={()=>{onSubmit()}}
         />
      </Modal>
      </>
   );
}
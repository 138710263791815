import {
   ROUTE_HOME,
   ROUTE_ACCOUNT,
   ROUTE_USER_CREATE,
   ROUTE_PERFIL,
   ROUTE_ENTITY,
   ROUTE_USER,
   ROUTE_CONFIGURACION,
   ROUTE_USER_UPDATE,
   ROUTE_ENTITY_CREATE,
   ROUTE_ENTITY_UPDATE,
   ROUTE_ENTITY_USERS
} from "../constants/route-map";

export const ROLE_SUPER_ADMIN = 'SUPERADMIN';
export const ROLE_ADMIN = 'ADMINISTRADOR';
export const ROLE_SUPERVISOR = 'SUPERVISOR';
export const ROLE_VENDEDOR = 'VENDEDOR';
export const ROLE_TRABAJADOR = 'TRABAJADOR';
export const ROLE_ADMIN_PROYECCION = 'ADMIN_PROYECCION';

export const ROUTES_FOR_SUPER_ADMIN = [
   {
      module: ROUTE_ENTITY,
      navigators: []
   },
   {
      module: ROUTE_ACCOUNT,
      navigators: [ROUTE_PERFIL]
   },
]
export const ROUTES_FOR_TRABAJADOR = [
   {
      module: ROUTE_ACCOUNT,
      navigators: [ROUTE_PERFIL]
   }
]

export const ROUTES_FOR_ADMIN = [
   {
      module: ROUTE_HOME,
      navigators: []
   },
   {
      module: ROUTE_USER,
     navigators: []
   },
   {
      module: ROUTE_PERFIL,
     navigators: []
   },
   {
      module: ROUTE_ACCOUNT,
      navigators: [
         ROUTE_PERFIL,
         ROUTE_USER,
         ROUTE_CONFIGURACION]
   },
   {
      module: ROUTE_CONFIGURACION,
      navigators: []
   }
]
export const ROUTES_FOR_VENDEDOR = [
   {
      module: ROUTE_HOME,
      navigators: []
   },
   {
      module: ROUTE_ACCOUNT,
      navigators: [ROUTE_PERFIL]
   }
]
export const ROUTES_FOR_SUPERVISOR = [
   {
      module: ROUTE_HOME,
      navigators: []
   },
   {
      module: ROUTE_USER,
     navigators: []
   }
]

export const MAIN_REDIRECT_FOR_SUPER_ADMIN = ROUTE_HOME;
export const MAIN_REDIRECT_FOR_ADMIN       = ROUTE_HOME;


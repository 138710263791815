import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, InputAdornment, FormControl, FormControlLabel, Checkbox, Stack, Snackbar, Alert, } from '@mui/material'
import React, { useState, useRef, useEffect } from "react";
// import { Props } from "../Workers.type"
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TableBody from '@mui/material/TableBody';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TableCell from '@mui/material/TableCell';
import NumbersIcon from '@mui/icons-material/Numbers';
import TableContainer from '@mui/material/TableContainer';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PhoneIcon from '@mui/icons-material/Phone';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import AssignmentIcon from '@mui/icons-material/Assignment';
import NextWeekIcon from '@mui/icons-material/NextWeek';
import TableRow from '@mui/material/TableRow';
import '../Workers.css'
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import { DeleteIcon } from "@/toolbox/constants/icons";
import { responsabilityFamilyService } from "@/service/services/ResponsabilityFamily.service";
import { TableData } from "@/components/common/Table";
import { dateFormat } from "@/toolbox/helpers/date.helper";
import { DesktopDatePicker } from "@mui/lab";


   const header = [
   { name: 'name_employee', label: 'Nombre', width: '' },
   { name: 'birth_date', label: 'Fecha de Nacimiento', width: '' },
   { name: 'valid_since', label: 'Vigencia Inicio', width: '' },
   { name: 'valid_until', label: 'Vigencia Término', width: '' },
   { name: 'typeFamily', label: 'Tipo', width: '' }]

   const dataAction = [
      { name: 'edit', route: null },
      { name: 'delete', route: null},
   ]


export const DataModalFamily: React.FC<any> = (props): JSX.Element => {
   const [error, setError] = useState<any>('')
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [ejecutivoComercial, setEjecutivoComercial] = useState('');
   const [estado, setEstado] = useState('');
   // const [data, setData] = React.useState<Date | null>(new Date());
   
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'success',
      message: 'Error',
      autoHideDuration: 5000,
  })

   const [formValues, setFormValues] = useState<any>({
      name: '',
      birth_date: null,
      valid_since: null,
      valid_until: null,
      idrelationship: 0,
      sexo: 0,
      idtyperesponsabilityfamily : 0,
      idfamilyburden: props.employee.idfamilyburden,
      idemployee: props.employee.idemployee,
   })
   const [dataInitial, setDataInitial] = useState<any>({
      typeResponsabilityFamily:[],
      relationShip:[]
   })
   const [dataAutocomplete, setDataAutocomplete] = useState<any>({
      parentesco: null,
      sexo: null,
      tipo_carga_familiar: null
   })
   const [dataRespoFamily,setDataRespoFamily] = useState<any>([])
   const inputRefs = useRef<Array<InputRef | null>>([]);

   const handleChangeEjecutivoComercial = (event: SelectChangeEvent) => {
      setEjecutivoComercial(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };


   const handleChangeBirthDate = (newValue) => {
      setError('')
      setFormValues({...formValues, birth_date: newValue.target.value})
    };

    const handleChangeValidSince = (newValue) => {
      setError('')
      setFormValues({...formValues, valid_since: newValue.target.value})
    };

    const handleChangeValidUntil = (newValue) => {
      setError('')
      setFormValues({...formValues, valid_until: newValue.target.value})
    };

   const sexoValue = [
      { id:1, label: 'Masculino'},
      { id:2, label: 'Femenino' }
   ]

   useEffect(()=>{
      getResponsabilityFamily(props.employee.id);
      getDataInitial();
   },[])

   const getResponsabilityFamily = async(id_employee) => {
      const resp:any = await responsabilityFamilyService.getResponsabilityFamily(id_employee);
      setDataRespoFamily(resp.data);
   }

   const getDataInitial = async() =>{
      const resp:any = await responsabilityFamilyService.getDataInitial();
      if(resp){setDataInitial(prev => ({...prev , typeResponsabilityFamily:resp.data.type_responsability_family}))}
      if(resp){setDataInitial(prev => ({...prev , relationShip:resp.data.relation_ship}))}
   }

   const handleInput = (e) => {
      const name = e.name? e.name : e.target.name;
      setError('');
      switch(name){
         case 'name':
            setFormValues({...formValues, name: e.target.value});
            break;
         case 'birth_date':
               setFormValues({ ...formValues, birth_date: e});
            break;
         case 'valid_since':
            setFormValues({ ...formValues, valid_since: e });
            break;
         case 'valid_until':
            setFormValues({ ...formValues, valid_until: e});
            break;
         case 'sexo':
            setFormValues({ ...formValues, sexo: e.target.value});
            break;
      }
   }
   const limpiarDatosFamiliar = () => {
      setFormValues({
         ...formValues,
         name:'',
         birth_date: '',
         valid_since: '',
         valid_until: ''
         // idfamilyburden: props.employee.idfamilyburden,
         // idemployee: props.employee.idemployee,
      })
   }
   const CreateResponsabilityFamily = async() => {
      console.log(formValues)
      if(!formValues.name){
         return setError('name');
      }
      if(!formValues.birth_date){
         return setError('birth_date');
      }
      if(!formValues.valid_since){
         return setError('valid_since');
      }
      if(!formValues.valid_until){
         return setError('valid_until');
      }
      if(!dataAutocomplete.sexo){
         return setError('sexo');
      }
      if(!dataAutocomplete.parentesco){
         return setError('relationShip');
      }
      if(!dataAutocomplete.tipo_carga_familiar){
         return setError('tipo_carga_familiar');
      }
     
      const dataCreate = {
         ...formValues,
         idrelationship: dataAutocomplete.parentesco?.id,
         sexo: dataAutocomplete.sexo?.toString(),
         idtyperesponsabilityfamily : dataAutocomplete.tipo_carga_familiar?.id,
         birth_date: dateFormat(formValues.birth_date),
         valid_until: dateFormat(formValues.valid_until),
         valid_since: dateFormat(formValues.valid_since),
         idemployee: props.employee.id
      }

      //console.log(dataCreate)
          const resp:any = await responsabilityFamilyService.postCreateResponsabilityFamily(dataCreate);
         console.log("respuesta: ",resp)
          if(resp?.data?.status){
            getResponsabilityFamily(props.employee.id);
            limpiarDatosFamiliar();
            
           return setSnackBarConfig({...snackBarConfig, open:true, severity:'success', message:'Registro exitoso'})
         }

          return snackBarConfig({...snackBarConfig, open:true, severity:'error', message:'Ocurrio un problema'})
   }

   const recuperarData = (data) => {
      switch(data){
         case 'edit':
            break;
         case 'delete':
            break;
      }
   }

   return (
      <Container maxWidth='xl'>
         <Grid container spacing={3} mt={2} >
            <Grid container mb={2}>
               <Grid item xs={10}>
                  <Typography variant="h6" id="modal-modal-title" className='tittle'>
                     Mantenedor de Cargas Familiares del Trabajador
                  </Typography>
               </Grid>
               <Grid item xs={2} textAlign="end">
                  <AssignmentIcon style={{ color: '#FF9700' }} />
               </Grid>
            </Grid>
            <Grid container spacing={3} className={`e-mb-lg`}>

               <Grid item xs={12} sm={4}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <NumbersIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="name"
                     type='text'
                     placeholder="Nombre"
                     onChange={(e) => {handleInput(e);}}
                     value={formValues.name}
                     error={error == 'name' ? true: false}
                     disableElevation
                     validateOnBlur
                     dense
                     // disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={4}>               
                  <TextField
                           variant="standard"
                           fullWidth
                           name="birth_date"
                           label="Fecha de Nacimiento"
                           type="date"
                           onChange={handleChangeBirthDate}
                           value={formValues.birth_date}
                           InputLabelProps={{
                              shrink: true,
                           }}
                           error={props.error=='birth_date'}
                           helperText={props.error=='birth_date' && 'Campo obligatorio'}
                        />
               </Grid>
               <Grid item xs={12} sm={4}>
                  <TextField
                           variant="standard"
                           fullWidth
                           name="valid_since"
                           label="Vigencia desde"
                           type="date"
                           onChange={handleChangeValidSince}
                           value={formValues.valid_since}
                           InputLabelProps={{
                              shrink: true,
                           }}
                           error={props.error=='valid_since'}
                           helperText={props.error=='valid_since' && 'Campo obligatorio'}
                        />
               </Grid>
               <Grid item xs={12} sm={4}>               
                  <TextField
                           variant="standard"
                           fullWidth
                           name="valid_until"
                           label="Hasta"
                           type="date"
                           onChange={handleChangeValidUntil}
                           value={formValues.valid_until}
                           InputLabelProps={{
                              shrink: true,
                           }}
                           error={props.error=='valid_until'}
                           helperText={props.error=='valid_until' && 'Campo obligatorio'}
                        />
               </Grid>
               <Grid item xs={12} sm={4}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     // options={parentescoValue}
                     options={dataInitial.relationShip}
                     getOptionLabel={(option: any) => option.name ? option.name : ''}
                     onChange={(e, value) => {
                        if(value){setDataAutocomplete(prev=>({...prev,parentesco:value}))
                        setError('')}
                     }}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} 
                     error={error == 'relationShip'}
                     helperText={error == 'relationShip' && 'Campo obligatorio'}
                     label="Parentesco" />}
                  />
               </Grid>               
               <Grid item xs={12} sm={4}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={sexoValue}
                     onChange={(e, value:any) => {
                        if(value){setDataAutocomplete(prev=>({...prev,sexo:value.id}))
                     setError('')}
                     }}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params}
                     error={error == 'sexo' }
                     helperText={error == 'sexo' && 'Campo obligatorio'}
                     label="Sexo" />}
                  />
               </Grid>
               <Grid item xs={12} sm={4}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     // options={cargaValue}
                     options={dataInitial.typeResponsabilityFamily}
                     getOptionLabel={(option: any) => option.name ? option.name : ''}
                     onChange={(e, value) => {
                        if(value){setDataAutocomplete(prev=>({...prev,tipo_carga_familiar:value}))
                     setError('')}
                     }}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} 
                     error={error == 'tipo_carga_familiar' ? true: false}
                     helperText={error == 'tipo_carga_familiar' && 'Campo obligatorio'}
                     label="Tipo Carga Familiar" />}
                  />
               </Grid>
            </Grid>
            <Grid container mt={2}>
               <Grid item xs={12} textAlign="center">
                  <button className='btn_add' onClick={()=>{CreateResponsabilityFamily()}}>
                     Agregar
                  </button>
               </Grid>

            </Grid>
            <Grid xs={12}>
               <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={2}>
                  LISTADO DE CARGAS FAMILIARES
               </Typography>
            </Grid>
            {/* <Paper sx={{ width: '100%', overflow: 'hidden' }}>
               <TableContainer sx={{ maxHeight: 200 }}>
                  <Table size="small" stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>                        
                           <TableCell>Nombre</TableCell>
                           <TableCell>Fecha de Nacimiento</TableCell>
                           <TableCell>Vigencia Inicio</TableCell>
                           <TableCell>Vigencia Término</TableCell>
                           <TableCell>Tipo</TableCell>
                           <TableCell>Opciones</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsListado.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.nombre}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}                           
                                 <TableCell>
                                    <Grid>
                                    <IconButton
                                    >
                                       <EditIcon />
                                    </IconButton>
                                    <IconButton
                                    >
                                       <DeleteIcon />
                                    </IconButton>
                                    </Grid>
                                    
                                 </TableCell>
                              </TableRow>
                           );
                        })}

                     </TableBody>
                  </Table>
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsListado.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper> */}
            <TableData
            header={header}
            action={dataAction}
            data={dataRespoFamily}
            RecuperarData={recuperarData}
            // row_initial={3}
            />
         </Grid>
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>



      </Container >
   );
};


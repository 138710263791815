import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Container } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { ModalProject } from '@/components/Modal/ModalProject';
import { ProjectService } from '@/service/services/Project.service';
import { ModalProjectDelete } from '@/components/Modal/ModalProjectDelete';
import { Loading } from '@/components/common/Loading';
import { Props } from './RequestType.type';
import { requestTypeService } from '@/service/services/RequestType.service';
import { ModalRequestType } from '@/components/Modal/ModalRequestType';
import { ModalRequestTypeDelete } from '@/components/Modal/ModalRequestTypeDelete';

export const RequestTypeView: React.FC<Props> = (props:any): JSX.Element => {

   const [loading, setLoading] = useState<boolean>(false);
   const [requestTypes, serequestTypes] = useState([]);
   const [showRequestTypeModal, setShowRequestTypeModal] = useState<boolean>(false);
   const [showRequestTypeDeleteModal, setShowRequestTypeDeleteModal] = useState<boolean>(false);
   const [actionSelected, setActionSelected] = useState<String>('');
   const [requestTypeSelected, setRequestTypeSelected] = useState<String>('');

   useEffect(() => {
    getRequestTypes()
   },[]);

   const getRequestTypes = async () => {
      setLoading(true);
      const response = await requestTypeService.getRequestTypes();
      if (response.data) {
        serequestTypes(response.data || [])
      } else {
         console.log('error')
      }
      setLoading(false);
   }

   function RecuperarData(value){
      console.log(value);
      setRequestTypeSelected(value);
   }

  return (
      <Protected>
         {loading && <Loading/>}
         <Container>
            <TableDataV2
               title={'Tipos de solicitudes'}
               data={requestTypes}
               header={[
                  { name: 'name', label: 'Nombre', filter: false, Chip: true },
               ]}
               actionSelect={setActionSelected}
               setModalSave={setShowRequestTypeModal}
               setModalDelete={setShowRequestTypeDeleteModal}
               status_action
               // checkbox
               RecuperarData={RecuperarData}
            />
         </Container>

         { 
            showRequestTypeModal && (
               <ModalRequestType
                  open={showRequestTypeModal}
                  closeModal={()=>{setShowRequestTypeModal(false)}}
                  actionSelected={actionSelected}
                  onListRequestType={getRequestTypes}
                  requestType={requestTypeSelected}
                  clearState={()=>{setRequestTypeSelected('')}}
               />
            )
         }
         {
            actionSelected === 'delete' && (
               <ModalRequestTypeDelete
                  open={showRequestTypeDeleteModal}
                  closeModal={()=>{setShowRequestTypeDeleteModal(false)}}
                  actionSelected={actionSelected}
                  onListRequestType={getRequestTypes}
                  requestType={requestTypeSelected}
                  clearState={()=>{setRequestTypeSelected('')}}
               />
            )
         }
      
      </Protected>
   )
};
import { TextField } from "@mui/material";
import { useState } from "react";

export const InputChildren =(props) => {
    const { row, setDataOptional, dataOptional, editRLEOptional } = props;
    const [dataRLEOptional, setDataRLEOptional] = useState(dataOptional);
    const [currentText, setCurrentText] = useState(row);

    const changeDescription = (e, value) => {
        setCurrentText({
           ...value,
           alias_name: e.target.value
        })
        const changeIndefiniteValidity = dataRLEOptional.map((row) => 
              row.id === value.id ? currentText : row
        );
        setDataRLEOptional(changeIndefiniteValidity);
    }
  
     const onBlurDescription = (e, value) => {
        setCurrentText({
            ...value,
            alias_name: e.target.value
         })
         const changeIndefiniteValidity = dataRLEOptional.map((row) => 
               row.id === value.id ? currentText : row
         );
        editRLEOptional(currentText)
        setDataOptional(changeIndefiniteValidity);
    }

    return (
        <TextField
            disabled = {props.row.status == '0' ? true : false}
            value={currentText.alias_name}
            onBlur={(e)=>{onBlurDescription(e, props.row)}}
            onChange={(e)=>{changeDescription(e, row)}}
        />
    );
}
import { userRepository } from "../repositories/User.repository";
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

export const userService = {
   getRoles,
   getUsers,
   getUserPage,
   getUserSearch,
   updateParticipationUsers,
   ResendPassword,
   createUser,
   createOnlyUser,
   RelationUSerEmployee,
   updatePassword,
   editDetailUserCompany,
}

async function getRoles(){
   const res = await userRepository.getRoles();
   return res;
}

async function getUsers () {
   const user= await userRepository.getUsers();
   return user
}

async function getUserPage (perPage, page) {
   const user= await userRepository.getUserPage(perPage, page);
   return user
}

async function getUserSearch (search) {
   const user= await userRepository.getUserSearch(search);
   return user
}

async function updateParticipationUsers (participacion) {
   const user= await userRepository.updateParticipationUsers(participacion);
   return user
}

async function ResendPassword(idusuario){
   const user= await userRepository.ResendPassword(idusuario);
   return user
}

async function createUser(data){
   const res = await userRepository.createUser(data);
   return res;
}

async function createOnlyUser(data){
   const res = await userRepository.createOnlyUser(data);
   return res;
}

async function RelationUSerEmployee (idUser, idEmployee) {
   const user= await userRepository.RelationUSerEmployee(idUser, idEmployee);
   return user
}

async function updatePassword (dataPassword) {
   const user= await userRepository.updatePassword(dataPassword);
   return user
}

async function editDetailUserCompany (dataUserCompany) {
   const user= await userRepository.editDetailUserCompany(dataUserCompany);
   return user
}

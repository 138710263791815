import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);
const rut_empresa = dataUser?.user?.rut_empresa;

export const JobOffertRepository = {

    getJobOffert: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/jobOffer`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createJobOffert: async (dataJobOffert) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/jobOffer/create`, {
            id_profile: dataJobOffert.id_profile,
            start_postulation: dataJobOffert.start_postulation,
            end_postulation: dataJobOffert.end_postulation,
            n_available_positions: dataJobOffert.n_available_positions,
            minimum_salary: dataJobOffert.minimum_salary,
            maximum_salary: dataJobOffert.maximum_salary,
            id_charge: dataJobOffert.id_charge,
            mode: dataJobOffert.mode,
            type_of_day: dataJobOffert.type_of_day,
            specification: dataJobOffert.specification,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    editJobOffert: async (dataPhase) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/jobOffer/update`, {
            name: dataPhase.name,
            description: dataPhase.description,
            id: dataPhase.id
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    //specifications 

    getSpecification: async(idJobOffert): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/jobOffer/documents/${idJobOffert}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createSpecification: async (dataJob) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/jobOffer/new/specification`, {
            specification: dataJob.specification,
            type: dataJob.type,
            required_do: dataJob.required_do,
            id_job_offer: dataJob.id_job_offer
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    createSpecificationMultiple: async (dataJob) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/jobOffer/new/specificationMultiple`, {
            id_job_offer: dataJob.id_job_offer,
            specifications: dataJob.specifications
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    editSpecification: async (dataPhase) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/phase/update`, {
            name: dataPhase.name,
            description: dataPhase.description,
            id: dataPhase.id
        });
        const {data, error, message} = res;
        return { data, error, message };
    },
} 
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Autocomplete, Divider, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { PhasesService } from "@/service/services/Phases.service";
import { Loading } from "@/components/common/Loading";
import { AssistanceService } from "@/service/services/Assistance.service";
import { Props } from "./ModalAbsence.type";

const typeAbsence = [
    {
        id: 1,
        description: "Inasistencia",
        status: "1"
    },
    {
        id: 2,
        description: "Licencia",
        status: "1"
    }
]

export const ModalAbsence: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, absence, onListAbsences, workerSelected, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [typeAbsences, setTypeAbsences] = useState<any>(typeAbsence);
    const [typeDiscounts, setTypeDiscounts] = useState<any>([]);
    const [disableTypeDesc, setDisabledTypeDesc] = useState<boolean>(false)

    console.log(absence);

    const [data, setData] = useState({
        id: absence ? absence.id : '',
        idemployee: absence ? absence.idemployee : '',
        idtypeabsence: absence ? absence.idtypeabsence : '',
        type_discount: absence ? absence.type_discount : '',
        date_registration: absence ? absence.date_registration : '',
        total_absence: absence ? absence.total_absence : '',
        observation: absence ? absence.observation : '',
    });

    useEffect(()=> {
        getComboAbsences()
    },[]);

    const getComboAbsences = async() => {
        setLoading(true);
        const resp:any = await AssistanceService.getComboAbsences();
        // setTypeAbsences(resp.data.typeAbsence || []);
        setTypeDiscounts(resp.data.typeDiscount || []);
        setLoading(false);
    }

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.total_absence) errors.total_absence = "total inasistencia requerido";
        if(!values.observation) errors.observation = "observación requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            id: values.id,
            idemployee: workerSelected.id,
            idtypeabsence : values.idtypeabsence,
            type_discount : values.type_discount,
            date_registration : values.date_registration,
            total_absence : values.total_absence,
            observation : values.observation,
        }
        setLoading(true);
        if(absence && actionSelected=='edit'){
            await AssistanceService.editAbsence(formData, absence.id).then((data) => {
                onListAbsences && onListAbsences(workerSelected.id);
                closeModal && closeModal();
            })
        }else{
            await AssistanceService.createAbsence(formData).then((data) => {
                onListAbsences && onListAbsences(workerSelected.id);
                closeModal && closeModal();
            })
        }
        setLoading(false);
    }
    return(
        <>
        {loading && <Loading/>}

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={absence ? "Editar inasistencia" : "Agregar Inasistencia"}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >
                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>Tipo de inasistencia:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="idtypeabsence"
                                                variant="standard"
                                                value={values.idtypeabsence}
                                                onChange={(event) => { 
                                                setFieldValue("idtypeabsence", event.target.value);
                                                setDisabledTypeDesc(false)
                                                if(event.target.value == typeAbsence[1].id){
                                                    setFieldValue("type_discount", typeDiscounts[0].id);
                                                    setDisabledTypeDesc(true)
                                                }
                                               
                                                }}
                                                error={ errors.idtypeabsence && touched.idtypeabsence ? true : false }
                                                fullWidth
                                            >
                                                {
                                                    typeAbsences && typeAbsences.map((typeAbsence, i) => {
                                                        return <MenuItem key={i} value={typeAbsence.id}>{typeAbsence.description}</MenuItem>;
                                                    })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>Tipo de descuento:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="type_discount"
                                                variant="standard"
                                                value={values.type_discount}
                                                disabled={disableTypeDesc}
                                                onChange={(event) => { 
                                                setFieldValue("type_discount", event.target.value);
                                                }}
                                                error={ errors.type_discount && touched.type_discount ? true : false }
                                                fullWidth
                                            >
                                                {
                                                    typeDiscounts && typeDiscounts.map((typeDiscount, i) => {
                                                        return <MenuItem key={i} value={typeDiscount.id}>{typeDiscount.description}</MenuItem>;
                                                    })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Fecha inicio:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                id="date_registration"
                                                variant="standard"
                                                fullWidth
                                                name="date_registration"
                                                type="date"
                                                onChange={handleChange}
                                                value={values.date_registration}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={errors.date_registration && touched.date_registration ? true : false}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>total inasistencias:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="total_absence"
                                            variant="standard"
                                            type="number" 
                                            name="total_absence"
                                            fullWidth
                                            value={values.total_absence}
                                            onChange={handleChange}
                                            placeholder="total inasistencias"
                                            error={errors.total_absence && touched.total_absence ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Observación:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="observation"
                                            variant="standard"
                                            type="text" 
                                            name="observation"
                                            multiline
                                            maxRows={4}
                                            fullWidth
                                            value={values.observation}
                                            onChange={handleChange}
                                            placeholder="observacion"
                                            error={errors.observation && touched.observation ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(absence && actionSelected=='edit') ? "ACTUALIZAR" : "REGISTRAR"}
                                    cancelText="CANCELAR" 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}
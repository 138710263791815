import React, { useMemo, useState } from 'react';
import { BrowserRouter as Router, Route, RouteProps, Switch, Redirect } from 'react-router-dom';
import * as Routes from '@constants/route-map';
import { useLocalStorage } from '@toolbox/hooks/local-storage.hook';
import { KEY_USER_DATA } from '@toolbox/constants/local-storage';
import {
   ROLE_ADMIN,
   ROLE_SUPER_ADMIN,
   ROLE_TRABAJADOR,
} from '@/toolbox/defaults/static-roles';
import Unauthorized from '@views/_unautorized';
import { PrivateRoute, AuthRoute } from './PrivateRoute';
import { HomeView } from '@/views/Home';
import { LoginView } from '@/views/Login';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { UsersView } from '@/views/Mainteiners/Users/User';
import { WorkerView } from '@/views/Mainteiners/WorkersOld/Worker';
import { BranchOfficeView } from '@/views/Mainteiners/BranchOffices/BranchOffice';
import { CostCenterView } from '@/views/Mainteiners/CostCenters';
import { DeparmentView } from '@/views/Mainteiners/Departaments';
import { ChargeView } from '@/views/Mainteiners/Charges';
import { ProjectView } from '@/views/Mainteiners/Proyects';
import { PhasesView } from '@/views/Recruitment/Phases';
import { RecluitingTeamView } from '@/views/Recruitment/RecluitingTeam';
import { Announcement } from '@/views/Recruitment/Announcement/Announcement';
import { ProfileView } from '@/views/Recruitment/Profiles';
import { ApplicantsView } from '@/views/Recruitment/Applicants';
import { LiquidationMasiveView } from '@/views/Liquidations/LiquidationMasive';
import {PreviewLiquidationMasiveView} from '@/views/Liquidations/LiquidationMasive/PreviewLiquidationMasive';
import { LiquidationProjectView } from '@/views/Liquidations/LiquidationProject';
import { LiquidationSalaryView } from '@/views/Liquidations/LiquidationSalary';
import { LiquidationWorkersView } from '@/views/Liquidations/LiquidationWorkers';
import { StructureLreView } from '@/views/Liquidations/StructureLre';
import { SettlementActive } from '@/views/Liquidations/SettlementActive';
import { ExtraHoursView } from '@/views/NewMonth/ExtraHours';
import { ValueExtraHoursView } from '@/views/Mainteiners/ValueExtraHours';
import { AssistancesView } from '@/views/NewMonth/Assistances';
import { AdvancesView } from '@/views/NewMonth/Advances';
import { InventoryWorkersView } from '@/views/NewMonth/InventoryWorkers';
import { DelayView } from '@/views/NewMonth/Delay';
import { PDFView } from '@/views/PDF';
import { Workers } from '@/views/Mainteiners/Workers';
import { MovementsView } from '@/views/NewMonth/Movements';

import { TemplateMonthlyView } from '@/views/Liquidations/TemplateMonthly';
import { CertificateEmployeeView } from '@/views/Liquidations/TemplateMonthly/PDF';
import { RequestTypeView } from '@/views/Mainteiners/RequestType';
import { MyLiquidationSalaryView } from '@/views/Liquidations/MyLiquidationSalary/MyLiquidationSalary';
import { DelayAbsenceExtraHourView } from '@/views/Reports/DelayAbsenceExtraHour/DelayAbsenceExtraHour';
import { ReportLiquidations } from '@/views/Reports/ReportLiquidations/ReportLiquidations';
import { ReportEmployeesView } from '@/views/Reports/ReportEmployees/ReportEmployees';
import { ReportRequestView } from '@/views/Reports/ReportRequest/ReportRequest';
import { ReportExpenses } from '@/views/Reports/ReportExpenses/ReportExpenses';

import { RequestView } from '@/views/Request';
import { BillsView } from '@/views/Bills';
import { MyRequestView } from '@/views/MyRequest';
import { EmployeeExpenseView } from '@/views/NewMonth/EmployeeExpense/EmployeeExpense';
import { AdminExpenseView } from '@/views/NewMonth/AdminExpense';
import { PreviewLiquidationRequestView } from '@/components/Modal/ModalGenerateLiquidation/PreviewLiquitacionRequest';
import { PreviewAmountGeneral } from '@/components/Modal/ModalAmountGeneral/PreviewAmountGeneral';
import { WorkerValidationView } from '@/views/IdentityValidations/WorkerValidation';
import { AdminValidationView } from '@/views/IdentityValidations/AdminValidation';
import { ContractEmployee } from '@/views/Contract/ContractEmployee';
import { CompaniesView } from '@/views/Mainteiners/Companies';
import { TurnView } from '@/views/Mainteiners/Turn';
import { CompanySettingsView } from '@/views/CompanySettings/CompanySetting';
import { VariableGlobalView } from '@/views/Mainteiners/VariableGlobal';
import { WorkerContactView } from '@/views/Mainteiners/WorkerContact';
import { AnnexedView } from '@/views/Annexed';



import { NotificationsTypeView } from '@/views/Mainteiners/TypeNotifications';




import { ReminderTypeView } from '@/views/Mainteiners/TypeReminder';
import { IpsAcceptView } from '@/views/NewMonth/IPS/IpsAccept/IpsAccept';
import { IpsDeniedView } from '@/views/NewMonth/IPS/IpsDenied/IpsDenied';
import { CountryView } from '@/views/Mainteiners/Ubigeo/Country/Country';
import { DepartamentView } from '@/views/Mainteiners/Ubigeo/Departament/Departament';
import { DistrictView } from '@/views/Mainteiners/Ubigeo/District/District';
import { ProvinceView } from '@/views/Mainteiners/Ubigeo/Province/Province';
import { TimeZoneView } from '@/views/Mainteiners/TimeZone';
import { BranchOfficeTypesView } from '@/views/Mainteiners/BranchOfficeTypes';




import { StructureMaintenerView } from '@/views/Mainteiners/StructureManteiner';
import { PoliticsView } from '@/views/Politics';
import { ReportLiquidationByProject } from '@/views/Reports/ReportLiquidationByProject/ReportLiquidationByProject';
import { ProductsView } from '@/views/Mainteiners/Products';
import { ReportProducts } from '@/views/Reports/ReportProducts/ReportProducts';
import { GroupCompanyView } from '@/views/Mainteiners/GroupCompanies';
import { GroupCompaniesAllView } from '@/views/Mainteiners/GroupCompaniesAll';
import { ReportgGroupCompany } from '@/views/Reports/ReportGroupCompany/ReportGroupCompany';
import { ContractEdit } from '@/views/CustomizeContract/ContractEdit/ContractEdit';
import { ContractDocumentsView } from '@/views/CustomizeContract/ContractDocuments';
import { ContractCreateView } from '@/views/CustomizeContract/ContractCreate/ContractCreate';

import { CustomizeContract } from '@/views/Mainteiners/Prueba';
import { NewContractCreate } from '@/views/CustomizeContract/NewContractCreate/NewContractCreate';
import { AssistanceWorkerView } from '@/views/AssistanceWorker';
import { AbsenceView } from '@/views/NewMonth/Absence';




import { ScheduleView } from '@/views/Mainteiners/ScheduleCompany';
import { ScheduleWorkerView } from '@/views/NewMonth/ScheduleWorker';




const AllRoutes: React.FC = () => {

   const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);

   const moduleHome = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_HOME} component={HomeView} />,
   ];

   const moduleUser = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_USER} component={UsersView} />,
   ];
   const moduleWorker = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_WORKER} component={Workers} />,
   ];
   const modulBranchOffice = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_BRANCH_OFFICE} component={BranchOfficeView} />,
   ];
   const modulBranchOfficeTypes = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_BRANCH_OFFICE_TYPE} component={BranchOfficeTypesView} />,
   ];
   const modulCenterCost = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_COST_CENTER} component={CostCenterView} />,
   ];
   const modulDeparment = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_DEPARMENT} component={DeparmentView} />,
   ];
   const modulCharges = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_CHARGES} component={ChargeView} />,
   ];
   const modulProjects = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_PROJECT} component={ProjectView} />,
   ];
   const modulValueExtraHours = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_VALUE_EXTRA_HOURS} component={ValueExtraHoursView} />,
   ];
   const modulRequestType = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_REQUEST_TYPE} component={RequestTypeView} />,
   ];
   const moduleCompanies = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_COMPANIES} component={CompaniesView} />,
   ];
   const modulTurn = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_TURNS} component={TurnView} />,
   ];
   const moduleCompanySetting = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_COMPANY_SETTING} component={CompanySettingsView} />,
   ];
   const modulVariableGlobal = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_VARIABLE_GLOBAL} component={VariableGlobalView} />,
   ];
   const moduleWorkerContact = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_WORKER_CONTACT} component={WorkerContactView} />,
   ];
   const moduleWorkerAnnexed = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_ANNEXED} component={AnnexedView} />,
   ];
   const moduleTypeNotifications = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_TYPE_NOTIFICATIONS} component={NotificationsTypeView} />,
   ]; 
   const moduleTypeReminder = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_TYPE_REMINDER} component={ReminderTypeView} />,
   ];
   const moduleTimeZone = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_TIME_ZONE} component={TimeZoneView} />,
   ];
   const moduleStructureManteiner = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_STRUCTURE_MANTEINER} component={StructureMaintenerView} />,
   ];
 const moduleProducts = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_PRODUCTS} component={ProductsView} />,
   ];
   const modulePolitics = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_POLITICS} component={PoliticsView} />,
   ];
   const modulePoliticsManteiner = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_POLITICS_MASTER} component={PoliticsView} />,
   ];
   const moduleGroupCompany = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_GROUP_COMPANIES} component={GroupCompanyView} />,
   ];
   const moduleGroupCompaniesAll = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_GROUP_COMPANIES_ALL} component={GroupCompaniesAllView} />,
   ]
   const moduleSchedule = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_SCHEDULE} component={ScheduleView} />,
   ]

   //ubigeo
   const moduleCountry = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_COUNTRIES} component={CountryView} />,
   ];
   const moduleDepartament = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_DEPARTAMENT} component={DepartamentView} />,
   ];
   const moduleProvince = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_PROVINCE} component={ProvinceView} />,
   ];
   const moduleDistrict = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_DISTRICT} component={DistrictView} />,
   ];

   //novedades-mes
   const moduleAssistances = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_ASSISTANCES} component={AssistancesView} />,
   ];
   const moduleAbsence = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_ABSENCES} component={AbsenceView} />,
   ];
   const moduleExtraHours = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_EXTRA_HOURS} component={ExtraHoursView} />,
   ];
   const moduleDelay = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_ARREARS} component={DelayView} />,
   ];
   const moduleAdvances = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_ADVANCES} component={AdvancesView} />,
   ];
   const moduleMovements = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_MOVEMENTS} component={MovementsView} />,
   ];
   const moduleInventoryWorkers = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_INVENTORY_WORKERS} component={InventoryWorkersView} />,
   ];
   const moduleEmployeeExpense = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_EMPLOYEE_EXPENSE} component={EmployeeExpenseView} />,
   ];
	const moduleAdminExpense = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_EXPENSE_REQUESTS} component={AdminExpenseView} />,
   ];
   const moduleIpsAccept = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_IP_ACCEPT} component={IpsAcceptView} />,
   ];
   const moduleIpsDenied = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_IP_DENIED} component={IpsDeniedView} />,
   ];
   const moduleScheduleWorker = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_SCHEDULE_EMPLOYEE} component={ScheduleWorkerView} />,
   ]

   //liquidaciones
   const moduleLiquidationMasive = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_LIQUIDATION_MASIVE} component={LiquidationMasiveView} />,
   ];
   const PreviewLiquidationMasive = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_LIQUIDATION_MASIVE_PREVIEW} component={PreviewLiquidationMasiveView} />,
   ];
   const moduleLiquidationProject = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_LIQUIDATION_PROJECT} component={LiquidationProjectView} />,
   ];
   const moduleLiquidationSalary = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_LIQUIDATION_SALARY} component={LiquidationSalaryView} />,
   ];
   const moduleMyLiquidationSalary = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_MY_LIQUIDATION_SALARY} component={MyLiquidationSalaryView} />,
   ];
   const moduleLiquidationWorkers = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_ACTIVE_LIQUIDATION_WORKERS} component={SettlementActive} />,
   ];
   const MyRequestWorkers = [
      <PrivateRoute key={6} exact path={Routes.ROUTE_MY_REQUEST} component={MyRequestView} />,
   ];
   const moduleStructureLre = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_STRUCTURE_LRE} component={StructureLreView} />,
   ];
   const moduleTemplateMonthly = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_TEMPLATE_LIQUIDATION_MONTHLY} component={TemplateMonthlyView} />,
   ]
   const moduleCertificateEmployee = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_CERTIFICATE_EMPLOYEE_PDF} component={CertificateEmployeeView} />,
   ]

   const moduleMyLiquidationSalaryPDF = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_PDF_LIQUIDATION_SALARY} component={PreviewLiquidationRequestView} />,
   ]
   const modulePreviewAmountGeeneralPDF = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_PDF_AMOUNT_GENERAL} component={PreviewAmountGeneral} />,
   ]
   
   
   //custom view pdf
   const visualizerPDF = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_CUSTOM_VISUALIZER_PDF} component={PDFView} />,
   ];
   const customizeContract = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_CUSTOMIZE_CONTRACT} component={ContractEdit} />,
   ];
   const moduleContractDocument = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_DOCUMENT_CONTRACT} component={ContractDocumentsView} />,
   ];
   const moduleCreateContractDocument = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_CREATE_CONTRACT_DOCUMENT} component={NewContractCreate} />,
   ];

   //reclutamiento
   const moduleRecluitingTeam = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_RECRUITING_TEAM} component={RecluitingTeamView} />,
   ];
   const modulePhases = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_PHASES} component={PhasesView} />,
   ];
   const moduleAnnouncement = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_ANNOUNCEMENT} component={Announcement} />,
   ];
   const moduleProfile = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_PROFILES} component={ProfileView} />,
   ];
   const moduleApplicants = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_APPLICANTS} component={ApplicantsView} />,
   ];

   //reportes 
   const moduleReportEmployees = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_REPORT_EMPLOYEES} component={ReportEmployeesView} />,
   ];
   const moduleDelayAbsenceExtraHour = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_REPORT_WORKER_DELAY} component={DelayAbsenceExtraHourView} />,
   ];
   
   const moduleReportsLiquidations = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_REPORT_LIQUIDATIONS} component={ReportLiquidations} />,
   ];

   const moduleReportRequest = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_REPORT_REQUEST} component={RequestView} />,
   ];
   const moduleReportBills = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_REPORT_BILLS} component={BillsView} />,
   ];
   const moduleReportExpenses = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_REPORT_EXPENS} component={ReportExpenses} />,
   ];
   const moduleReportLiquidationProject = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_REPORT_LIQUIDATION_PROJECTS} component={ReportLiquidationByProject} />,
   ];
   const moduleReportProducts = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_REPORT_INVENTORY_PRODUCTS} component={ReportProducts} />,
   ];
   const moduleReportGroupCompany = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_REPORT_MULTI_COMPANY} component={ReportgGroupCompany} />,
   ];

   const modulePrueba = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_PRUEBA} component={CustomizeContract} />,
   ];

   //Identity Validations
   const moduleAdminValidation = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_ADMIN_VALIDATIONS} component={AdminValidationView} />,
   ];
   const moduleWorkerValidation = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_MY_VALIDATIONS} component={WorkerValidationView} />,
   ];

   // contratos
   const moduleWorkerContract = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_CONTRACT_EMPLOYEE} component={ContractEmployee} />,
   ];
//Assistance employee
   const moduleAssistanceWorker = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_ASSISTANCES_WORKER} component={AssistanceWorkerView} />,
   ];
   

   const routes = useMemo(() => {
      let role: string = 'prueba';
      //console.log(role)
      if (!!dataUser) {
         if (!!dataUser.user) {
            role = dataUser.user.role;
         }
      }

      switch (role) {
         case ROLE_SUPER_ADMIN:
            return (
               <Router>
                  <Switch>
                     <AuthRoute exact path={Routes.ROUTE_LOGIN} component={LoginView} />
                     {moduleCompanies}
                     {moduleCompanySetting}

                     {moduleHome}
                     {visualizerPDF}

                     {moduleUser}
                     {moduleWorker}
                     {moduleWorkerContact}
                     {modulBranchOffice}
                     {modulBranchOfficeTypes}
                     {modulCenterCost}
                     {modulDeparment}
                     {modulCharges}
                     {modulProjects}
                     {modulValueExtraHours}
                     {modulRequestType}
                     {MyRequestWorkers}
                     {modulTurn}
		               {moduleCompanies}
                     {modulVariableGlobal}
                     {moduleWorkerAnnexed}
                     {moduleTypeNotifications}
 		               {moduleTypeReminder}
                     {moduleTimeZone}
                     {moduleStructureManteiner}
                     {moduleGroupCompaniesAll}
                     {modulePoliticsManteiner}

                     {moduleCountry}
                     {moduleDepartament}
                     {moduleProvince}
                     {moduleDistrict}

                     {moduleAssistances}
                     {moduleAbsence}
                     {moduleExtraHours}
                     {moduleDelay}
                     {moduleAdvances}
                     {moduleMovements}
                     {moduleInventoryWorkers}
                     {moduleAdminExpense}
                     {moduleAdminValidation}

                     {moduleLiquidationMasive}
                     {moduleLiquidationProject}
                     {moduleLiquidationSalary}
                     {moduleMyLiquidationSalary}
                     {moduleLiquidationWorkers}
                     {moduleStructureLre}
                     {PreviewLiquidationMasive}
                     {moduleTemplateMonthly}
                     {moduleCertificateEmployee}
                     {moduleMyLiquidationSalaryPDF}
                     {modulePreviewAmountGeeneralPDF}

                     {moduleRecluitingTeam}
                     {modulePhases}
                     {moduleAnnouncement}
                     {moduleProfile}
                     {moduleApplicants}


                     {moduleReportRequest}
                     {moduleReportBills}
 		               {moduleDelayAbsenceExtraHour}
                     {moduleReportEmployees}

                     {moduleReportsLiquidations}
                     {moduleReportExpenses}

                     {moduleWorkerContract}
                     {customizeContract}


                  </Switch>
               </Router>
            );
         case ROLE_ADMIN:
            return (
               <Router>
                  <Switch>
                     <AuthRoute exact path={Routes.ROUTE_LOGIN} component={LoginView} />

                     {moduleCompanies}
                     {moduleCompanySetting}

                     {moduleHome}
                     {visualizerPDF}

                     {moduleUser}
                     {moduleWorker}
                     {moduleWorkerContact}
                     {modulBranchOffice}
                     {modulCenterCost}
                     {modulDeparment}
                     {modulCharges}
                     {modulProjects}
                     {modulValueExtraHours}
                     {modulRequestType}
                     {MyRequestWorkers}
                     {modulTurn}
		               {moduleCompanies}
                     {modulVariableGlobal}
                     {moduleWorkerAnnexed}
                     {moduleProducts}

                     {modulePolitics}
                     {modulePoliticsManteiner}
		    {moduleGroupCompany}//
                     {moduleSchedule}
                     {moduleScheduleWorker}

                     {moduleAssistances}
                     {moduleAbsence}
                     {moduleExtraHours}
                     {moduleDelay}
                     {moduleAdvances}
                     {moduleMovements}
                     {moduleInventoryWorkers}
                     {moduleAdminExpense}
                     {moduleAdminValidation}
                     {moduleIpsAccept}
                     {moduleIpsDenied}

                     {moduleLiquidationMasive}
                     {moduleLiquidationProject}
                     {moduleLiquidationSalary}
                     {moduleMyLiquidationSalary}
                     {moduleLiquidationWorkers}
                     {moduleStructureLre}
                     {PreviewLiquidationMasive}
                     {moduleTemplateMonthly}
                     {moduleCertificateEmployee}
                     {moduleMyLiquidationSalaryPDF}
                     {modulePreviewAmountGeeneralPDF}

                     {moduleRecluitingTeam}
                     {modulePhases}
                     {moduleAnnouncement}
                     {moduleProfile}
                     {moduleApplicants}


                     {moduleReportRequest}
                     {moduleReportBills}
 		               {moduleDelayAbsenceExtraHour}
                     {moduleReportEmployees}
                     {moduleReportLiquidationProject}
                     {moduleReportProducts}

                     {moduleReportsLiquidations}
                     {moduleReportExpenses}
 			{moduleReportGroupCompany}

                     {moduleWorkerContract}
                     {customizeContract}
                     {moduleCreateContractDocument}
                     {moduleContractDocument}

                     {<Route path='*' exact={true} component={() => {
                        return <Redirect to={Routes.ROUTE_COMPANIES} />
                     }} />}
                  </Switch>
               </Router>
            );
         case ROLE_TRABAJADOR:
            return (
               <Router>
                  <Switch>
                     <AuthRoute exact path={Routes.ROUTE_LOGIN} component={LoginView} />
                     {moduleHome}
                     {visualizerPDF}

                     {moduleEmployeeExpense}
                     {modulePolitics}
                     {moduleAssistances}
                     {moduleExtraHours}
                     {moduleDelay}
                     {moduleAdvances}
                     {moduleMovements}
                     {moduleInventoryWorkers}
                     {moduleMyLiquidationSalary}
                     {moduleStructureLre}
		     {MyRequestWorkers}
                     {moduleTemplateMonthly}
                     {moduleCertificateEmployee}
                     {moduleWorkerValidation} 
                     {moduleWorkerContract}
                     {moduleAssistanceWorker}

                     {<Route path='*' exact={true} component={() => {
                        return <Redirect to={Routes.ROUTE_MY_VALIDATIONS} />
                     }} />}
                  </Switch>
               </Router>
            );

         default:
            return (
               <Router>
                  <Switch>
                     {moduleHome}
                     {visualizerPDF}

                     {moduleUser}
                     {moduleWorker}
                     {modulBranchOffice}
                     {modulCenterCost}
                     {modulDeparment}
                     {modulCharges}
                     {modulProjects}
                     {modulValueExtraHours}
                     {modulRequestType}
                     {MyRequestWorkers}
                     {modulTurn}
                     {moduleTypeNotifications}
                     {moduleTypeReminder}
                     {moduleTimeZone}
                     {moduleStructureManteiner}
			{moduleGroupCompany}
                     {moduleGroupCompaniesAll}
                     {moduleSchedule}
                     {moduleScheduleWorker}

                     {moduleAssistances}
                     {moduleAbsence}
                     {moduleExtraHours}
                     {moduleDelay}
                     {moduleAdvances}
                     {moduleMovements}
                     {moduleInventoryWorkers}
                     {moduleAdminExpense}
                     {moduleAdminValidation}

                     {moduleLiquidationMasive}
                     {moduleLiquidationProject}
                     {moduleLiquidationSalary}
                     {moduleMyLiquidationSalary}
                     {moduleLiquidationWorkers}
                     {moduleStructureLre}
                     {PreviewLiquidationMasive}
                     {moduleTemplateMonthly}
                     {moduleCertificateEmployee}

                     {moduleRecluitingTeam}
                     {/* {modulePhases} */}
                     {moduleAnnouncement}
                     {moduleProfile}
                     {moduleApplicants}


                     {moduleReportRequest}
                     {moduleReportBills}
 		     {moduleDelayAbsenceExtraHour}
                     {moduleReportEmployees}
                     {moduleReportsLiquidations}
                     {moduleReportExpenses}

                     {moduleEmployeeExpense}

                     {moduleWorkerContract}

                     {moduleCompanies}
                     {moduleCompanySetting}
                     {customizeContract}
                     {moduleWorkerValidation}
                     {moduleAssistanceWorker}

                     <AuthRoute exact path={Routes.ROUTE_LOGIN} component={LoginView} />
                     {<Route path='*' exact={true} component={() => {
                        return <Redirect to={Routes.ROUTE_LOGIN} />
                     }} />}
                  </Switch>
               </Router>
            )
      }
   },// eslint-disable-next-line
      [JSON.stringify(dataUser)]);

   return routes;
}

export default AllRoutes;

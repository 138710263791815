import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Alert, Backdrop, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { costCentersRepository } from '@/service/repositories/CostCenters.repository';
import { Loading } from '@/components/common/Loading';
import { Props } from './ValueExtraHours.type';


export const ValueExtraHoursView: React.FC<Props> = (props:any): JSX.Element => {

   const [loading, setLoading] = useState<boolean>(false);
   const [costCenters, setCostCenters] = useState([]);
   const [showCostCenterModal, setShowCostCenterModal] = useState<boolean>(false);
   const [showCostCenterDeleteModal, setShowCostCenterDeleteModal] = useState<boolean>(false);
   const [actionSelected, setActionSelected] = useState<String>('');
   const [costCenterSelected, setCostCenterSelected] = useState<String>('');

   useEffect(() => {
      getCenterCostos()
   },[]);

   const getCenterCostos = async () => {
      setLoading(true);
      const response = await costCentersRepository.getCostCenters();
      if (response.data) {
         setCostCenters(response.data || [])
      } else {
         console.log('error')
      }
      setLoading(false);
   }

   function RecuperarData(value){
      console.log(value)
   }

  return (
      <Protected>
         {loading && <Loading/>}
         <Container>
            <TableDataV2
               title={'Centro de Costos'}
               data={costCenters}
               header={[
                  { name: 'name', label: 'Nombre', filter: false, Chip: true },
                  { name: 'description', label: 'Descripcion', filter: false, Chip: false },
               ]}
               actionSelect={setActionSelected}
               setModalSave={setShowCostCenterModal}
               setModalDelete={setShowCostCenterDeleteModal}
               status_action
               // checkbox
               RecuperarData={RecuperarData}
            />
         </Container>
      
      </Protected>
   )
};
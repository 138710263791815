import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Button, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { AssistanceService } from "@/service/services/Assistance.service";
import { Props } from "./ModalContractValidation.type";
import { WorkerValidationService } from "@/service/services/WorkerValidation.service";
import { ContractService } from "@/service/services/Contract.service";

export const ModalContractValidation: React.FC<Props> = (props: Props): JSX.Element => {

    const { open, setOpen, createSignature } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [imageDoc, setImageDoc] = useState<any>("" || undefined);

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [data, setData] = useState({
        idtypesignature: null,
        img: ''
    });

    const [typeSignature, setTypeSignature] = useState<any>([])

    const validateForm = (values) => {

    }

    const setNameFile = (event) => {
        const files = event.target && event.target.files || [];
        const fileSignature = files[0];

        if (fileSignature) {

            const nameAsArray = fileSignature.name.split(".") || [];
            const extension = nameAsArray.slice(-1)
            const nameWithoutExtension = nameAsArray.filter((word, i) => i != nameAsArray.length - 1);

            if (extension == 'jpg' || extension == 'JPG' || extension == 'jpeg' ||
                extension == 'JPEG' || extension == 'png' || extension == 'PNG') {
                setImageDoc(fileSignature) 
                return fileSignature
            } else {
                return (
                    setSnackBarConfig(prev => ({
                        ...prev, open: true,
                        severity: 'warning',
                        message: `El formato de imagen no es correcta`
                    }
                    ))
                );
            }
        }
        return "";
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!imageDoc) {
            setLoading(false)
            return (
                setSnackBarConfig(prev => ({
                    ...prev, open: true,
                    severity: 'warning',
                    message: `Debe subir la imagen correspondiente`
                }
                ))
            );
        }
        console.log(data)
        if(!data.idtypesignature){
            setLoading(false)
            return (
                setSnackBarConfig(prev => ({
                    ...prev, open: true,
                    severity: 'warning',
                    message: `Elija un tipo de firma`
                }
                ))
            );
        }

        const formData = new FormData();
        formData.append("idtypesignature", data.idtypesignature);
        formData.append("img", imageDoc);
        const respSig  = await createSignature(formData);
        if(respSig?.data){
            setSnackBarConfig(prev => ({
                ...prev, open: true,
                severity: 'success',
                message: `Firma enviada con éxito, pendiente de validacion`
            }
            ))
        }else{
            setSnackBarConfig(prev => ({
                ...prev, open: true,
                severity: 'warning',
                message: `Ocurrio un problema`
            }
            ))
        }
        setLoading(false);
        setOpen(false)
    }

    const showDocument = (image) => {
        setLoading(true);

        if (image) {
            window.open(image, '_blank');
        }

        setLoading(false);
    }

    const getTypeSignature = async() => {
        const res = await ContractService.getTypeSignature();
        setTypeSignature(res?.data || [])
    }

    useEffect(()=>{
        getTypeSignature();
    },[])


    return (
        <>
            {loading && <Loading />}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>

            <Modal open={open} handleClose={() => setOpen(false)} handle size="sm">
                <ModalHeader
                    text={"Enviar Firma"}
                    className='positionElements'
                    onCancel={() => setOpen(false)}
                >
                </ModalHeader>
                <ModalBody>

                    <form onSubmit={handleSubmit}>
                        <Grid container >
                        <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                                <Typography variant="body1"><b>Tipo de Firma:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Autocomplete
                                                    id="idtypesignature"
                                                    // value={name}
                                                    options={typeSignature || []}
                                                    getOptionLabel={(option:any) => option.name || ""}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} variant="standard" label="Escriba para buscar (*)" />}
                                                    onChange={(e:any, newValue) => {console.log(newValue); setData({...data, idtypesignature: newValue.id}) }}
                                                />
                                            </Grid>
                                        </Grid>

                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1" className="custom-input"><b>Imagen:</b></Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Button variant="contained" component="label" fullWidth>
                                        <input
                                            id="imgDoc"
                                            type="file"
                                            name="imgDoc"
                                            onChange={(event) => {
                                                setNameFile(event);
                                            }}
                                        />
                                    </Button>
                                </Grid>
                            </Grid>

                            


                        </Grid>
                        <Divider />

                        <ModalFooter
                            buttonType="submit"
                            confirmText={"ENVIAR"}
                            // cancelText="CANCELAR" 
                            // onCancel={closeModal}
                            onConfirm={handleSubmit}
                        />


                    </form>


                </ModalBody>
            </Modal>
        </>
    );
}
import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);
const rut_empresa = dataUser?.user?.rut_empresa;

export const EmployeeExpenseRepository = {

    getExpensesByEmployeeSession: async(status:number, period:number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/expenseReports/myList?status=${status}&idperiod=${period}`);
        const {data, error, message} = res;
        return { data, error, message };
    },
    
    getDetailsExpense: async(idExpense:any, review_status:any, report_type:any, status:any): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/expenseReports/indexDetailsAdmin/${idExpense}?review_status=${review_status}&report_type=${report_type}&status=${status}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    verifyExpenseEmployee: async(year:any, month:any): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/expenseReports/verifyReportSlope?year=${year}&month=${month}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    listReportType: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/expenseReports/listReportType`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createExpenseEmployee: async (dataExpense) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/expenseReports/create`, dataExpense);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createDetailExpenseEmployee: async (idExpense:number, dataExpense) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/expenseReports/createDetail/${idExpense}`, dataExpense);
        const {data, error, message} = res;
        return { data, error, message };
    },

    editReportDetail: async (idDetail:number, dataExpense) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/expenseReports/editReportDetail/${idDetail}`, dataExpense);
        const {data, error, message} = res;
        return { data, error, message };
    },

    deleteExpenseEmployee: async(idExpense:number): Promise<any> => {
        const res= await http.delete<any>(`${API_URL_BASE}/v1/expenseReports/delete/${idExpense}`)
        return res;
    },
}   
import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Container } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { Loading } from '@/components/common/Loading';
import { Props } from './TypeReminder.type';
import { ReminderTypeService } from '@/service/services/ReminderTypee.service';
import { ModalReminderType } from '@/components/Modal/ModalTypeReminder';
import { ModalReminderTypeDelete } from '@/components/Modal/ModalTypeReminderDelete';

export const ReminderTypeView: React.FC<Props> = (props:any): JSX.Element => {

   const [loading, setLoading] = useState<boolean>(false);
   const [reminderTypes, setReminderTypes] = useState([]);
   const [showReminderTypeModal, setShowReminderTypeModal] = useState<boolean>(false);
   const [showReminderTypeDeleteModal, setShowReminderTypeDeleteModal] = useState<boolean>(false);
   const [actionSelected, setActionSelected] = useState<String>('');
   const [reminderTypeSelected, setReminderTypeSelected] = useState<String>('');

   useEffect(() => {
    getReminderTypes()
   },[]);

   const getReminderTypes = async () => {
      setLoading(true);
      const response = await ReminderTypeService.getReminderTypes();
      if (response.data) {
        setReminderTypes(response.data || [])
      } else {
         console.log('error')
      }
      setLoading(false);
   }

   function RecuperarData(value){
      console.log(value);
      setReminderTypeSelected(value);
   }

  return (
      <Protected>
         {loading && <Loading/>}
         <Container>
            <TableDataV2
               title={'Tipos de recordatorios'}
               data={reminderTypes}
               header={[
                  { name: 'name', label: 'Nombre', filter: false, Chip: true }
               ]}
               actionSelect={setActionSelected}
               setModalSave={setShowReminderTypeModal}
               setModalDelete={setShowReminderTypeDeleteModal}
               status_action
               // checkbox
               RecuperarData={RecuperarData}
            />
         </Container>

         { 
            showReminderTypeModal && (
               <ModalReminderType
                  open={showReminderTypeModal}
                  closeModal={()=>{setShowReminderTypeModal(false)}}
                  actionSelected={actionSelected}
                  onListReminderType={getReminderTypes}
                  reminderType={reminderTypeSelected}
                  clearState={()=>{setReminderTypeSelected('')}}
               />
            )
         }
         {
            actionSelected === 'delete' && (
               <ModalReminderTypeDelete
                  open={showReminderTypeDeleteModal}
                  closeModal={()=>{setShowReminderTypeDeleteModal(false)}}
                  actionSelected={actionSelected}
                  onListReminderType={getReminderTypes}
                  reminderType={reminderTypeSelected}
                  clearState={()=>{setReminderTypeSelected('')}}
               />
            )
         }
      
      </Protected>
   )
};
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Autocomplete, Divider, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { PhasesService } from "@/service/services/Phases.service";
import { Loading } from "@/components/common/Loading";
import { AssistanceService } from "@/service/services/Assistance.service";
import { Props } from "./ModalRequestType.type";
import { requestTypeService } from "@/service/services/RequestType.service";

export const ModalRequestType: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, requestType, onListRequestType, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);

    const [data, setData] = useState({
        id: requestType ? requestType.id : '',
        name: requestType ? requestType.name : '',
    });

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.name) errors.name = "nombre requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            id: values.id,
            name : values.name,
        }
        setLoading(true);
        if(requestType && actionSelected=='edit'){
            await requestTypeService.editRequestType(formData, requestType.id).then((data) => {
                onListRequestType && onListRequestType();
                closeModal && closeModal();
            })
        }else{
            await requestTypeService.createRequestType(formData).then((data) => {
                onListRequestType && onListRequestType();
                closeModal && closeModal();
            })
        }
        setLoading(false);
    }
    return(
        <>
        {loading && <Loading/>}

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={(requestType ? "editar tipo de solicitud" : "agregar tipo de solicitud")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Nombre:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="name"
                                            variant="standard"
                                            type="text" 
                                            name="name"
                                            fullWidth
                                            value={values.name}
                                            onChange={handleChange}
                                            placeholder="nombre"
                                            error={errors.name && touched.name ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(requestType && actionSelected=='edit') ? "ACTUALIZAR" : "REGISTRAR"}
                                    cancelText="CANCELAR" 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}

import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Button, Card, Grid, MenuItem, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Loading } from "@/components/common/Loading";
import { companiesService } from '@/service/services/Companies.service';
import { Props } from './IpSetting.type';
import { ConfigurationIPService } from '@/service/services/ConfigurationIP.service';

export const IpSetting: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, dataIP, onListDataIP, onListDataCompany,  clearState, dataCompany } = props;

    const [loading, setLoading] = useState<boolean>(false);
    

    const [data, setData] = useState({
        id: dataIP ? dataIP.idcompany : '',
        start_access: dataIP ? dataIP.start_access : '',
        end_access: dataIP ? dataIP.end_access : '',
        ip_access_request: dataCompany ? dataCompany.ip_access_request : '',
    });

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.start_access && values.ip_access_request == '2') errors.start_access = "acceso requerido";
        if(!values.end_access && values.ip_access_request == '2') errors.end_access = "acceso requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            start_access : values.ip_access_request == '2' ? values.start_access : null,
            end_access : values.ip_access_request == '2' ? values.end_access : null,
            ip_access_request : values.ip_access_request,
        }
        setLoading(true);
        await ConfigurationIPService.editSettingIP(formData).then((data) => {
            onListDataCompany && onListDataCompany();
            onListDataIP && onListDataIP();
            closeModal && closeModal();
        })
        setLoading(false);
    }

    return(
        <>
        {loading && <Loading/>}

        
            <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                    return(
                        <form onSubmit={handleSubmit}>
                            <Card sx={{ width: "100%", marginTop: '20px' }}>
                                <TableContainer>
                                    <Table>
                                        <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                            <TableRow >
                                                <TableCell >
                                                    <Typography>Configuración de Acceso por IP</Typography>
                                                </TableCell>
                                                <TableCell >
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>

                                            <TableRow >
                                                <TableCell size={'small'} sx={{ bgcolor: '#F4F6F8', width: '30%' }}>
                                                    Inicio de Acceso
                                                </TableCell>
                                                <TableCell >
                                                    <Select
                                                        id="ip_access_request"
                                                        name='ip_access_request'
                                                        variant="standard"
                                                        value={values.ip_access_request}
                                                        onChange={(event) => { 
                                                        setFieldValue("ip_access_request", event.target.value);
                                                        }}
                                                        sx={{ width: '500px' }}
                                                    >
                                                    <MenuItem key={0} value={0}>No tiene restricción por horario</MenuItem>;
                                                    <MenuItem key={1} value={1}>Tiene restricción por ip, pero no por horario</MenuItem>;
                                                    <MenuItem key={2} value={2}>Tiene restricción por ip y  por horario, de acuerdo a la empresa</MenuItem>;
                                                    <MenuItem key={3} value={3}>Tiene restricción por ip y por horario, de acuerdo al trabajador</MenuItem>;
                                                        
                                                    </Select>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow >
                                                <TableCell size={'small'} sx={{ bgcolor: '#F4F6F8', width: '30%' }}>
                                                    Inicio de Acceso
                                                </TableCell>
                                                <TableCell >
                                                    <TextField
                                                        id="start_access"
                                                        variant="standard"
                                                        sx={{ width: '500px' }}
                                                        name="start_access"
                                                        type="time"
                                                        onChange={handleChange}
                                                        value={values.start_access}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={errors.start_access && touched.start_access ? true : false}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell size={'small'} sx={{ bgcolor: '#F4F6F8', width: '30%' }}>
                                                    Fin de Acceso
                                                </TableCell>
                                                <TableCell >
                                                    <TextField
                                                        id="end_access"
                                                        variant="standard"
                                                        sx={{ width: '500px' }}
                                                        name="end_access"
                                                        type="time"
                                                        onChange={handleChange}
                                                        value={values.end_access}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={errors.end_access && touched.end_access ? true : false}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Grid sx={{ textAlign: 'center', margin: '10px' }}>
                                    <Button color="primary" variant="contained" onClick={() => {handleSubmit()}}>
                                        ACTUALIZAR
                                    </Button>
                                </Grid>
                            </Card>
                        </form>
                    )
                }}
            </Formik>
        </>
    );
}
import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from '@/components/common/Loading';
import { Alert, Autocomplete, Backdrop, Button, Select, Card, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { ReportService } from '@/service/services/Report.service';
import { WorkerService } from '@/service/services/Worker.service';
import { readLocalStorage, saveLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_RUTA, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { PeriodService } from "@/service/services/Period.service";
import { moneyFormat } from '@/toolbox/helpers/money.helper';
import { API_URL_BASE } from '@/toolbox/defaults/app';


export const ReportgGroupCompany: React.FC<any> = (props: any): JSX.Element => {

    const [data, setData] = useState<any>([]);

    const [periods, setPeriods] = useState<any>([]);
    const [years, setYears] = useState<any>([]);
    const [yearSelected, setYearSelected] = useState<any>('');
    const [monthSelected, setMonthSelected] = useState<any>('');

    const [rutasHeader, setRutasHeader] = React.useState([]);
    const [excelDissable, setExcelDissable]= useState<any>(false);
 
    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const userData = readLocalStorage(KEY_USER_DATA);

    useEffect(() => {
        getPeriods();
    }, []);

    const getPeriods = async () => {
        setLoading(true);
        const resp: any = await PeriodService.getPeriods();
        const newData = [];
        const arrayYears = [];
        resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
            newData.push({
                id: item.id,
                month: item.month,
                year: item.year,
                alias_name: item.month + '-' + item.year,
            })
            const found = arrayYears.find(element => element == item.year);
            if (!found) { arrayYears.push(item.year); }

        });
        setYears(arrayYears);
        setPeriods(newData || []);
        setLoading(false);
    }
    const limpiarData = () => {
        
    }
    const showLiquidationsReport = async () => {
        limpiarData();
        setLoading(true);
        if (!monthSelected || !yearSelected) {
            setLoading(false);
            return (
                setSnackBarConfig(prev => ({
                    ...prev, open: true,
                    severity: 'warning',
                    message: `Debe seleccionar Mes y Año.`
                }
                ))
            );
        }

        const periodSelected = monthSelected + '-' + yearSelected;
        const period = periods.find(element => element.alias_name == periodSelected);
        const dataPre = {
            idperiod: period.id,
            month: period.month,
            year: period.year
        }
        await ReportService.getReportGroupCompany(dataPre.idperiod, userData?.user.iduser).then((resp) => {
            setData(resp.data || []);
            if((resp.data).length > 0) setExcelDissable(true);
            setLoading(false);
        })

    }

    const getReportGroupCompanyExcel = async() => {
        setLoading(true);
        if (!monthSelected || !yearSelected) {
            setLoading(false);
            return (
                setSnackBarConfig(prev => ({
                    ...prev, open: true,
                    severity: 'warning',
                    message: `Debe seleccionar Mes y Año.`
                }
                ))
            );
        }

        const periodSelected = monthSelected + '-' + yearSelected;
        const period = periods.find(element => element.alias_name == periodSelected);

        await ReportService.getReportGroupCompanyExcel(period.id, userData?.user.iduser).then((resp) => {

            const btn_excel = document.createElement("a")
            btn_excel.href = `${API_URL_BASE}${resp.data.link}`

            btn_excel.download = 'MultiCompanyExport.xlsx'

            document.body.appendChild(btn_excel);
            btn_excel.click();
            document.body.removeChild(btn_excel)
            setLoading(false);
        })

    }

    return (
        <Protected>
            {loading && <Loading />}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>
            <Container>
                <Typography variant='h6' component='h1' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                    REPORTE DE GRUPO DE EMPRESAS
                </Typography>
                <Grid item xs={12} spacing={2} container direction={'row'} justifyContent='center' alignItems={'center'} marginTop='15px'>
                    <Grid item xs={12} md={4} display='flex' alignItems="center" spacing={2}>
                        <Typography variant="body1" style={{ marginRight: '10px' }}><b>Período:</b> </Typography>
                        <Select
                            id="id_education_level"
                            variant="standard"
                            value={yearSelected}
                            onChange={(event) => {
                                setYearSelected(event.target.value);
                            }}
                            fullWidth
                        >
                            {
                                years.map((year, i) => {
                                    return <MenuItem key={i} value={year}>{year}</MenuItem>;
                                })
                            }
                        </Select>
                    </Grid>
                    <Grid item xs={12} md={4} display='flex' alignItems="center">
                        <Typography style={{ marginRight: '10px' }} variant="body1"><b>Mes:</b> </Typography>
                        <Select
                            id="id_education_level"
                            variant="standard"
                            value={monthSelected}
                            onChange={(event) => { setMonthSelected(event.target.value) }}
                            fullWidth
                        >
                            <MenuItem value={'01'}>Enero</MenuItem>
                            <MenuItem value={'02'}>Febrero</MenuItem>
                            <MenuItem value={'03'}>Marzo</MenuItem>
                            <MenuItem value={'04'}>Abril</MenuItem>
                            <MenuItem value={'05'}>Mayo</MenuItem>
                            <MenuItem value={'06'}>Junio</MenuItem>
                            <MenuItem value={'07'}>Julio</MenuItem>
                            <MenuItem value={'08'}>Agosto</MenuItem>
                            <MenuItem value={'09'}>Septiembre</MenuItem>
                            <MenuItem value={'10'}>Octubre</MenuItem>
                            <MenuItem value={'11'}>Noviembre</MenuItem>
                            <MenuItem value={'12'}>Diciembre</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} md={4} >
                        <Button variant="contained" onClick={showLiquidationsReport}>
                            Filtrar
                        </Button>
                    </Grid>
                    {excelDissable &&
                        <Grid container spacing={3} sx={{ marginTop: '10px', marginBottom: '20px' }}>
                            <Grid item xs={12} md={12} display='flex' justifyContent='flex-end'>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={getReportGroupCompanyExcel}
                                >
                                    Descargar excel
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Grid>

                {/* <Typography variant='subtitle1' component='h4' gutterBottom sx={{ m: 1, fontWeight: 600, width: "100%", marginTop: '20px' }}>
                    Se encontraron empleados registrados
                </Typography> */}
{/* 
                <Button variant="contained" onClick={()=>{getExcelReport()}}>
                        Descargar Excel
                    </Button> */}

                <Card sx={{ width: "100%", marginTop: '20px' }}>
                    <TableContainer>
                        <Table>
                            <TableHead sx={{ bgcolor: '#EDEFF1' }}>

                                <TableRow >
                                    <TableCell >
                                        <Typography>RUT</Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography>Empresa</Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography>Cantidad Empleados</Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography>Pago AFP</Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography>Pago Salud</Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography>Pago Total</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.map(row => {
                                    return (
                                        <TableRow >
                                             <TableCell >
                                                {row.company.registry_number}
                                            </TableCell>
                                            <TableCell >
                                                {row.company.business_name}
                                            </TableCell>
                                            <TableCell >
                                                {row.countEmployees}
                                            </TableCell>
                                            <TableCell >
                                                {moneyFormat(row.totalAFP)}
                                            </TableCell>
                                            <TableCell >
                                                {moneyFormat(row.totalHealt)}
                                            </TableCell>
                                            <TableCell >
                                                {moneyFormat(row.totalPayment)}
                                            </TableCell>
                                        </TableRow >
                                    )
                                })}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </Container>
        </Protected>
    )
};
import { responsabilityFamilyRepository } from "../repositories/ResponsabilityFamily.repository";

export const responsabilityFamilyService = {
   getResponsabilityFamily,
   postCreateResponsabilityFamily,
   getDataInitial,
};
async function getResponsabilityFamily (id_employee) {
   const resp= await responsabilityFamilyRepository.getResponsabilityFamily(id_employee);
   return resp
}

async function postCreateResponsabilityFamily(data){
    const resp = await responsabilityFamilyRepository.postCreateResponsabilityFamily(data);
    return resp
}

async function getDataInitial(){
   const resp = await responsabilityFamilyRepository.getDataInitial();
   return resp
}



import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Autocomplete, Button, Divider, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { PhasesService } from "@/service/services/Phases.service";
import { Loading } from "@/components/common/Loading";
import { AssistanceService } from "@/service/services/Assistance.service";
import { Props } from "./ModalPolitics.type";
import { requestTypeService } from "@/service/services/RequestType.service";
import { PoliticService } from "@/service/services/Politic.service";
import { API_URL_BASE } from "@/toolbox/defaults/app";
import { KEY_RUTA, KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_TRABAJADOR } from "@/toolbox/defaults/static-roles";

export const ModalPolitics: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, politic, onListPolitics, clearState } = props;
    const userData = readLocalStorage(KEY_USER_DATA)
    const role = userData.user.role
    const route = readLocalStorage(KEY_RUTA)
    const [loading, setLoading] = useState<boolean>(false);

    const [data, setData] = useState({
        idtypedocument: politic ? politic.idtypedocument: '',
        pdf: politic ? politic.pdf :'',
    });

    const [currentPDF, setCurrentPDF] = useState<any>(null) 

    const [typeDocuments, setTypeDocuments] = useState<any>([]);
    const [typeDocumentsSelected, setTypeDocumentsSelected] = useState<any>(null)
    const validateForm = (values) => {
        let errors: any = {};
        // if(!values.pdf) errors.name = "nombre requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        if(!currentPDF){
            return alert("Seleccione un archivo");
        }
        let type;
        if(role == ROLE_SUPER_ADMIN){type = '1'}
        if(role == ROLE_ADMIN){
           if( route == 'Politicas y Privacidad'){type = '1'}else{type = '0'}
        }
        if(role == ROLE_TRABAJADOR){type = '0'}
        const formData = new FormData();
        formData.append("idtypedocument", typeDocumentsSelected.id);
        formData.append("PDF", currentPDF)
        formData.append("type", type)
        setLoading(true);
        if(politic && actionSelected=='edit'){
            await PoliticService.editDocuments( politic.id, formData).then((data) => {
                onListPolitics && onListPolitics();
                closeModal && closeModal();
            })
        }else{
            await PoliticService.uploadDocuments(formData).then((data) => {
                onListPolitics && onListPolitics();
                closeModal && closeModal();
            })
        }
        setLoading(false);
    }

    const setNameFile = (event) => {
        const files = event.target && event.target.files || [];
        const fileSignature = files[0];

        if (fileSignature) {

            const nameAsArray = fileSignature.name.split(".") || [];
            const extension = nameAsArray.slice(-1)
            const nameWithoutExtension = nameAsArray.filter((word, i) => i != nameAsArray.length - 1);

            if (extension == 'pdf' ) {
                setCurrentPDF(fileSignature) 
                return fileSignature
            } else {
                return (
                    null
                );
            }
        }
        return "";
    };

    const getTypeDocuments = async() => {
        const resp = await PoliticService.getTypeDocuments();
        setTypeDocuments(resp.data);
        if(actionSelected == 'edit'){
            const docsFind = resp.data.find((value)=> value.id == politic.idtypedocument);
            setTypeDocumentsSelected(docsFind)
        }
    }

    useEffect(()=>{
        getTypeDocuments();
    },[])
    return(
        <>
        {loading && <Loading/>}

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={(politic ? "Editar Documento" : "Agregar Documento")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                                <Typography variant="body1"><b>tipo de Documento:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Autocomplete
                                                    id="disable-portal"
                                                    disablePortal
                                                    value={typeDocumentsSelected}
                                                    options={typeDocuments || []}
                                                    getOptionLabel={(option) => option.name || ""}
                                                    fullWidth
                                                    disabled={actionSelected=='edit'}
                                                    renderInput={(params) => <TextField {...params} variant="standard" label="Escriba para buscar (*)" />}
                                                    onChange={(e, newValue) => { setTypeDocumentsSelected(newValue) }}
                                                />
                                            </Grid>
                                        </Grid>
                                    { actionSelected == 'edit' && <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1" className="custom-input"><b>Ver PDF:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Button variant="contained" component="label" fullWidth >
                                                <a href={`${API_URL_BASE}/${politic.route}`} target='_blank' style={{textDecoration:'none', color:'#fff'}}> Ver PDF</a>
                                            </Button>
                                        </Grid>
                                    </Grid> }
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1" className="custom-input"><b>Cargar PDF:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Button variant="contained" component="label" fullWidth>
                                                <input
                                                    id="pdf"
                                                    type="file"
                                                    name="pdf"
                                                    onChange={(event) => {
                                                        setFieldValue("pdf", setNameFile(event));
                                                    }}
                                                />
                                            </Button>
                                        </Grid>
                                    </Grid> 
                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(politic && actionSelected=='edit') ? "ACTUALIZAR" : "REGISTRAR"}
                                    cancelText="CANCELAR" 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}
import { chargesRepository } from "../repositories/Charge.repository";

export const ChargeService = {
    getChargesCompany,
    createCharge,
    editCharge,
    deleteCharge,
    getChargesResponsability,
    createChargeResponsability,
    editChargeResponsability,
    deleteChargeResponsability
}

async function getChargesCompany(){
    const res = await chargesRepository.getChargesCompany();
    return res;
}
async function createCharge(data:{}){
    const res = await chargesRepository.createCharge(data);
    return res;
}

async function editCharge( idCharge ,data:{}){
    const res = await chargesRepository.editCharge(idCharge, data);
    return res;
}

async function deleteCharge( idCharge){
    const res = await chargesRepository.deleteCharge(idCharge);
    return res;
}

async function getChargesResponsability(id){
    const res = await chargesRepository.getChargesResponsability(id);
    return res;
}
async function createChargeResponsability(data:{}){
    const res = await chargesRepository.createChargeResponsability(data);
    return res;
}

async function editChargeResponsability( idCharge ,data:{}){
    const res = await chargesRepository.editChargeResponsability(idCharge, data);
    return res;
}

async function deleteChargeResponsability( idCharge){
    const res = await chargesRepository.deleteChargeResponsability(idCharge);
    return res;
}

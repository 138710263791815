import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Alert, Backdrop, CircularProgress, Container, Divider, Grid, IconButton, Menu, Snackbar, Typography,Button, Box, Card} from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { TableBody, Select, FormControl, InputLabel, MenuItem, TextField, TableCell, TableContainer, TableHead, TablePagination, TableRow, Table, createTheme, ThemeProvider } from '@mui/material';
import {useHistory} from 'react-router-dom'
import * as Routes from '@constants/route-map';
import { PeriodService } from '@/service/services/Period.service';
import { RemunerationService } from '@/service/services/Remuneration.service';
import { TableData } from '@/components/common/Table';
import { Props } from './PreviewEbook.type';
import { templateRemunerationMonthlyService } from '@/service/services/TemplateRemunerationMonthly.service';

const cabecera = [
    { name: 'Rut trabajador (cód 1101)', label: 'Rut trabajador (cód 1101)', width: '200px' },
    { name: 'Fecha inicio contrato (cód 1102)', label: 'Fecha inicio contrato (cód 1102)', width: '200px' },
    { name: 'Fecha de término de contrato (cód 1103)', label: 'Fecha de término de contrato (cód 1103)', width: '200px' },
    { name: 'Causal de término del contrato (cód 1104)', label: 'Causal de término del contrato (cód 1104)', width: '200px' },
    { name: 'Región de prestación de los servicios (cód 1105)', label: 'Región de prestación de los servicios (cód 1105)', width: '200px' },
    { name: 'Comuna de prestación de los servicios (cód 1106)', label: 'Comuna de prestación de los servicios (cód 1106)', width: '200px' },
    { name: 'Tipo de impuesto a la renta (cód 1170)', label: 'Tipo de impuesto a la renta (cód 1170)', width: '200px' },
    { name: 'Técnico extranjero exención de cotizaciones previsionales (Ley 18.156) (cód 1146)', label: 'Técnico extranjero exención de cotizaciones previsionales (Ley 18.156) (cód 1146)', width: '200px' },
    { name: 'Código tipo de jornada (cód 1107)', label: 'Código tipo de jornada (cód 1107)', width: '200px' },
    { name: 'Persona con discapacidad/pensionado por invalidez (cód 1108)', label: 'Persona con discapacidad/pensionado por invalidez (cód 1108)', width: '200px' },
    { name: 'Pensionado por vejez (cód 1109)', label: 'Pensionado por vejez (cód 1109)', width: '200px' },
    { name: 'AFP (cód 1141)', label: 'AFP (cód 1141)', width: '200px' },
    { name: 'IPS (ExINP) (cód 1142)', label: 'IPS (ExINP) (cód 1142)', width: '200px' },
    { name: 'FONASA / ISAPRE (cód 1143)', label: 'FONASA / ISAPRE (cód 1143)', width: '200px' },
    { name: 'AFC (cód 1151)', label: 'AFC (cód 1151)', width: '200px' },
    { name: 'CCAF (cód 1110)', label: 'CCAF (cód 1110)', width: '200px' },
    { name: 'Org. administrador ley 16.744 (cód 1152)', label: 'Org. administrador ley 16.744 (cód 1152)', width: '200px' },
    { name: 'Número cargas familiares legales autorizadas (cód 1111)', label: 'Número cargas familiares legales autorizadas (cód 1111)', width: '200px' },
    { name: 'Número de cargas familiares maternales (cód 1112)', label: 'Número de cargas familiares maternales (cód 1112)', width: '200px' },
    { name: 'Número de cargas familiares invalidez (cód 1113)', label: 'Número de cargas familiares invalidez (cód 1113)', width: '200px' },
    { name: 'Tramo asignación familiar (cód 1114)', label: 'Tramo asignación familiar (cód 1114)', width: '200px' },
    { name: 'Rut org sindical 1 (cód 1171)', label: 'Rut org sindical 1 (cód 1171)', width: '200px' },
    { name: 'Rut org sindical 2 (cód 1172)', label: 'Rut org sindical 2 (cód 1172)', width: '200px' },
    { name: 'Rut org sindical 3 (cód 1173)', label: 'Rut org sindical 3 (cód 1173)', width: '200px' },
    { name: 'Rut org sindical 4 (cód 1174)', label: 'Rut org sindical 4 (cód 1174)', width: '200px' },
    { name: 'Rut org sindical 5 (cód 1175)', label: 'Rut org sindical 5 (cód 1175)', width: '200px' },
    { name: 'Rut org sindical 6 (cód 1176)', label: 'Rut org sindical 6 (cód 1176)', width: '200px' },
    { name: 'Rut org sindical 7 (cód 1177)', label: 'Rut org sindical 7 (cód 1177)', width: '200px' },
    { name: 'Rut org sindical 8 (cód 1178)', label: 'Rut org sindical 8 (cód 1178)', width: '200px' },
    { name: 'Rut org sindical 9 (cód 1179)', label: 'Rut org sindical 9 (cód 1179)', width: '200px' },
    { name: 'Rut org sindical 10 (cód 1180)', label: 'Rut org sindical 10 (cód 1180)', width: '200px' },
    { name: 'Número días trabajados en el mes (cód 1115)', label: 'Número días trabajados en el mes (cód 1115)', width: '200px' },
    { name: 'Número días de licencia médica en el mes (cód 1116)', label: 'Número días de licencia médica en el mes (cód 1116)', width: '200px' },
    { name: 'Número días de vacaciones en el mes (cód 1117)', label: 'Número días de vacaciones en el mes (cód 1117)', width: '200px' },
    { name: 'Subsidio trabajador joven (cód 1118)', label: 'Subsidio trabajador joven (cód 1118)', width: '200px' },
    { name: 'Puesto Trabajo Pesado (cód 1154)', label: 'Puesto Trabajo Pesado (cód 1154)', width: '200px' },
    { name: 'Ahorro previsional voluntario individual (cód 1155)', label: 'Ahorro previsional voluntario individual (cód 1155)', width: '200px' },
    { name: 'Ahorro previsional voluntario colectivo (cód 1157)', label: 'Ahorro previsional voluntario colectivo (cód 1157)', width: '200px' },
    { name: 'Indemnización a todo evento (art. 164) (cód 1131)', label: 'Indemnización a todo evento (art. 164) (cód 1131)', width: '200px' },
    { name: 'Tasa indemnización a todo evento (Art 164) (cód 1132)', label: 'Tasa indemnización a todo evento (Art 164) (cód 1132)', width: '200px' },
    { name: 'Sueldo (cód 2101)', label: 'Sueldo (cód 2101)', width: '200px' },
    { name: 'Sobresueldo (cód 2102)', label: 'Sobresueldo (cód 2102)', width: '200px' },
    { name: 'Comisiones (mensual) (cód 2103)', label: 'Comisiones (mensual) (cód 2103)', width: '200px' },
    { name: 'Semana corrida mensual (Art 45) (cód 2104)', label: 'Semana corrida mensual (Art 45) (cód 2104)', width: '200px' },
    { name: 'Participación (mensual) (cód 2105)', label: 'Participación (mensual) (cód 2105)', width: '200px' },
    { name: 'Gratificación (mensual) (cód 2106)', label: 'Gratificación (mensual) (cód 2106)', width: '200px' },
    { name: 'Recargo 30% día domingo (Art. 38) (cód 2107)', label: 'Recargo 30% día domingo (Art. 38) (cód 2107)', width: '200px' },
    { name: 'Remuneración variable pagada en vacaciones (Art 71) (cód 2108)', label: 'Remuneración variable pagada en vacaciones (Art 71) (cód 2108)', width: '200px' },
    { name: 'Remuneración variable pagada en clausura (Art. 38 DFL 2) (cód 2109)', label: 'Remuneración variable pagada en clausura (Art. 38 DFL 2) (cód 2109)', width: '200px' },
    { name: 'Aguinaldo (cód 2110)', label: 'Aguinaldo (cód 2110)', width: '200px' },
    { name: 'Bonos u otras remuneraciones fijas mensuales (cód 2111)', label: 'Bonos u otras remuneraciones fijas mensuales (cód 2111)', width: '200px' },
    { name: 'Tratos (mensual) (cód 2112)', label: 'Tratos (mensual) (cód 2112)', width: '200px' },
    { name: 'Bonos u otras remuneraciones variables mensuales o superiores a un mes (cód 2113)', label: 'Bonos u otras remuneraciones variables mensuales o superiores a un mes (cód 2113)', width: '200px' },
    { name: 'Ejercicio opción no pactada en contrato (Art. 17 N°8 LIR) (cód 2114)', label: 'Ejercicio opción no pactada en contrato (Art. 17 N°8 LIR) (cód 2114)', width: '200px' },
    { name: 'Beneficios en especie constitutivos de remuneración (cód 2115)', label: 'Beneficios en especie constitutivos de remuneración (cód 2115)', width: '200px' },
    { name: 'Remuneraciones bimestrales (devengo en dos meses) (cód 2116)', label: 'Remuneraciones bimestrales (devengo en dos meses) (cód 2116)', width: '200px' },
    { name: 'Remuneraciones trimestrales (devengo en tres meses) (cód 2117)', label: 'Remuneraciones trimestrales (devengo en tres meses) (cód 2117)', width: '200px' },
    { name: 'Remuneraciones cuatrimestrales (devengo en cuatro meses) (cód 2118)', label: 'Remuneraciones cuatrimestrales (devengo en cuatro meses) (cód 2118)', width: '200px' },
    { name: 'Remuneraciones semestrales (devengo en sesis meses) (cód 2119)', label: 'Remuneraciones semestrales (devengo en sesis meses) (cód 2119)', width: '200px' },
    { name: 'Remuneraciones anuales (devengo en doce meses) (cód 2120)', label: 'Remuneraciones anuales (devengo en doce meses) (cód 2120)', width: '200px' },
    { name: 'Participación anual (devengo en doce meses (cód 2121)', label: 'Participación anual (devengo en doce meses (cód 2121)', width: '200px' },
    { name: 'Gratificación anual (devengo en doce meses) (cód 2122)', label: 'Gratificación anual (devengo en doce meses) (cód 2122)', width: '200px' },
    { name: 'Otras remuneraciones superiores a un mes (cód 2123)', label: 'Otras remuneraciones superiores a un mes (cód 2123)', width: '200px' },
    { name: 'Pago por horas de trabajo sindical (cód 2124)', label: 'Pago por horas de trabajo sindical (cód 2124)', width: '200px' },
    { name: 'Sueldo empresarial (cód 2161)', label: 'Sueldo empresarial (cód 2161)', width: '200px' },
    { name: 'Subsidio por incapacidad laboral por licencia médica - total mensual (cód 2201)', label: 'Subsidio por incapacidad laboral por licencia médica - total mensual (cód 2201)', width: '200px' },
    { name: 'Beca de estudio (Art. 17 N°18 LIR) (cód 2202)', label: 'Beca de estudio (Art. 17 N°18 LIR) (cód 2202)', width: '200px' },
    { name: 'Gratificaciones de zona (Art.17 N°27) (cód 2203)', label: 'Gratificaciones de zona (Art.17 N°27) (cód 2203)', width: '200px' },
    { name: 'Otros ingresos no constitutivos de renta (Art 17 N°29 LIR) (cód 2204)', label: 'Otros ingresos no constitutivos de renta (Art 17 N°29 LIR) (cód 2204)', width: '200px' },
    { name: 'Colación total mensual (Art 41) (cód 2301)', label: 'Colación total mensual (Art 41) (cód 2301)', width: '200px' },
    { name: 'Movilización total mensual (Art 41) (cód 2302)', label: 'Movilización total mensual (Art 41) (cód 2302)', width: '200px' },
    { name: 'Viáticos total mensual (Art 41) (cód 2303)', label: 'Viáticos total mensual (Art 41) (cód 2303)', width: '200px' },
    { name: 'Asignación de pérdida de caja total mensual (Art 41) (cód 2304)', label: 'Asignación de pérdida de caja total mensual (Art 41) (cód 2304)', width: '200px' },
    { name: 'Asignación de desgaste herramientas total mensual (Art 41) (cód 2305)', label: 'Asignación de desgaste herramientas total mensual (Art 41) (cód 2305)', width: '200px' },
    { name: 'Asignación familiar legal total mensual (Art 41) (cód 2311)', label: 'Asignación familiar legal total mensual (Art 41) (cód 2311)', width: '200px' },
    { name: 'Gastos por causa del trabajo (Art 41 CdT) y gastos de representación (Art. 42 Nº1 LIR) (cód 2306)', label: 'Gastos por causa del trabajo (Art 41 CdT) y gastos de representación (Art. 42 Nº1 LIR) (cód 2306)', width: '200px' },
    { name: 'Gastos por cambio de residencia (Art 53) (cód 2307)', label: 'Gastos por cambio de residencia (Art 53) (cód 2307)', width: '200px' },
    { name: 'Sala cuna (Art 203) (cód 2308)', label: 'Sala cuna (Art 203) (cód 2308)', width: '200px' },
    { name: 'Asignación trabajo a distancia o teletrabajo (cód 2309)', label: 'Asignación trabajo a distancia o teletrabajo (cód 2309)', width: '200px' },
    { name: 'Depósito convenido hasta UF 900 (cód 2347)', label: 'Depósito convenido hasta UF 900 (cód 2347)', width: '200px' },
    { name: 'Alojamiento por razones de trabajo (Art 17 N°14 LIR) (cód 2310)', label: 'Alojamiento por razones de trabajo (Art 17 N°14 LIR) (cód 2310)', width: '200px' },
    { name: 'Asignación de traslación (Art. 17 N°15 LIR) (cód 2312)', label: 'Asignación de traslación (Art. 17 N°15 LIR) (cód 2312)', width: '200px' },
    { name: 'Indemnización por feriado legal (cód 2313)', label: 'Indemnización por feriado legal (cód 2313)', width: '200px' },
    { name: 'Indemnización años de servicio (cód 2314)', label: 'Indemnización años de servicio (cód 2314)', width: '200px' },
    { name: 'Indemnización sustitutiva del aviso previo (cód 2315)', label: 'Indemnización sustitutiva del aviso previo (cód 2315)', width: '200px' },
    { name: 'Indemnización fuero maternal (Art 163 bis) (cód 2316)', label: 'Indemnización fuero maternal (Art 163 bis) (cód 2316)', width: '200px' },
    { name: 'Indemnización a todo evento (Art.164) (cód 2331)', label: 'Indemnización a todo evento (Art.164) (cód 2331)', width: '200px' },
    { name: 'Indemnizaciones voluntarias tributables (cód 2417)', label: 'Indemnizaciones voluntarias tributables (cód 2417)', width: '200px' },
    { name: 'Indemnizaciones contractuales tributables (cód 2418)', label: 'Indemnizaciones contractuales tributables (cód 2418)', width: '200px' },
    { name: 'Cotización obligatoria previsional (AFP o IPS) (cód 3141)', label: 'Cotización obligatoria previsional (AFP o IPS) (cód 3141)', width: '200px' },
    { name: 'Cotización obligatoria salud 7% (cód 3143)', label: 'Cotización obligatoria salud 7% (cód 3143)', width: '200px' },
    { name: 'Cotización voluntaria para salud (cód 3144)', label: 'Cotización voluntaria para salud (cód 3144)', width: '200px' },
    { name: 'Cotización AFC -Trabajador (cód 3151)', label: 'Cotización AFC -Trabajador (cód 3151)', width: '200px' },
    { name: 'Cotizaciones técnico extranjero para seguridad social fuera de Chile (cód 3146)', label: 'Cotizaciones técnico extranjero para seguridad social fuera de Chile (cód 3146)', width: '200px' },
    { name: 'Descuento depósito convenido hasta UF 900 anual (cód 3147)', label: 'Descuento depósito convenido hasta UF 900 anual (cód 3147)', width: '200px' },
    { name: 'Cotización ahorro previsional voluntario individual modalidad A (cód 3155)', label: 'Cotización ahorro previsional voluntario individual modalidad A (cód 3155)', width: '200px' },
    { name: 'Cotización ahorro previsional voluntario individual modalidad B hasta UF 50 (cód 3156)', label: 'Cotización ahorro previsional voluntario individual modalidad B hasta UF 50 (cód 3156)', width: '200px' },
    { name: 'Cotización ahorro previsional voluntario colectivo modalidad A (cód 3157)', label: 'Cotización ahorro previsional voluntario colectivo modalidad A (cód 3157)', width: '200px' },
    { name: 'Cotización ahorro previsional voluntario colectivo modalidad B hasta UF 50 (cód 3158)', label: 'Cotización ahorro previsional voluntario colectivo modalidad B hasta UF 50 (cód 3158)', width: '200px' },
    { name: 'Impuesto retenido por remuneraciones (cód 3161)', label: 'Impuesto retenido por remuneraciones (cód 3161)', width: '200px' },
    { name: 'Impuesto retenido por indemnizaciones (cód 3162)', label: 'Impuesto retenido por indemnizaciones (cód 3162)', width: '200px' },
    { name: 'Mayor retención de impuesto solicitada por el trabajador (cód 3163)', label: 'Mayor retención de impuesto solicitada por el trabajador (cód 3163)', width: '200px' },
    { name: 'Impuesto retenido por reliquidación de remuneraciones devengadas en otros períodos mensuales (cód 3164)', label: 'Impuesto retenido por reliquidación de remuneraciones devengadas en otros períodos mensuales (cód 3164)', width: '200px' },
    { name: 'Diferencia de impuesto por reliquidación de remuneraciones devengadas en este período (cód 3165)', label: 'Diferencia de impuesto por reliquidación de remuneraciones devengadas en este período (cód 3165)', width: '200px' },
    { name: 'Retención préstamo clase media 2020 (Ley 21.252) (cód 3166)', label: 'Retención préstamo clase media 2020 (Ley 21.252) (cód 3166)', width: '200px' },
    { name: 'Rebaja zona extrema DL 889 (cód 3167)', label: 'Rebaja zona extrema DL 889 (cód 3167)', width: '200px' },
    { name: 'Cuota sindical 1 (cód 3171)', label: 'Cuota sindical 1 (cód 3171)', width: '200px' },
    { name: 'Cuota sindical 2 (cód 3172)', label: 'Cuota sindical 2 (cód 3172)', width: '200px' },
    { name: 'Cuota sindical 3 (cód 3173)', label: 'Cuota sindical 3 (cód 3173)', width: '200px' },
    { name: 'Cuota sindical 4 (cód 3174)', label: 'Cuota sindical 4 (cód 3174)', width: '200px' },
    { name: 'Cuota sindical 5 (cód 3175)', label: 'Cuota sindical 5 (cód 3175)', width: '200px' },
    { name: 'Cuota sindical 6 (cód 3176)', label: 'Cuota sindical 6 (cód 3176)', width: '200px' },
    { name: 'Cuota sindical 7 (cód 3177)', label: 'Cuota sindical 7 (cód 3177)', width: '200px' },
    { name: 'Cuota sindical 8 (cód 3178)', label: 'Cuota sindical 8 (cód 3178)', width: '200px' },
    { name: 'Cuota sindical 9 (cód 3179)', label: 'Cuota sindical 9 (cód 3179)', width: '200px' },
    { name: 'Cuota sindical 10 (cód 3180)', label: 'Cuota sindical 10 (cód 3180)', width: '200px' },
    { name: 'Crédito social CCAF (cód 3110)', label: 'Crédito social CCAF (cód 3110)', width: '200px' },
    { name: 'Cuota vivienda o educación Art. 58 (cód 3181)', label: 'Cuota vivienda o educación Art. 58 (cód 3181)', width: '200px' },
    { name: 'Crédito cooperativas de ahorro (Art 54 Ley Coop.) (cód 3182)', label: 'Crédito cooperativas de ahorro (Art 54 Ley Coop.) (cód 3182)', width: '200px' },
    { name: 'Otros descuentos autorizados y solicitados por el trabajador (cód 3183)', label: 'Otros descuentos autorizados y solicitados por el trabajador (cód 3183)', width: '200px' },
    { name: 'Cotización adicional trabajo pesado- trabajador (cód 3154)', label: 'Cotización adicional trabajo pesado- trabajador (cód 3154)', width: '200px' },
    { name: 'Donaciones culturales y de reconstrucción (cód 3184)', label: 'Donaciones culturales y de reconstrucción (cód 3184)', width: '200px' },
    { name: 'Otros descuentos (Art 58) (cód 3185)', label: 'Otros descuentos (Art 58) (cód 3185)', width: '200px' },
    { name: 'Pensiones de alimentos (cód 3186)', label: 'Pensiones de alimentos (cód 3186)', width: '200px' },
    { name: 'Descuento mujer casada (Art 59) (cód 3187)', label: 'Descuento mujer casada (Art 59) (cód 3187)', width: '200px' },
    { name: 'Descuento por anticipos o préstamos (cód 3188)', label: 'Descuento por anticipos o préstamos (cód 3188)', width: '200px' },
    { name: 'Aporte AFC -empleador (cód 4151)', label: 'Aporte AFC -empleador (cód 4151)', width: '200px' },
    { name: 'Aporte empleador seguro accidentes del trabajo y Ley SANNA (Ley 16.744) (cód 4152)', label: 'Aporte empleador seguro accidentes del trabajo y Ley SANNA (Ley 16.744) (cód 4152)', width: '200px' },
    { name: 'Aporte empleador indemnización a todo evento (Art 164) (cód 4131)', label: 'Aporte empleador indemnización a todo evento (Art 164) (cód 4131)', width: '200px' },
    { name: 'Aporte adicional trabajo pesado- empleador (cód 4154)', label: 'Aporte adicional trabajo pesado- empleador (cód 4154)', width: '200px' },
    { name: 'Aporte empleador seguro invalidez y sobrevivencia (cód 4155)', label: 'Aporte empleador seguro invalidez y sobrevivencia (cód 4155)', width: '200px' },
    { name: 'Aporte empleador ahorro previsional voluntario colectivo (cód 4157)', label: 'Aporte empleador ahorro previsional voluntario colectivo (cód 4157)', width: '200px' },
    { name: 'Total haberes (cód 5201)', label: 'Total haberes (cód 5201)', width: '200px' },
    { name: 'Total haberes imponibles y tributables (cód 5210)', label: 'Total haberes imponibles y tributables (cód 5210)', width: '200px' },
    { name: 'Total haberes imponibles y no tributables (cód 5220)', label: 'Total haberes imponibles y no tributables (cód 5220)', width: '200px' },
    { name: 'Total haberes no imponibles y no tributables (cód 5230)', label: 'Total haberes no imponibles y no tributables (cód 5230)', width: '200px' },
    { name: 'Total haberes no imponibles y tributables (cód 5240)', label: 'Total haberes no imponibles y tributables (cód 5240)', width: '200px' },
    { name: 'Total descuentos (cód 5301)', label: 'Total descuentos (cód 5301)', width: '200px' },
    { name: 'Total descuentos impuestos a las remuneraciones (cód 5361)', label: 'Total descuentos impuestos a las remuneraciones (cód 5361)', width: '200px' },
    { name: 'Total descuentos impuestos por indemnizaciones (cód 5362)', label: 'Total descuentos impuestos por indemnizaciones (cód 5362)', width: '200px' },
    { name: 'Total descuentos por cotizaciones del trabajador (cód 5341)', label: 'Total descuentos por cotizaciones del trabajador (cód 5341)', width: '200px' },
    { name: 'Total otros descuentos (cód 5302)', label: 'Total otros descuentos (cód 5302)', width: '200px' },
    { name: 'Total aportes empleador (cód 5410)', label: 'Total aportes empleador (cód 5410)', width: '200px' },
    { name: 'Total líquido (cód 5501)', label: 'Total líquido (cód 5501)', width: '200px' },
    { name: 'Total indemnizaciones (cód 5502)', label: 'Total indemnizaciones (cód 5502)', width: '200px' },
    { name: 'Total indemnizaciones tributables (cód 5564)', label: 'Total indemnizaciones tributables (cód 5564)', width: '200px' },
    { name: 'Total indemnizaciones no tributables (cód 5565)', label: 'Total indemnizaciones no tributables (cód 5565)', width: '200px' },
];


export const PreviewEbook: React.FC<Props> = (props: any): JSX.Element => {
    const { data, setShowEbook, setShowMain, period, costCenter } = props;

    console.log(data);

    useEffect(()=> {
    },[]);

    const ebookDocument = async(typeDownload) => {
        
        if( typeDownload == 'excel'){
           const respExcel = await templateRemunerationMonthlyService.createElectronicBookExcel(period.id, costCenter?.id || "");
           // window.open(`${respExcel.data.link}`, '_blank');

           const btn_excel = document.createElement("a")
           btn_excel.href = `${respExcel.data.link}`
           btn_excel.download = 'previred.xlsx'

           document.body.appendChild(btn_excel);
           btn_excel.click();
           document.body.removeChild(btn_excel)

        }else if(typeDownload == 'excel-no-header'){
            const respExcel = await templateRemunerationMonthlyService.createElectronicBookExcelNotHeaders(period.id, costCenter?.id || "");

           const btn_excel = document.createElement("a")
           btn_excel.href = `${respExcel.data.link}`
           btn_excel.download = 'previred.xlsx'

           document.body.appendChild(btn_excel);
           btn_excel.click();
           document.body.removeChild(btn_excel)
   
        }else if(typeDownload == 'csv'){
            const respCSV = await templateRemunerationMonthlyService.createElectronicBookCsv(period.id, costCenter?.id || "");
            const btn_csv = document.createElement("a")
            btn_csv.href = `${respCSV.data.link}`
            btn_csv.download = 'previred.csv'
 
            document.body.appendChild(btn_csv);
            btn_csv.click();
            document.body.removeChild(btn_csv)
    
         }

    }

    return (

        <Container>
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} display='flex' justifyContent='space-between'>
                <Typography variant='h6' component='h1' gutterBottom className="tittle">
                    PREVIRED
                </Typography>
                <Button
                     color="error"
                     variant="contained"
                     onClick={()=>{
                        setShowEbook(false)
                        setShowMain(true)
                     }}
                  >
                   regresar
                  </Button>
            </Grid>
        </Grid>

        <Grid item xs={12} spacing={2} container direction={'row'} justifyContent='center' alignItems={'center'} marginTop='15px'>

            <Grid item xs={12} container alignItems="center" justifyContent="start" marginBottom='25px'>
                <Grid item xs={4}>
                <Typography variant="subtitle1" className="custom-input"><b>Previred formato excel:</b></Typography>
                </Grid>
                <Grid item xs={8} justifyContent='start'>
                    <Button onClick={()=>{ebookDocument('excel')}} type={'submit'} size="medium" color={"primary"} variant="contained" disableElevation sx={{width:'220px'}}>
                        Descargar
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                <Grid item xs={4}>
                <Typography variant="subtitle1" className="custom-input"><b>Previred formato excel sin cabecera:</b></Typography>
                </Grid>
                <Grid item xs={8}>
                    <Button onClick={()=>{ebookDocument('excel-no-header')}} type={'submit'} size="medium" color={"primary"} variant="contained" disableElevation sx={{width:'220px'}}>
                        Descargar 
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                <Grid item xs={4}>
                <Typography variant="subtitle1" className="custom-input"><b>Previred formato csv:</b></Typography>
                </Grid>
                <Grid item xs={8}>
                    <Button onClick={()=>{ebookDocument('csv')}} type={'submit'} size="medium" color={"primary"} variant="contained" disableElevation sx={{width:'220px'}}>
                        Descargar
                    </Button>
                </Grid>
            </Grid>

        </Grid>
        <TableData
            header={cabecera}
            data={data || []}
        />
        {/* <TableDataV2
            title={'Valor de hora extras por empleado'}
            data={data}
            header={cabecera}
            disabled_title={true}
            btn_disabled
            btn_edit_disable={true}
            btn_delete_disable={true}
        /> */}
        </Container>
    )
};
import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);
const rut_empresa = dataUser?.user?.rut_empresa;

export const PeriodRepository = {

    getPeriods: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/period`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getPeriodsActually: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/period/actually`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    // createPeriod: async (dataAdvance, idEmployee: number) : Promise<any> => {
    //     const res = await http.post<any>(`${API_URL_BASE}/v1/advance/${idEmployee}`, {
    //         idperiod: dataAdvance.idperiod,
    //         value: dataAdvance.value,
    //     });
    //     const {data, error, message} = res;
    //     return { data, error, message };
    // },

    // editPeriod: async (dataAdvance, idAdvance: number) : Promise<any> => {
    //     const res = await http.patch<any>(`${API_URL_BASE}/v1/advance/${idAdvance}`, {
    //         idperiod: dataAdvance.idperiod,
    //         value: dataAdvance.value,
    //     });
    //     const {data, error, message} = res;
    //     return { data, error, message };
    // },

    // deletePeriod: async(idAdvance:number): Promise<any> => {
    //     const res= await http.delete<any>(`${API_URL_BASE}/v1/advance/${idAdvance}`)
    //     return res;
    // },
}   
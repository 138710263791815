import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);
const rut_empresa = dataUser?.user?.rut_empresa;

export const PhasesRepository = {

    getPhases: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/phase`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createPhase: async (dataPhase) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/phase/create`, {
            name: dataPhase.name,
            description: dataPhase.description
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    editPhase: async (dataPhase) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/phase/update`, {
            name: dataPhase.name,
            description: dataPhase.description,
            id: dataPhase.id
        });
        const {data, error, message} = res;
        return { data, error, message };
    },
}   
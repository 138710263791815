import { useEffect, useState } from "react"
import { TableDataV2 } from "@/components/common/Tablev2"
import { Protected } from "@/components/layout/Protected"
import { Container } from "@mui/material"
import { Props } from "./Bills.type"
import { RequestService } from "@/service/services/Request.service"
import { Loading } from "@/components/common/Loading"
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage"
import { ModalBills } from "@/components/Modal/ModalBills"

export const BillsView: React.FC<Props> = (props: any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [request, setRequest] = useState([
        {
            name: 'Luis',
            description: 'InkaKola'
        },
        {
            name: 'Luis',
            description: 'InkaKola'
        }, {
            name: 'Luis',
            description: 'InkaKola'
        }
    ]);
    const [requestModal, setRequestModal] = useState<boolean>(false);
    const [actionSelected, setActionSelected] = useState<any>('');
    const [requestSelected, setRequestSelected] = useState<any>('');
    const [view, setView] = useState<String>('workers');

    const cabecera = [
        { name: 'name', label: 'Nombre' },
        { name: 'description', label: 'Descripcion' }];


    useEffect(() => {

    }, []);


    function RecuperarData(value) {
        setRequestSelected(value)
        setRequestModal(true)
        console.log(value)
    }

    return (
        <Protected>
            {loading && <Loading />}
            <Container>
                <TableDataV2
                    title={'Gastos'}
                    data={request || []}
                    header={cabecera}
                    actionSelect={setActionSelected}
                    status_action
                    btn_disabled
                    btn_edit_disable={true}
                    btn_delete_disable={true}
                    btn_helper
                    // checkbox
                    RecuperarData={RecuperarData}
                />
            </Container>

            {
                requestModal && (
                    <ModalBills
                        open={requestModal}
                        closeModal={() => { setRequestModal(false) }}
                        setView={setView}
                    />
                )
            }

        </Protected>
    )
}
import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Container } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { Loading } from '@/components/common/Loading';
import { Props } from './Politics.type';
import { PoliticService } from '@/service/services/Politic.service';
import { ModalPolitics } from '@/components/Modal/ModalPolitics/ModalPolitics';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_RUTA, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_TRABAJADOR } from '@/toolbox/defaults/static-roles';
import { API_URL_BASE } from '@/toolbox/defaults/app';

export const PoliticsView: React.FC<Props> = (props:any): JSX.Element => {

   const [loading, setLoading] = useState<boolean>(false);
   const [politics, setPolitics] = useState([]);
   const [showPoliticsModal, setShowPoliticsModal] = useState<boolean>(false);
   const [showPoliticsDeleteModal, setShowPoliticsDeleteModal] = useState<boolean>(false);
   const [actionSelected, setActionSelected] = useState<String>('');
   const [politicsSelected, setPoliticsSelected] = useState<String>('');
   const userData = readLocalStorage(KEY_USER_DATA)
   const role = userData.user.role
   const route = readLocalStorage(KEY_RUTA)
   console.log(role)
   console.log(route)
   useEffect(() => {
    getPoliticsDocuments()
   },[route]);

   const getPoliticsDocuments = async () => {
      setLoading(true);
      let type;
      if(role == ROLE_SUPER_ADMIN){type = '1'}
      if(role == ROLE_ADMIN){
         if( route == 'Politicas y Privacidad'){type = '1'}else{type = '0'}
      }
      if(role == ROLE_TRABAJADOR){type = '0'}
      console.log(type)
      const response = await PoliticService.getDocuments(type);
      if (response.data) {
        setPolitics(response.data || [])
      } else {
         console.log('error')
      }
      setLoading(false);
   }

   function RecuperarData(value){
      console.log(value);
      setPoliticsSelected(value);
      if(value.action == 'view_document'){
        
         const btn_ahref = document.createElement("a")
         btn_ahref.href = `${API_URL_BASE}/${value.route}`
         btn_ahref.target = '_blank'
         document.body.appendChild(btn_ahref);
         btn_ahref.click();
         document.body.removeChild(btn_ahref)
      }
   }

  return (
      <Protected>
         {loading && <Loading/>}
         <Container>
           { role == ROLE_TRABAJADOR &&
            <TableDataV2
               title={'Politicas y Privacidad'}
               data={politics}
               header={[
                  { name: 'name', label: 'Nombre', filter: false, Chip: true },
               ]}
               actionSelect={setActionSelected}
               setModalSave={setShowPoliticsModal}
               setModalDelete={setShowPoliticsDeleteModal}
               btn_delete_disable={true}
               btn_edit_disable
               btn_disabled
               btn_view_document
               status_action
               // checkbox
               RecuperarData={RecuperarData}
            />
         }
         {
            role == ROLE_SUPER_ADMIN && 
            <TableDataV2
            title={'Politicas y Privacidad'}
            data={politics}
            header={[
               { name: 'name', label: 'Nombre', filter: false, Chip: true },
            ]}
            actionSelect={setActionSelected}
            setModalSave={setShowPoliticsModal}
            setModalDelete={setShowPoliticsDeleteModal}
            btn_delete_disable={true}
            status_action
            // checkbox
            RecuperarData={RecuperarData}
         />
         }

         {
            (role == ROLE_ADMIN && route == 'Politicas y Privacidad' ) &&
            <TableDataV2
            title={'Politicas y Privacidad del Software'}
            data={politics}
            header={[
               { name: 'name', label: 'Nombre', filter: false, Chip: true },
            ]}
            actionSelect={setActionSelected}
            setModalSave={setShowPoliticsModal}
            setModalDelete={setShowPoliticsDeleteModal}
            btn_delete_disable={true}
            btn_edit_disable
            btn_disabled
            btn_view_document
            status_action
            // checkbox
            RecuperarData={RecuperarData}
         />
         }

{
            (role == ROLE_ADMIN && route != 'Politicas y Privacidad' ) &&
            <TableDataV2
            title={'Politicas y Privacidad de la Empresa'}
            data={politics}
            header={[
               { name: 'name', label: 'Nombre', filter: false, Chip: true },
            ]}
            actionSelect={setActionSelected}
            setModalSave={setShowPoliticsModal}
            setModalDelete={setShowPoliticsDeleteModal}
            btn_delete_disable={true}
            status_action
            // checkbox
            RecuperarData={RecuperarData}
         />
         }
         
         </Container>

         { 
            showPoliticsModal && (
               <ModalPolitics
                  open={showPoliticsModal}
                  closeModal={()=>{setShowPoliticsModal(false)}}
                  actionSelected={actionSelected}
                  onListPolitics={getPoliticsDocuments}
                  politic={politicsSelected}
                  clearState={()=>{setPoliticsSelected('')}}
               />
            )
         }
      
      </Protected>
   )
};
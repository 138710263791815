import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);
const rut_empresa = dataUser?.user?.rut_empresa;

export const AssistanceRepository = {

    getExtraHoursByEmployee: async(idEmployee: number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/assistance/extraHour/${idEmployee}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getAbsencesByEmployee: async(idEmployee: number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/assistance/absence/${idEmployee}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getDelaysByEmployee: async(idEmployee: number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/assistance/delay/${idEmployee}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getComboAbsences: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/assistance/typeAbsenceDiscount`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getTypeExtraHour: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/assistance/typeExtraHour`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getDetailEmployeeOPT: async(idEmployee: number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/assistance/detailExtraHourEmployeeOPT/${idEmployee}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createExtraHour: async (dataExtraHour) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/assistance/extraHour`, {
            idemployee: dataExtraHour.idemployee,
            iddetstructureemployeeopt: dataExtraHour.iddetstructureemployeeopt,
            hour: dataExtraHour.hour,
            date: dataExtraHour.date,
            code: dataExtraHour.code,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    createAbsence: async (dataAbsence) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/assistance/absence`, {
            idemployee: dataAbsence.idemployee,
            idtypeabsence: dataAbsence.idtypeabsence,
            type_discount: dataAbsence.type_discount,
            date_registration: dataAbsence.date_registration,
            total_absence: dataAbsence.total_absence,
            observation: dataAbsence.observation,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    createDelay: async (dataDelay) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/assistance/delay`, {
            idemployee: dataDelay.idemployee,
            date: dataDelay.date,
            total_delay: dataDelay.total_delay,
            observation: dataDelay.observation,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    editExtraHour: async (dataExtraHour, idExtraHour: number) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/assistance/extraHour/${idExtraHour}`, {
            idemployee: dataExtraHour.idemployee,
            iddetstructureemployeeopt: dataExtraHour.iddetstructureemployeeopt,
            hour: dataExtraHour.hour,
            date: dataExtraHour.date,
            code: dataExtraHour.code,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    editAbsence: async (dataAbsence, idAbsence: number) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/assistance/absence/${idAbsence}`, {
            idemployee: dataAbsence.idemployee,
            idtypeabsence: dataAbsence.idtypeabsence,
            type_discount: dataAbsence.type_discount,
            date_registration: dataAbsence.date_registration,
            total_absence: dataAbsence.total_absence,
            observation: dataAbsence.observation,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    editDelay: async (dataDelay, idDelay: number) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/assistance/delay/${idDelay}`, {
            idemployee: dataDelay.idemployee,
            date: dataDelay.date,
            total_delay: dataDelay.total_delay,
            observation: dataDelay.observation,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    deleteExtraHour: async(idExtraHour:number): Promise<any> => {
        const res= await http.delete<any>(`${API_URL_BASE}/v1/assistance/extraHour/${idExtraHour}`)
        return res;
    },

    deleteAbsence: async(idAbsence:number): Promise<any> => {
        const res= await http.delete<any>(`${API_URL_BASE}/v1/assistance/absence/${idAbsence}`)
        return res;
    },

    deleteDelay: async(idDelay:number): Promise<any> => {
        const res= await http.delete<any>(`${API_URL_BASE}/v1/assistance/delay/${idDelay}`)
        return res;
    },
}   
import { TurnRepository } from "../repositories/Turn.repository";

export const TurnService = {
    getTurns,
    createTurn,
    editTurn,
    deleteTurn
}

async function getTurns(){
    const res = await TurnRepository.getTurns();
    return res;
}

async function createTurn(data:{}){
    const res = await TurnRepository.createTurn(data);
    return res;
}

async function editTurn(id, data:{}){
    const res = await TurnRepository.editTurn(id, data);
    return res;
}

async function deleteTurn(id){
    const res = await TurnRepository.deleteTurn(id);
    return res;
}

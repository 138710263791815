import { JobOffertRepository } from "../repositories/JobOffert.repository";

export const JobOffertService = {
    getJobOffert,
    createJobOffert,
    editJobOffert,
    getSpecification,
    createSpecification,
    createSpecificationMultiple,
}

async function getJobOffert(){
    const res = await JobOffertRepository.getJobOffert();
    return res;
}

async function createJobOffert(data:{}){
    const res = await JobOffertRepository.createJobOffert(data);
    return res;
}

async function editJobOffert(data:{}){
    const res = await JobOffertRepository.editJobOffert(data);
    return res;
}

//specifications

async function getSpecification(idJobOffert){
    const res = await JobOffertRepository.getSpecification(idJobOffert);
    return res;
}

async function createSpecification(data:{}){
    const res = await JobOffertRepository.createSpecification(data);
    return res;
}

async function createSpecificationMultiple(data:{}){
    const res = await JobOffertRepository.createSpecificationMultiple(data);
    return res;
}

import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Container } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { Loading } from '@/components/common/Loading';
import { ConfigurationIPService } from '@/service/services/ConfigurationIP.service';
import { ModalIpAccept } from '@/components/Modal/ModalIpAccept/ModalIpAccept';
import { ModalIpDelete } from '@/components/Modal/ModalIpDelete/ModalIpDelete';
import { Props } from './IpsDenied.type';
import { ModalIpDenied } from '@/components/Modal/ModalIpDenied/ModalIpDenied';


export const IpsDeniedView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [IpsDenied, setIpsDenied] = useState([]);
    const [showIpDeniedModal, setShowIpDeniedModal] = useState<boolean>(false);
    const [showIpDeniedDeleteModal, setShowIpDeniedDeleteModal] = useState<boolean>(false);
    const [actionSelected, setActionSelected] = useState<String>('');
    const [ipDeniedSelected, setIpDeniedSelected] = useState<String>('');

    useEffect(() => {
        getIPDeniedByCompany()
    },[]);

        const getIPDeniedByCompany = async() => {
            setLoading(true);
            await ConfigurationIPService.getIPDeniedByCompany().then((data) => {
                setIpsDenied(data.data || []);
                setLoading(false);
            })
        }

    function RecuperarData(value){
        console.log(value)
        setIpDeniedSelected(value)
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Container>
                <TableDataV2
                title={"IP'S DENEGADAS"}
                data={IpsDenied}
                header={[
                    { name: 'ip', label: 'IP', filter: false, Chip: false },
                    { name: 'user_name', label: 'Nombre de Usuario', filter: false, Chip: true },
                    { name: 'n_document', label: 'N° documento', filter: false, Chip: true },
                    { name: 'email', label: 'Correo de Usuario', filter: false, Chip: true },
                ]}
                actionSelect={setActionSelected}
                setModalSave={setShowIpDeniedModal}
                setModalDelete={setShowIpDeniedDeleteModal}
                status_action
                RecuperarData={RecuperarData}
                />
            </Container>

            { 
                showIpDeniedModal && (
                <ModalIpDenied
                    open={showIpDeniedModal}
                    closeModal={()=>{setShowIpDeniedModal(false)}}
                    actionSelected={actionSelected}
                    onListIps={getIPDeniedByCompany}
                    userIp={ipDeniedSelected}
                    clearState={()=>{setIpDeniedSelected('')}}
                />
                )
            }
            {
                actionSelected === 'delete' && (
                <ModalIpDelete
                    open={showIpDeniedDeleteModal}
                    closeModal={()=>{setShowIpDeniedDeleteModal(false)}}
                    actionSelected={actionSelected}
                    onListIps={getIPDeniedByCompany}
                    userIp={ipDeniedSelected}
                    clearState={()=>{setIpDeniedSelected('')}}
                    type='denied'
                />
                )
            }
        
        </Protected>
    )
};
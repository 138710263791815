import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Props } from "./ModalRequest.type";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Button, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { PhasesService } from "@/service/services/Phases.service";
import { Loading } from "@/components/common/Loading";
import { requestTypeService } from "@/service/services/RequestType.service";
import { RequestService } from "@/service/services/Request.service";
import { useHistory } from "react-router-dom";
import { ROUTE_LIQUIDATION_SALARY, ROUTE_ASSISTANCES, ROUTE_ARREARS } from "@/toolbox/constants/route-map";

export const ModalRequest: React.FC<Props> = (props: any): JSX.Element => {
    const history = useHistory();
    const { open, closeModal, actionSelected, request, requestCreate} = props;

    const [loading, setLoading] = useState<boolean>(false);

    const [dataForm, setDataForm] = useState({
        observations: '',
        from_date_request: '',
        to_date_request: '',
        detail: '',
    });
    const [error, setError] = useState(null)
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
     });
    const [typeRequest, setTypeRequest] = useState<any>([])
    const [typeRequestSelected, setTypeRequestSelected] = useState<any>(null)

    const getTypeRequest = async() => {
        const respType = await requestTypeService.getRequestTypes();
        setTypeRequest(respType?.data)
    }

    useEffect(()=>{
        getTypeRequest();
    },[])

    const onSubmit = async (e, status=null) => {
        e.preventDefault();
        // setLoading(true);
       
        console.log(request);
        // SOLO EL ADMIN PUEDE EDITAR, LA OBSERVACION Y EL NUEVO ESTADO, LOS OTROS PARAMETROS SON PORQUE LA API ME LOS PIDE COMO OBLIGATORIO
        if (request && actionSelected == 'edit') {
            const dataRequest = {
                observations: dataForm.observations,
                status_review: status,
                detail: request.detail,
                idrequests_type: request.idrequests_type,
                status: request.status
            }
            const respEditRequest = await RequestService.AceptorDeniedRequest(request?.id, dataRequest)
            // setLoading(false);
            if(!respEditRequest?.data){
                setSnackBarConfig({...snackBarConfig, open:true, severity:'success', message:'Ocurrio un problema, vuelva a intentarlo'})
                setTimeout(()=>{
                    setLoading(false);
                    closeModal()
                },2000)
                return
            }
            setSnackBarConfig({...snackBarConfig, open:true, severity:'success', message:'Estado de solicitud actualizado con éxito'})
            
            
        } else {
            const dataRequest = {
                detail: dataForm.detail,
                from_date_request: dataForm.from_date_request,
                to_date_request: dataForm.to_date_request,
                idrequests_type: typeRequestSelected?.id
             }
            const respRequest = await requestCreate(dataRequest)
           
            respRequest?.data && setSnackBarConfig({...snackBarConfig, open:true, severity:'success', message:'Solicitud generada con éxito'})

        }
        setTimeout(()=>{
            setLoading(false);
            closeModal()
        },2000)
       
    }

    const handleInput = (e) => {
        setError("")
        const changedFormValues = {
            ...dataForm,
            [e.target.name]: e.target.value
        }
        setDataForm(changedFormValues);
    }

    return (
        <>
            {loading && <Loading />}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>
            <Modal open={open} handleClose={closeModal} handle size="sm">
                <ModalHeader
                    text={(actionSelected == 'save') ? "Agregar Solicitud": "¿Desea Aceptar o Rechazar la Solicitud?" }
                    className='positionElements'
                    onCancel={closeModal}
                >
                </ModalHeader>
                <ModalBody>
                    <Grid container >
                       { actionSelected == 'save' ?
                       <>
                       <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                            <Grid item xs={4}>
                                <Typography variant="body1"><b>Tipo de Solicitud:</b> </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    id="id_education_level"
                                    variant="standard"
                                    value={typeRequestSelected}
                                    onChange={(event) => {
                                        setTypeRequestSelected(event.target.value);
                                    }}
                                    fullWidth
                                >
                                    {
                                        typeRequest.map((row, i) => {
                                            return <MenuItem key={i} value={row}>{row.name}</MenuItem>;
                                        })
                                    }
                                </Select>
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                            <Grid item xs={4}>
                                <Typography variant="body1"><b>Motivo:</b> </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    value={dataForm?.detail}
                                    type='text'
                                    multiline
                                    maxRows={4}
                                    fullWidth
                                    onChange={handleInput}
                                    name={'detail'}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                            <Grid item xs={4}>
                                <Typography variant="body1"><b>Fecha Inicial:</b> </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    value={dataForm?.from_date_request}
                                    variant="standard"
                                    type="date"
                                    fullWidth
                                    onChange={handleInput}
                                    name={'from_date_request'}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                            <Grid item xs={4}>
                                <Typography variant="body1"><b>Fecha Final:</b> </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    value={dataForm?.to_date_request}
                                    type="date"
                                    variant="standard"
                                    fullWidth
                                    onChange={handleInput}
                                    name={'to_date_request'}
                                />
                            </Grid>
                        </Grid>
                       </>:
                        <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                        <Grid item xs={4}>
                            <Typography variant="body1"><b>Observacion:</b> </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                value={dataForm?.observations}
                                type='text'
                                multiline
                                maxRows={4}
                                fullWidth
                                onChange={handleInput}
                                name={'observations'}
                            />
                        </Grid>
                    </Grid>
                       }

                    </Grid>
                </ModalBody>
                {
                    actionSelected == 'save' ?
                    <ModalFooter
                    buttonType="submit"
                    confirmText={" CREAR NUEVA"}
                    cancelText="CANCELAR"
                    onCancel={closeModal}
                    onConfirm={(e) => { onSubmit(e) }}
                />:
                <ModalFooter
                    buttonType="submit"
                    confirmText={" ACEPTAR SOLICITUD"}
                    cancelText="RECHAZAR SOLICITUD"
                    onCancel={(e) => { onSubmit(e,2) }}
                    onConfirm={(e) => { onSubmit(e,1) }}
                />

                }
                
            </Modal>
        </>
    );
}
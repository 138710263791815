import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Accordion, AccordionSummary, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, AccordionDetails, Autocomplete, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography, IconButton } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import { PhasesService } from "@/service/services/Phases.service";
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModaAmountGeneral.type";
import '../../../assets/styles/modals.scss';
import { userService } from "@/service/services/User.service";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { readLocalStorage, saveLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { templateRemunerationMonthlyRepository } from "@/service/repositories/TemplateRemunerationMonthly.repository";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ROUTE_PDF_AMOUNT_GENERAL } from "@/toolbox/constants/route-map";

export const ModalAmountGeneral: React.FC<Props> = (props: any): JSX.Element => {

    const { open, closeModal, actionSelected, user, onListUsers, clearState, idperiod } = props;
    const [expanded, setExpanded] = useState('panel1');

    const [loading, setLoading] = useState<boolean>(false);
    const [roles, setRoles] = useState<any>([]);
    const [afp, setAfp] = useState<any>([]);
    const [isapre, setIsapre] = useState<any>([]);
    const [mutual, setMutual] = useState<any>([]);

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [data, setData] = useState<any>({
        id: '',
        email: '',
        user_name: '',
        password: '',
        confirm_password: '',
        n_document: '',
        role: '1',
        assign_ip: '',
    });

    useEffect(() => {
        // getRoles();
        getAbstractPrevired();
    }, []);


    // const getRoles = async () => {
    //     setLoading(true);
    //     await userService.getRoles().then((data) => {
    //         setRoles(data.data || []);
    //         setLoading(false);
    //     })
    // }
    // getAbstractPrevired
    const getAbstractPrevired = async () => {
        const resp = await templateRemunerationMonthlyRepository.getAbstractPrevired(idperiod)
        if (resp?.data) {
            setAfp(resp?.data?.afp);
            setIsapre(resp?.data?.isapre);
            setMutual(resp?.data?.mutual);
        }
        console.log(resp?.data)
    }
    const getDetailPreviredAFP = async () => {
        const pdfResp:any = templateRemunerationMonthlyRepository.getDetailPreviredAFP(idperiod);
      if(pdfResp){
         const blob = new Blob([pdfResp], {type: 'application/pdf'});
         const blobURL = URL.createObjectURL(blob);
         saveLocalStorage('previewAmountGeneral',{pdf: blobURL})
         window.open(ROUTE_PDF_AMOUNT_GENERAL, '_blank');
         //aca podemos crear para que se visualize en un ruta y despues de ello atrapar el blob con el mensaje de detail en la parte superior
      }
      setLoading(false);
      closeModal && closeModal();
    //   dataRequest && setDataRequest(null)
    }
    

    // const onSubmit = async (values, { resetForm }) => {

    //     const formData = {
    //         id: values.id,
    //         email: values.email,
    //         user_name: values.user_name,
    //         password: values.password,
    //         confirm_password: values.confirm_password,
    //         n_document: values.n_document,
    //         role: values.role,
    //         assign_ip: values.assign_ip,
    //     }
    //     setLoading(true);
    //     if (user && actionSelected == 'edit') {
    //         console.log('edit');
    //         // await PhasesService.editPhase(formData).then((data) => {
    //         //     onListUsers && onListUsers();
    //         //     closeModal && closeModal();
    //         // })
    //     } else {
    //         await userService.createUser(formData).then((data) => {
    //             if (data.data.status === false) {
    //                 return (
    //                     setSnackBarConfig(prev => ({
    //                         ...prev, open: true,
    //                         severity: 'error',
    //                         message: `${data.data.message}`
    //                     }))
    //                 )
    //             }
    //             closeModal && closeModal();
    //             onListUsers && onListUsers();
    //         })
    //     }
    //     setLoading(false);
    // }

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };


    return (
        <>
            {loading && <Loading />}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>

            <Modal open={open} handleClose={closeModal} handle size="sm">
                <ModalHeader
                    text={'Resumen Previded'}
                    className='positionElements'
                    onCancel={closeModal}
                    clearState={clearState}
                >
                </ModalHeader>
                <ModalBody>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>AFP</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer>
                                <Table>
                                    <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                        <TableRow >
                                            <TableCell >
                                                <Typography>Institución</Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography>Total a Pagar</Typography>
                                            </TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {afp?.detail?.map((row) => {
                                            return (
                                                <TableRow >
                                                    <TableCell size={'small'}>
                                                        {row?.AFP}
                                                    </TableCell>
                                                    <TableCell size={'small'}>
                                                        {row?.total}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                        <TableRow >
                                            <TableCell size={'small'}>
                                                Subtotal AFP
                                            </TableCell>
                                            <TableCell size={'small'}>
                                                {afp?.total} <IconButton onClick={getDetailPreviredAFP} title='ver detalles'><VisibilityIcon/></IconButton>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography>ISAPRE</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <TableContainer>
                                <Table>
                                    <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                        <TableRow >
                                            <TableCell >
                                                <Typography>Institución</Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography>Código</Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography>Total a Pagar</Typography>
                                            </TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {isapre?.detail?.map((row) => {
                                            return (
                                                <TableRow >
                                                    <TableCell size={'small'}>
                                                        {row?.salud}
                                                    </TableCell>
                                                    <TableCell size={'small'}>
                                                        {row?.codigo}
                                                    </TableCell>
                                                    <TableCell size={'small'}>
                                                        {row?.total}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                        <TableRow >
                                            <TableCell size={'small'}>
                                                Subtotal ISAPRE
                                            </TableCell>
                                            <TableCell size={'small'}>
                                                {/* {isapre?.total} */}
                                            </TableCell>
                                            <TableCell size={'small'}>
                                                {isapre?.total} <IconButton onClick={getDetailPreviredAFP} title='ver detalles'><VisibilityIcon/></IconButton>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                        >
                            <Typography>MUTUAL</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <TableContainer>
                                <Table>
                                    <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                        <TableRow >
                                            <TableCell >
                                                <Typography>Institución</Typography>
                                            </TableCell>
                                           
                                            <TableCell >
                                                <Typography>Total a Pagar</Typography>
                                            </TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {mutual?.detail?.map((row) => {
                                            return (
                                                <TableRow >
                                                    <TableCell size={'small'}>
                                                        {row?.name}
                                                    </TableCell>                                                
                                                    <TableCell size={'small'}>
                                                        {row?.total}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                        <TableRow >
                                            <TableCell size={'small'}>
                                                Subtotal Mutual
                                            </TableCell>
                                            <TableCell size={'small'}>

                                                {mutual?.total} <IconButton onClick={getDetailPreviredAFP} title='ver detalles'><VisibilityIcon/></IconButton>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                </ModalBody>
            </Modal>
        </>
    );
}
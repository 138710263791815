import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { AssistanceService } from "@/service/services/Assistance.service";
import { Props } from "./ModalProjectEmployee.type";
import { WorkerService } from "@/service/services/Worker.service";
import { ProjectService } from "@/service/services/Project.service";

export const ModalProjectEmployee: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, employee, onListEmployees, projectSelected, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);

    const [workers, setWorkers] = useState<any>([]);
    const [workerSelected, setWorkerSelected] = useState<any>('');

    const [data, setData] = useState({
        id: employee ? employee.id : '',
        function_project: employee ? employee.function_project : '',
        idemployee: employee ? employee.idemployee : '',
    });

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    })

    useEffect(()=> {
        getWorkers()
    },[]);

    useEffect(() => {
        if(employee && workers){
            setCustomEmployee(employee.idemployee, workers);
        }

    },[employee, workers]);

    const getWorkers = async() => {
        setLoading(true);
        const resp:any = await WorkerService.getWorkers();
        
        const oldData = resp.data || [];
        let newData = [];
        oldData.length > 0 && oldData.map((item) => {
            let obj = {
                id: item.id,
                name: item.name+' '+item.surname+' '+item.second_surname,
            }
            newData.push(obj);
        });
        setWorkers(newData || []);
        setLoading(false);
    }

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.function_project) errors.function_project = "funcion requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        if(!workerSelected){
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: `Debe seleccionar un Empleado` }
                ))
            );
        }

        const formData = {
            id: values.id,
            idproject : projectSelected.id,
            function_project: values.function_project,
            idemployee: workerSelected.id,
        }
        setLoading(true);
        if(employee && actionSelected=='edit'){
            await ProjectService.editEmployee(formData, employee.iddetailproject).then((data) => {
                onListEmployees && onListEmployees(projectSelected.id);
                closeModal && closeModal();
            })
        }else{
            await ProjectService.addEmployee(formData).then((data) => {
                onListEmployees && onListEmployees(projectSelected.id);
                closeModal && closeModal();
            })
        }
        setLoading(false);
    }

    function setCustomEmployee(idEmployee, workers): any {
        const data = workers && workers.find(worker => worker.id === idEmployee);
        const newData = {
            id: data?.id,
            name: data?.name
        }
        return setWorkerSelected(newData);
    }

    return(
        <>
        {loading && <Loading/>}

        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={(employee && actionSelected=='edit') ? "Editar Empleado" : "Agregar Empleado"}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Función:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                id="function_project"
                                                variant="standard"
                                                fullWidth
                                                name="function_project"
                                                onChange={handleChange}
                                                value={values.function_project}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={errors.function_project && touched.function_project ? true : false}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>Empleado:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                id="disable-portal"
                                                disablePortal
                                                value={workerSelected}
                                                options={workers || []}
                                                getOptionLabel={(option) => option.name || ""}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} variant="standard" label="Escriba para buscar" />}
                                                onChange={(e, newValue) => { setWorkerSelected(newValue) }}
                                            />
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(employee && actionSelected=='edit') ? "ACTUALIZAR" : "REGISTRAR"}
                                    cancelText="CANCELAR" 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}
import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';


export const companiesRepository = {

    getCompaniesAdmin: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/company`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getGroupCompanies: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/companyGroup`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getGroupCompaniesById: async(idUserAdmin): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/companyGroup/user/${idUserAdmin}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getCompanies: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/company/me`);
        const {data, error, message} = res;
        return { data, error, message };
    }, 

    companiesByUser: async(idUser:number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/company/companiesByUser?iduser=${idUser}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createRequestGroupCompany: async (dataCompany) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/companyGroup`, dataCompany);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createCompany: async (dataCompany) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/company`, dataCompany);
        const {data, error, message} = res;
        return { data, error, message };
    },

    editCompany: async (idBranch, dataCompany) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/company/${idBranch}`, dataCompany);
        const {data, error, message} = res;
        return { data, error, message };
    },

    editDecisionGroupCompany: async (id, dataStatus) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/companyGroup/${id}`, dataStatus);
        const {data, error, message} = res;
        return { data, error, message };
    },

    deleteCompany: async(idCompany:number): Promise<any> => {
        const res= await http.delete<any>(`${API_URL_BASE}/v1/company/${idCompany}`)
        return res;
    },

    getCajas: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/combobox/compensationBox`);
        const {data, error, message} = res;
        return res;
    }, 

    getMutualityByCompany: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/combobox/mutuality`);
        const {data, error, message} = res;
        return { data, error, message };
    }, 

    getDataCompany: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/companyData`);
        const {data, error, message} = res;
        return { data, error, message };
    }, 

    updateMutual: async (dataCompany) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/companyData/updateMutual`, dataCompany);
        const {data, error, message} = res;
        return { data, error, message };
    },

    updateExtraHours: async (dataCompany) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/companyData/extraHours`, dataCompany);
        const {data, error, message} = res;
        return { data, error, message };
    },

    updateCloseDay: async (dataCompany) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/companyData/closeDay`, dataCompany);
        const {data, error, message} = res;
        return { data, error, message };
    },

    deleteGroupCompany: async (id) : Promise<any> => {
        const res = await http.delete<any>(`${API_URL_BASE}/v1/companyGroup/${id}`);
        const {data, error, message} = res;
        return { data, error, message };
    },
}



import { templateRemunerationMonthlyRepository } from "../repositories/TemplateRemunerationMonthly.repository";

export const templateRemunerationMonthlyService = {
    getPrevired,
    createPreviredExcel,
    createPreviredCsv,
    getAbstractPrevired,
    getDetailPreviredAFP,
    createElectronicBookExcel,
    createElectronicBookExcelNotHeaders,
    createElectronicBookCsv,
    getDJ,
    createDJExcel,
    createDJCsv,
    getElectronicBook,
    getCertificateRemuneration,
    createCertificateRemuneration,
    createPDFCertificateRemuneration
}

async function getPrevired(idperiod, idcostcenter){
   const res = await templateRemunerationMonthlyRepository.getPrevired(idperiod, idcostcenter);
   return res;
}

async function createPreviredExcel(idperiod, idcostcenter){
    const res = await templateRemunerationMonthlyRepository.createPreviredExcel(idperiod, idcostcenter);
    return res;
 }

 async function createPreviredCsv(idperiod, idcostcenter){
    const res = await templateRemunerationMonthlyRepository.createPreviredCsv(idperiod, idcostcenter);
    return res;
 }

async function getAbstractPrevired(idperiod) {
   const res = await templateRemunerationMonthlyRepository.getAbstractPrevired(idperiod);
   return res;
}

async function getDetailPreviredAFP(idperiod) {
   const res = await templateRemunerationMonthlyRepository.getDetailPreviredAFP(idperiod);
   return res;
}

 async function createElectronicBookExcel(idperiod, idcostcenter){
    const res = await templateRemunerationMonthlyRepository.createElectronicBookExcel(idperiod, idcostcenter);
    return res;
 }


 async function createElectronicBookExcelNotHeaders(idperiod, idcostcenter){
    const res = await templateRemunerationMonthlyRepository.createElectronicBookExcelNotHeaders(idperiod, idcostcenter);
    return res;
 }

  async function createElectronicBookCsv(idperiod, idcostcenter){
    const res = await templateRemunerationMonthlyRepository.createElectronicBookCsv(idperiod, idcostcenter);
    return res;
 }
 async function getDJ(year){
    const res = await templateRemunerationMonthlyRepository.getDJ(year);
    return res;
 }
 async function createDJExcel(year){
    const res = await templateRemunerationMonthlyRepository.createDJExcel(year);
    return res;
 }
 async function createDJCsv(year){
    const res = await templateRemunerationMonthlyRepository.createDJCsv(year);
    return res;
 }
 async function getElectronicBook(idperiod, idcostcenter){
    const res = await templateRemunerationMonthlyRepository.getElectronicBook(idperiod, idcostcenter);
    return res;
 }

 async function getCertificateRemuneration(year, idEmployee){
    const res = await templateRemunerationMonthlyRepository.getCertificateRemuneration(year, idEmployee);
    return res;
 }
 async function createCertificateRemuneration(year, idEmployee){
    const res = await templateRemunerationMonthlyRepository.createCertificateRemuneration(year, idEmployee);
    return res;
 }
 async function createPDFCertificateRemuneration(year, idEmployee){
    const res = await templateRemunerationMonthlyRepository.createPDFCertificateRemuneration(year, idEmployee);
    return res;
 }


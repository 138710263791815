import { Loading } from "@/components/common/Loading";
import { AssistanceService } from "@/service/services/Assistance.service";
import { companiesService } from "@/service/services/Companies.service";
import { requestTypeService } from "@/service/services/RequestType.service";
import { Typography } from "@mui/material";
import { useState } from "react";
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { ModalHeader } from "../ModalHeader";
import { Props } from "./ModalGroupCompanyDelete.type";

export const ModalGroupCompanyDelete: React.FC<Props> = (props:any): JSX.Element => {
    const { open, closeModal, groupCompany, onList, clearState } = props;
    const [loading, setLoading] = useState<boolean>(false);

    const onDeleted= async()=>{
        setLoading(true);
        await companiesService.deleteGroupCompany(groupCompany.id).then((data) => {
            onList && onList();
            closeModal && closeModal();
        })
        setLoading(false);
    }
  
  return (
    <>
    {loading && <Loading/>}
    <Modal
      open={open}
      handleClose={closeModal}
      size="xs"
    >
      <ModalHeader
        text="ELIMINAR EMPRESA"
        className='positionElements'
        onCancel={closeModal}
        clearState={clearState}
      >
      </ModalHeader>

      <ModalBody>
        <Typography variant="subtitle1" className="textColor"> 
          ¿Está seguro de eliminar esta compañia?
        </Typography>
      </ModalBody>

      <ModalFooter 
        confirmText="Eliminar"
        cancelText="CANCELAR"
        onCancel={closeModal}
        className="footerRight"
        onConfirm={onDeleted}
        clearState={clearState}
      />
    </Modal>
    </>
  )
}
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, InputAdornment, FormControl, FormControlLabel, Checkbox, Stack, Tooltip, Select, MenuItem, InputLabel, } from '@mui/material'
import React, { useState, useRef, useEffect } from "react";
import AssignmentIcon from '@mui/icons-material/Assignment';
import '../Workers.css'
import TextField from '@mui/material/TextField';
import { TableData } from "@/components/common/Table";
import { EmploymentHistoryService } from "@/service/services/EmploymentHistory.service";
import { Loading } from "@/components/common/Loading";


const header = [
   // { name: 'start_date', label: 'Estado', width: '' },
   { name: 'start_date', label: 'Fecha de Inicio', width: '' },
   { name: 'end_date', label: 'Fecha de termino', width: '' },
   { name: 'causal', label: 'causal', width: '' },
]

const dataAction = [
   { name: 'edit', route: null },
   { name: 'delete', route: null},
]

export const DataModalWork: React.FC<any> = (props): JSX.Element => {
   const { employee, dataInitial } = props;

   const contractType = dataInitial?.ContractType || [];
   const contractState = dataInitial?.State || [];

   const [loading, setLoading] = useState(false);
   const [error, setError] = useState<any>('')
   
   const [employmentHistories, setEmploymentHistories] = useState<any>([]);
   const [formValues, setFormValues] = useState<any>({
      idemployee: employee.id,
      idtype_contract: '',
      status_contract: '',
      start_date: '',
      end_date: '',
      causal: '',
   });

   useEffect(() => {
      getEmploymentHistory();
   }, []);

   const getEmploymentHistory = async () => {
      setLoading(true);
      await EmploymentHistoryService.getEmploymentHistory(employee.id).then((data) => {
         setEmploymentHistories(data.data || [])
         setLoading(false);
      });
   }

   const handleInput = (e) => {
      const name = e.name? e.name : e.target.name;
      setError('');
      switch(name){
         case 'start_date':
            setFormValues({...formValues, start_date: e.target.value});
            break;
         case 'end_date':
               setFormValues({ ...formValues, end_date: e.target.value});
            break;
         case 'idtype_contract':
            setFormValues({ ...formValues, idtype_contract: e.target.value });
            break;
         case 'status_contract':
            setFormValues({ ...formValues, status_contract: e.target.value});
            break;
         case 'causal':
            setFormValues({ ...formValues, causal: e.target.value});
            break;
      }
   }

   const recuperarData = (data) => {
      switch(data.action){
         case 'edit':
            // setFormValues({
            //    idemployee: employee.id,
            //    idtype_contract: data.idtype_contract || '',
            //    status_contract: data.status_contract || '',
            //    start_date: data.start_date || '',
            //    end_date: data.end_date || '',
            //    causal: data.causal || '',
            // })
            break;
         case 'delete':
            break;
      }
   }

   const causalValue = [
      { label: 'ART. 160 N 2: Negociaciones prohibidas por escrito' },
      { label: 'ART. 160 N 3: No concurrencia a las labores' },
      { label: 'ART. 160 N 4: Negociaciones prohibidas por escrito' },
      { label: 'ART. 160 N 5: Abandono del trabajo' },
      { label: 'ART. 160 N 6: Negociaciones prohibidas por escrito' }
   ]

   // const causalValue = [
   //    { id:1, label: 'ART. 160 N 2: Negociaciones prohibidas por escrito' },
   //    { id:2, label: 'ART. 160 N 3: No concurrencia a las labores' },
   //    { id:3, label: 'ART. 160 N 4: Negociaciones prohibidas por escrito' },
   //    { id:4, label: 'ART. 160 N 5: Abandono del trabajo' },
   //    { id:5, label: 'ART. 160 N 6: Negociaciones prohibidas por escrito' }
   // ]

   const createEmploymentHistory = async() => {
      if(!formValues.start_date){
         return setError('start_date');
      }
      if(!formValues.end_date){
         return setError('end_date');
      }
      if(!formValues.idtype_contract){
         return setError('idtype_contract');
      }
      if(!formValues.status_contract){
         return setError('status_contract');
      }

      setLoading(true);
      await EmploymentHistoryService.createEmploymentHistory(formValues).then((data) => {
         getEmploymentHistory();
         setFormValues({
            idemployee: employee.id,
            idtype_contract: '',
            status_contract: '',
            start_date: '',
            end_date: '',
            causal: '',
         })
      });
      setLoading(false);
   }

   return (
      <Container maxWidth='xl'>
         {loading && <Loading />}
         <Grid container spacing={3} mt={2} >
            <Grid container mb={2}>
               <Grid item xs={10}>
                  <Typography variant="h6" id="modal-modal-title" className='tittle'>
                     Mantenedor de Historial Laboral
                  </Typography>
                  <Typography variant="caption" id="modal-modal-title">
                  Incorpore Historial Laboral del trabajador
                  </Typography>
               </Grid>
               <Grid item xs={2} textAlign="end">
                  <AssignmentIcon style={{ color: '#FF9700' }} />
               </Grid>
            </Grid>

            <Grid container spacing={3} className={`e-mb-lg`}>

               <Grid item xs={12} sm={4}>               
                  <TextField
                     variant="standard"
                     fullWidth
                     name="start_date"
                     label="Fecha de inicio"
                     type="date"
                     onChange={(e) => {handleInput(e);}}
                     value={formValues.start_date}
                     InputLabelProps={{
                        shrink: true,
                     }}
                     error={error == 'start_date' ? true: false}
                  />
               </Grid>
               <Grid item xs={12} sm={4}>
                  <TextField
                     variant="standard"
                     fullWidth
                     name="end_date"
                     label="Fecha termino"
                     type="date"
                     onChange={(e) => {handleInput(e);}}
                     value={formValues.end_date}
                     InputLabelProps={{
                        shrink: true,
                     }}
                     error={error == 'end_date' ? true: false}
                  />
               </Grid>

               <Grid item xs={12} sm={4}>
               <FormControl variant="standard" sx={{width:'100%'}}>
                  <InputLabel id="demo-simple-select-standard-label">Tipo contrato</InputLabel>
                  <Select
                        id="idtype_contract"
                        name="idtype_contract"
                        label="ipo contrato"
                        variant="standard"
                        value={formValues.idtype_contract}
                        onChange={(e) => {handleInput(e);}}
                        error={error == 'idtype_contract' ? true: false}
                        fullWidth
                  >
                        {
                        contractType.map((type, i) => {
                           return <MenuItem key={i} value={type.id}>{type.name}</MenuItem>;
                        })
                        }
                  </Select>
               </FormControl>
               </Grid>

               <Grid item xs={12} sm={4}>
                  <FormControl variant="standard" sx={{width:'100%'}}>
                  <InputLabel id="demo-simple-select-standard-label">Estado contrato</InputLabel>
                  <Select
                     id="status_contract"
                     name="status_contract"
                     variant="standard"
                     value={formValues.status_contract}
                     onChange={(e) => {handleInput(e);}}
                     error={error == 'status_contract' ? true: false}
                     fullWidth
                  >
                     {
                        contractState.map((state, i) => {
                           return <MenuItem key={i} value={state.valueStatus}>{state.status}</MenuItem>;
                        })
                     }
                  </Select>
                  </FormControl>
               </Grid>
               
               <Grid item xs={8} sx={{marginTop:'3px'}}>
                  <Autocomplete
                     id="disable-portal"
                     size='small'
                     disablePortal
                     options={causalValue}
                     renderInput={(params) => <TextField {...params} variant="standard"  label="Causal Último Finiquito" />}
                  />
               </Grid>
            </Grid>
            <Grid container mt={2}>
               <Grid item xs={12} textAlign="center">
                  <button className='btn_add' onClick={createEmploymentHistory} style={{cursor: 'pointer'}}>
                     Agregar
                  </button>
               </Grid>

            </Grid>
            <Grid xs={12}>
               <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={2}>
                  LISTADO DE HISTORIAL LABORAL
               </Typography>
            </Grid>
            <TableData
               header={header}
               action={dataAction}
               data={employmentHistories || []}
               RecuperarData={recuperarData}
               // row_initial={3}
            />
         </Grid>
      </Container >
   );
};


import { AssistanceService } from "@/service/services/Assistance.service";
import { PhasesService } from "@/service/services/Phases.service";
import { Typography } from "@mui/material";
import { useState } from "react";
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { ModalHeader } from "../ModalHeader";
import { Props } from "./ModalExtraHourDelete.type";

export const ModalExtraHourDelete: React.FC<Props> = (props:any): JSX.Element => {
    const { open, closeModal, extraHour, onListExtraHours, workerSelected, clearState } = props;
    const [loading, setLoading] = useState<boolean>(false);

    const onDeleted= async()=>{
        setLoading(true);
        await AssistanceService.deleteExtraHour(extraHour.id).then((data) => {
            onListExtraHours && onListExtraHours(workerSelected.id);
            closeModal && closeModal();
        })
        setLoading(false);
    }
  
  return (
    <>
    <Modal
      open={open}
      handleClose={closeModal}
      size="xs"
    >
      <ModalHeader
        text="ELIMINAR HORA EXTRA"
        className='positionElements'
        onCancel={closeModal}
        clearState={clearState}
      >
      </ModalHeader>

      <ModalBody>
        <Typography variant="subtitle1" className="textColor"> 
          ¿Está seguro de eliminar esta Hora extra?
        </Typography>
      </ModalBody>

      <ModalFooter 
        confirmText="Eliminar"
        cancelText="CANCELAR"
        onCancel={closeModal}
        className="footerRight"
        onConfirm={onDeleted}
        clearState={clearState}
      />
    </Modal>
    </>
  )
}
import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from '@/components/common/Loading';
import { Alert, Autocomplete, Backdrop, Button, Select, Card, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { ReportService } from '@/service/services/Report.service';
import { WorkerService } from '@/service/services/Worker.service';
import { readLocalStorage, saveLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_RUTA, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { PeriodService } from "@/service/services/Period.service";
import { API_URL_BASE } from '@/toolbox/defaults/app';


export const ReportLiquidations: React.FC<any> = (props: any): JSX.Element => {

    const [data, setData] = useState<any>('')

    //data localstorage
    const data_user = readLocalStorage(KEY_USER_DATA);
    const id_company = data_user?.user?.id_company;

    const [periods, setPeriods] = useState<any>([]);
    const [years, setYears] = useState<any>([]);
    const [yearSelected, setYearSelected] = useState<any>('');
    const [monthSelected, setMonthSelected] = useState<any>('');

    const [rutasHeader, setRutasHeader] = React.useState([]);
    //DATA
    const [branch_office_obj, setBranch_office_obj] = React.useState([]);
    const [business_area_obj, setBusiness_area_obj] = React.useState([]);
    const [chargue_type_obj, setChargue_typee_obj] = React.useState([]);
    const [cost_center_obj, setCost_center_obj] = React.useState([]);
    const [countEmployees_total, setCountEmployees_total] = React.useState([]);

    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    useEffect(() => {
        getPeriods();
    }, []);
    useEffect(() => {
        separarFunciones();
    }, [data]);


    const getPeriods = async () => {
        setLoading(true);
        const resp: any = await PeriodService.getPeriods();
        const newData = [];
        const arrayYears = [];
        resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
            newData.push({
                id: item.id,
                month: item.month,
                year: item.year,
                alias_name: item.month + '-' + item.year,
            })
            const found = arrayYears.find(element => element == item.year);
            if (!found) { arrayYears.push(item.year); }

        });
        setYears(arrayYears);
        setPeriods(newData || []);
        setLoading(false);
    }
    const limpiarData = () => {
        setBranch_office_obj([]);
        setBusiness_area_obj([]);
        setChargue_typee_obj([]);
        setCost_center_obj([]);
    }
    const showLiquidationsReport = async () => {
        limpiarData();
        setLoading(true);
        if (!monthSelected || !yearSelected) {
            setLoading(false);
            return (
                setSnackBarConfig(prev => ({
                    ...prev, open: true,
                    severity: 'warning',
                    message: `Debe seleccionar Mes y Año.`
                }
                ))
            );
        }

        const periodSelected = monthSelected + '-' + yearSelected;
        const period = periods.find(element => element.alias_name == periodSelected);
        const dataPre = {
            idperiod: period.id,
            month: period.month,
            year: period.year
        }
        await ReportService.getReportLiquidation(id_company, dataPre.idperiod).then((resp) => {
            setData(resp || []);
            setLoading(false);
        })

    }
    console.log('data data: ', data?.data?.employee)

    const separarFunciones = () => {


        const branch_office = data?.data?.employee?.branch_office || [];
        const business_area = data?.data?.employee?.business_area || [];
        const chargue_type = data?.data?.employee?.chargue_type || [];
        const cost_center = data?.data?.employee?.cost_center || []
        const countEmployees = data?.data?.employee?.countEmployees || 0;

        setCountEmployees_total(countEmployees);
        console.log('JJOJOJO: ', cost_center)
        if (branch_office.length > 0) {
            let obj;
            let branch_office_obj_values = [];
            for (let index = 0; index < branch_office.length; index++) {
                const keys = Object.keys(branch_office[index]);
                const values = Object.values(branch_office[index]);
                obj = {
                    ...obj,
                    name: keys[0],
                    name2: keys[1],
                    chargue_type: values[0],
                    full_payment: values[3]
                }
                branch_office_obj_values.push(obj || {});
            }
            setBranch_office_obj(branch_office_obj_values)
        }
        if (business_area.length > 0) {
            let obj;
            let business_area_obj_values = [];
            for (let index = 0; index < business_area.length; index++) {
                const keys = Object.keys(business_area[index]);
                const values = Object.values(business_area[index]);
                console.log(values)
                obj = {
                    ...obj,
                    name: keys[0],
                    name2: keys[1],
                    business_area: values[0],
                    full_payment: values[3]
                }
                business_area_obj_values.push(obj || {});
            }
            setBusiness_area_obj(business_area_obj_values)
        }
        if (chargue_type.length > 0) {
            let obj;
            let chargue_type_obj_values = [];
            for (let index = 0; index < chargue_type.length; index++) {
                const keys = Object.keys(chargue_type[index]);
                const values = Object.values(chargue_type[index]);
                obj = {
                    ...obj,
                    name: keys[0],
                    name2: keys[1],
                    chargue_type: values[0],
                    full_payment: values[3]
                }
                chargue_type_obj_values.push(obj || {});
            }
            setChargue_typee_obj(chargue_type_obj_values)
        }
        if (cost_center.length > 0) {
            let obj;
            let cost_center_obj_values = [];
            for (let index = 0; index < cost_center.length; index++) {
                const keys = Object.keys(cost_center[index]);
                const values = Object.values(cost_center[index]);
                console.log(values)
                obj = {
                    ...obj,
                    name: keys[0],
                    name2: keys[1],
                    cost_center: values[0],
                    full_payment: values[3]
                }
                cost_center_obj_values.push(obj || {});
                // console.log('asas: ',cost_center_obj)
            }
            setCost_center_obj(cost_center_obj_values)
        }
        // if(countEmployees_obj.length >0){
        //     let obj;
        //     for (let index = 0; index < branch_office.length; index++) {
        //         const keys = Object.keys(branch_office[index]);
        //         const values = Object.values(branch_office[index]);
        //         obj={
        //             ...obj,
        //             name:keys[0],
        //             name2:keys[1],
        //             chargue_type: values[0],
        //             full_payment: values[1]
        //         }
        //         branch_office_obj.push(obj);
        //     }
        // }

    }

    const  getExcelReport = async() =>{
        limpiarData();
        setLoading(true);
        if (!monthSelected || !yearSelected) {
            setLoading(false);
            return (
                setSnackBarConfig(prev => ({
                    ...prev, open: true,
                    severity: 'warning',
                    message: `Debe seleccionar Mes y Año.`
                }
                ))
            );
        }

        const periodSelected = monthSelected + '-' + yearSelected;
        const period = periods.find(element => element.alias_name == periodSelected);
        const dataPre = {
            idperiod: period.id,
            month: period.month,
            year: period.year}
        
        const respExcel = await ReportService.getReportLiquidationExcel(id_company,dataPre?.idperiod);
       
        const btn_excel = document.createElement("a")
        btn_excel.href = `${API_URL_BASE}${respExcel.data.link}`
        btn_excel.download = 'Reporte.xlsx'

        document.body.appendChild(btn_excel);
        btn_excel.click();
        document.body.removeChild(btn_excel)
        setLoading(false);
   }
    return (
        <Protected>
            {loading && <Loading />}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>
            <Container>
                <Typography variant='h6' component='h1' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                    REPORTE POR LIQUIDACIONES
                </Typography>
                <Grid item xs={12} spacing={2} container direction={'row'} justifyContent='center' alignItems={'center'} marginTop='15px'>
                    <Grid item xs={12} md={4} display='flex' alignItems="center" spacing={2}>
                        <Typography variant="body1" style={{ marginRight: '10px' }}><b>Período:</b> </Typography>
                        <Select
                            id="id_education_level"
                            variant="standard"
                            value={yearSelected}
                            onChange={(event) => {
                                setYearSelected(event.target.value);
                            }}
                            fullWidth
                        >
                            {
                                years.map((year, i) => {
                                    return <MenuItem key={i} value={year}>{year}</MenuItem>;
                                })
                            }
                        </Select>
                    </Grid>
                    <Grid item xs={12} md={4} display='flex' alignItems="center">
                        <Typography style={{ marginRight: '10px' }} variant="body1"><b>Mes:</b> </Typography>
                        <Select
                            id="id_education_level"
                            variant="standard"
                            value={monthSelected}
                            onChange={(event) => { setMonthSelected(event.target.value) }}
                            fullWidth
                        >
                            <MenuItem value={'01'}>Enero</MenuItem>
                            <MenuItem value={'02'}>Febrero</MenuItem>
                            <MenuItem value={'03'}>Marzo</MenuItem>
                            <MenuItem value={'04'}>Abril</MenuItem>
                            <MenuItem value={'05'}>Mayo</MenuItem>
                            <MenuItem value={'06'}>Junio</MenuItem>
                            <MenuItem value={'07'}>Julio</MenuItem>
                            <MenuItem value={'08'}>Agosto</MenuItem>
                            <MenuItem value={'09'}>Septiembre</MenuItem>
                            <MenuItem value={'10'}>Octubre</MenuItem>
                            <MenuItem value={'11'}>Noviembre</MenuItem>
                            <MenuItem value={'12'}>Diciembre</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} md={4} >
                        <Button variant="contained" onClick={showLiquidationsReport}>
                            Filtrar
                        </Button>
                    </Grid>
                </Grid>

                <Typography variant='subtitle1' component='h4' gutterBottom sx={{ m: 1, fontWeight: 600, width: "100%", marginTop: '20px' }}>
                    Se encontraron {countEmployees_total?countEmployees_total:0} empleados registrados
                </Typography>

                <Button variant="contained" onClick={()=>{getExcelReport()}}>
                        Descargar Excel
                    </Button>

                <Card sx={{ width: "100%", marginTop: '20px' }}>
                    <Typography variant='h6' component='h2' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                        Sucursales
                    </Typography>
                    <TableContainer>
                        <Table>
                            <TableHead sx={{ bgcolor: '#EDEFF1' }}>

                                <TableRow >
                                    <TableCell >
                                        <Typography>Nombre</Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography>Cantidad Empleados</Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography>Total Pago</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {branch_office_obj?.map(row => {
                                    return (
                                        <TableRow >
                                            <TableCell >
                                                {row.name}
                                            </TableCell>
                                            <TableCell >
                                                {row.chargue_type}
                                            </TableCell>
                                            <TableCell >
                                                {row.full_payment}
                                            </TableCell>
                                        </TableRow >
                                    )
                                })}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
                <Card sx={{ width: "100%", marginTop: '20px' }}>
                    <Typography variant='h6' component='h2' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                        Área de Negocio
                    </Typography>
                    <TableContainer>
                        <Table>
                            <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                <TableRow >
                                    <TableCell >
                                        <Typography>Nombre</Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography>Cantidad Empleados</Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography>Total Pago</Typography>
                                    </TableCell>
                                </TableRow>


                            </TableHead>
                            <TableBody>
                                {business_area_obj?.map(row => {
                                    return (
                                        <TableRow >
                                            <TableCell >
                                                {row.name}
                                            </TableCell>
                                            <TableCell >
                                                {row.business_area}
                                            </TableCell>
                                            <TableCell >
                                                {row.full_payment}
                                            </TableCell>
                                        </TableRow >
                                    )
                                })}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
                <Card sx={{ width: "100%", marginTop: '20px' }}>
                    <Typography variant='h6' component='h2' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                        Tipos de cargo
                    </Typography>
                    <TableContainer>
                        <Table>
                            <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                <TableRow >
                                    <TableCell >
                                        <Typography>Nombre</Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography>Cantidad Empleados</Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography>Total Pago</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {chargue_type_obj?.map(row => {
                                    return (
                                        <TableRow >
                                            <TableCell >
                                                {row.name}
                                            </TableCell>
                                            <TableCell >
                                                {row.chargue_type}
                                            </TableCell>
                                            <TableCell >
                                                {row.full_payment}
                                            </TableCell>
                                        </TableRow >
                                    )
                                })}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
                {/* TABLA CENTRO DE COSTOS */}
                <Card sx={{ width: "100%", marginTop: '20px' }}>
                    <Typography variant='h6' component='h2' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                        Centro de Costos
                    </Typography>
                    <TableContainer>
                        <Table>
                            <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                <TableRow >
                                    <TableCell >
                                        <Typography>Nombre</Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography>Cantidad Empleados</Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography>Total Pago</Typography>
                                    </TableCell>
                                </TableRow>


                            </TableHead>
                            <TableBody>
                                {cost_center_obj?.map(row => {
                                    return (
                                        <TableRow >
                                            <TableCell >
                                                {row.name}
                                            </TableCell>
                                            <TableCell >
                                                {row.cost_center}
                                            </TableCell>
                                            <TableCell >
                                                {row.full_payment}
                                            </TableCell>
                                        </TableRow >
                                    )
                                })}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </Container>
        </Protected>
    )
};
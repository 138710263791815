import { AfpRepository } from "../repositories/Afp.repository";
import { RemNationalityRepository } from "../repositories/RemNationality.repository";

export const RemNationalityService = {
    getNationalities,
}

async function getNationalities(){
    const res = await RemNationalityRepository.getNationalities();
    return res;
}
import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Alert, Backdrop, CircularProgress, Container, Divider, Grid, IconButton, Menu, Snackbar, Typography,Button, Box, Card} from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { Props } from './LiquidationMasive.type';
import { TableBody, Select, FormControl, InputLabel, MenuItem, TextField, TableCell, TableContainer, TableHead, TablePagination, TableRow, Table, createTheme, ThemeProvider } from '@mui/material';
import {useHistory} from 'react-router-dom'
import * as Routes from '@constants/route-map';
import { PeriodService } from '@/service/services/Period.service';
import { RemunerationService } from '@/service/services/Remuneration.service';


export const LiquidationMasiveView: React.FC<Props> = (props: any): JSX.Element => {
   const history= useHistory();
   const [loading, setLoading] = useState<boolean>(false);
   const [masiveLiquidations, setMasiveLiquidations] = useState<any>([]);
   const [masiveLiquidationSelected, setmasiveLiquidationSelected] = useState<any>('');
   const [showMasiveLiquidationModal, setShowMasiveLiquidationModal] = useState<boolean>(false);
   const [showMasiveLiquidationDeleteModal, setShowMasiveLiquidationDeleteModal] = useState<boolean>(false);
   const [actionSelected, setActionSelected] = useState<any>('');

   const [periods, setPeriods] = useState<any>([]);
   const [years, setYears] = useState<any>([]);
   const [yearSelected, setYearSelected] = useState<any>('');
   const [monthSelected, setMonthSelected] = useState<any>('');

   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   });

   useEffect(()=> {
      getPeriods();
   },[]);

   const getPeriods = async() => {
      setLoading(true);
      const resp:any = await PeriodService.getPeriods();
      const newData = [];
      const arrayYears = [];
      resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
         newData.push({
            id: item.id,
            month: item.month,
            year: item.year,
            alias_name: item.month + '-' +  item.year,
         })
         const found = arrayYears.find(element => element == item.year);
         if(!found) { arrayYears.push(item.year); }
         
      });
      setYears(arrayYears);
      setPeriods(newData || []);
      setLoading(false);
   }

   const showLiquidationMasive = async() => {
      setLoading(true);
      if(!monthSelected || !yearSelected){
         setLoading(false);
         return (
            setSnackBarConfig(prev => ({ ...prev, open: true, 
               severity:'warning', 
               message: `Debe seleccionar Mes y Año.` }
            ))
         );
      }

      const periodSelected = monthSelected + '-' + yearSelected;
      const period = periods.find(element => element.alias_name == periodSelected);
      const dataPre = {
         idperiod: period.id,
         month: period.month,
         year: period.year
      }

      const resp:any = await RemunerationService.preRemunerationStoreMassive(dataPre);
      if(resp?.data?.detail[0]?.status != true){
         setLoading(false);
         return (
            setSnackBarConfig(prev => ({ ...prev, open: true, 
               severity:'error', 
               message: `${resp?.data?.detail[0]?.message || 'Ocurrió algun error inesperado'}` }
            ))
         );
      }

      // console.log(resp);     

      let detailEmployees = [];
      if(resp.data.detail && (resp.data.detail).length > 0){
         for (let i = 0; i < (resp.data.detail).length; i++) {

            let detailEmployee = resp.data.detail[i].detail.employee;

            const TDL = resp.data.detail[i].detail.TDL;
            detailEmployee.TDL = TDL;

            const TotalDP = resp.data.detail[i].detail.TDP;
            detailEmployee.TotalDP = TotalDP;

            const TotalDV = resp.data.detail[i].detail.TDV;
            detailEmployee.TotalDV = TotalDV;

            const TotalHINT = resp.data.detail[i].detail.THINT;
            detailEmployee.TotalHINT = TotalHINT;

            const TotalHIT = resp.data.detail[i].detail.THIT;
            detailEmployee.TotalHIT = TotalHIT;

            const TotalHNINT = resp.data.detail[i].detail.THNINT;
            detailEmployee.TotalHNINT = TotalHNINT;
            
            const TotalHNIT = resp.data.detail[i].detail.THNIT;
            detailEmployee.TotalHNIT = TotalHNIT;

            detailEmployees.push(detailEmployee);
         }
      }

      const dataPreMassive = {
         dataMassive: detailEmployees,
         period: period,
      }

      setLoading(false);
      history.push(Routes.ROUTE_LIQUIDATION_MASIVE_PREVIEW, dataPreMassive);
   }

   return (
      <Protected>
         {loading && <Loading />}
         <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                  {snackBarConfig.message || ""}
            </Alert>
         </Snackbar>

         <Container>
            <Grid container spacing={3}>
               <Grid item xs={12} md={12}>
                  <Typography variant='h6' component='h1' gutterBottom className="tittle">
                     LIQUIDACIONES MASIVAS
                  </Typography>
                  <Grid sx={{ pb: 2 }}>
                     <Typography >Seleccione periodo y mes para generar remuneración masiva</Typography>
                  </Grid>
               </Grid>
            </Grid>

            <Grid item xs={12} spacing={2} container direction={'row'} justifyContent='center' alignItems={'center'} marginTop='15px'>
                  <Grid item xs={12} md={4} display='flex' alignItems="center" spacing={2}>
                     <Typography variant="body1" style={{marginRight:'10px'}}><b>Período:</b> </Typography>
                     <Select
                        id="id_education_level"
                        variant="standard"
                        value={yearSelected}
                        onChange={(event) => {
                           setYearSelected(event.target.value);
                        }}
                        fullWidth
                     >
                        {
                           years.map((year, i) => {
                              return <MenuItem key={i} value={year}>{year}</MenuItem>;
                           })
                        }
                     </Select>
                  </Grid>
                  <Grid item xs={12} md={4} display='flex' alignItems="center">
                     <Typography style={{marginRight:'10px'}}variant="body1"><b>Mes:</b> </Typography>
                     <Select
                        id="id_education_level"
                        variant="standard"
                        value={monthSelected}
                        onChange={(event) => {setMonthSelected(event.target.value)}}
                        fullWidth
                     >
                        <MenuItem value={'01'}>Enero</MenuItem>
                        <MenuItem value={'02'}>Febrero</MenuItem>
                        <MenuItem value={'03'}>Marzo</MenuItem>
                        <MenuItem value={'04'}>Abril</MenuItem>
                        <MenuItem value={'05'}>Mayo</MenuItem>
                        <MenuItem value={'06'}>Junio</MenuItem>
                        <MenuItem value={'07'}>Julio</MenuItem>
                        <MenuItem value={'08'}>Agosto</MenuItem>
                        <MenuItem value={'09'}>Septiembre</MenuItem>
                        <MenuItem value={'10'}>Octubre</MenuItem>
                        <MenuItem value={'11'}>Noviembre</MenuItem>
                        <MenuItem value={'12'}>Diciembre</MenuItem>
                     </Select>
                  </Grid>
                  <Grid item xs={12} md={4} >
                     <Button onClick={showLiquidationMasive} >
                        Siguiente
                     </Button>
                  </Grid>
            </Grid>
         </Container>
      </Protected>
   )
};
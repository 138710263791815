import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Container } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { Loading } from '@/components/common/Loading';
import { Props } from './TimeZone.type';
import { TimeZoneService } from '@/service/services/TimeZone.service';
import { ModalTimeZone } from '@/components/Modal/ModalTimeZone/ModalTimeZone';
import { ModalTimeZoneDelete } from '@/components/Modal/ModalTimeZoneDelete';

export const TimeZoneView: React.FC<Props> = (props:any): JSX.Element => {

   const [loading, setLoading] = useState<boolean>(false);
   const [timeZones, setTimeZones] = useState([]);
   const [showTimeZoneModal, setShowTimeZoneModal] = useState<boolean>(false);
   const [showTimeZoneDeleteModal, setShowTimeZoneDeleteModal] = useState<boolean>(false);
   const [actionSelected, setActionSelected] = useState<String>('');
   const [timeZoneSelected, setTimeZoneSelected] = useState<String>('');

   useEffect(() => {
    getTimeZone()
   },[]);

   const getTimeZone = async () => {
      setLoading(true);
      const response = await TimeZoneService.getTimeZone();
      if (response.data) {
        setTimeZones(response.data || [])
      } else {
         console.log('error')
      }
      setLoading(false);
   }

   function RecuperarData(value){
      console.log(value);
      setTimeZoneSelected(value);
   }

  return (
      <Protected>
         {loading && <Loading/>}
         <Container>
            <TableDataV2
               title={'Zonas horarias'}
               data={timeZones}
               header={[
                  { name: 'description', label: 'Descripcion', filter: false, Chip: true },
                  { name: 'operation', label: 'Operación', filter: false, Chip: true },
                  { name: 'time_zone', label: 'Zona Horaria', filter: false, Chip: true }
               ]}
               actionSelect={setActionSelected}
               setModalSave={setShowTimeZoneModal}
               setModalDelete={setShowTimeZoneDeleteModal}
               status_action
               // checkbox
               RecuperarData={RecuperarData}
            />
         </Container>

         { 
            showTimeZoneModal && (
               <ModalTimeZone
                  open={showTimeZoneModal}
                  closeModal={()=>{setShowTimeZoneModal(false)}}
                  actionSelected={actionSelected}
                  onListZoneTime={getTimeZone}
                  timeZone={timeZoneSelected}
                  clearState={()=>{setTimeZoneSelected('')}}
               />
            )
         }
         {
            actionSelected === 'delete' && (
               <ModalTimeZoneDelete
                  open={showTimeZoneDeleteModal}
                  closeModal={()=>{setShowTimeZoneDeleteModal(false)}}
                  actionSelected={actionSelected}
                  onListZoneTime={getTimeZone}
                  timeZone={timeZoneSelected}
                  clearState={()=>{setTimeZoneSelected('')}}
               />
            )
         }
      
      </Protected>
   )
};
import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './User.type'
import { Alert, Backdrop, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar } from "@mui/material";
import { SpinnerGrow } from '@/components/common/Spinner';
import { TableDataV2 } from '@/components/common/Tablev2';
import { userRepository } from '@/service/repositories/User.repository';
import { userService } from '@/service/services/User.service';
import { Loading } from '@/components/common/Loading';
import { ModalUser } from '@/components/Modal/ModalUser';


export const UsersView: React.FC<Props> = (props:any): JSX.Element => {
   const [loading, setLoading] = useState<boolean>(false);
   const [users, setUsers]= useState([]);
   const [showUserModal, setShowUserModal] = useState<boolean>(false);
   const [actionSelected, setActionSelected] = useState<String>('');
   const [userSelected, setUserSelected] = useState<String>('');

   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   });

   useEffect(() => {
      getUsers();
   },[]);

   const getUsers = async () => {
      setLoading(true);
      const response = await userService.getUsers();
      if (response.data) {
         setUsers(response.data || [])
      } else {
         console.log('error')
      }
      setLoading(false);
   }

   function RecuperarData(value){
      console.log(value);
      setUserSelected(value);
   }

  return (
      <Protected>
         {loading && <Loading/>}
         <Container>
            <TableDataV2
               title={'Usuarios'}
               data={users}
               header={[
                  { name: 'user_name', label: 'Nombre de Usuario', filter: false, Chip: false },
                  { name: 'n_document', label: 'N Documento', filter: false, Chip: true },
                  { name: 'email', label: 'Correo', filter: false, Chip: false },
                  { name: 'role', label: 'Rol', filter: false, Chip: true },
               ]}
               actionSelect={setActionSelected}
               setModalSave={setShowUserModal}
               setModalHelper={setShowUserModal}
               btn_edit_disable={true}
               btn_helper
               status_action
               RecuperarData={RecuperarData}
            />
         </Container>

         { 
            showUserModal && (
               <ModalUser
                  open={showUserModal}
                  closeModal={()=>{setShowUserModal(false)}}
                  actionSelected={actionSelected}
                  onListUsers={getUsers}
                  user={userSelected}
                  clearState={()=>{setUserSelected('')}}
               />
            )
         }
      
      </Protected>
   )
};
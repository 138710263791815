import { expenseAdminRepository } from "../repositories/ExpenseAdmin.repository";

export const ExpenseAdminService = {
    getExpenseAdmin,
    editExpenseAdmin,
}

async function getExpenseAdmin(review_status, status){
    const res = await expenseAdminRepository.getExpenseAdmin(review_status, status);
    return res;
}
async function editExpenseAdmin(data, idReport){
    const res = await expenseAdminRepository.editExpenseAdmin(data, idReport);
    return res;
}

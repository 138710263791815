import { userRepository } from "../repositories/User.repository";
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { workerRepository } from "../repositories/Worker.repository";
export const WorkerService = {
   // getUser: (idToken: string) => {
   //    return userRepository.getUser(idToken)
   // },
   getUser,
   getUserPage,
   getUserSearch,
   updateParticipationUsers,
   ResendPassword,
   postCreateWorkersContract,
   getDataInitialEmployee,
   getWorkers: (search= "") => {
      return workerRepository.getWorkers()
   },
   createWorker: (dataWorker) => {
      return workerRepository.createWorker(dataWorker)
   },
   editWorker: (dataUser, id_worker) => {
      return workerRepository.editWorker(dataUser, id_worker)
   },
   getContracts: () => {
      return workerRepository.getContracts()
   },
   getEmployeeContract: (id) => {
      return workerRepository.getEmployeeContract(id)
   },
   patchEmployeeContract: (dataEmployee, id_employee) => {
      let objNew = {}
      const objOriginal = readLocalStorage('EMPLOYEE_END');
      if(objOriginal.n_document != dataEmployee.n_document ){
         objNew = {...objNew, n_document: dataEmployee.n_document}
      }
      if(objOriginal.name != dataEmployee.name ){
         objNew = {...objNew, name: dataEmployee.name}
      }
      if(objOriginal.surname != dataEmployee.surname ){
         objNew = {...objNew, surname: dataEmployee.surname}
      }
      if(objOriginal.second_surname != dataEmployee.second_surname ){
         objNew = {...objNew, second_surname: dataEmployee.second_surname}
      }
      if(objOriginal.idcivilstatus != dataEmployee.idcivilstatus ){
         objNew = {...objNew, idcivilstatus: dataEmployee.idcivilstatus}
      }
      if(objOriginal.idcategoryemployee != dataEmployee.idcategoryemployee ){
         objNew = {...objNew, idcategoryemployee: dataEmployee.idcategoryemployee}
      }
      if(objOriginal.idprofession != dataEmployee.idprofession ){
         objNew = {...objNew, idprofession: dataEmployee.idprofession}
      }
      if(objOriginal.idposition != dataEmployee.idposition ){
         objNew = {...objNew, idposition: dataEmployee.idposition}
      }
      if(objOriginal.sexo != dataEmployee.sexo ){
         objNew = {...objNew, sexo: dataEmployee.sexo}
      }
      if(objOriginal.birth_date != dataEmployee.birth_date ){
         objNew = {...objNew, birth_date: dataEmployee.birth_date}
      }
      if(objOriginal.pensioner != dataEmployee.pensioner ){
         objNew = {...objNew, pensioner: dataEmployee.pensioner}
      }
      if(objOriginal.state != dataEmployee.state ){
         objNew = {...objNew, state: dataEmployee.state}
      }
      // if(objOriginal.idmilitary != dataEmployee.idmilitary ){
      //    objNew = {...objNew, idmilitary: dataEmployee.idmilitary}
      // }
      if(objOriginal.ideducation != dataEmployee.ideducation ){
         objNew = {...objNew, ideducation: dataEmployee.ideducation}
      }
      if(objOriginal.direccion != dataEmployee.direccion ){
         objNew = {...objNew, direccion: dataEmployee.direccion}
      }
      if(objOriginal.nationality != dataEmployee.idnationality ){
         objNew = {...objNew, idnationality: dataEmployee.idnationality}
      }
      // //
      // if(objOriginal.idprovince != dataEmployee.n_document ){
      //    objNew = {...objNew, n_document: dataEmployee.n_document}
      // }
      // //
      // if(objOriginal.iddepartment != dataEmployee.n_document ){
      //    objNew = {...objNew, n_document: dataEmployee.n_document}
      // }
      // //
      if(objOriginal.iddistrict != dataEmployee.iddistrict ){
         objNew = {...objNew, iddistrict: dataEmployee.iddistrict}
      }
      if(objOriginal.idworkmode != dataEmployee.idworkmode ){
         objNew = {...objNew, idworkmode: dataEmployee.idworkmode}
      }
      if(objOriginal.idworkingtime != dataEmployee.idworkingtime ){
         objNew = {...objNew, idworkingtime: dataEmployee.idworkingtime}
      }
      if(objOriginal.idtype_duration_contract != dataEmployee.idtype_duration_contract ){
         objNew = {...objNew, idtype_duration_contract: dataEmployee.idtype_duration_contract}
      }
      // if(objOriginal.iddistrictbranchoffice != dataEmployee.n_document ){
      //    objNew = {...objNew, n_document: dataEmployee.n_document}
      // }//
      if(objOriginal.idbranchoffice != dataEmployee.idbranchoffice ){
         objNew = {...objNew, idbranchoffice: dataEmployee.idbranchoffice}
      }
      if(objOriginal.celular != dataEmployee.celular ){
         objNew = {...objNew, celular: dataEmployee.celular}
      }
      if(objOriginal.telefono != dataEmployee.celular ){
         objNew = {...objNew, celular: dataEmployee.celular}
      }
      if(objOriginal.emergency_phone != dataEmployee.emergency_phone ){
         objNew = {...objNew, emergency_phone: dataEmployee.emergency_phone}
      }
      if(objOriginal.email != dataEmployee.email ){
         objNew = {...objNew, email: dataEmployee.email}
      }
      if(objOriginal.idtypemoney != dataEmployee.idtypemoney ){
         objNew = {...objNew, idtypemoney: dataEmployee.idtypemoney}
      }
      if(objOriginal.idcharge != dataEmployee.idcharge ){
         objNew = {...objNew, idcharge: dataEmployee.idcharge}
      }
      if(objOriginal.idbusinessarea != dataEmployee.idbusinessarea ){
         objNew = {...objNew, idbusinessarea: dataEmployee.idbusinessarea}
      }
      if(objOriginal.idtype_contract != dataEmployee.idtype_contract ){
         objNew = {...objNew, idtype_contract: dataEmployee.idtype_contract}
      }
      if(objOriginal.workerstate != dataEmployee.workerstate ){
         objNew = {...objNew, workerstate: dataEmployee.workerstate}
      }
      if(objOriginal.workersubsidy != dataEmployee.workersubsidy ){
         objNew = {...objNew, workersubsidy: dataEmployee.workersubsidy}
      }
      if(objOriginal.idcommuneprovisionservices != dataEmployee.idcommuneprovisionservices ){
         objNew = {...objNew, idcommuneprovisionservices: dataEmployee.idcommuneprovisionservices}
      }
      if(objOriginal.money != dataEmployee.money ){
         objNew = {...objNew, money: dataEmployee.money}
      }
      if(objOriginal.idgratificationtype != dataEmployee.idgratificationtype ){
         objNew = {...objNew, idgratificationtype: dataEmployee.idgratificationtype}
      }
      if(objOriginal.idcostcenter != dataEmployee.idcostcenter ){
         objNew = {...objNew, idcostcenter: dataEmployee.idcostcenter}
      }
      if(objOriginal.private_role != dataEmployee.private_role ){
         objNew = {...objNew, private_role: dataEmployee.private_role}
      }
      if(objOriginal.n_control_card != dataEmployee.n_control_card ){
         objNew = {...objNew, n_control_card: dataEmployee.n_control_card}
      }
      if(objOriginal.years_other_employers != dataEmployee.years_other_employers ){
         objNew = {...objNew, years_other_employers: dataEmployee.years_other_employers}
      }
      if(objOriginal.apv_libro_rem != dataEmployee.apv_libro_rem ){
         objNew = {...objNew, apv_libro_rem: dataEmployee.apv_libro_rem}
      }
      if(objOriginal.unemployment_insurance != dataEmployee.unemployment_insurance ){
         objNew = {...objNew, unemployment_insurance: dataEmployee.unemployment_insurance}
      }
      if(objOriginal.idemployeetype != dataEmployee.idemployeetype ){
         objNew = {...objNew, idemployeetype: dataEmployee.idemployeetype}
      }
      if(objOriginal.iddisableperson != dataEmployee.iddisableperson ){
         objNew = {...objNew, iddisableperson: dataEmployee.iddisableperson}
      }
      if(objOriginal.time_control != dataEmployee.time_control ){
         objNew = {...objNew, time_control: dataEmployee.time_control}
      }
      if(objOriginal.idAFP != dataEmployee.idAFP ){
         objNew = {...objNew, idAFP: dataEmployee.idAFP}
      }
      if(objOriginal.idsalud != dataEmployee.idsalud ){
         objNew = {...objNew, idsalud: dataEmployee.idsalud}
      }
      if(objOriginal.idAPV != dataEmployee.idAPV ){
         objNew = {...objNew, idAPV: dataEmployee.idAPV}
      }
      if(objOriginal.reduced_tax != dataEmployee.reduced_tax ){
         objNew = {...objNew, reduced_tax: dataEmployee.reduced_tax}
      }
      if(objOriginal.apv_afp != dataEmployee.apv_afp ){
         objNew = {...objNew, apv_afp: dataEmployee.apv_afp}
      }
      if(objOriginal.book_rem_electr != dataEmployee.book_rem_electr ){
         objNew = {...objNew, book_rem_electr: dataEmployee.book_rem_electr}
      }
      if(objOriginal.n_contrato != dataEmployee.n_contrato ){
         objNew = {...objNew, n_contrato: dataEmployee.n_contrato}
      }
      if(objOriginal.idctosalud != dataEmployee.idctosalud ){
         objNew = {...objNew, idctosalud: dataEmployee.idctosalud}
      }
      if(objOriginal.cot_salud_por != dataEmployee.cot_salud_por ){
         objNew = {...objNew, cot_salud_por: dataEmployee.cot_salud_por}
      }
      if(objOriginal.cot_salud_$ != dataEmployee.cot_salud_ ){
         objNew = {...objNew, cot_salud_: dataEmployee.cot_salud_}
      }
      if(objOriginal.cto_salud_uf != dataEmployee.cto_salud_uf ){
         objNew = {...objNew, cto_salud_uf: dataEmployee.cto_salud_uf}
      }
      if(objOriginal.payment_method != dataEmployee.payment_method ){
         objNew = {...objNew, payment_method: dataEmployee.payment_method}
      }
      if(objOriginal.load_number != dataEmployee.load_number ){
         objNew = {...objNew, load_number: dataEmployee.load_number}
      }
      if(objOriginal.idfamilystretch != dataEmployee.idfamilystretch ){
         objNew = {...objNew, idfamilystretch: dataEmployee.idfamilystretch}
      }
      if(objOriginal.idbank != dataEmployee.idbank ){
         objNew = {...objNew, idbank: dataEmployee.idbank}
      }
      if(objOriginal.idwaypay != dataEmployee.idwaypay ){
         objNew = {...objNew, idwaypay: dataEmployee.idwaypay}
      }
      if(objOriginal.idaccounttype != dataEmployee.idaccounttype ){
         objNew = {...objNew, idaccounttype: dataEmployee.idaccounttype}
      }
      if(objOriginal.account_number != dataEmployee.account_number ){
         objNew = {...objNew, account_number: dataEmployee.account_number}
      }
      if(objOriginal.iddocumentcontract != dataEmployee.iddocumentcontract ){
         objNew = {...objNew, iddocumentcontract: dataEmployee.iddocumentcontract}
      }

      if(Object.entries(objNew).length === 0) return false;
      return workerRepository.patchEditWorkersContract(objNew, id_employee)
   },
   getUserDetails: (id) => {
      return userRepository.getUserDetails(id)
   },
   createUser: (dataUser) => {
      return userRepository.createUser(dataUser)
   },
   updateUser: (id, dataUser) => {
      return userRepository.updateUser(id, dataUser)
   },
   updateStatusUser: (id, dataUser) => {
      return userRepository.updateStatusUser(id, dataUser)
   },
   deleteUser: (id) => {
      return userRepository.deleteUser(id)
   },
   verifyUsernick: (user_nick) =>{
      return userRepository.verifyUsernick(user_nick)
   },
   verifyEmail: (email) =>{
      return userRepository.verifyEmail(email)
   },
   getUserData: () =>{
      return userRepository.getUserData();
   }
};

async function postCreateWorkersContract (dt) {
   const worker = await workerRepository.postCreateWorkersContract(dt);
   return worker;
}

async function getDataInitialEmployee () {
   const user= await workerRepository.getDataInitialEmployee();
   return user
}
async function getUser () {
   const user= await userRepository.getUser();
   return user
}
async function getUserPage (perPage, page) {
   const user= await userRepository.getUserPage(perPage, page);
   return user
}
async function getUserSearch (search) {
   const user= await userRepository.getUserSearch(search);
   return user
}
async function updateParticipationUsers (participacion) {
   const user= await userRepository.updateParticipationUsers(participacion);
   return user
}

async function ResendPassword(idusuario)
{
   const user= await userRepository.ResendPassword(idusuario);
   return user
}

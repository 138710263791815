import { Loading } from "@/components/common/Loading";
import { Protected } from "@/components/layout/Protected";
import { ModalRequestLiquidationError } from "@/components/Modal/ModalRequestLiquidationError";
import { PeriodService } from "@/service/services/Period.service";
import { RemunerationService } from "@/service/services/Remuneration.service";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { readLocalStorage, saveLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { Alert, Button, Grid, MenuItem, Select, Snackbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ConfirmDialog } from '@components/common/DialogConfirm';

export const MyLiquidationSalaryView = () => {

   const [years, setYears] = useState<any>([]);
   const [periods, setPeriods] = useState<any>([]);
   const [yearSelected, setYearSelected] = useState<any>(null);
   const [loading, setLoading] = useState<boolean>(false);
   const [monthSelected, setMonthSelected] = useState<any>(null);
   const [liquidation, setLiquidation] = useState<any>('');
   const [dataPDF, setDataPDF] = useState<any>(null)
   const userData = readLocalStorage(KEY_USER_DATA);
   const idEmployee = userData?.user.idemployee;

   const [openModal, setOpenModal] = useState<boolean>(false)

   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   });

   const [Dialog, setDialog] = useState<any>({
      open: false,
      title: 'Eliminar',
      confirm: false,
      id: null,
      message: ``
  })

   useEffect(() => {
      getPeriods();
   }, []);

   const getPeriods = async () => {
      setLoading(true);
      const resp: any = await PeriodService.getPeriods();
      const newData = [];
      const arrayYears = [];
      resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
         newData.push({
            id: item.id,
            month: item.month,
            year: item.year,
            alias_name: item.month + '-' + item.year,
         })
         const found = arrayYears.find(element => element == item.year);
         if (!found) { arrayYears.push(item.year); }

      });
      setYears(arrayYears);
      setPeriods(newData || []);
      setLoading(false);
   }

   const onSubmit = async () => {
      setLoading(true);
      if (!monthSelected || !yearSelected) {
         setLoading(false);
         return (
            setSnackBarConfig(prev => ({
               ...prev, open: true,
               severity: 'warning',
               message: `Debe llenar todos los campos`
            }
            ))
         );
      }

      const periodSelected = monthSelected + '-' + yearSelected;
      const period = periods.find(element => element.alias_name == periodSelected);

      const dataPdf = {
         idperiod: period.id,
         idemployee: idEmployee,
      }

      const verifyResp: any = await RemunerationService.verfifyRemuneration(idEmployee, period.id);

      if ((verifyResp.data.message).includes('Existe')) {
         const detail = verifyResp.data?.detail[0];
         setLoading(false);
         setLiquidation(detail)
         const pdfResp: any = await RemunerationService.generatePDFRemuneration(dataPdf);
         if (pdfResp) {

            const blob = new Blob([pdfResp], { type: 'application/pdf' });
            const blobURL = URL.createObjectURL(blob);
            setDataPDF(blobURL)

         }
      }
      setLoading(false);
   }

   const requestRemuneration = async (dataRemu) =>{
      const dataRequest = {
         detail: dataRemu.detail,
         from_date_request: dataRemu.from_date_request,
         to_date_request: dataRemu.to_date_request,
         idremuneration: liquidation.id
      }
      const res = await RemunerationService.requestRemunerationError(dataRequest)
      if(res?.data)
      {
         setSnackBarConfig({...snackBarConfig, open:true, severity:'success', message:'Solicitud generada con éxito'})
      }
   }

   const Delete = async () => {
      const { confirm, id } = Dialog;
      try {
          if (confirm == true) {
            
          }
      } catch (e) {
          // console.log(e)
      }
  }

   const changeEmployeeStatus = async (status_confirmation) =>
  {
   const res = await RemunerationService.changeEmployeeStatus(liquidation.id, status_confirmation);
   // if()
  }


   return (
      <Protected>
         {loading && <Loading />}
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
               {snackBarConfig.message || ""}
            </Alert>
         </Snackbar>
         <ConfirmDialog
            open={Dialog.open}
            title={Dialog.title}
            message={Dialog.message}
            onConfirm={() => Delete()}
            onClose={() => setDialog(prev => ({ ...prev, open: false }))}
        />
         <Grid container p={5} >
            <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
               <Grid item xs={4}>
                  <Typography variant="body1"><b>Período:</b> </Typography>
               </Grid>
               <Grid item xs={8}>
                  <Select
                     id="id_education_level"
                     variant="standard"
                     value={yearSelected}
                     onChange={(event) => {
                        setYearSelected(event.target.value);
                     }}
                     fullWidth
                  >
                     {
                        years.map((year, i) => {
                           return <MenuItem key={i} value={year}>{year}</MenuItem>;
                        })
                     }
                  </Select>
               </Grid>
            </Grid>

            <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
               <Grid item xs={4}>
                  <Typography variant="body1"><b>Mes:</b> </Typography>
               </Grid>
               <Grid item xs={8}>
                  <Select
                     id="id_education_level"
                     variant="standard"
                     value={monthSelected}
                     onChange={(event) => { setMonthSelected(event.target.value) }}
                     fullWidth
                  >
                     <MenuItem value={'01'}>Enero</MenuItem>
                     <MenuItem value={'02'}>Febrero</MenuItem>
                     <MenuItem value={'03'}>Marzo</MenuItem>
                     <MenuItem value={'04'}>Abril</MenuItem>
                     <MenuItem value={'05'}>Mayo</MenuItem>
                     <MenuItem value={'06'}>Junio</MenuItem>
                     <MenuItem value={'07'}>Julio</MenuItem>
                     <MenuItem value={'08'}>Agosto</MenuItem>
                     <MenuItem value={'09'}>Septiembre</MenuItem>
                     <MenuItem value={'10'}>Octubre</MenuItem>
                     <MenuItem value={'11'}>Noviembre</MenuItem>
                     <MenuItem value={'12'}>Diciembre</MenuItem>
                  </Select>
               </Grid>
            </Grid>

            <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
               <Button onClick={() => { onSubmit() }} type={'submit'} size="small" color={"primary"} variant="contained" >
                  {`ver liquidación`}
               </Button>
            </Grid>
           { dataPDF &&  <Button onClick={()=>{setOpenModal(true)}}>Solicitar Revisión</Button>}
           { dataPDF &&  <Button onClick={()=>{changeEmployeeStatus(1)}}>Confirmar</Button>}
            <iframe src={dataPDF} id="pdfVer" height="1000px" width="100%"></iframe>

          <ModalRequestLiquidationError
          open={openModal}
          closeModal={()=>setOpenModal(false)}
          requestRemuneration={requestRemuneration}
          />
         </Grid>
      </Protected>
   )
}
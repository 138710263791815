import { Props } from './ScheduleWorker.type';
import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Alert, Backdrop, Box, Breadcrumbs, Button, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar, Typography } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { WorkerService } from '@/service/services/Worker.service';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import GroupsIcon from '@mui/icons-material/Groups';
import { AdvanceService } from '@/service/services/Advance.service';
import { ModalAdvance } from '@/components/Modal/ModalAdvance';
import { ModalAdvanceDelete } from '@/components/Modal/ModalAdvanceDelete';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { RleStructureService } from '@/service/services/RleStructure.service';
import { ROLE_ADMIN } from "@/toolbox/defaults/static-roles";
import { ScheduleService } from '@/service/services/Schedule.service';
import { ModalScheduleWorker } from '@/components/Modal/ModalScheduleWorker/ModalScheduleWorker';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

export const ScheduleWorkerView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [workers, setWorkers] = useState<any>([]);
    const [workerSelected, setWorkerSelected] = useState<any>('');
    const [schedule, setSchedule] = useState<any>([]);
    const [scheduleDetail, setScheduleDetail] = useState<any>([])
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showModalDetail, setShowModalDetail] = useState<boolean>(false)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [scheduleSelected, setScheduleSelected] = useState<any>('');
    const [actionSelected, setActionSelected] = useState<any>('');
    const [view, setView] = useState<String>('workers');
    const [showSchedule, setShowSchedule] = useState<boolean>(false);
    const [showScheduleDetail, setShowSchduleDetail] = useState<boolean>(false)
    const dataUser = readLocalStorage(KEY_USER_DATA);
    const role = dataUser?.user?.role;
    const sessionUser = dataUser?.user;

    const cabecera = [
        { name: 'n_document', label: 'RUT TRABAJADOR' },
        { name: 'surname', label: 'APELLIDO PATERNO' },
        { name: 'second_surname', label: 'APELLIDO MATERNO' },
        { name: 'name', label: 'NOMBRES' }];

    const cabeceraSchedule = [
    { name: 'check_in_times', label: 'Horario de Entrada' },
    { name: 'start_collation', label: 'Inicio de Colación' },
    { name: 'end_collation', label: 'Fin de Colacion' },
    { name: 'closing_hour', label: 'Horario de Salida' },
    ]

    const cabeceraScheduleDetaiil = [
        { name: 'check_in_times', label: 'Horario de Entrada' },
        { name: 'start_collation', label: 'Inicio de Colación' },
        { name: 'end_collation', label: 'Fin de Colacion' },
        { name: 'closing_hour', label: 'Horario de Salida' },
        { name: 'start_date', label: 'Fecha de Inicio' },
        { name: 'end_date', label: 'Fecha Fin' },
        { name: 'weekly', label: 'Dias Laborables' },
        { name: 'observations', label: 'Observaciones' },
    ]

    useEffect(()=> {
      getWorkers();
    },[]);

    useEffect(()=> {
        if(workerSelected) getScheduleByEmployee(workerSelected.id);
    },[workerSelected]);

    useEffect(()=>{
        if(view == 'view_detail') getScheduleDetailByEmployee(workerSelected.id, scheduleSelected.id)
    },[scheduleSelected])

    const getWorkers = async() => {
        setLoading(true);
        const resp:any = await WorkerService.getWorkers();
        setWorkers(resp.data || []);
        setLoading(false);
    }

    const getScheduleByEmployee = async(idEmployee) => {
        setLoading(true)
        const resp = await ScheduleService.getScheduleEmployee(idEmployee);
        setSchedule(resp.data || [])
        setLoading(false)
    }

    const getScheduleDetailByEmployee = async(idEmployee, idworkSchedule) => {
        setLoading(true)
        const resp = await ScheduleService.getScheduleDetailEmployee(JSON.stringify([idEmployee]), idworkSchedule);
        setScheduleDetail(resp.data || [])
        setLoading(false)
    }

    function RecuperarData(value){

        !workerSelected && setWorkerSelected(value);
        workerSelected && setScheduleSelected(value);
        console.log(value.action)
        if(value.action == "helper"){
            if(view == 'workers'){
                setShowSchedule(true)
                setView('schedule')
            }else{
                setShowSchduleDetail(true)
                setView('view_detail')
                setScheduleSelected(value)
            }
           
         }
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Container>
            {
                role == ROLE_ADMIN && (
                    <Breadcrumbs aria-label="breadcrumb">
                        <StyledBreadcrumb
                            label="Lista de Empleados"
                            onClick={() => {
                                setView('workers');
                                setWorkerSelected('');
                                setShowSchedule(false);
                            }}
                            icon={<GroupsIcon fontSize="small" />}
                        />
                        {
                            showSchedule && (
                                <StyledBreadcrumb
                                    label={`Horario`}
                                    onClick={() => {setView('schedule')}}
                                    icon={<GroupsIcon fontSize="small" />}
                                />
                            )
                        }
                         {
                            showScheduleDetail && (
                                <StyledBreadcrumb
                                    label={`Detalle de Horario`}
                                    onClick={() => {setView('view_detail')}}
                                    icon={<GroupsIcon fontSize="small" />}
                                />
                            )
                        }
                    </Breadcrumbs>
                )
            }
            
            {
                (role == ROLE_ADMIN && view == 'workers') && (
                    <TableDataV2
                        title={'Horarios por empleado'}
                        data={workers}
                        header={cabecera}
                        actionSelect={setActionSelected}
                        status_action
                        disabled_title={true}
                        btn_disabled
                        btn_edit_disable={true}
                        btn_delete_disable={true}
                        btn_helper
                        currentView={view}
                        RecuperarData={RecuperarData}
                        />
                )
            }
            {
                view == 'schedule' && (
                    <TableDataV2
                        title={`Horarios de "${workerSelected?.name || sessionUser?.user_name}"`}
                        data={schedule}
                        header={cabeceraSchedule}
                        actionSelect={setActionSelected}
                        setModalSave={setShowModal}
                        setModalDelete={setShowDeleteModal}
                        status_action
                        disabled_title={false}
                        btn_disabled={false}
                        btn_edit_disable={false}
                        btn_delete_disable={false}
                        currentView={view}
                        btn_helper
                        RecuperarData={RecuperarData}
                    />
                )
            }

{
                view == 'view_detail' && (
                    <TableDataV2
                        title={`Detalle de Horarios de "${workerSelected?.name || sessionUser?.user_name}"`}
                        data={scheduleDetail}
                        header={cabeceraScheduleDetaiil}
                        actionSelect={setActionSelected}
                        setModalSave={setShowModalDetail}
                        setModalDelete={setShowDeleteModal}
                        status_action
                        disabled_title={false}
                        btn_disabled={false}
                        btn_edit_disable={false}
                        btn_delete_disable={false}
                        currentView={view}
                        RecuperarData={RecuperarData}
                    />
                )
            }
            </Container>

            { 
                showModal && (
                    <ModalScheduleWorker
                        open={showModal}
                        closeModal={()=>{setShowModal(false)}}
                        actionSelected={actionSelected}
                        onList={getScheduleByEmployee}
                        schedule={scheduleSelected}
                        workerSelected={workerSelected || sessionUser}
                        clearState={()=>{setScheduleSelected('')}}
                    />
                )
            }
            {/* {
                actionSelected === 'delete' && (
                    <ModalAdvanceDelete
                        open={showAdvanceDeleteModal}
                        closeModal={()=>{setShowAdvanceDeleteModal(false)}}
                        actionSelected={actionSelected}
                        onListAdvances={getAdvancesByEmployee}
                        advance={advanceSelected}
                        workerSelected={workerSelected || sessionUser}
                        clearState={()=>{setAdvanceSelected('')}}
                    />
                )
            } */}
        
        </Protected>
    )
};
import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';

export const notificationsTypeRepository = {

    getNotificationsTypes: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/notifications/typeNotifications`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createNotificationsType: async (dataNotificationsType) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/notifications/typeNotifications`, {
            name: dataNotificationsType.name,
            url: dataNotificationsType.url,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    editNotificationsType: async (dataNotificationsType, idNotificationsType: number) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/notifications/typeNotifications/${idNotificationsType}`, {
            name: dataNotificationsType.name,
            url: dataNotificationsType.url,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    deleteNotificationsType: async(idNotificationsType:number): Promise<any> => {
        const res= await http.delete<any>(`${API_URL_BASE}/v1/notifications/typeNotifications/${idNotificationsType}`)
        return res;
    },
}



import { AdminValidationRepository } from "../repositories/AdminVAlidation.repository";

export const AdminValidationService = {
    getAdminValidations,
    editAdminValidation,
}

async function getAdminValidations(status:any){
    const res = await AdminValidationRepository.getAdminValidations(status);
    return res;
}

async function editAdminValidation(idValidation, data){
    const res = await AdminValidationRepository.editAdminValidation(idValidation, data);
    return res;
}



import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './Charges.type'
import { Loading } from '@/components/common/Loading';
import { Alert, Backdrop, Breadcrumbs, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar } from "@mui/material";
import Chip from '@mui/material/Chip';
import { emphasize, styled } from '@mui/material/styles';
import { SpinnerGrow } from '@/components/common/Spinner';
import { TableDataV2 } from '@/components/common/Tablev2';
import { chargesRepository } from '@/service/repositories/Charge.repository';
import { ModalCharges } from '@/components/Modal/ModalCharges';
import { ModalchargeDelete } from '@/components/Modal/ModalchargeDelete/ModalChargeDelete';
import GroupsIcon from '@mui/icons-material/Groups';
import { ChargeService } from '@/service/services/Charge.service';
import { ModalChargeResponsability } from '@/components/Modal/ModalChargeResponsability';
import { ModalChargeResponsabilityDelete } from '@/components/Modal/ModalChargeResponsabilityDelete';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
   const backgroundColor =
       theme.palette.mode === 'light'
           ? theme.palette.grey[100]
           : theme.palette.grey[800];
   return {
       backgroundColor,
       height: theme.spacing(3),
       color: theme.palette.text.primary,
       fontWeight: theme.typography.fontWeightRegular,
       '&:hover, &:focus': {
           backgroundColor: emphasize(backgroundColor, 0.06),
       },
       '&:active': {
           boxShadow: theme.shadows[1],
           backgroundColor: emphasize(backgroundColor, 0.12),
       },
   };
});

export const ChargeView: React.FC<Props> = (props: any): JSX.Element => {

   const [openView, setOpenView] = useState(false);
   const [view, setView] = useState<String>('charge');
   const [charges, setCharges] = useState([])
   const [showChargeModal, setShowChargeModal] = useState<boolean>(false);
   const [showChargeDeleteModal, setShowChargeDeleteModal] = useState<boolean>(false);
   const [actionSelected, setActionSelected] = useState<String>('');
   const [chargeSelected, setChargeSelected] = useState<any>(null);
   const [loading, setLoading] = useState<boolean>(false);

   const [responsability, setResponsability] = useState<any>([])
   const [showChargeResponsability, setShowChargeResponsibility] = useState<any>(false)
   const [responsabilitySelected, setResponsabilitySelected] = useState<any>(null)
   const [showChargeResponsabilityModal, setShowChargeResponsabilityModal] = useState<boolean>(false);
   const [showChargeResponsabilityDeleteModal, setShowChargeResponsabilityDeleteModal] = useState<boolean>(false);
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   })
   const getChargesCompany = async () => {
      setLoading(true);
      const response = await chargesRepository.getChargesCompany();
      console.log(response)
      if (response.data) {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: response.data,
         }));
         setCharges(response.data)
      } else {
         console.log('error')
      }
      setLoading(false);
   }

   function RecuperarData(value) {
      !chargeSelected && setChargeSelected(value)
      chargeSelected && setResponsabilitySelected(value)
      if(value.action == "view-detail"){
         setView('Responsability')
      }
   }

   const getResponsabilities = async (id) =>{
      const resp:any = await ChargeService.getChargesResponsability(id);
      if(resp){setResponsability(resp.data) }
   }

   useEffect(() => {
      getChargesCompany()
   }, [])

   useEffect(()=>{
      if(chargeSelected){
         getResponsabilities(chargeSelected.id)
      }
   },[chargeSelected])

   return (
      <Protected>
         {loading && <Loading/>}
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openView}
         >
            <Grid container
               direction="row"
               justifyContent="center"
               alignItems="center">
               <SpinnerGrow />
               <h1 style={{ marginLeft: '10px' }}><strong>Cargando...</strong></h1>
            </Grid>
         </Backdrop>
         <Breadcrumbs aria-label="breadcrumb">
                <StyledBreadcrumb
                    label="Lista de Cargos"
                    onClick={() => {
                        setView('charge');
                        setChargeSelected('');
                        setShowChargeResponsibility(false);
                    }}
                    icon={<GroupsIcon fontSize="small" />}
                />
                {
                   view == 'Responsability'&& (
                        <StyledBreadcrumb
                            label={`Funciones`}
                            onClick={() => {setView('Responsability')}}
                            icon={<GroupsIcon fontSize="small" />}
                        />
                    )
                }
            </Breadcrumbs>
         <Container>
           { view == 'charge' && <TableDataV2
               data={charges}
               header={[
                  { name: 'name', label: 'Nombre', filter: false, Chip: true },
                  { name: 'charge_description', label: 'Descripcion', filter: false, Chip: false },
               ]}
               status_action
               btn_detail_view
               //  checkbox
               title={'Cargos'}
               RecuperarData={RecuperarData}
               setModalSave={setShowChargeModal}
               setModalDelete={setShowChargeDeleteModal}
               actionSelect={setActionSelected}
            />}
             { view == 'Responsability' && <TableDataV2
               data={responsability}
               header={[
                  { name: 'description', label: 'Descripcion', filter: false, Chip: false },
               ]}
               status_action
               //  checkbox
               title={`Funciones del cargo ${chargeSelected.name}`}
               RecuperarData={RecuperarData}
               setModalSave={setShowChargeResponsabilityModal}
               setModalDelete={setShowChargeResponsabilityDeleteModal}
               actionSelect={setActionSelected}
            />}
         </Container>
         {
            showChargeModal && (
               <ModalCharges
                  open={showChargeModal}
                  closeModal={() => { setShowChargeModal(false) }}
                  actionSelected={actionSelected}
                  onListCharges={getChargesCompany}
                  charge={chargeSelected}
                  clearState={() => { setChargeSelected('') }}
               />
            )
         }
         {
            showChargeResponsabilityModal && (
               <ModalChargeResponsability
                  open={showChargeResponsabilityModal}
                  closeModal={() => { setShowChargeResponsabilityModal(false) }}
                  actionSelected={actionSelected}
                  onListChargesResponsability={getResponsabilities}
                  responsability={responsabilitySelected}
                  clearState={() => { setResponsabilitySelected('') }}
                  chargeSelected={chargeSelected}
               />
            )
         }
         {
            actionSelected === 'delete' && view == 'charge' && (
               <ModalchargeDelete
                  open={showChargeDeleteModal}
                  closeModal={()=>{setShowChargeDeleteModal(false)}}
                  actionSelected={actionSelected}
                  onListCharges={getChargesCompany}
                  charge={chargeSelected}
                  clearState={()=>{setChargeSelected('')}}
               />
            )
         }
         {
            actionSelected === 'delete' && view == 'Responsability' && (
               <ModalChargeResponsabilityDelete
               open={showChargeResponsabilityDeleteModal}
               closeModal={()=>{setShowChargeResponsabilityDeleteModal(false)}}
               actionSelected={actionSelected}
               onListChargesResponsability={getResponsabilities}
               responsability={responsabilitySelected}
               clearState={()=>{setResponsabilitySelected('')}}
               chargeSelected={chargeSelected}
               />
            )
         }
      </Protected>
   )
};
import { timeZoneRepository } from "../repositories/TimeZone.repository";

export const TimeZoneService = {
    getTimeZone,
    createTimeZone,
    editTimeZone,
    deleteTimeZone,
}

async function getTimeZone(){
    const res = await timeZoneRepository.getTimeZone();
    return res;
}

async function createTimeZone(data){
    const res = await timeZoneRepository.createTimeZone(data);
    return res;
}

async function editTimeZone(data, idRequestType: number){
    const res = await timeZoneRepository.editTimeZone(data, idRequestType);
    return res;
}

async function deleteTimeZone(idRequestType:number){
    const res = await timeZoneRepository.deleteTimeZone(idRequestType);
    return res;
 }
import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from '@/components/common/Loading';
import { Alert,  Card,  Container,  Grid,  Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { ProductService } from '@/service/services/Products.service';


export const ReportProducts: React.FC<any> = (props: any): JSX.Element => {
   
    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [reports, setReports] = useState<any>([]);

    useEffect(()=> {
        getReportEmployee();
     },[]);

    const getReportEmployee = async () => {

        setLoading(true);

        await ProductService.inventaryGeneralProduct().then((data) => {
            if(data.data.status == false){
                setLoading(false);
                return (
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'warning', 
                        message: `${data.data.message || 'Ocurrió algún error'}` }
                    ))
                );
            }
            setReports(data.data || []);
            setLoading(false);
        })
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Snackbar
                open={snackBarConfig.open} 
                autoHideDuration={snackBarConfig.autoHideDuration} 
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>
            <Container>
                <Typography variant='h6' component='h1' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                    REPORTE DE PRODUCTOS
                </Typography>
                
                {
                    reports.length > 0 && reports.map((item)=>(
                        <>
                        <Card sx={{ width: "100%", marginTop: '20px' }}>
                            <Grid sx={{ width: "100%", padding: '20px' }}>
                                <Typography variant="h5" sx={{ pt: 1 }}>
                                    Producto "{item.name}"
                                </Typography>
                                <Typography variant="subtitle1" sx={{ pt: 1 }}>
                                    Stock: {item.stock || '0'}
                                </Typography>
                            </Grid>
                        </Card>

                        {
                            (item.detalle).length > 0 ? (
                                <Card sx={{ width: "100%", marginTop: '20px' }}>
                                    <Typography variant='h6' component='h2' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                                        {`Detalle del producto`}
                                    </Typography>

                                    <TableContainer>
                                        <Table>
                                            <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                                <TableRow >
                                                    <TableCell >
                                                        <Typography>Tipo de Movimiento</Typography>
                                                    </TableCell>
                                                    <TableCell >
                                                        <Typography>Fecha</Typography>
                                                    </TableCell>
                                                    <TableCell >
                                                        <Typography>Fecha Acordada de Retorno</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {
                                                    (item.detalle).map((detail)=>(
                                                        <TableRow >
                                                            <TableCell size={'small'}>
                                                                {detail.type_movement}
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {detail.date}
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {detail.agreed_return_date}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                                
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Card>
                            ) : (
                                <Typography variant='h6' component='h2' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                                        {`No se encontró Participación en ${item.name} para este periodo.`}
                                </Typography>
                            )
                        }

                        
                        </>
                    ))
                }

            </Container>
        </Protected>
    )
};
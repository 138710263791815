import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalUser.type";
import '../../../assets/styles/modals.scss';
import { userService } from "@/service/services/User.service";
import { companiesService } from "@/service/services/Companies.service";

export const ModalUser: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, user, onListUsers, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [roles, setRoles] = useState<any>([]);
    const [companies, setCompanies] = useState<any>([]);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [data, setData] = useState<any>({
        id: '',
        email: '',
        user_name: '',
        password: '',
        confirm_password: '',
        n_document: '',
        role: '1',
        assign_ip: '',
        idCompany: '',
    });

    useEffect(() => {
        getRoles();
        getCompaniesAdmin();
    }, []);

    useEffect(() => {
        async function initialData(){
            if(user && roles){
                setLoading(true);
                await companiesService.companiesByUser(user.iduser).then((data) => {
                    const companiesByUser = data?.data;
                    setData({
                        id: user ? user.iduser : '',
                        email: user ? user.email : '',
                        user_name: user ? user.user_name : '',
                        password: user ? user.password : '',
                        confirm_password: user ? user.confirm_password : '',
                        n_document: user ? user.n_document : '',
                        role: user ? getCustomRoleName(user.role, roles) : 1,
                        idCompany: user ? getCustomCompanies(companiesByUser || []) : '',
                        assign_ip: user ? user.assign_ip : '',
                    });
                })
                setLoading(false);
            }
        }
        initialData();
    }, [user, roles]);

    const getRoles = async() => {
        setLoading(true);
        await userService.getRoles().then((data) => {
            setRoles(data.data || []);
            setLoading(false);
        })
    }

    const getCompaniesAdmin = async() => {
        setLoading(true);
        await companiesService.getCompaniesAdmin().then((data) => {
            setCompanies(data.data || []);
            setLoading(false);
        })
    }

    function getCustomCompanies(originalCompanies): any {
        return originalCompanies ? originalCompanies.map(company => ({id: company.id, business_name: company.business_name})) : [];
    }

    const customCompanies = getCustomCompanies(companies || []);

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.email && !user) errors.email = "correo requerido";
        if(!values.user_name && !user ) errors.user_name = "nombre de usuario requerido";
        if(values.password != values.confirm_password && !user ) errors.confirm_password = "las contraseñas mno coinciden";
        if(!values.n_document && !user) errors.n_document = "numero de documento requerido";
        if(!values.role && !user) errors.role = "rol requerido";
        if(!values.idCompany && !user) errors.idCompany = "empresa requerido";
        if(!values.assign_ip && !user) errors.assign_ip = "nombre de usuario requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        let arrayIdsCompanies= [];

        values.idCompany && values.idCompany.map((item) => {
            arrayIdsCompanies.push(item.id);
        });

        const formData = {
            id: values.id,
            email: values.email,
            user_name : values.user_name,
            password : values.password,
            confirm_password : values.confirm_password,
            n_document : values.n_document,
            role : values.role,
            idCompany : JSON.stringify(arrayIdsCompanies),
            assign_ip : values.assign_ip,
        }
        const dataEditDetail = {
            iduser: values.id,
            idcompanies: JSON.stringify(arrayIdsCompanies),
            roleuser: values.role,
        }

        setLoading(true);
        if(user && actionSelected=='helper'){
            await userService.editDetailUserCompany(dataEditDetail).then((data) => {
                if(data.data.status === true){
                    setLoading(false);
                    closeModal && closeModal();
                    onListUsers && onListUsers();
                }else{
                    setLoading(false);
                    return (
                        setSnackBarConfig(prev => ({ ...prev, open: true, 
                            severity:'error', 
                            message: `${data.data.message || 'Ocurrió algún error'}` 
                        }))
                    )
                }
            })
        }else{
            await userService.createOnlyUser(formData).then((data) => {
                if(data.data.status === false){
                    setLoading(false);
                    return (
                        setSnackBarConfig(prev => ({ ...prev, open: true, 
                            severity:'error', 
                            message: `${data.data.message || 'Ocurrió algún error'}` 
                        }))
                    )
                }else{
                    closeModal && closeModal();
                    onListUsers && onListUsers();
                    setLoading(false);
                }
            })
        }
        setLoading(false);
    }

    const getCustomRoleName = (oldRolName, roles) => {
        const customRol = roles.find(rol => rol.name == oldRolName);
        return  customRol ? customRol.id : ''
    }

    return(
        <>
        {loading && <Loading/>}
        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={(user && actionSelected=='edit') ? "Editar usuario" : (user && actionSelected=='helper') ? "Empresas a la que el Usuario Pertenece" : "Agregar usuario"}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    {
                                        !user && (
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>Correo:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                <TextField
                                                    id="email"
                                                    variant="standard"
                                                    type="email" 
                                                    name="email"
                                                    fullWidth
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    placeholder="correo de usuario"
                                                    error={errors.email && touched.email ? true : false}
                                                    disabled={user ? true :  false}
                                                />
                                                </Grid>
                                            </Grid>
                                        )
                                    }

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Nombre de usuario:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="user_name"
                                            variant="standard"
                                            type="text" 
                                            name="user_name"
                                            multiline
                                            fullWidth
                                            value={values.user_name}
                                            onChange={handleChange}
                                            placeholder="nombre de usuario"
                                            error={errors.user_name && touched.user_name ? true : false}
                                            disabled={user ? true :  false}
                                        />
                                        </Grid>
                                    </Grid>

                                    {
                                        !user && (
                                            <>
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>N° de documento:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                <TextField
                                                    id="n_document"
                                                    variant="standard"
                                                    type="text" 
                                                    name="n_document"
                                                    multiline
                                                    fullWidth
                                                    value={values.n_document}
                                                    onChange={handleChange}
                                                    placeholder="numero de documento"
                                                    error={errors.n_document && touched.n_document ? true : false}
                                                    disabled={user ? true :  false}
                                                />
                                                </Grid>
                                            </Grid>

                                            <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                    <Typography variant="body1"><b>Rol:</b> </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Select
                                                        id="role"
                                                        variant="standard"
                                                        value={values.role}
                                                        onChange={(event) => { 
                                                        setFieldValue("role", event.target.value);
                                                        }}
                                                        error={ errors.role && touched.role ? true : false }
                                                        fullWidth
                                                        disabled={user ? true :  false}
                                                    >
                                                        {
                                                            roles.map((rol, i) => {
                                                                return <MenuItem key={i} value={rol.id}>{rol.name}</MenuItem>;
                                                            })
                                                        }
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                            </>
                                        )
                                    }

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>Empresas:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                multiple={true}
                                                disablePortal
                                                id="combo-box-demo"
                                                value={values.idCompany || []}
                                                options={customCompanies || []}
                                                getOptionLabel={(option) => option.business_name || ""}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} variant="standard" label="Escriba para buscar" />}
                                                onChange={(e, newValue) => {
                                                    setFieldValue("idCompany", newValue);
                                                    console.log(newValue)
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    {
                                        !user && (
                                            <>
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>assign_ip:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                <TextField
                                                    id="assign_ip"
                                                    variant="standard"
                                                    type="number" 
                                                    name="assign_ip"
                                                    fullWidth
                                                    value={values.assign_ip}
                                                    onChange={handleChange}
                                                    placeholder="assign_ip"
                                                    error={errors.assign_ip && touched.assign_ip ? true : false}
                                                />
                                                </Grid>
                                            </Grid>
                                            </>
                                        )
                                    }

                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(user && actionSelected=='edit') ? "ACTUALIZAR" : (user && actionSelected=='helper') ? "ACTUALIZAR EMPRESAS" : "REGISTRAR"}
                                    cancelText="CANCELAR" 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}
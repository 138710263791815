import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Divider, Grid, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalTimeZone.type";
import { TimeZoneService } from "@/service/services/TimeZone.service";

export const ModalTimeZone: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, timeZone, onListZoneTime, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);

    const [data, setData] = useState({
        id: timeZone ? timeZone.id : '',
        description: timeZone ? timeZone.description : '',
        operation: timeZone ? timeZone.operation : '',
        time_zone: timeZone ? timeZone.time_zone : ''
    });

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.description) errors.description = "nombre requerido";
        if(!values.operation) errors.operation = "nombre requerido";
        if(!values.time_zone) errors.time_zone = "nombre requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            id: values.id,
            description : values.description,
            operation : values.operation,
            time_zone : values.time_zone
        }
        setLoading(true);
        if(timeZone && actionSelected=='edit'){
            await TimeZoneService.editTimeZone(formData, timeZone.id).then((data) => {
                onListZoneTime && onListZoneTime();
                closeModal && closeModal();
            })
        }else{
            await TimeZoneService.createTimeZone(formData).then((data) => {
                onListZoneTime && onListZoneTime();
                closeModal && closeModal();
            })
        }
        setLoading(false);
    }
    return(
        <>
        {loading && <Loading/>}

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={(timeZone ? "Editar Zona Horaria" : "Agregar Zona Horaria")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Descripción:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="description"
                                            variant="standard"
                                            type="text" 
                                            name="description"
                                            fullWidth
                                            value={values.description}
                                            onChange={handleChange}
                                            placeholder="Descripción"
                                            error={errors.description && touched.description ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Operación:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="operation"
                                            variant="standard"
                                            type="text" 
                                            name="operation"
                                            fullWidth
                                            value={values.operation}
                                            onChange={handleChange}
                                            placeholder="Operación"
                                            error={errors.operation && touched.operation ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Zona Horaria:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="time_zone"
                                            variant="standard"
                                            type="text" 
                                            name="time_zone"
                                            fullWidth
                                            value={values.time_zone}
                                            onChange={handleChange}
                                            placeholder="Zona Horaria"
                                            error={errors.time_zone && touched.time_zone ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(timeZone && actionSelected=='edit') ? "ACTUALIZAR" : "REGISTRAR"}
                                    cancelText="CANCELAR" 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}
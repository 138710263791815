import { Grid, Typography, List, ListItem, ListItemText, Divider } from "@mui/material";
import { styled } from '@mui/material/styles';

const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
}));

export const DiscountEmployee = (props: any): JSX.Element => {
    const { data } = props;

    return(
        <>
        <Grid display='flex'>
            <Grid item xs={12} md={6}>
                <Typography variant="body2" style={{marginTop: '10px'}}>DESCUENTOS PREVISIONALES</Typography>
                <Demo>
                    <List>
                        <ListItem>
                            <ListItemText style={{minWidth: '230px', maxWidth: '230px'}}>
                                <Typography variant="body2" >Cotiz. Obligat. AFP</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: $ ${data.quotation_AFP}`}</Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText style={{minWidth: '230px', maxWidth: '230px'}}>
                                <Typography variant="body2" >Seguro Cesantia</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: $ ${data.unemployment_insurance}`}</Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText style={{minWidth: '230px', maxWidth: '230px'}}>
                                <Typography variant="body2" >Cotiz. Obligat. Salud {`(${data.salud_percentage}%)`}</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: $ ${data.health_obligatory}`}</Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText style={{minWidth: '230px', maxWidth: '230px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold'}}>Total desc. previsionales</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: $ ${data.TDP}`}</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Demo>
            </Grid>

            {
                data.single_tax && data.single_tax != 0 && (
                    <Grid item xs={12} md={6}>
                        <Typography variant="body2" style={{marginTop: '10px'}}>DESCUENTOS LEGALES</Typography>
                        <Demo>
                            <List>
                                <ListItem>
                                    <ListItemText style={{minWidth: '230px', maxWidth: '230px'}}>
                                        <Typography variant="body2" >Impuesto único</Typography>
                                    </ListItemText>
                                    <ListItemText >
                                        <Typography variant="body2">{`: $ ${data.single_tax}`}</Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText style={{minWidth: '230px', maxWidth: '230px'}}>
                                        <Typography variant="body2" sx={{ fontWeight: 'bold'}}>Total desc. Legales</Typography>
                                    </ListItemText>
                                    <ListItemText >
                                        <Typography variant="body2">{`: $ ${data.single_tax}`}</Typography>
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Demo>
                    </Grid>
                )
            }
        </Grid>
        <Divider></Divider>
        <Grid display='flex'>
            <Grid item xs={12} md={6}>
                <Demo>
                    <List>
                        <ListItem>
                            <ListItemText style={{minWidth: '250px', maxWidth: '250px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>TOTAL DESCUENTOS GENERALES</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: $ ${data.TD}`}</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Demo>
            </Grid>
        </Grid>
        </>
    )
}
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Autocomplete, Divider, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { PhasesService } from "@/service/services/Phases.service";
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalExtraHour.type";
import { AssistanceService } from "@/service/services/Assistance.service";

export const ModalExtraHour: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, extraHour, onListExtraHours, workerSelected, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [structures, setStructures] = useState<any>([]);
    const [structureSelected, setStructureSelected] = useState<any>('');

    const [data, setData] = useState({
        id: extraHour ? extraHour.id : '',
        idemployee: extraHour ? extraHour.idemployee : '',
        iddetstructureemployeeopt: extraHour ? extraHour.iddetstructureemployeeopt : '',
        hour: extraHour ? extraHour.hour : '',
        date: extraHour ? extraHour.date : '',
        code: extraHour ? extraHour.code : '',
    });

    useEffect(()=> {
        if(workerSelected){
            getDetailEmployeeOPT()
        }
    },[workerSelected]);

    useEffect(() => {
        if(extraHour && structures){
            setCustomStructure(extraHour.iddetstructureemployeeopt, structures);
        }

    },[extraHour, structures]);

    // const getTypeExtraHour = async() => {
    //     setLoading(true);
    //     const resp:any = await AssistanceService.getTypeExtraHour();
    //     setStructures(resp.data || []);
    //     setLoading(false);
    // }

    const getDetailEmployeeOPT = async() => {
        setLoading(true);
        const resp:any = await AssistanceService.getDetailEmployeeOPT(workerSelected.id);
        setStructures(resp.data || []);
        setLoading(false);
    }

    const validateForm = (values) => {
        let errors: any = {};
        // if(!values.code) errors.code = "código requerido";
        if(!values.hour) errors.hour = "hora requerido";
        if(!values.date) errors.date = "fecha requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            id: values.id,
            idemployee: workerSelected.id,
            iddetstructureemployeeopt : structureSelected.id,
            hour : values.hour,
            date : values.date,
            code : structureSelected.code,
        }
        setLoading(true);
        if(extraHour && actionSelected=='edit'){
            await AssistanceService.editExtraHour(formData, extraHour.id).then((data) => {
                onListExtraHours && onListExtraHours(workerSelected.id);
                closeModal && closeModal();
            })
        }else{
            await AssistanceService.createExtraHour(formData).then((data) => {
                onListExtraHours && onListExtraHours(workerSelected.id);
                closeModal && closeModal();
            })
        }
        setLoading(false);
    }

    function setCustomStructure(idStructure, structures): any {
        const data = structures && structures.find(structure => structure.id === idStructure);
        const newData = {
            id: data?.id,
            alias_name: data?.alias_name
        }
        return setStructureSelected(newData);
    }

    return(
        <>
        {loading && <Loading/>}

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={(extraHour && actionSelected=='edit') ? "Editar horas extras" : "Agregar horas extras"}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>Estructura:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                id="disable-portal"
                                                disablePortal
                                                value={structureSelected}
                                                options={structures || []}
                                                getOptionLabel={(option) => option.alias_name || ""}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} variant="standard" label="Escriba para buscar" />}
                                                onChange={(e, newValue) => { setStructureSelected(newValue) }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Fecha:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                id="date"
                                                variant="standard"
                                                fullWidth
                                                name="date"
                                                type="date"
                                                onChange={handleChange}
                                                value={values.date}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={errors.date && touched.date ? true : false}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Cantidad hora:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="hour"
                                            variant="standard"
                                            type="number" 
                                            name="hour"
                                            fullWidth
                                            value={values.hour}
                                            onChange={handleChange}
                                            placeholder="cantidad hora"
                                            error={errors.hour && touched.hour ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(extraHour && actionSelected=='edit') ? "ACTUALIZAR" : "REGISTRAR"}
                                    cancelText="CANCELAR" 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}
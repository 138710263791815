import { AssistanceService } from "@/service/services/Assistance.service";
import { ConfigurationIPService } from "@/service/services/ConfigurationIP.service";
import { CostCenterService } from "@/service/services/CostCenter.service";
import { ProfileService } from "@/service/services/Profile.service";
import { Typography } from "@mui/material";
import { useState } from "react";
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { ModalHeader } from "../ModalHeader";
import { Props } from "./ModalIpDelete.type";

export const ModalIpDelete: React.FC<Props> = (props:any): JSX.Element => {
    const { open, closeModal, userIp, onListIps, clearState, type } = props;
    const [loading, setLoading] = useState<boolean>(false);

    const onDeletedAccept= async()=>{
        setLoading(true);
        await ConfigurationIPService.deleteAccessIP(userIp.id).then((data) => {
            onListIps && onListIps();
            closeModal && closeModal();
        })
        setLoading(false);
    }

    const onDeletedDenied= async()=>{
      setLoading(true);
      await ConfigurationIPService.deleteDeniedIP(userIp.id).then((data) => {
          onListIps && onListIps();
          closeModal && closeModal();
      })
      setLoading(false);
  }
  
  return (
    <>
    <Modal
      open={open}
      handleClose={closeModal}
      size="xs"
    >
      <ModalHeader
        text="ELIMINAR IP"
        className='positionElements'
        onCancel={closeModal}
        clearState={clearState}
      >
      </ModalHeader>

      <ModalBody>
        <Typography variant="subtitle1" className="textColor"> 
          ¿Está seguro de eliminar esta IP?
        </Typography>
      </ModalBody>

      <ModalFooter 
        confirmText="Eliminar"
        cancelText="CANCELAR"
        onCancel={closeModal}
        className="footerRight"
        onConfirm={type == 'accept' ? onDeletedAccept : onDeletedDenied}
        clearState={clearState}
      />
    </Modal>
    </>
  )
}
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik } from 'formik';
import { useEffect, useState } from "react";
import { Divider, Grid, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalWorkerContact.type";
import { WorkerContactService } from "@/service/services/WorkerContact.service";

export const ModalWorkerContact: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, contact, onListContacts, workerSelected, clearState } = props;

    console.log(workerSelected);

    const [loading, setLoading] = useState<boolean>(false);

    const [data, setData] = useState({
        id: contact ? contact.id : '',
        email: contact ? contact.email : '',
        landline: contact ? contact.landline : '',
        personal_phone: contact ? contact.personal_phone : '',
        emergency_phone: contact ? contact.emergency_phone : '',
    });

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.email) errors.email = "correo requerido";
        if(!values.landline) errors.landline = "telefono fijo requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            id: values.id,
            email: values.email,
            landline : values.landline,
            personal_phone : values.personal_phone,
            emergency_phone : values.emergency_phone,
        }
        setLoading(true);
        if(contact && actionSelected=='edit'){
            await WorkerContactService.editEmployeeContact(formData, contact.id).then((data) => {
                onListContacts && onListContacts(workerSelected.id);
                closeModal && closeModal();
            })
        }else{
            await WorkerContactService.createEmployeeContact(formData, workerSelected.id).then((data) => {
                onListContacts && onListContacts(workerSelected.id);
                closeModal && closeModal();
            })
        }
        setLoading(false);
    }
    return(
        <>
        {loading && <Loading/>}

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={(contact && actionSelected=='edit') ? "Editar Contacto" : "Agregar Contacto"}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Correo:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                id="email"
                                                variant="standard"
                                                fullWidth
                                                name="email"
                                                type="email"
                                                onChange={handleChange}
                                                placeholder="email"
                                                value={values.email}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={errors.email && touched.email ? true : false}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Teléfono Fijo:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="landline"
                                            variant="standard"
                                            type="text" 
                                            name="landline"
                                            fullWidth
                                            value={values.landline}
                                            onChange={handleChange}
                                            placeholder="telefono fijo"
                                            error={errors.landline && touched.landline ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Teléfono Personal:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="personal_phone"
                                            variant="standard"
                                            type="text" 
                                            name="personal_phone"
                                            fullWidth
                                            value={values.personal_phone}
                                            onChange={handleChange}
                                            placeholder="telefono personal"
                                            error={errors.personal_phone && touched.personal_phone ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Teléfono de Emergencia:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="emergency_phone"
                                            variant="standard"
                                            type="text" 
                                            name="emergency_phone"
                                            fullWidth
                                            value={values.emergency_phone}
                                            onChange={handleChange}
                                            placeholder="telefono de emergencia"
                                            error={errors.emergency_phone && touched.emergency_phone ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(contact && actionSelected=='edit') ? "ACTUALIZAR" : "REGISTRAR"}
                                    cancelText="CANCELAR" 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}
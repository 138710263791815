export const ROUTE_HOME                         = '/';
export const ROUTE_PRUEBA                       = '/prueba';
export const ROUTE_LOGIN                        = '/login';
export const ROUTE_ACCOUNT                      = "/cuenta";
export const ROUTE_NOTIFY                       = "/notificaciones";
export const ROUTE_ENTITY                       = "/empresas";
export const ROUTE_ENTITY_CREATE                = "/empresas/crear";
export const ROUTE_ENTITY_UPDATE                = "/empresas/detalle";
export const ROUTE_ENTITY_USERS                 = "/empresas/usuarios";
export const ROUTE_PERFIL                       = "/perfil";
export const ROUTE_CONFIGURACION                = "/configuracion";
export const ROUTE_VARIABLE_GLOBAL              = "/varibles-globales";

export const ROUTE_COMPANIES                    = "/empresas";
export const ROUTE_COMPANY_SETTING              = "/empresa-configuraciones";

export const ROUTE_GROUP_COMPANIES              = "/grupos-empresas";
export const ROUTE_GROUP_COMPANIES_ALL          = "/usuarios-grupos-empresas"

export const ROUTE_USER                         = "/usuarios";
export const ROUTE_WORKER                       = "/trabajadores";
export const ROUTE_WORKER_CONTACT               = "/contacto-trabajadores";
export const ROUTE_BRANCH_OFFICE                = "/sucursales";
export const ROUTE_BRANCH_OFFICE_TYPE           = "/tipo-sucursales";
export const ROUTE_COST_CENTER                  = "/centro-costos";
export const ROUTE_DEPARMENT                    = "/departamentos";
export const ROUTE_CHARGES                      = "/cargos";
export const ROUTE_PROJECT                      = "/proyectos";
export const ROUTE_VALUE_EXTRA_HOURS            = "/valor-horas-extras";
export const ROUTE_MOVEMENTS                    = "/movimientos";
export const ROUTE_REQUEST_TYPE                 = "/tipos-solicitud";
export const ROUTE_TURNS                        = "/giros-empresa";
export const ROUTE_TYPE_NOTIFICATIONS           = "/type-notifications";
export const ROUTE_TYPE_REMINDER                = "/tipos-recordatorios";
export const ROUTE_TIME_ZONE                    = "/zona-horaria";
export const ROUTE_STRUCTURE_MANTEINER          = "/estructuras-maestro"; 
export const ROUTE_PRODUCTS                     = "/productos"; 
export const ROUTE_SCHEDULE                     = "/horarios-empresas";
export const ROUTE_SCHEDULE_EMPLOYEE            = "/horarios-empleados"

export const ROUTE_POLITICS                     = "/politicas-privacidad";
export const ROUTE_POLITICS_MASTER               = "/politicas-privacidad-mantenedor";

export const ROUTE_COUNTRIES                    = "/paises";
export const ROUTE_DEPARTAMENT                  = "/paises-departamentos";
export const ROUTE_PROVINCE                     = "/provincias";
export const ROUTE_DISTRICT                     = "/distritos";

export const ROUTE_ASSISTANCES                  = "/asistencias";
export const ROUTE_ABSENCES                     = "/inasistencias";
export const ROUTE_ASSISTANCES_WORKER           = "/asistencia-empleado";
export const ROUTE_EXTRA_HOURS                  = "/horas-extras";
export const ROUTE_ARREARS                      = "/atrasos";
export const ROUTE_ADVANCES                     = "/anticipos";
export const ROUTE_INVENTORY_WORKERS            = "/inventario-trabajadores";
export const ROUTE_EMPLOYEE_EXPENSE             = "/mis-gastos";
export const ROUTE_EXPENSE_REQUESTS             = "/solicitudes-gastos";

export const ROUTE_CONFIGURATION_IP             = "/configuracion-ip";
export const ROUTE_IP_ACCEPT                    = "/ip-aceptadas";
export const ROUTE_IP_DENIED                    = "/ip-denegadas";

export const ROUTE_PROFILES                     = "/perfiles";
export const ROUTE_ACTIVE_STRUCTURE             = "/estructura-activa";
export const ROUTE_RECRUITING_TEAM              = "/equipo-reclutamiento";
export const ROUTE_PHASES                       = "/fases";
export const ROUTE_ANNOUNCEMENT                 = "/convocatorias";
export const ROUTE_APPLICANTS                   = "/postulantes";

export const ROUTE_STRUCTURE_LRE                = "/estructura-lre";
export const ROUTE_ACTIVE_LIQUIDATION_WORKERS   = "/liquidacion-activa-trabajadores";
export const ROUTE_LIQUIDATION_SALARY           = "/liquidacion-sueldo";
export const ROUTE_PDF_LIQUIDATION_SALARY       = "/liquidacion-sueldo-PDF";
export const ROUTE_PDF_AMOUNT_GENERAL           = "/montos-generales";
export const ROUTE_MY_LIQUIDATION_SALARY        = "/mi-liquidacion-sueldo";
export const ROUTE_LIQUIDATION_MASIVE           = "/liquidacion-masiva";
export const ROUTE_LIQUIDATION_PROJECT          = "/liquidacion-proyecto";
export const ROUTE_TEMPLATE_LIQUIDATION_MONTHLY = "/planillas-remuneraciones-mensuales";
export const ROUTE_LIQUIDATION_MASIVE_PREVIEW   = "/liquidacion-masiva-generate";
export const ROUTE_CUSTOM_VISUALIZER_PDF        = "/custom-pdf";

export const ROUTE_REPORT_EMPLOYEES             = "/reporte-trabajadores";
export const ROUTE_REPORT_LIQUIDATIONS          = "/reporte-liquidaciones";
// export const ROUTE_REPORT_REQUEST               = "/reporte-solicitudes";
export const ROUTE_REPORT_WORKER_DELAY          = "/reporte-trabajador-tardanzas";
export const ROUTE_REPORT_EXPENS                = "/reporte-gastos";
export const ROUTE_REPORT_LIQUIDATION_PROJECTS  = "/reporte-liquidacion-proyectos";
export const ROUTE_REPORT_INVENTORY_PRODUCTS     = "/reporte-productos";
export const ROUTE_REPORT_MULTI_COMPANY         = "/reporte-multi-empresa";

export const ROUTE_CERTIFICATE_EMPLOYEE_PDF     = "/certificate-employee-pdf"
export const ROUTE_USER_CREATE                  = "/usuarios/crear";
export const ROUTE_USER_UPDATE                  = "/usuarios/detalle";

export const ROUTE_MY_REQUEST                   = "/my-request";
export const ROUTE_CREATE_REQUEST               = "/create-request";
export const ROUTE_REPORT_REQUEST               = "/reporte/solicitud";
export const ROUTE_REPORT_BILLS                 = "/reporte/gastos";
export const ROUTE_MY_VALIDATIONS               = "/mis-validaciones";
export const ROUTE_ADMIN_VALIDATIONS            = "/validaciones-identidad";

export const ROUTE_CONTRACT_EMPLOYEE            = "/contrato";
export const ROUTE_DOCUMENT_CONTRACT            = "/documento-contrato";
export const ROUTE_CREATE_CONTRACT_DOCUMENT     = "/crear-documento-contrato";
export const ROUTE_ANNEXED                      = "/contrato-annexed";
export const ROUTE_CUSTOMIZE_CONTRACT           = "/contrato-custom";


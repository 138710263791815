import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Alert, Backdrop, Box, Breadcrumbs, Button, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar, Typography } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { WorkerService } from '@/service/services/Worker.service';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import GroupsIcon from '@mui/icons-material/Groups';
import { AssistanceService } from '@/service/services/Assistance.service';
import { Props } from './Annexed.type';
import { ModalDelay } from '@/components/Modal/ModalDelay';
import { ModalDelayDelete } from '@/components/Modal/ModalDelayDelete';
import { ContractService } from '@/service/services/Contract.service';
import { dateFormat, dateFormatTime } from '@/toolbox/helpers/date.helper';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

export const AnnexedView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [workers, setWorkers] = useState<any>([]);
    const [workerSelected, setWorkerSelected] = useState<any>('');
    const [annexed, setAnnexed] = useState<any>([]);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
     })
     
    // const [showDelayModal, setShowDelayModal] = useState<boolean>(false);
    // const [showDelayDeleteModal, setShowDelayDeleteModal] = useState<boolean>(false);
    // const [delaySelected, setDelaySelected] = useState<any>('');
    const [actionSelected, setActionSelected] = useState<any>('');
    const [view, setView] = useState<String>('workers');
    const [showAnnexed, setShowAnnexed] = useState<boolean>(false);

    const cabecera = [
        { name: 'n_document', label: 'RUT TRABAJADOR' },
        { name: 'surname', label: 'APELLIDO PATERNO' },
        { name: 'second_surname', label: 'APELLIDO MATERNO' },
        { name: 'name', label: 'NOMBRES' }];

    const cabeceraAnnexed = [
        { name: 'column_name', label: 'CAMPO' },
        { name: 'description_after_value', label: 'ANTES' },
        { name: 'description_before_value', label: 'DESPUES' },
    ];

    useEffect(()=> {
        getWorkers()
    },[]);


    useEffect(()=> {
        if(workerSelected){
            //necesito el id del contrato
            getAnnexedByEmployee()
        }
    },[workerSelected]);

    const getWorkers = async() => {
        setLoading(true);
        const resp:any = await WorkerService.getWorkers();
        setWorkers(resp.data || []);
        setLoading(false);
    }

    const getAnnexedByEmployee = async() => {
        setLoading(true);
        const resp = await WorkerService.getContracts();
        console.log(resp.data)
        console.log(workerSelected)
        const empContract = resp.data.find(res => { return res.idemployee == workerSelected.id })
        console.log(empContract)
        const respAnnexed = await ContractService.getAnnexed(empContract.id)
            console.log(respAnnexed)
            if(respAnnexed.data.length == 0){
                setSnackBarConfig(prev => ({ ...prev, open: true, severity:'info', message: 'No existen anexos en el contrato' }))      
                setLoading(false);
                return
            }
            setView('annexed');
            setAnnexed(respAnnexed.data || []);
            setLoading(false);
    }

    const viewDocument = async(id) =>{
        const pdfResp: any = await ContractService.getDocumentAnnexed(id);
        if (pdfResp) {
            const blob = new Blob([pdfResp], { type: 'application/pdf' });
            const blobURL = URL.createObjectURL(blob);
            window.open(blobURL, '_blank');
            //aca podemos crear para que se visualize en un ruta y despues de ello atrapar el blob con el mensaje de detail en la parte superior
        }
    }

    function RecuperarData(value){
        console.log(value);
        !workerSelected && setWorkerSelected(value);
        // workerSelected && setDelaySelected(value);
        setShowAnnexed(true);
        // setOpenModal(true)
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Container>
                <Snackbar
                    open={snackBarConfig.open}
                    autoHideDuration={snackBarConfig.autoHideDuration}
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert
                        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                        severity={snackBarConfig.severity}
                        variant="filled"
                    >
                        {snackBarConfig.message}
                    </Alert>
                </Snackbar>
            <Breadcrumbs aria-label="breadcrumb">
                <StyledBreadcrumb
                    label="Lista de Empleados"
                    onClick={() => {
                        setView('workers');
                        setWorkerSelected('');
                        setShowAnnexed(false);
                    }}
                    icon={<GroupsIcon fontSize="small" />}
                />
                {
                    showAnnexed && (
                        <StyledBreadcrumb
                            label={`Anexos`}
                            onClick={() => {setView('delays')}}
                            icon={<GroupsIcon fontSize="small" />}
                        />
                    )
                }
            </Breadcrumbs>
            {
                view == 'workers' && (
                    <TableDataV2
                        title={'Anexos por empleado'}
                        data={workers}
                        header={cabecera}
                        actionSelect={setActionSelected}
                        status_action
                        disabled_title={false}
                        btn_disabled
                        btn_edit_disable={true}
                        btn_delete_disable={true}
                        btn_helper
                        currentView={view}
                        RecuperarData={RecuperarData}
                        />
                )
            }
            {
                view == 'annexed' && (
                    annexed.map((item)=>{
                        return (
                            <>
                                <Box
                                    sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        flexWrap: 'wrap',
                                        m: -1,
                                        mt:5
                                    }}
                                >
                                    {!props?.disabled_title && <Typography variant='h6' component='h1' gutterBottom className="tittle"
                                        sx={{ m: 1, fontWeight: 600 }}
                                    >
                                        { (`Anexos de "${workerSelected?.name}" - ${dateFormat(item[0].date)} ${dateFormatTime(item[0].date)}`).toUpperCase()}
                                    </Typography>}
                                    <Box sx={{ m: 1 }}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={()=>{viewDocument(item[0].id)}}
                                        >
                                            Ver Documento
                                        </Button>
                                    </Box>
                                </Box>
                           <TableDataV2
                          title={`Anexos de "${workerSelected?.name}" - ${dateFormat(item[0].date)} ${dateFormatTime(item[0].date)}`}
                          data={item}
                          header={cabeceraAnnexed}
                          actionSelect={setActionSelected}
                          // setModalSave={setShowDelayModal}
                          // setModalDelete={setShowDelayDeleteModal}
                          // status_action
                          disabled_title={true}
                          btn_disabled={true}
                          btn_edit_disable={true}
                          btn_delete_disable={true}
                          currentView={view}
                          RecuperarData={RecuperarData}
                      />
                          </>
                        )
                       
                    })

                    
                    
                )
            }
            </Container>

            {/* { 
                showDelayModal && (
                    <ModalDelay
                        open={showDelayModal}
                        closeModal={()=>{setShowDelayModal(false)}}
                        actionSelected={actionSelected}
                        onListDelays={getDelaysByEmployee}
                        delay={delaySelected}
                        workerSelected={workerSelected}
                        clearState={()=>{setDelaySelected('')}}
                    />
                )
            }
            {
                actionSelected === 'delete' && (
                    <ModalDelayDelete
                        open={showDelayDeleteModal}
                        closeModal={()=>{setShowDelayDeleteModal(false)}}
                        actionSelected={actionSelected}
                        onListDelays={getDelaysByEmployee}
                        delay={delaySelected}
                        workerSelected={workerSelected}
                        clearState={()=>{setDelaySelected('')}}
                    />
                )
            }
         */}
        </Protected>
    )
};
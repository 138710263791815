import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
// import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

// const dataUser = readLocalStorage(KEY_USER_DATA);
// const rut_empresa = dataUser?.user?.rut_empresa;

export const RecluitingTeamRepository = {

    getRecluitingTeam: async(idJobOffert:number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/recruitingTeam?id_job_offer=${idJobOffert}`);
        const {data, error, message} = res;
        return { data, error, message };
    },
    
    createRecluitingTeam: async (dataRecluitingTeam) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/recruitingTeam/create`, {
            id_user: dataRecluitingTeam.id_user,
            id_job_offer: dataRecluitingTeam.id_job_offer,
            role: dataRecluitingTeam.role
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    editRecluitingTeam: async (dataPhase): Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/recruitingTeam/update`, {
            id_user: dataPhase.id_user,
            id_job_offer: dataPhase.id_job_offer,
            role: dataPhase.role,
            id: dataPhase.id
        });
        const { data, error, message } = res;
        return { data, error, message };
    },
}   
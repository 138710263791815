
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Button, Card, Grid, MenuItem, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Loading } from "@/components/common/Loading";
import { Props } from './MutualRate.type';
import { companiesService } from '@/service/services/Companies.service';

export const MutualRate: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, dataCompany, onListDataCompany, mutuality, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);
    

    const [data, setData] = useState({
        id: dataCompany ? dataCompany.idcompany : '',
        idmutuality: dataCompany ? dataCompany.idmutuality : '',
        mutual_rate: dataCompany ? dataCompany.mutual_rate : '',
    });

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.idmutuality) errors.idmutuality = "mutual requerido";
        if(!values.mutual_rate) errors.mutual_rate = "mutual rate requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            idmutuality : values.idmutuality,
            mutual_rate : values.mutual_rate,
        }
        setLoading(true);
        await companiesService.updateMutual(formData).then((data) => {
            onListDataCompany && onListDataCompany();
            closeModal && closeModal();
        })
        setLoading(false);
    }
    return(
        <>
        {loading && <Loading/>}

        
            <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                    return(
                        <form onSubmit={handleSubmit}>
                            <Card sx={{ width: "100%", marginTop: '20px' }}>
                                <TableContainer>
                                    <Table>
                                        <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                            <TableRow >
                                                <TableCell >
                                                    <Typography>Mutual y Mutual Rate</Typography>
                                                </TableCell>
                                                <TableCell >
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            <TableRow >
                                                <TableCell size={'small'} sx={{ bgcolor: '#F4F6F8', width: '30%' }}>
                                                    Mutual
                                                </TableCell>
                                                <TableCell >
                                                    <Select
                                                        id="idmutuality"
                                                        name="idmutuality"
                                                        variant="standard"
                                                        value={values.idmutuality}
                                                        onChange={(event) => {
                                                            setFieldValue("idmutuality", event.target.value);
                                                        }}
                                                        sx={{ width: '400px' }}
                                                        error={errors.idmutuality && touched.idmutuality ? true : false}
                                                    >
                                                        {mutuality.map((mutual, i) => {
                                                            return <MenuItem key={i} value={mutual.id}>{mutual.name}</MenuItem>;
                                                        })}
                                                    </Select>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow >
                                                <TableCell size={'small'} sx={{ bgcolor: '#F4F6F8', width: '30%' }}>
                                                    Mutual Rate
                                                </TableCell>
                                                <TableCell >
                                                    <TextField
                                                        id="mutual_rate"
                                                        variant="standard"
                                                        sx={{ width: '400px' }}
                                                        name="mutual_rate"
                                                        type="number"
                                                        onChange={handleChange}
                                                        value={values.mutual_rate}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={errors.mutual_rate && touched.mutual_rate ? true : false}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Grid sx={{ textAlign: 'center', margin: '10px' }}>
                                    <Button color="primary" variant="contained" onClick={() => {handleSubmit()}}>
                                        ACTUALIZAR
                                    </Button>
                                </Grid>
                            </Card>
                        </form>
                    )
                }}
            </Formik>
        </>
    );
}
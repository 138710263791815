import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);
const rut_empresa = dataUser?.user?.rut_empresa;

export const RequestRepository = {

    getRequestReport: async(id, id_period): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/dashboard/request/${id}?idperiod=${id_period}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getMyRequest: async(year, month): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/requestManagement/myList?year=${year}&month=${month}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getRequestForAdmin: async(year, month): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/requestManagement/adminList?year=${year}&month=${month}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getRequestLiquidation: async(idRequest): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/requestManagement/getLiquidation/${idRequest}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createRequestWorker: async(dataRequest): Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/requestManagement/create`, {
            idrequests_type: dataRequest.idrequests_type,
            detail: dataRequest.detail,
            from_date_request: dataRequest.from_date_request,
            to_date_request: dataRequest.to_date_request,
       });
        const {data, error, message} = res;
        return { data, error, message };
    },

    AceptorDeniedRequest: async(id, dataRequest): Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/requestManagement/adminEdit/${id}`, {
            status_review: dataRequest.status_review,
            observations: dataRequest.observations,
            detail: dataRequest.detail,
            idrequests_type: dataRequest.idrequests_type,
            status: dataRequest.status
       });
        const {data, error, message} = res;
        return { data, error, message };
    }
    /*createPhase: async (dataPhase) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/phase/create`, {
            name: dataPhase.name,
            description: dataPhase.description
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    editPhase: async (dataPhase) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/phase/update`, {
            name: dataPhase.name,
            description: dataPhase.description,
            id: dataPhase.id
        });
        const {data, error, message} = res;
        return { data, error, message };
    },*/
}   
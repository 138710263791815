import {
   CAMPANA
} from '@constants/action-type'

const initialState = {
   dataCampana:{}
}

export default function(state = initialState, action:any){
   switch(action.type){
       case CAMPANA:
           return {
               ...state,
               dataCampana: action.payload,
           }
       default: return state
   }
}

import { http } from '../http/http';
import { UserDetailsDTO,UserDTO,UserPost,UserRequestPost, UsersDTO} from '../http/dto/UserDTO';
import { UserDetails, Users } from '../models/User';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { CostCenter } from '../models/CostCenter';

const dataUser = readLocalStorage(KEY_USER_DATA);
const idCompany = dataUser?.user?.id_company;


export const AssistsWorkerRepository = {

    getAssitsCalendar: async(idEmployee: number, start_date: any, end_date: any): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/markedAssists/schedule/${idEmployee}?start_date=${start_date}&end_date=${end_date}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createAssists: async (dataAssists) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/markedAssists`, {
            idemployee: dataAssists.idemployee,
            date: dataAssists.date,
            start_time: dataAssists.start_time,
            start_refreshment: dataAssists.start_refreshment,
            end_refreshment: dataAssists.end_refreshment,
            end_time: dataAssists.end_time,
            observations: dataAssists.observations,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    createAssistMasive: async (dataAssists) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/markedAssists/massive`, {
            idemployees: dataAssists.idemployees,
            start_date: dataAssists.start_date,
            end_date: dataAssists.end_date,
            start_time: dataAssists.start_time,
            start_refreshment: dataAssists.start_refreshment,
            end_refreshment: dataAssists.end_refreshment,
            end_time: dataAssists.end_time,
            observations: dataAssists.observations,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    updateAssists: async (idAssists: number, dataAssists) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/markedAssists/${idAssists}`, {
            idemployee: dataAssists.idemployee,
            date: dataAssists.date,
            start_time: dataAssists.start_time,
            start_refreshment: dataAssists.start_refreshment,
            end_refreshment: dataAssists.end_refreshment,
            end_time: dataAssists.end_time,
            observations: dataAssists.observations,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    deleteAssists: async(idAssists:number): Promise<any> => {
        const res= await http.delete<any>(`${API_URL_BASE}/v1/markedAssists/${idAssists}`)
        return res;
    },
}



import * as React from 'react';
import { Box, List, ListItemText } from '@mui/material';
import { ListItemIcon, ListItemButton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import GroupIcon from '@mui/icons-material/Group';
import HandymanIcon from '@mui/icons-material/Handyman';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ChatIcon from '@mui/icons-material/Chat';
import AddCommentIcon from '@mui/icons-material/AddComment';
import ThreePIcon from '@mui/icons-material/ThreeP';
import { readLocalStorage, saveLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_RUTA, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_TRABAJADOR } from '@/toolbox/defaults/static-roles';
import { useLocation } from 'react-router-dom';
import {  ROUTE_ACTIVE_LIQUIDATION_WORKERS, ROUTE_ACTIVE_STRUCTURE, ROUTE_ANNOUNCEMENT, ROUTE_APPLICANTS, ROUTE_BRANCH_OFFICE, ROUTE_CHARGES, ROUTE_COST_CENTER, ROUTE_DEPARMENT, ROUTE_VALUE_EXTRA_HOURS, ROUTE_HOME, ROUTE_LIQUIDATION_MASIVE, ROUTE_LIQUIDATION_PROJECT, ROUTE_LIQUIDATION_SALARY, ROUTE_PHASES, ROUTE_PROFILES, ROUTE_PROJECT, ROUTE_RECRUITING_TEAM, ROUTE_STRUCTURE_LRE, ROUTE_USER, ROUTE_WORKER, ROUTE_ASSISTANCES, ROUTE_EXTRA_HOURS, ROUTE_ARREARS, ROUTE_ADVANCES, ROUTE_INVENTORY_WORKERS, ROUTE_MOVEMENTS,ROUTE_TEMPLATE_LIQUIDATION_MONTHLY, ROUTE_MY_LIQUIDATION_SALARY, ROUTE_REQUEST_TYPE, ROUTE_MY_REQUEST, ROUTE_CREATE_REQUEST, ROUTE_REPORT_EMPLOYEES, ROUTE_REPORT_LIQUIDATIONS, ROUTE_REPORT_REQUEST, ROUTE_REPORT_WORKER_DELAY, ROUTE_REPORT_EXPENS, ROUTE_EMPLOYEE_EXPENSE, ROUTE_EXPENSE_REQUESTS, ROUTE_MY_VALIDATIONS, ROUTE_ADMIN_VALIDATIONS, ROUTE_CONTRACT_EMPLOYEE, ROUTE_COMPANIES, ROUTE_TURNS, ROUTE_VARIABLE_GLOBAL, ROUTE_WORKER_CONTACT, ROUTE_ANNEXED, ROUTE_TYPE_NOTIFICATIONS, ROUTE_TYPE_REMINDER , ROUTE_IP_ACCEPT, ROUTE_IP_DENIED, ROUTE_COUNTRIES, ROUTE_DEPARTAMENT, ROUTE_PROVINCE, ROUTE_DISTRICT, ROUTE_TIME_ZONE, ROUTE_STRUCTURE_MANTEINER, ROUTE_BRANCH_OFFICE_TYPE, ROUTE_POLITICS, ROUTE_POLITICS_MASTER,ROUTE_PRODUCTS, ROUTE_REPORT_INVENTORY_PRODUCTS, ROUTE_REPORT_LIQUIDATION_PROJECTS, ROUTE_CUSTOMIZE_CONTRACT, ROUTE_GROUP_COMPANIES, ROUTE_GROUP_COMPANIES_ALL, ROUTE_REPORT_MULTI_COMPANY, ROUTE_DOCUMENT_CONTRACT, ROUTE_CREATE_CONTRACT_DOCUMENT, ROUTE_ASSISTANCES_WORKER, ROUTE_ABSENCES, ROUTE_SCHEDULE, ROUTE_SCHEDULE_EMPLOYEE} from '@/toolbox/constants/route-map';
import AccountBoxSharpIcon from '@mui/icons-material/AccountBoxSharp';
import GroupSharpIcon from '@mui/icons-material/GroupSharp';
import TableChartSharpIcon from '@mui/icons-material/TableChartSharp';
import LeaderboardSharpIcon from '@mui/icons-material/LeaderboardSharp';
import ShoppingCartSharpIcon from '@mui/icons-material/ShoppingCartSharp';
import HttpsSharpIcon from '@mui/icons-material/HttpsSharp';


const rutasSuperAdmin = [
  {
    id:1,
    name: "Maestros",
    icon: <HandymanIcon />,
    rutas: [
      {
        name_ruta: "Empresas",
        location: ROUTE_COMPANIES,
        icon: <GroupIcon/>,
      },
      {
        name_ruta: "Grupo de Empresas",
        location: ROUTE_GROUP_COMPANIES_ALL,
        icon: <GroupIcon/>,
      },
      {
        name_ruta: "Usuarios",
        location: ROUTE_USER,
        icon: <AccountBoxSharpIcon/>,
      },
      {
        name_ruta: "Variables Globales",
        location: ROUTE_VARIABLE_GLOBAL,
        icon: <AccountBoxSharpIcon/>,
      },
      {
        name_ruta: "Estructuras",
        location: ROUTE_STRUCTURE_MANTEINER,
        icon: <AccountBoxSharpIcon/>,
      },
      {
        name_ruta: "Tipos de Notificaciones",
        location: ROUTE_TYPE_NOTIFICATIONS,
        icon: <AccountBoxSharpIcon/>,
      },
      {
        name_ruta: "Tipos de Recordatorios",
        location: ROUTE_TYPE_REMINDER,
        icon: <AccountBoxSharpIcon/>,
      },
      {
        name_ruta: "Zonas Horarias",
        location: ROUTE_TIME_ZONE,
        icon: <AccountBoxSharpIcon/>,
      },
      {
        name_ruta: "Tipos de Sucursales",
        location: ROUTE_BRANCH_OFFICE_TYPE,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Politicas del Software",
        location: ROUTE_POLITICS_MASTER,
        icon: <LeaderboardSharpIcon/>,
      },
    ] 
  },
  {
    id:2,
    name: "Ubigeo",
    icon: <HandymanIcon />,
    rutas: [
      {
        name_ruta: "Paises",
        location: ROUTE_COUNTRIES,
        icon: <GroupIcon/>,
      },
      {
        name_ruta: "Regiones",
        location: ROUTE_DEPARTAMENT,
        icon: <AccountBoxSharpIcon/>,
      },
      {
        name_ruta: "Provincias",
        location: ROUTE_PROVINCE,
        icon: <AccountBoxSharpIcon/>,
      },
      {
        name_ruta: "Comunas",
        location: ROUTE_DISTRICT,
        icon: <AccountBoxSharpIcon/>,
      },
    ] 
  },
]


const rutasAdmin = [
  {
    id:1,
    name: "Maestros",
    icon: <HandymanIcon />,
    rutas: [ 
      {
        name_ruta: "Empresas",
        location: ROUTE_COMPANIES,
        icon: <GroupIcon/>,
      },
      {
        name_ruta: "Grupo de Empresas",
        location: ROUTE_GROUP_COMPANIES,
        icon: <GroupIcon/>,
      },
      {
        name_ruta: "Usuarios",
        location: ROUTE_USER,
        icon: <AccountBoxSharpIcon/>,
      },
      {
        name_ruta: "Trabajadores",
        location: ROUTE_WORKER,
        icon: <GroupSharpIcon/>,
      },
      {
        name_ruta: "Anexos",
        location: ROUTE_ANNEXED,
        icon: <GroupSharpIcon/>,
      },
      {
        name_ruta: "Contactos del Trabajdor",
        location: ROUTE_WORKER_CONTACT,
        icon: <GroupSharpIcon/>,
      },
      {
        name_ruta: "Sucursales",
        location: ROUTE_BRANCH_OFFICE,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Proyectos",
        location: ROUTE_PROJECT,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Centros De Costos",
        location: ROUTE_COST_CENTER,
        icon: <TableChartSharpIcon/>,
      },
      {
        name_ruta: "Departamentos",
        location: ROUTE_DEPARMENT,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Cargos",
        location: ROUTE_CHARGES,
        icon: <GroupSharpIcon/>,
      },
      // {
      //   name_ruta: "Valor Horas Extras",
      //   location: ROUTE_VALUE_EXTRA_HOURS,
      //   icon: <LeaderboardSharpIcon/>,
      // },
      {
        name_ruta: "Tipos de solicitudes",
        location: ROUTE_REQUEST_TYPE,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Giros de la empresa",
        location: ROUTE_TURNS,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Productos",
        location: ROUTE_PRODUCTS,
        icon: <LeaderboardSharpIcon/>,
      }, {
        name_ruta: "Mis Politicas y Privacidad",
        location: ROUTE_POLITICS_MASTER,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Documento Contratos",
        location: ROUTE_DOCUMENT_CONTRACT,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Horarios",
        location: ROUTE_SCHEDULE,
        icon: <LeaderboardSharpIcon/>,
      },
    ] 
  },
  {
    id:2,
    name: "Novedades Del Mes",
    icon: <ShoppingCartSharpIcon/>,
    rutas: [
      {
        name_ruta: "Asistencias",
        location: ROUTE_ASSISTANCES,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Inasistencias",
        location: ROUTE_ABSENCES,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Horas Extras",
        location: ROUTE_EXTRA_HOURS,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Atrasos",
        location: ROUTE_ARREARS,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Anticipos",
        location: ROUTE_ADVANCES,
        icon: <HttpsSharpIcon/>,
      },
      {
        name_ruta: "Solicitudes",
        location: ROUTE_MY_REQUEST,
        icon: <HttpsSharpIcon/>,
      },
      {
        name_ruta: "Movimientos",
        location: ROUTE_MOVEMENTS,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Validaciones de Identidad",
        location: ROUTE_ADMIN_VALIDATIONS,
        icon: <HttpsSharpIcon/>,
      },
      {
        name_ruta: "Inventarios De Trabajadores",
        location: ROUTE_INVENTORY_WORKERS,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Solicitudes Gastos",
        location: ROUTE_EXPENSE_REQUESTS,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "IPs Aceptadas",
        location: ROUTE_IP_ACCEPT,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Ips Denegadas",
        location: ROUTE_IP_DENIED,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Horarios",
        location: ROUTE_SCHEDULE_EMPLOYEE,
        icon: <LeaderboardSharpIcon/>,
      }
    ] 
  },
  {
    id:2,
    name: "Contratos",
    icon: <LeaderboardSharpIcon/>,
    rutas: [
      {
        name_ruta: "Trabajadores",
        // location: "/gestion-alertas",
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Finiquitos",
        // location: "/gestion-alertas",
        icon: <LeaderboardSharpIcon/>,
      }
    ] 
  },
  {
    id:3,
    name: "Liquidaciones",
    icon: <LeaderboardSharpIcon/>,
    rutas: [
      {
        name_ruta: "Estructura LRE",
        location: ROUTE_STRUCTURE_LRE,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Liquidación Activa - Trabajadores",
        location: ROUTE_ACTIVE_LIQUIDATION_WORKERS,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Liquidación De Sueldo",
        location: ROUTE_LIQUIDATION_SALARY,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Liquidación Masiva",
        location: ROUTE_LIQUIDATION_MASIVE,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Planilla Remuneraciones Mensuales",
        location: ROUTE_TEMPLATE_LIQUIDATION_MONTHLY,
        icon: <LeaderboardSharpIcon/>,
      },
      // {
      //   name_ruta: "Liquidación por Proyecto",
      //   location: ROUTE_LIQUIDATION_PROJECT,
      //   icon: <LeaderboardSharpIcon/>,
      // }
    ] 
  },
  {
    id:4,
    name: "Reclutamiento",
    icon: <LeaderboardSharpIcon/>,
    rutas: [
      {
        name_ruta: "Perfiles",
        location: ROUTE_PROFILES,
        icon: <LeaderboardSharpIcon/>,
      },
      // {
      //   name_ruta: "Estructura Activa - Trabajadores",
      //   location: ROUTE_ACTIVE_STRUCTURE,
      //   icon: <LeaderboardSharpIcon/>,
      // },
      {
        name_ruta: "Equipo de Reclutamiento",
        location: ROUTE_RECRUITING_TEAM,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Fases",
        location: ROUTE_PHASES,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Convocatorias",
        location: ROUTE_ANNOUNCEMENT,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Postulantes",
        location: ROUTE_APPLICANTS,
        icon: <LeaderboardSharpIcon/>,
      }
    ] 
  },
  {
    id:5,
    name: "Reportes",
    icon: <LeaderboardSharpIcon/>,
    rutas: [
      {
        name_ruta: "Empleados",
        location: ROUTE_REPORT_EMPLOYEES,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Grupo de Empresas",
        location: ROUTE_REPORT_MULTI_COMPANY,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Liquidaciones",
        location: ROUTE_REPORT_LIQUIDATIONS,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Liquidacion por Proyectos",
        location: ROUTE_REPORT_LIQUIDATION_PROJECTS,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Solicitudes",
        location: ROUTE_REPORT_REQUEST,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Faltas, Tardanzas y Horas Ext.",
        location: ROUTE_REPORT_WORKER_DELAY,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Gastos",
        location: ROUTE_REPORT_EXPENS,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Productos",
        location: ROUTE_REPORT_INVENTORY_PRODUCTS,
        icon: <LeaderboardSharpIcon/>,
      },
      // {
      //   name_ruta: "Reporte de Gasto Mensual",
      //   location: "/gestion-alertas",
      //   icon: <LeaderboardSharpIcon/>,
      // },
      // {
      //   name_ruta: "Reporte De Inventario Trabajador",
      //   location: "/gestion-alertas",
      //   icon: <LeaderboardSharpIcon/>,
      // },
      // {
      //   name_ruta: "Reporte Por Proyectos",
      //   location: "/gestion-alertas",
      //   icon: <LeaderboardSharpIcon/>,
      // }
    ] 
  },
  ,
  {
    id:5,
    name: "Politicas y Privacidad",
    icon: <LeaderboardSharpIcon/>,
    rutas: [
      {
        name_ruta: "Politicas y Privacidad del Software",
        location: ROUTE_POLITICS,
        icon: <LeaderboardSharpIcon/>,
      }
    ] 
  }
]

const rutasEmployee = [
  {
    id:2,
    name: "Novedades Del Mes",
    icon: <ShoppingCartSharpIcon/>,
    rutas: [
      {
        name_ruta: "Mis Anticipos",
        location: ROUTE_ADVANCES,
        icon: <HttpsSharpIcon/>,
      },
      {
        name_ruta: "Mis Solicitudes",
        location: ROUTE_MY_REQUEST,
        icon: <HttpsSharpIcon/>,
      },
      {
        name_ruta: "Mis Gastos",
        location: ROUTE_EMPLOYEE_EXPENSE,
        icon: <HttpsSharpIcon/>,
      },
    ] 
  },
  {
    id:3,
    name: "Liquidaciones",
    icon: <LeaderboardSharpIcon/>,
    rutas: [
      {
        name_ruta: "Estructura LRE",
        location: ROUTE_STRUCTURE_LRE,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Mis liquidaciones",
        location: ROUTE_MY_LIQUIDATION_SALARY,
        icon: <LeaderboardSharpIcon/>,
      },
      {
        name_ruta: "Planilla Remuneraciones Mensuales",
        location: ROUTE_TEMPLATE_LIQUIDATION_MONTHLY,
        icon: <LeaderboardSharpIcon/>,
      }
    ] 
  },
  {
    id:4,
    name: "Validación de Identidad",
    icon: <ShoppingCartSharpIcon/>,
    rutas: [
      {
        name_ruta: "Mis Validaciones",
        location: ROUTE_MY_VALIDATIONS,
        icon: <HttpsSharpIcon/>,
      },
    ] 
  },
  {
    id:5,
    name: "Contrato",
    icon: <ShoppingCartSharpIcon/>,
    rutas: [
      {
        name_ruta: "Mi Contrato",
        location: ROUTE_CONTRACT_EMPLOYEE,
        icon: <HttpsSharpIcon/>,
      },
    ] 
  },
  {
    id:6,
    name: "Politicas y Privacidad",
    icon: <LeaderboardSharpIcon/>,
    rutas: [
      {
        name_ruta: "Documentos",
        location: ROUTE_POLITICS,
        icon: <LeaderboardSharpIcon/>,
      },
    ] 
  },
  {
    id:2,
    name: "Control Asistencia",
    icon: <ShoppingCartSharpIcon/>,
    rutas: [
      {
        name_ruta: "Mis Asistencias",
        location: ROUTE_ASSISTANCES_WORKER,
        icon: <HttpsSharpIcon/>,
      },
    ] 
  },
]

export default function NavSection() {
  const history = useHistory()
  const { pathname } = useLocation();
  const initialRuta = readLocalStorage(KEY_RUTA)
  const [ruta, setRuta] = React.useState(initialRuta || '');
  const data_user = readLocalStorage(KEY_USER_DATA);
  const type_user = data_user.user.role;
  const [rutasHeader, setRutasHeader] = React.useState([]);

  const validateType = () => {
    switch (type_user) {
      case ROLE_SUPER_ADMIN:
        setRutasHeader(rutasSuperAdmin)
        break;
      case ROLE_ADMIN:
        setRutasHeader(rutasAdmin)
        break;
      case ROLE_TRABAJADOR:
        setRutasHeader(rutasEmployee)
        break;
      default:
        console.log('vamos a ver que ponemos')
        break;
    }
  }
 
  React.useEffect(()=>{
  validateType();
  },[]);

  const renderPrueba =
    <Box>
      <List disablePadding sx={{ p: 1 }}>
        {
          rutasHeader.map((value) => {
           
          return(
          <>
          <ListItemButton
            sx={{
              color: ruta === value.name && '#3b3b3b',
              bgcolor: ruta === value.name && '#EDEFF1',
              fontWeight: ruta === value.name && 'fontWeightBold',
              height: 48,
              position: 'relative',
              textTransform: 'capitalize',
              borderRadius: 2,
            }}
            onClick={() => {
              if (ruta === value.name) {
                setRuta('')
              } else {
                saveLocalStorage(KEY_RUTA, value.name)
                setRuta(value.name)
              }
            }}
           
          >
              <ListItemIcon
               sx={{
                width: 22,
                height: 22,
                color: 'inherit',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              >{value.icon}</ListItemIcon>

              <ListItemText disableTypography primary={value.name} />  
          </ListItemButton>

          {ruta === value.name &&
              value.rutas.map((item)=>{
               return(
                <ListItemButton
                onClick={()=>{history.push(item.location)}}
                sx={{
                  height: 48,
                  position: 'relative',
                  textTransform: 'capitalize',
                  borderRadius: 2,
                  marginLeft: '35px',
                    color: pathname === item.location && '#3b3b3b',
                }}
              >
                <ListItemIcon
                sx={{
                  width: 22,
                  height: 22,
                  color: 'inherit',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                >{item.icon}</ListItemIcon>

                <ListItemText disableTypography primary={item.name_ruta} />
              </ListItemButton>
               )
              })
              }
          </>
            
          )
          })
        }
      </List>
    </Box>

  return (
    <>
     { renderPrueba}
    </>
 
  );
}

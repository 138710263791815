import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Container } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { Loading } from '@/components/common/Loading';
import { Props } from './IpsAccept.type';
import { ConfigurationIPService } from '@/service/services/ConfigurationIP.service';
import { ModalIpAccept } from '@/components/Modal/ModalIpAccept/ModalIpAccept';
import { ModalIpDelete } from '@/components/Modal/ModalIpDelete/ModalIpDelete';


export const IpsAcceptView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [IpsAccept, setIpsAccept] = useState([]);
    const [showIpAcceptModal, setShowIpAcceptModal] = useState<boolean>(false);
    const [showIpAcceptDeleteModal, setShowIpAcceptDeleteModal] = useState<boolean>(false);
    const [actionSelected, setActionSelected] = useState<String>('');
    const [IpAcceptSelected, setIpAcceptSelected] = useState<String>('');

    useEffect(() => {
        getIPAccessByCompany()
    },[]);

        const getIPAccessByCompany = async() => {
            setLoading(true);
            await ConfigurationIPService.getIPAccessByCompany().then((data) => {
                setIpsAccept(data.data || []);
                setLoading(false);
            })
        }

    function RecuperarData(value){
        console.log(value)
        setIpAcceptSelected(value)
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Container>
                <TableDataV2
                title={"IP'S ACEPTADAS"}
                data={IpsAccept}
                header={[
                    { name: 'ip', label: 'IP', filter: false, Chip: false },
                    { name: 'user_name', label: 'Nombre de Usuario', filter: false, Chip: true },
                    { name: 'n_document', label: 'N° documento', filter: false, Chip: true },
                    { name: 'email', label: 'Correo de Usuario', filter: false, Chip: true },
                ]}
                actionSelect={setActionSelected}
                setModalSave={setShowIpAcceptModal}
                setModalDelete={setShowIpAcceptDeleteModal}
                status_action
                RecuperarData={RecuperarData}
                />
            </Container>

            { 
                showIpAcceptModal && (
                <ModalIpAccept
                    open={showIpAcceptModal}
                    closeModal={()=>{setShowIpAcceptModal(false)}}
                    actionSelected={actionSelected}
                    onListIps={getIPAccessByCompany}
                    userIp={IpAcceptSelected}
                    clearState={()=>{setIpAcceptSelected('')}}
                />
                )
            }
            {
                actionSelected === 'delete' && (
                <ModalIpDelete
                    open={showIpAcceptDeleteModal}
                    closeModal={()=>{setShowIpAcceptDeleteModal(false)}}
                    actionSelected={actionSelected}
                    onListIps={getIPAccessByCompany}
                    userIp={IpAcceptSelected}
                    clearState={()=>{setIpAcceptSelected('')}}
                    type='accept'
                />
                )
            }
        
        </Protected>
    )
};
import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Alert, Backdrop, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar, Breadcrumbs } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { ModalCompanyDelete } from '@/components/Modal/ModalCompanyDelete';
import { Props } from './Products.type';
import { ProductService } from '@/service/services/Products.service';
import { ModalProduct } from '@/components/Modal/ModalProduct/ModalProducts';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import GroupsIcon from '@mui/icons-material/Groups';
import { ModalProductMovement } from '@/components/Modal/ModalProductMovement/ModalProductMovements';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
 });


export const ProductsView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [products, setProducts] = useState<any>([]);
    const [showProductModal, setShowProductModal] = useState<boolean>(false);
    const [showProductDeleteModal, setShowProductDeleteModal] = useState<boolean>(false);
    const [actionSelected, setActionSelected] = useState<any>('');
    const [productSelected, setProductSelected] = useState<any>('');

    const [purchases, setPurchases] = useState<any>([]);
    const [showPurchaseModal, setShowPurchaseModal] = useState<boolean>(false);
    const [purchaseSelected, setPurchaseSelected] = useState<any>('');
    const [showPurchases, setShowPurchases] = useState<boolean>(false);
    const [view, setView] = useState<String>('products');

    const [movement, setMovement] = useState<any>([]);
    const [showMovementModal, setShowMovementModal] = useState<boolean>(false);
    const [showMovement, setShowMovement] = useState<boolean>(false);
    const [movementSelected, setMovementSelected] = useState<any>('');

    const headerProducts = [
        { name: 'name', label: 'Nombre', filter: false, Chip: true },
        { name: 'brand', label: 'Modelo', filter: false, Chip: false },
        { name: 'description', label: 'Descripción', filter: false, Chip: false },
    ]
    const cabeceraPurchases = [
        { name: 'num_doc', label: 'N° de Documento' },
        { name: 'date_purchase', label: 'Fecha de Compra' },
        { name: 'model', label: 'Modelo' },
        { name: 'net_cost', label: 'net_cost' },
        { name: 'status_initial', label: 'Estado Inicial' },
        { name: 'estimated_duration', label: 'Duración Estimada' },
     ];
  
     const cabeceraMovements = [
        { name: 'type_movement', label: 'Tipo de Movimiento' },
        { name: 'date', label: 'Fecha' },
        { name: 'agreed_return_date', label: 'Fecha de Retorno Acordado' },
     ];

    useEffect(() => {
        getProducts();
    },[]);

    useEffect(()=> {
        if(productSelected && actionSelected=='helper' && view=='purchases'){
            purchasesByProdyct(productSelected.id);
        }
    },[productSelected]);
  
     useEffect(()=> {
        if(purchaseSelected && actionSelected=='helper' && view=='movements'){
            movementsByPurchase(purchaseSelected.id);
        }
    },[purchaseSelected]);

    const getProducts = async () => {
        setLoading(true);
        const response = await ProductService.getProducts();
        if (response.data) {
            setProducts(response.data || [])
        } else {
            console.log('error')
        }
        setLoading(false);
    }

    const purchasesByProdyct = async(idProduct) => {
        setLoading(true);
        await ProductService.purchasesByProdyct(idProduct).then((data) => {
            setPurchases(data.data || []);
           setLoading(false);
        })
    }

    const movementsByPurchase = async(idPurchase) => {
        setLoading(true);
        await ProductService.movementsByPurchase(idPurchase).then((data) => {
            setMovement(data.data || []);
           setLoading(false);
        })
    }

    function RecuperarData(value){
        console.log(value);

        !productSelected && setProductSelected(value);
        productSelected && view=='purchases' && setPurchaseSelected(value);
        productSelected && purchaseSelected && view=='movements' && setMovementSelected(value);

        if(value.action == "helper" && view=='products'){
            setShowPurchases(true);
            setView('purchases');
        }else if(value.action == "helper" && view=='purchases'){
            setShowMovement(true);
            setView('movements');
        }
    }

    return (
        <Protected>
            {loading && <Loading/>}

            <Container>

                <Breadcrumbs aria-label="breadcrumb">
                <StyledBreadcrumb
                    label="Lista de Productos"
                    onClick={() => {
                        setView('products');
                        setProductSelected('');
                        setShowPurchases(false);
                        setShowMovement(false);
                    }}
                    icon={<GroupsIcon fontSize="small" />}
                />
                {
                    showPurchases && (
                        <StyledBreadcrumb
                            label={`Compras por Producto`}
                            onClick={() => {
                                setView('purchases');
                                setPurchaseSelected('');
                                setShowMovement(false);
                            }}
                            icon={<GroupsIcon fontSize="small" />}
                        />
                    )
                }
                {
                    showMovement && (
                        <StyledBreadcrumb
                            label={`Movimientos de la Compra`}
                            onClick={() => {setView('movements')}}
                            icon={<GroupsIcon fontSize="small" />}
                        />
                    )
                }
                </Breadcrumbs>

                {
                    view == 'products' && (
                        <TableDataV2
                        title={'Productos'}
                        data={products || []}
                        header={headerProducts}
                        actionSelect={setActionSelected}
                        setModalSave={setShowProductModal}
                        setModalDelete={setShowProductDeleteModal}
                        btn_delete_disable={true}
                        btn_helper
                        status_action
                        RecuperarData={RecuperarData}
                        />
                    )
                }

                {
                    view == 'purchases' && (
                        <TableDataV2
                        title={'Compras'}
                        data={purchases || []}
                        header={cabeceraPurchases}
                        actionSelect={setActionSelected}
                        setModalSave={setShowPurchaseModal}
                        btn_delete_disable={true}
                        btn_edit_disable={true}
                        btn_helper
                        btn_disabled
                        status_action
                        RecuperarData={RecuperarData}
                        />
                    )
                }

                {
                    view == 'movements' && (
                        <TableDataV2
                        title={'Movimientos'}
                        data={movement || []}
                        header={cabeceraMovements}
                        actionSelect={setActionSelected}
                        setModalSave={setShowMovementModal}
                        btn_delete_disable={true}
                        btn_disabled
                        status_action
                        RecuperarData={RecuperarData}
                        />
                    )
                }

            </Container>

            { 
                showProductModal && (
                <ModalProduct
                    open={showProductModal}
                    closeModal={()=>{setShowProductModal(false)}}
                    actionSelected={actionSelected}
                    onListProduct={getProducts}
                    product={productSelected}
                    clearState={()=>{setProductSelected('')}}
                />
                )
            }
            {
                actionSelected === 'delete' && (
                <ModalCompanyDelete
                    open={showProductDeleteModal}
                    closeModal={()=>{setShowProductDeleteModal(false)}}
                    actionSelected={actionSelected}
                    onListCompanies={getProducts}
                    company={productSelected}
                    clearState={()=>{setProductSelected('')}}
                />
                )
            }
            {
                (showMovementModal && view == 'movements') && (
                <ModalProductMovement
                    open={showMovementModal}
                    closeModal={()=>{setShowMovementModal(false)}}
                    actionSelected={actionSelected}
                    onListMovements={movementsByPurchase}
                    movement={movementSelected}
                    purchaseSelected={purchaseSelected}
                    clearState={()=>{setMovementSelected('')}}
                    type={'changebranchoffice'}
                />
                )
            }
        
        </Protected>
    )
};
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Button, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalExpenseEmployee.type";
import { EmployeeExpenseService } from "@/service/services/EmployeeExpense.service";
import { PeriodService } from "@/service/services/Period.service";

export const ModalExpenseEmployee: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, expense, onListExpenses, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [countReport, setCountReport] = useState<any>([]);
    const [expenseReportType, setExpenseReportType] = useState<any>([]);
    const [currentCv0, setCurrentCv0] = useState<any>(null); 
    const [currentCv1, setCurrentCv1] = useState<any>(null); 
    const [currentCv2, setCurrentCv2] = useState<any>(null); 

    const [periods, setPeriods] = useState<any>([]);
    const [years, setYears] = useState<any>([]);
    const [yearSelected, setYearSelected] = useState<any>('');
    const [monthSelected, setMonthSelected] = useState<any>('');

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [data, setData] = useState({
        id: expense ? expense.id : '',
        detail: expense ? expense.detail : '',
        idperiod: expense ? expense.idperiod : '',
        numberReport: expense ? expense.numberReport : '',

        idexpense_report_type0: expense ? expense.idexpense_report_type0 : '',
        detailReport0: expense ? expense.detailReport0 : '',
        amount0: expense ? expense.amount0 : '',
        doc0: expense ? expense.doc0 : '',

        idexpense_report_type1: expense ? expense.idexpense_report_type1 : '',
        detailReport1: expense ? expense.detailReport1 : '',
        amount1: expense ? expense.amount1 : '',
        doc1: expense ? expense.doc1 : '',

        idexpense_report_type2: expense ? expense.idexpense_report_type2 : '',
        detailReport2: expense ? expense.detailReport2 : '',
        amount2: expense ? expense.amount2 : '',
        doc2: expense ? expense.doc2 : '',
    });

    useEffect(() => {
        listReportType();
        getPeriods();
    }, []);

    const listReportType = async () => {
        setLoading(true);
        await EmployeeExpenseService.listReportType().then((data) => {
            setExpenseReportType(data.data || [])
           setLoading(false);
        });
    }

    const getPeriods = async() => {
        setLoading(true);
        const resp:any = await PeriodService.getPeriods();
        const newData = [];
        const arrayYears = [];
        resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
           newData.push({
              id: item.id,
              month: item.month,
              year: item.year,
              alias_name: item.month + '-' +  item.year,
           })
           const found = arrayYears.find(element => element == item.year);
           if(!found) { arrayYears.push(item.year); }
           
        });
        setYears(arrayYears);
        setPeriods(newData || []);
        setLoading(false);
    }

    const selectCountReport = (event) => {
        const count = event.target.value;
        let array = [];
        for (let index = 0; index < count; index++) {
            let obj = {
                name: `Detalle N° ${index + 1}`
            }
            array.push(obj);
        }
        setCountReport(array);
    }

    const setNameFile = (event) => {
        const files = event.target && event.target.files || [];
        const currentCv = files[0];
        
        if(currentCv){
            if(countReport.length >= 1 ) setCurrentCv0(currentCv);
            if(countReport.length >= 2 ) setCurrentCv1(currentCv);
            if(countReport.length >= 3 ) setCurrentCv2(currentCv);

            const nameAsArray = currentCv.name.split(".") || [];
            const nameWithoutExtension = nameAsArray.filter((word, i) => i != nameAsArray.length-1);
            const nameFile = nameWithoutExtension.join(".") || "";
            return currentCv;
        }
        return "";
    };


    const validateForm = (values) => {
        let errors: any = {};
        if(!values.detail) errors.detail = "nombre requerido";
        // if(!values.date) errors.date = "fecha requerido";
        if(!values.numberReport) errors.numberReport = "numero de reporte requerido";

        if(countReport.length >= 1 && !values.idexpense_report_type0) errors.idexpense_report_type0 = "typo de reporte requerido";
        if(countReport.length >= 1 && !values.detailReport0) errors.detailReport0 = "detalle requerido";
        if(countReport.length >= 1 && !values.amount0) errors.amount0 = "monto requerido";
        // if(countReport.length >= 1 && !values.doc0) errors.doc0 = "documento requerido";

        if(countReport.length >= 2 && !values.idexpense_report_type1) errors.idexpense_report_type1 = "typo de reporte requerido";
        if(countReport.length >= 2 && !values.detailReport1) errors.detailReport1 = "detalle requerido";
        if(countReport.length >= 2 && !values.amount1) errors.amount1 = "monto requerido";
        // if(countReport.length >= 1 && !values.doc1) errors.doc1 = "documento requerido";

        if(countReport.length >= 3 && !values.idexpense_report_type2) errors.idexpense_report_type2 = "typo de reporte requerido";
        if(countReport.length >= 3 && !values.detailReport2) errors.detailReport2 = "detalle requerido";
        if(countReport.length >= 3 && !values.amount2) errors.amount2 = "monto requerido";
        // if(countReport.length >= 1 && !values.doc1) errors.doc1 = "documento requerido";
        console.log(errors);
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {
        
        if(!monthSelected || !yearSelected){
            return (
               setSnackBarConfig(prev => ({ ...prev, open: true, 
                  severity:'warning', 
                  message: `Debe seleccionar Mes y Año.` }
               ))
            );
        }

        if(countReport.length >= 1 && !currentCv0){
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: `Seleccione un archivo para el primer detalle` }
                ))
            );
        }

        if(countReport.length >= 2 && !currentCv1){
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: `Seleccione un archivo para el segundo detalle` }
                ))
            );
        }

        if(countReport.length >= 3 && !currentCv2){
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: `Seleccione un archivo para el tercer detalle` }
                ))
            );
        }

        const periodSelected = monthSelected + '-' + yearSelected;
        const period = periods.find(element => element.alias_name == periodSelected);

        const formData = new FormData();
        formData.append("id", values.id);
        formData.append("detail", values.detail);
        formData.append("idperiod", period.id);
        formData.append("numberReport", values.numberReport);

        formData.append("idexpense_report_type0", values.idexpense_report_type0);
        formData.append("detailReport0", values.detailReport0);
        formData.append("amount0", values.amount0);
        formData.append("doc0", values.doc0);

        formData.append("idexpense_report_type1", values.idexpense_report_type1);
        formData.append("detailReport1", values.detailReport1);
        formData.append("amount1", values.amount1);
        formData.append("doc1", values.doc1);

        formData.append("idexpense_report_type2", values.idexpense_report_type2);
        formData.append("detailReport2", values.detailReport2);
        formData.append("amount2", values.amount2);
        formData.append("doc2", values.doc2);

        setLoading(true);
        let generateExpense;

        // if(values.date){
        //     const Datefilter = (values.date).split("-");
        //     const year = Datefilter[0];
        //     const month = Datefilter[1];

        //     await EmployeeExpenseService.verifyExpenseEmployee(year, month).then((data) => {
        //         if(data.data.status == false){
        //             generateExpense = false;
        //             setLoading(false);
        //             return (
        //                 setSnackBarConfig(prev => ({ ...prev, open: true, 
        //                     severity:'warning', 
        //                     message: `${data.data.message}` }
        //                 ))
        //             );
        //         }else{
        //             generateExpense = true;
        //         }
        //     })
        // }

        // if(generateExpense == true){
            await EmployeeExpenseService.createExpenseEmployee(formData).then((data) => {
                if(data.data.status == false){
                    setLoading(false);
                    return (
                        setSnackBarConfig(prev => ({ ...prev, open: true, 
                            severity:'warning', 
                            message: `${data.data.message}` }
                        ))
                    );
                }
                onListExpenses && onListExpenses();
                closeModal && closeModal();
            })
        // }
        setLoading(false);
    }

    return(
        <>
        {loading && <Loading/>}
        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} handle size="lg">
            <ModalHeader
                text={(expense && actionSelected=='edit') ? "Editar Gasto" : "Agregar Gasto"}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    <Grid item xs={6} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={3}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Detalle:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="detail"
                                            variant="standard"
                                            type="text" 
                                            name="detail"
                                            multiline
                                            maxRows={4}
                                            fullWidth
                                            value={values.detail}
                                            onChange={handleChange}
                                            placeholder="concepto de detalle"
                                            error={errors.detail && touched.detail ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={6} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={3}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Periodo:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <Select
                                            id="id_education_level"
                                            variant="standard"
                                            value={yearSelected}
                                            onChange={(event) => {
                                            setYearSelected(event.target.value);
                                            }}
                                            fullWidth
                                        >
                                            {
                                            years.map((year, i) => {
                                                return <MenuItem key={i} value={year}>{year}</MenuItem>;
                                            })
                                            }
                                        </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={6} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={3}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Mes:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <Select
                                            id="id_education_level"
                                            variant="standard"
                                            value={monthSelected}
                                            onChange={(event) => {setMonthSelected(event.target.value)}}
                                            fullWidth
                                        >
                                            <MenuItem value={'01'}>Enero</MenuItem>
                                            <MenuItem value={'02'}>Febrero</MenuItem>
                                            <MenuItem value={'03'}>Marzo</MenuItem>
                                            <MenuItem value={'04'}>Abril</MenuItem>
                                            <MenuItem value={'05'}>Mayo</MenuItem>
                                            <MenuItem value={'06'}>Junio</MenuItem>
                                            <MenuItem value={'07'}>Julio</MenuItem>
                                            <MenuItem value={'08'}>Agosto</MenuItem>
                                            <MenuItem value={'09'}>Septiembre</MenuItem>
                                            <MenuItem value={'10'}>Octubre</MenuItem>
                                            <MenuItem value={'11'}>Noviembre</MenuItem>
                                            <MenuItem value={'12'}>Diciembre</MenuItem>
                                        </Select>
                                        </Grid>
                                    </Grid>

                                    {/* <Grid item xs={6} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={3}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Fecha:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                id="date"
                                                variant="standard"
                                                fullWidth
                                                name="date"
                                                type="date"
                                                onChange={handleChange}
                                                value={values.date}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={errors.date && touched.date ? true : false}
                                            />
                                        </Grid>
                                    </Grid> */}

                                    <Grid item xs={6} container alignItems="center" justifyContent="center" marginBottom='25px' >                
                                        <Grid item xs={3}>
                                            <Typography variant="subtitle1"><b>N° de reportes:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="numberReport"
                                                variant="standard"
                                                value={values.numberReport}
                                                onChange={(event) => {
                                                    setFieldValue("numberReport", event.target.value)
                                                    selectCountReport(event);
                                                }}
                                                fullWidth
                                                error={errors.numberReport && touched.numberReport ? true : false}
                                            >
                                                <MenuItem value="1">1</MenuItem>
                                                <MenuItem value="2">2</MenuItem>
                                                <MenuItem value="3">3</MenuItem>
                                            </Select>
                                        </Grid> 
                                    </Grid>

                                    {
                                        countReport.length >= 1 && (
                                            <>
                                            <Grid item xs={12} container alignItems="center" justifyContent="start" marginBottom='10px' >
                                                {`Detalle N° 1`}
                                            </Grid>
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' sx={{border: '1px #b9b1a9 solid'}} >
                                                <Grid item xs={6} container alignItems="center" justifyContent="center"  marginTop='25px' marginBottom='25px' >                
                                                    <Grid item xs={3}>
                                                        <Typography variant="subtitle1"><b>Tipo de reporte:</b> </Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Select
                                                            id="idexpense_report_type0"
                                                            variant="standard"
                                                            value={values.idexpense_report_type0}
                                                            onChange={(event) => {
                                                                setFieldValue("idexpense_report_type0", event.target.value)
                                                            }}
                                                            fullWidth
                                                            error={errors.idexpense_report_type0 && touched.idexpense_report_type0 ? true : false}
                                                        >
                                                            {
                                                                expenseReportType.length > 0 && expenseReportType.map((type, i) => {
                                                                    return <MenuItem key={i} value={type.id}>{type.name}</MenuItem>;
                                                                })
                                                            }
                                                        </Select>
                                                    </Grid> 
                                                </Grid>

                                                <Grid item xs={6} container alignItems="center" justifyContent="center" marginBottom='25px' marginTop='25px'  >
                                                    <Grid item xs={3}>
                                                    <Typography variant="subtitle1" className="custom-input"><b>Detalle:</b></Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                    <TextField
                                                        id="detailReport0"
                                                        variant="standard"
                                                        type="text" 
                                                        name="detailReport0"
                                                        multiline
                                                        maxRows={4}
                                                        fullWidth
                                                        value={values.detailReport0}
                                                        onChange={handleChange}
                                                        placeholder="detalle"
                                                        error={errors.detailReport0 && touched.detailReport0 ? true : false}
                                                    />
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={6} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                    <Grid item xs={3}>
                                                    <Typography variant="subtitle1" className="custom-input"><b>Monto:</b></Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                    <TextField
                                                        id="amount0"
                                                        variant="standard"
                                                        type="number" 
                                                        name="amount0"
                                                        fullWidth
                                                        value={values.amount0}
                                                        onChange={handleChange}
                                                        placeholder="monto"
                                                        error={errors.amount0 && touched.amount0 ? true : false}
                                                    />
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={6} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                    <Grid item xs={3}>
                                                        <Typography variant="subtitle1" className="custom-input"><b>Documento:</b></Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Button variant="contained" component="label" fullWidth>
                                                            <input
                                                                id="doc0"
                                                                type="file"
                                                                name="doc0"
                                                                onChange={(event) => {
                                                                    setFieldValue("doc0", setNameFile(event));
                                                                }}
                                                            />
                                                        </Button>
                                                    </Grid>
                                                </Grid> 

                                            </Grid>
                                            </>
                                        )
                                    }
                                    {
                                        countReport.length >= 2 && (
                                            <>
                                            <Grid item xs={12} container alignItems="center" justifyContent="start" marginBottom='10px' >
                                                {`Detalle N° 2`}
                                            </Grid>
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' sx={{border: '1px #b9b1a9 solid'}} >
                                                <Grid item xs={6} container alignItems="center" justifyContent="center"  marginTop='25px' marginBottom='25px' >                
                                                    <Grid item xs={3}>
                                                        <Typography variant="subtitle1"><b>Tipo de reporte:</b> </Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Select
                                                            id="idexpense_report_type1"
                                                            variant="standard"
                                                            value={values.idexpense_report_type1}
                                                            onChange={(event) => {
                                                                setFieldValue("idexpense_report_type1", event.target.value)
                                                            }}
                                                            fullWidth
                                                            error={errors.idexpense_report_type1 && touched.idexpense_report_type1 ? true : false}
                                                        >
                                                            {
                                                                expenseReportType.length > 0 && expenseReportType.map((type, i) => {
                                                                    return <MenuItem key={i} value={type.id}>{type.name}</MenuItem>;
                                                                })
                                                            }
                                                        </Select>
                                                    </Grid> 
                                                </Grid>

                                                <Grid item xs={6} container alignItems="center" justifyContent="center" marginBottom='25px' marginTop='25px'  >
                                                    <Grid item xs={3}>
                                                    <Typography variant="subtitle1" className="custom-input"><b>Detalle:</b></Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                    <TextField
                                                        id="detailReport1"
                                                        variant="standard"
                                                        type="text" 
                                                        name="detailReport1"
                                                        multiline
                                                        maxRows={4}
                                                        fullWidth
                                                        value={values.detailReport1}
                                                        onChange={handleChange}
                                                        placeholder="detalle"
                                                        error={errors.detailReport1 && touched.detailReport1 ? true : false}
                                                    />
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={6} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                    <Grid item xs={3}>
                                                    <Typography variant="subtitle1" className="custom-input"><b>Monto:</b></Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                    <TextField
                                                        id="amount1"
                                                        variant="standard"
                                                        type="number" 
                                                        name="amount1"
                                                        fullWidth
                                                        value={values.amount1}
                                                        onChange={handleChange}
                                                        placeholder="monto"
                                                        error={errors.amount1 && touched.amount1 ? true : false}
                                                    />
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={6} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                    <Grid item xs={3}>
                                                        <Typography variant="subtitle1" className="custom-input"><b>Documento:</b></Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Button variant="contained" component="label" fullWidth>
                                                            <input
                                                                id="doc1"
                                                                type="file"
                                                                name="doc1"
                                                                onChange={(event) => {
                                                                    setFieldValue("doc1", setNameFile(event));
                                                                }}
                                                            />
                                                        </Button>
                                                    </Grid>
                                                </Grid> 

                                            </Grid>
                                            </>
                                        )
                                    }
                                    {
                                        countReport.length >= 3 && (
                                            <>
                                            <Grid item xs={12} container alignItems="center" justifyContent="start" marginBottom='10px' >
                                                {`Detalle N° 3`}
                                            </Grid>
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' sx={{border: '1px #b9b1a9 solid'}} >
                                                <Grid item xs={6} container alignItems="center" justifyContent="center"  marginTop='25px' marginBottom='25px' >                
                                                    <Grid item xs={3}>
                                                        <Typography variant="subtitle1"><b>Tipo de reporte:</b> </Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Select
                                                            id="idexpense_report_type2"
                                                            variant="standard"
                                                            value={values.idexpense_report_type2}
                                                            onChange={(event) => {
                                                                setFieldValue("idexpense_report_type2", event.target.value)
                                                            }}
                                                            fullWidth
                                                            error={errors.idexpense_report_type2 && touched.idexpense_report_type2 ? true : false}
                                                        >
                                                            {
                                                                expenseReportType.length > 0 && expenseReportType.map((type, i) => {
                                                                    return <MenuItem key={i} value={type.id}>{type.name}</MenuItem>;
                                                                })
                                                            }
                                                        </Select>
                                                    </Grid> 
                                                </Grid>

                                                <Grid item xs={6} container alignItems="center" justifyContent="center" marginBottom='25px' marginTop='25px'  >
                                                    <Grid item xs={3}>
                                                    <Typography variant="subtitle1" className="custom-input"><b>Detalle:</b></Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                    <TextField
                                                        id="detailReport2"
                                                        variant="standard"
                                                        type="text" 
                                                        name="detailReport2"
                                                        multiline
                                                        maxRows={4}
                                                        fullWidth
                                                        value={values.detailReport2}
                                                        onChange={handleChange}
                                                        placeholder="detalle"
                                                        error={errors.detailReport2 && touched.detailReport2 ? true : false}
                                                    />
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={6} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                    <Grid item xs={3}>
                                                    <Typography variant="subtitle1" className="custom-input"><b>Monto:</b></Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                    <TextField
                                                        id="amount2"
                                                        variant="standard"
                                                        type="number" 
                                                        name="amount2"
                                                        fullWidth
                                                        value={values.amount2}
                                                        onChange={handleChange}
                                                        placeholder="monto"
                                                        error={errors.amount2 && touched.amount2 ? true : false}
                                                    />
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={6} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                    <Grid item xs={3}>
                                                        <Typography variant="subtitle1" className="custom-input"><b>Documento:</b></Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Button variant="contained" component="label" fullWidth>
                                                            <input
                                                                id="doc2"
                                                                type="file"
                                                                name="doc2"
                                                                onChange={(event) => {
                                                                    setFieldValue("doc2", setNameFile(event));
                                                                }}
                                                            />
                                                        </Button>
                                                    </Grid>
                                                </Grid> 

                                            </Grid>
                                            </>
                                        )
                                    }

                                </Grid>

                                <Divider />

                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(expense && actionSelected=='edit') ? "ACTUALIZAR" : "REGISTRAR"}
                                    cancelText="CANCELAR" 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}
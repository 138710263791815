import { Props } from './Advances.type';
import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Alert, Backdrop, Box, Breadcrumbs, Button, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar, Typography } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { WorkerService } from '@/service/services/Worker.service';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import GroupsIcon from '@mui/icons-material/Groups';
import { AdvanceService } from '@/service/services/Advance.service';
import { ModalAdvance } from '@/components/Modal/ModalAdvance';
import { ModalAdvanceDelete } from '@/components/Modal/ModalAdvanceDelete';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { RleStructureService } from '@/service/services/RleStructure.service';
import { ROLE_ADMIN } from "@/toolbox/defaults/static-roles";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

export const AdvancesView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [workers, setWorkers] = useState<any>([]);
    const [workerSelected, setWorkerSelected] = useState<any>('');
    console.group(workerSelected);
    const [advances, setAdvances] = useState<any>([]);
    const [showAdvanceModal, setShowAdvanceModal] = useState<boolean>(false);
    const [showAdvanceDeleteModal, setShowAdvanceDeleteModal] = useState<boolean>(false);
    const [advanceSelected, setAdvanceSelected] = useState<any>('');
    const [actionSelected, setActionSelected] = useState<any>('');
    const [view, setView] = useState<String>('workers');
    const [showAdvances, setShowAdvances] = useState<boolean>(false);
    const [periodMonth, setPeriodMonth] = useState<any>(null);

    const dataUser = readLocalStorage(KEY_USER_DATA);
    const role = dataUser?.user?.role;
    const sessionUser = dataUser?.user;
    // console.log(sessionUser);

    const cabecera = [
        { name: 'n_document', label: 'RUT TRABAJADOR' },
        { name: 'surname', label: 'APELLIDO PATERNO' },
        { name: 'second_surname', label: 'APELLIDO MATERNO' },
        { name: 'name', label: 'NOMBRES' }];

    const cabeceraAdvance = [
    { name: 'periodo', label: 'PERIODO' },
    { name: 'value', label: 'VALOR' },
    { name: 'request_status', label: 'ESTADO' },];

    const addAdvanceStructure = async () =>{
        if(role == ROLE_ADMIN && periodMonth){
         const  structreAll =  await RleStructureService.getDetailEmployeeSO(workerSelected.id);
         console.log(structreAll)
         const StructureAdvance = structreAll.data.find(item => item.code == "DV4");
         console.log(StructureAdvance)
         console.log(periodMonth)
         let totalAdvance = parseFloat(advanceSelected.value);
         advances.map((advanceItem) => {
            console.log(advanceItem.periodo)
            if(advanceItem.periodo == periodMonth.monthSelected+' - '+periodMonth.yearSelected && advanceItem.request_status == "aceptada" ) {
                console.log(advanceItem)
                totalAdvance = totalAdvance + parseFloat(advanceItem.value)
            }
        })
        let totalAdvanceStr = totalAdvance+""
    
        const respEditStrc = await RleStructureService.editDetailEmployeeSO(StructureAdvance?.id,{status:"1",validity:periodMonth.yearSelected+'-'+periodMonth.monthSelected,value: totalAdvanceStr})
        console.log(respEditStrc)
        }
    }

    useEffect(()=> {
        if(role != 'TRABAJADOR') getWorkers();
    },[]);

    useEffect(()=> {
        if(role == 'TRABAJADOR' && sessionUser.idemployee) {getAdvancesByEmployee(sessionUser?.idemployee); setView('advances')}
    },[]);

    useEffect(()=> {
        if(workerSelected) getAdvancesByEmployee(workerSelected.id);
    },[workerSelected]);

    useEffect(()=>{
        if(!showAdvanceModal){addAdvanceStructure()}
    },[showAdvanceModal])

    const getWorkers = async() => {
        setLoading(true);
        const resp:any = await WorkerService.getWorkers();
        setWorkers(resp.data || []);
        setLoading(false);
    }

    const getAdvancesByEmployee = async(idEmployee) => {
        setLoading(true);
        await AdvanceService.getAdvancesByEmployee(idEmployee).then((data) => {
            const oldData = data.data || [];
            let newData = [];
            oldData.length > 0 && oldData.map((item) => {
                data = {
                    id: item.id,
                    authorized_date: item.authorized_date,
                    idauthorizeduser: item.idauthorizeduser,
                    idcompany: item.idcompany,
                    idemployee: item.idemployee,
                    idperiod: item.idperiod,
                    n_document: item.n_document,
                    name: item.name,
                    periodo: item.periodo,
                    request_status: item.request_status == '0' ? 'pendiente' : (item.request_status == '1' ? 'aceptada' : 'rechazada'),
                    second_surname: item.second_surname,
                    status: item.status,
                    surname: item.surname,
                    value: item.value,
                }
                newData.push(data);
            });
            setAdvances(newData || []);
            setLoading(false);
        })
    }

    function RecuperarData(value){
        console.log(value);
        if(role == ROLE_ADMIN){
            !workerSelected && setWorkerSelected(value);
            workerSelected && setAdvanceSelected(value);
        }else{
            setAdvanceSelected(value);
        }
        
        setShowAdvances(true);
        setView('advances');
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Container>
            {
                role == ROLE_ADMIN && (
                    <Breadcrumbs aria-label="breadcrumb">
                        <StyledBreadcrumb
                            label="Lista de Empleados"
                            onClick={() => {
                                setView('workers');
                                setWorkerSelected('');
                                setShowAdvances(false);
                            }}
                            icon={<GroupsIcon fontSize="small" />}
                        />
                        {
                            showAdvances && (
                                <StyledBreadcrumb
                                    label={`Anticipos`}
                                    onClick={() => {setView('advances')}}
                                    icon={<GroupsIcon fontSize="small" />}
                                />
                            )
                        }
                    </Breadcrumbs>
                )
            }
            
            {
                (role == ROLE_ADMIN && view == 'workers') && (
                    <TableDataV2
                        title={'Valor de hora extras por empleado'}
                        data={workers}
                        header={cabecera}
                        actionSelect={setActionSelected}
                        status_action
                        disabled_title={true}
                        btn_disabled
                        btn_edit_disable={true}
                        btn_delete_disable={true}
                        btn_helper
                        currentView={view}
                        RecuperarData={RecuperarData}
                        />
                )
            }
            {
                view == 'advances' && (
                    <TableDataV2
                        title={`Anticipos de "${workerSelected?.name || sessionUser?.user_name}"`}
                        data={advances}
                        header={cabeceraAdvance}
                        actionSelect={setActionSelected}
                        setModalSave={setShowAdvanceModal}
                        setModalDelete={setShowAdvanceDeleteModal}
                        status_action
                        disabled_title={false}
                        btn_disabled={false}
                        btn_edit_disable={false}
                        btn_delete_disable={false}
                        currentView={view}
                        RecuperarData={RecuperarData}
                    />
                )
            }
            </Container>

            { 
                showAdvanceModal && (
                    <ModalAdvance
                        open={showAdvanceModal}
                        closeModal={()=>{setShowAdvanceModal(false)}}
                        actionSelected={actionSelected}
                        onListAdvances={getAdvancesByEmployee}
                        advance={advanceSelected}
                        workerSelected={workerSelected || sessionUser}
                        clearState={()=>{setAdvanceSelected('')}}
                        setPeriodMonth={setPeriodMonth}
                    />
                )
            }
            {
                actionSelected === 'delete' && (
                    <ModalAdvanceDelete
                        open={showAdvanceDeleteModal}
                        closeModal={()=>{setShowAdvanceDeleteModal(false)}}
                        actionSelected={actionSelected}
                        onListAdvances={getAdvancesByEmployee}
                        advance={advanceSelected}
                        workerSelected={workerSelected || sessionUser}
                        clearState={()=>{setAdvanceSelected('')}}
                    />
                )
            }
        
        </Protected>
    )
};
import { AssistanceRepository } from "../repositories/Assistance.repository";

export const AssistanceService = {
    getExtraHoursByEmployee,
    getAbsencesByEmployee,
    getDelaysByEmployee,
    getComboAbsences,
    getTypeExtraHour,
    getDetailEmployeeOPT,
    createExtraHour,
    createAbsence,
    createDelay,
    editExtraHour,
    editAbsence,
    editDelay,
    deleteExtraHour,
    deleteAbsence,
    deleteDelay,
}

async function getExtraHoursByEmployee(idEmployee: number){
    const res = await AssistanceRepository.getExtraHoursByEmployee(idEmployee);
    return res;
}

async function getAbsencesByEmployee(idEmployee: number){
    const res = await AssistanceRepository.getAbsencesByEmployee(idEmployee);
    return res;
}

async function getComboAbsences(){
    const res = await AssistanceRepository.getComboAbsences();
    return res;
}

async function getDelaysByEmployee(idEmployee: number){
    const res = await AssistanceRepository.getDelaysByEmployee(idEmployee);
    return res;
}

async function getTypeExtraHour(){
    const res = await AssistanceRepository.getTypeExtraHour();
    return res;
}

async function getDetailEmployeeOPT(idEmployee: number){
    const res = await AssistanceRepository.getDetailEmployeeOPT(idEmployee);
    return res;
}

async function createExtraHour(data:{}){
    const res = await AssistanceRepository.createExtraHour(data);
    return res;
}

async function createAbsence(data:{}){
    const res = await AssistanceRepository.createAbsence(data);
    return res;
}

async function createDelay(data:{}){
    const res = await AssistanceRepository.createDelay(data);
    return res;
}

async function editExtraHour(data:{}, idExtraHour: number){
    const res = await AssistanceRepository.editExtraHour(data, idExtraHour);
    return res;
}

async function editAbsence(data:{}, idAbsence: number){
    const res = await AssistanceRepository.editAbsence(data, idAbsence);
    return res;
}

async function editDelay(data:{}, idDelay: number){
    const res = await AssistanceRepository.editDelay(data, idDelay);
    return res;
}

async function deleteExtraHour(idExtraHour:number){
    const res = await AssistanceRepository.deleteExtraHour(idExtraHour);
    return res;
}

async function deleteAbsence(idAbsence:number){
    const res = await AssistanceRepository.deleteAbsence(idAbsence);
    return res;
}

async function deleteDelay(idDelay:number){
    const res = await AssistanceRepository.deleteDelay(idDelay);
    return res;
}
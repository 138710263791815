import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Alert, Backdrop, Box, Breadcrumbs, Button, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar, Typography } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { Props } from './LiquidationSalary.type';
import { WorkerService } from '@/service/services/Worker.service';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import { ModalGenerateLiquidation } from '@/components/Modal/ModalGenerateLiquidation';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { RemunerationService } from "@/service/services/Remuneration.service";


const StyledBreadcrumb = styled(Chip)(({ theme }) => {
   const backgroundColor =
       theme.palette.mode === 'light'
           ? theme.palette.grey[100]
           : theme.palette.grey[800];
   return {
       backgroundColor,
       height: theme.spacing(3),
       color: theme.palette.text.primary,
       fontWeight: theme.typography.fontWeightRegular,
       '&:hover, &:focus': {
           backgroundColor: emphasize(backgroundColor, 0.06),
       },
       '&:active': {
           boxShadow: theme.shadows[1],
           backgroundColor: emphasize(backgroundColor, 0.12),
       },
   };
});

export const LiquidationSalaryView: React.FC<Props> = (props:any): JSX.Element => {

   console.log(props)
   const [loading, setLoading] = useState<boolean>(false);
   const [liquidationSalaries, setliquidationSalaries] = useState<any>([]);
   const [workerSelected, setWorkerSelected] = useState<any>('');
   const [openModal, setOpenModal] = useState<Boolean>(false)
   const [actionSelected, setActionSelected] = useState<any>('');
   const [view, setView] = useState<String>('workers');
   const [showLiquidation, setShowLiquitacion] = useState<boolean>(false);
   const [liquidationExists, setLiquitacionExists] = useState<any>('');
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   });
   const [dataRequest, setDataRequest] = useState<any>('');

   const cabecera = [
      { name: 'n_document', label: 'RUT TRABAJADOR' },
      { name: 'surname', label: 'APELLIDO PATERNO' },
      { name: 'second_surname', label: 'APELLIDO MATERNO' },
      { name: 'name', label: 'NOMBRES' }];

   const getWorkers = async() => {
      setLoading(true);
      const resp:any = await WorkerService.getWorkers();
      setliquidationSalaries(resp.data);
      setLoading(false);
   }


  async function RecuperarData(value){
      if (value.action == 'reprocess') {
         const res: any = await RemunerationService.changeStatusRemuneration(value.id)
         console.log(res?.data)
         if (res?.data?.status == false) {
            console.log(res?.data)
            setSnackBarConfig(prev => ({
               ...prev, open: true,
               severity: 'warning',
               message: res?.data?.message
            }))
         } else {
            console.log(value);
            setWorkerSelected(value);
            setOpenModal(true)
         }
      } else {
         console.log(value);
         setWorkerSelected(value);
         setOpenModal(true)
      }
      // else
      // {
        
      // }
     
   }

   const openEmployeeRequest = async(dataRequest) =>{
      console.log(dataRequest?.idEmployee)
      const respWorker = await WorkerService.getWorkers();
      const workersAll = respWorker.data;
      console.log(workersAll)
      const findEmployee = workersAll.find(value => value.id == dataRequest?.idEmployee);
      console.log(findEmployee)
      setWorkerSelected(findEmployee);
      // setMessageRequest(dataRequest.detail)
      const dataRequestProps = {
         workerSelected: findEmployee,
         messageDetail: dataRequest.detail,
         idRemuneration: dataRequest.idRemuneration,
         idPeriod: dataRequest.idPeriod
      }
      setDataRequest(dataRequestProps)
      setOpenModal(true)
   }

   useEffect(()=> {
      getWorkers()
      if(props?.location.state){
         console.log(props?.location.state)
         openEmployeeRequest(props?.location.state)
      }

   },[])

  return (
      <Protected>
         {loading && <Loading/>}
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
               {snackBarConfig.message || ""}
            </Alert>
         </Snackbar>
         <Container maxWidth='xl'>
            <Grid container spacing={2} >
               <Grid item xs={12} md={12} display='flex' justifyContent='end' >
                  <Box sx={{ m: 1 }}>
                     <Button
                        startIcon={(<UploadIcon fontSize="small" />)}
                        sx={{ mr: 1 }}
                     >
                        Subir Plantilla 
                     </Button>
                     <Button
                        startIcon={(<DownloadIcon fontSize="small" />)}
                        sx={{ mr: 1 }}
                     >
                        Descargar Plantilla
                     </Button>
                  </Box>
               </Grid>
            </Grid>

            <TableDataV2
               title={'Liquidacion de sueldos por trabajadores'}
               data={liquidationSalaries}
               header={cabecera}
               actionSelect={setActionSelected}
               status_action
               btn_disabled
               btn_edit_disable={true}
               btn_delete_disable={true}
               btn_helper
               btn_reprocess
               // checkbox
               RecuperarData={RecuperarData}
            />
         </Container>
         {
            openModal && (
               <ModalGenerateLiquidation
                  open={openModal}
                  closeModal = {()=> {setOpenModal(false)}}
                  workerSelected={workerSelected}
                  setShowLiquitacion={setShowLiquitacion}
                  setView={setView}
                  setLiquitacionExists={setLiquitacionExists}
                  dataRequest={dataRequest}
                  setDataRequest={setDataRequest}
               />
            )
         }
      
      </Protected>
   )
};
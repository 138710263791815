import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';

export const reminderTypeRepository = {

    getReminderTypes: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/typeReminder`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createReminderType: async (dataReminderType) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/typeReminder`, {
            name: dataReminderType.name,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    editReminderType: async (dataReminderType, idReminderType: number) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/typeReminder/${idReminderType}`, {
            name: dataReminderType.name,
            url: dataReminderType.url,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    deleteReminderType: async(idReminderType:number): Promise<any> => {
        const res= await http.delete<any>(`${API_URL_BASE}/v1/typeReminder/${idReminderType}`)
        return res;
    },
}



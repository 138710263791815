import { useEffect, useState } from "react"
import { Protected } from "@/components/layout/Protected"
import { Box, Button, ButtonGroup, Card, Container, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { Loading } from "@/components/common/Loading"
import { Props } from "./WorkerValidation.type"
import { WorkerValidationService } from "@/service/services/WorkerValidation.service"
import { ModalWorkerValidation } from "@/components/Modal/ModalWorkerValidation"
import EditIcon from '@mui/icons-material/Edit';
import { API_URL_BASE } from "@/toolbox/defaults/app"
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage"
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper"
import { ModalUpdatePassword } from "@/components/Modal/ModalUpdatePassword/ModalUpdatePassword"

export const WorkerValidationView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [showUpdatePassModal, setShowUpdatePassModal] = useState<boolean>(false);
    const [validations, setValidations] = useState([]);
    const [showValidationModal, setShowValidationModal] = useState<boolean>(false);
    const [showValidationDeleteModal, setShowValidationDeleteModal] = useState<boolean>(false);
    const [validationSelected, setValidationSelected] = useState<any>('');
    const [actionSelected, setActionSelected] = useState<any>('');

    const dataUser = readLocalStorage(KEY_USER_DATA);
    const statusConfirm = dataUser?.user?.status_confirm;

    useEffect(() => {
        if(statusConfirm=='0'){
            // setShowValidationModal(true);
            setShowUpdatePassModal(true);
        }
    }, [statusConfirm=='0']);

    useEffect(() => {
        getWorkerValidations();
    }, []);

    const getWorkerValidations = async() => {
        setLoading(true);
        await WorkerValidationService.getWorkerValidations().then((data) => {
            const oldData = data.data || [];
            let newData = [];
            oldData.length > 0 && oldData.map((item) => {
                data = {
                    id: item.id,
                    iduser: item.iduser,
                    face_img: `${API_URL_BASE}/${item.face_img}`,
                    doc_img: `${API_URL_BASE}/${item.doc_img}`,
                    face_matching: item.face_matching,
                    observations: item.observations,
                    status: item.status,
                    // review_status: item.review_status == '0' ? 'pendiente' : (item.review_status == '1' ? 'aceptada' : 'rechazada'),
                }
                newData.push(data);
            });

            setValidations(newData|| []);
            setLoading(false);
        })
    }

    function RecuperarData(value){
        setValidationSelected(value)
        console.log(value)
    }

    const showDocument = (image) => {
        setLoading(true);

        if(image){
            window.open(image, '_blank');
        }

        setLoading(false);
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Container>
                <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    m: -1,
                    py: 3
                }}
                >
                    <Typography variant='h6' component='h1' gutterBottom className="tittle"
                    sx={{ m: 1 , fontWeight:600}}
                    >
                        {'VALIDACIÓN DE IDENTIDAD'}
                    </Typography>

                    <Button
                     color="primary"
                     variant="contained"
                     onClick={()=>{
                        setShowValidationModal(true);
                     }}
                  >
                   Agregar
                  </Button>
                    
                </Box>
            <Card sx={{width:"100%"}}>
                <TableContainer>
                    <Table>
                        <TableHead sx={{ bgcolor: '#F4F6F8' }}>
                            <TableRow >
                                <TableCell >
                                    <Typography>Imagen Rostro</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography>Imagen Documento</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography>Coincidencias de rostros</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography>Observaciones</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography>Acciones</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                validations.length > 0 && validations.map((item) => (
                                    <TableRow sx={{maxHeight:'50px'}}>
                                        <TableCell size={'small'} sx={{maxHeight:'50px'}}>
                                            <img src={item.face_img} style={{maxHeight:'100px' ,maxWidth:'100px', cursor: 'pointer'}} onClick={()=>{showDocument(item.face_img)}}/>
                                        </TableCell>
                                        <TableCell size={'small'}>
                                        <img src={item.doc_img} style={{maxHeight:'100px', maxWidth:'100px', cursor: 'pointer'}} onClick={()=>{showDocument(item.doc_img)}}/>
                                        </TableCell>
                                        <TableCell size={'small'}>
                                            {item.face_matching || 'pendiente'}
                                        </TableCell>
                                        <TableCell size={'small'}>
                                            {item.observations || 'pendiente'}
                                        </TableCell>
                                        <TableCell size={'small'}  align="center" >
                                        <ButtonGroup variant="contained" color="inherit" aria-label="button-group-custom-table" style={{padding: "0px 5px"}}>
                                            <IconButton size="small" color="success" aria-label="view" 
                                                   onClick={() => {
                                                    setValidationSelected(item);
                                                    setShowValidationModal(true);
                                                }}
                                                >
                                                   <EditIcon fontSize='small' />
                                                </IconButton>
                                        </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
            </Container>


            {
                showUpdatePassModal && (
                    <ModalUpdatePassword
                        closeModal={() => {setShowUpdatePassModal(false)}}
                    />
                )
            }

            { 
                showValidationModal && (
                    <ModalWorkerValidation
                        open={showValidationModal}
                        closeModal={()=>{setShowValidationModal(false)}}
                        actionSelected={actionSelected}
                        onListValidations={getWorkerValidations}
                        validation={validationSelected}
                        clearState={()=>{setValidationSelected('')}}
                    />
                )
            }

            {/* {
                actionSelected === 'delete' && (
                    <ModalPhaseDelete
                        open={showValidationDeleteModal}
                        closeModal={()=>{setShowValidationDeleteModal(false)}}
                        actionSelected={actionSelected}
                        onListValidations={getWorkerValidations}
                        validation={validationSelected}
                        clearState={()=>{setValidationSelected('')}}
                    />
                )
            } */}
        </Protected>
    )
}
import { WorkerContactRepository } from "../repositories/WorkerContact.repository";

export const WorkerContactService = {
    getEmployeeContact,
    createEmployeeContact,
    editEmployeeContact,
    deleteEmployeeContact,
}

async function getEmployeeContact(idEmployee:number){
    const res = await WorkerContactRepository.getEmployeeContact(idEmployee);
    return res;
}

async function createEmployeeContact(data:{}, idEmployee:number){
    const res = await WorkerContactRepository.createEmployeeContact(data, idEmployee);
    return res;
}

async function editEmployeeContact(data:{}, idContact:number){
    const res = await WorkerContactRepository.editEmployeeContact(data, idContact);
    return res;
}

async function deleteEmployeeContact(idContact:number){
    const res = await WorkerContactRepository.deleteEmployeeContact(idContact);
    return res;
}


import { Loading } from "@/components/common/Loading";
import { RemunerationService } from "@/service/services/Remuneration.service";
import { readLocalStorage, removeLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { Alert, Autocomplete, Button, Container, Grid, Snackbar, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
// import { Props } from "./PDF.type"


export const PreviewAmountGeneral: React.FC<any> = (props:any): JSX.Element => {
    // const { pdf, dataRemu, idEmploye } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [btnSave, setBtnSave] = useState<boolean>(true);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });
    console.log(props)
    const data = readLocalStorage('previewAmountGeneral');
    console.log(data)

    const [dataPDF, setDataPDF] = useState<any>(data?.pdf)
    const [messageRequest, setMessageRequest] = useState<any>(data?.detail || null)
    console.log(dataPDF)
    return (
        <>
        {loading && <Loading/>}
        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>
        
       {messageRequest &&  <Grid container p={2}>
            <Typography  variant='h6' component='h1' gutterBottom className="tittle">Mensaje de la solicitud: {messageRequest}</Typography>
        </Grid>}
        <Grid container style={{ overflow: 'hidden !important', height:'100%', alignItems: 'center', textAlign: 'center'}}>
             <iframe src={dataPDF} id="pdfVer" height="1000px" width="100%"></iframe>
            
        </Grid>
        </>
    )
}
import React, { useEffect, useState } from 'react';
import { TableBody, TableCell, TableContainer, Typography, TablePagination, TableRow, Grid, Table, Button, Container, Card, Checkbox, Avatar, IconButton, Popover, MenuItem, ButtonGroup, Tooltip } from '@mui/material';
import { useHistory } from 'react-router-dom'
import ListHeader from './ListHeader';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { Box } from "@mui/material";
import { removeLocalStorage, saveLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import FeedIcon from '@mui/icons-material/Feed';
import { ROUTE_ANNOUNCEMENT, ROUTE_LIQUIDATION_SALARY, ROUTE_EXTRA_HOURS, ROUTE_ASSISTANCES, ROUTE_ARREARS, ROUTE_ADVANCES, ROUTE_ACTIVE_LIQUIDATION_WORKERS, ROUTE_LIQUIDATION_MASIVE_PREVIEW, ROUTE_MOVEMENTS, ROUTE_REPORT_EXPENS, ROUTE_EXPENSE_REQUESTS, ROUTE_EMPLOYEE_EXPENSE, ROUTE_USER, ROUTE_PROJECT, ROUTE_DEPARMENT } from '@/toolbox/constants/route-map';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import WorkIcon from '@mui/icons-material/Work';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WifiProtectedSetupIcon from '@mui/icons-material/WifiProtectedSetup';
import MovingIcon from '@mui/icons-material/Moving';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
type TableProps = {
   header?: Array<any>,
   data?: Array<any>,
   action?: Array<any>,
   RecuperarData?: (data) => void,
   Recuperarid?: (data) => void,
   perPage?: (perPage) => void,
   page?: (page) => void,
   actionSelect?: any,
   total?: any,
   setAddOpen?: any,
   colorHeader?: any,
   status_action?: boolean,
   title?: any,
   text_button_add?:any,
   text_eliminar?:any,
   // checkbox?: boolean,
   setModalSave?:any,
   setModalDelete?:any,
   setModalHelper?:any,
   setShowSpecification?: any,
   setShowTeam?: any,
   setView?: any,
   currentView?: any,
   disabled_title?: any,
   btn_disabled?:any,
   btn_edit_disable?:any,
   btn_delete_disable?:any,
   btn_detail?:any,
   btn_helper?:any,
   btn_foolder?:any,
   btn_add_person?:any,
   btn_reprocess?:any,
   btn_redirect?:any,
   btn_contract?:any,
   btn_detail_view?:any,
   btn_view_document?:any
   // setConfirmDelete?:boolean
}

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
   if (b[orderBy] < a[orderBy]) {
      return -1;
   }
   if (b[orderBy] > a[orderBy]) {
      return 1;
   }
   return 0;
}

function getComparator(order, orderBy) {
   return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
   const stabilizedThis = array.map((el, index) => [el, index]);
   stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
   });
   if (query) {
      return array.filter((_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
   }
   return stabilizedThis.map((el) => el[0]);
}

export const TableDataV2: React.FC<TableProps> = (props: TableProps): JSX.Element => {

   const history = useHistory();
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(5);
   const [dataSelected, setDataSelected] = useState<any>({});

   const pathname = window.location.pathname;

   useEffect(() => {
      if(pathname != `${ROUTE_ANNOUNCEMENT}`) removeLocalStorage('newJobOffert');
   },[])

   const nextPage = (toroute, row) => {
      history.push(toroute, row);
   };

   const handleChangePage = (event, newPage) => {
      setPage(newPage);
      if (props.page) {
         props.page(newPage);
      }

   };

   const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      if (props.perPage) {
         props.perPage(+event.target.value)
      }

      setPage(0);
   };

   const getTitleTooltip = () => {
      const pathname = window.location.pathname;
      switch (pathname) {
         case ROUTE_EXTRA_HOURS:
            return 'ver horas extras';
         case ROUTE_LIQUIDATION_SALARY:
            return 'crear Liquidación';
         case ROUTE_ASSISTANCES:
            return 'ver inasistencias';
         case ROUTE_ARREARS:
            return 'ver tardanzas';
         case ROUTE_ADVANCES:
            return 'ver anticipos';
         case ROUTE_ACTIVE_LIQUIDATION_WORKERS:
               return 'ver estructuras';
         case ROUTE_LIQUIDATION_MASIVE_PREVIEW:
            return 'ver detalle';
         case ROUTE_MOVEMENTS:
            return 'ver movimientos';
         case ROUTE_EXPENSE_REQUESTS || ROUTE_EMPLOYEE_EXPENSE:
            return 'ver detalle del reporte';
         case ROUTE_EMPLOYEE_EXPENSE:
            return 'ver detalle del reporte';
         case ROUTE_USER:
            return 'ver empresas a la que pertenece';
         case ROUTE_PROJECT:
            return 'detalles';
         case ROUTE_DEPARMENT:
            return 'ver responsabilidades';
      }
   }

   //////////////////////////////////////////
   const [open, setOpen] = useState(null);
   const [order, setOrder] = useState('asc');
   const [selected, setSelected] = useState<any>([]);
   const [orderBy, setOrderBy] = useState('name');
   const [filterName, setFilterName] = useState('');

   const handleOpenMenu = (event, data) => {
      setOpen(event.currentTarget);
      setDataSelected(data);
   };

   const handleCloseMenu = () => {
      setOpen(null);
   };

   const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
   };
   const handleSelectAllClick = (event) => {
      if (event.target.checked) {
         const newSelecteds = props.data.map((n) => n.name);
         setSelected(newSelecteds);
         return;
      }
      setSelected([]);
   };

   const handleClick = (event, name) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];
      if (selectedIndex === -1) {
         newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
         newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
         newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
         newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }
      setSelected(newSelected);
   };

   const filteredUsers = applySortFilter(props.data, getComparator(order, orderBy), filterName);
   let recorrido = [];
   if (props.perPage || props.page) {
      recorrido = filteredUsers;
   } else {
      recorrido = filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
   }

   // const isNotFound = !filteredUsers.length && !!filterName;
   return (
      <>
         <Container>
            <Box
               sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  m: -1,
                  py: 3
               }}
            >
               {!props?.disabled_title && <Typography variant='h6' component='h1' gutterBottom className="tittle"
                  sx={{ m: 1 , fontWeight:600}}
               >
                  {props.title && (props.title).toUpperCase()}
               </Typography>}
               <Box sx={{ m: 1 }}>
                  {/* <Button
                     startIcon={(<UploadIcon fontSize="small" />)}
                     sx={{ mr: 1 }}
                  >
                     Import
                  </Button>
                  <Button
                     startIcon={(<DownloadIcon fontSize="small" />)}
                     sx={{ mr: 1 }}
                  >
                     Export
                  </Button> */}
                  {!props?.btn_disabled &&<Button
                     color="primary"
                     variant="contained"
                     onClick={()=>{
                           props.setModalSave && props.setModalSave(true); 
                           props.actionSelect && props.actionSelect('save'); 
                          // props.RecuperarData(null)
                     }}
                  >
                   Agregar
                  </Button>}
               </Box>
            </Box>

            <Card sx={{width:"100%"}}>
               <TableContainer>
                  <Table>
                     <ListHeader
                        order={order}
                        orderBy={orderBy}
                        headLabel={props.header}
                        rowCount={props.data.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                        status_action={props.status_action}
                        // checkbox={props.checkbox}
                     />
                     <TableBody>
                        {
                           recorrido.map((data, id_data) => {
                              const { id, name } = data;
                              const selectedUser = selected.indexOf(name) !== -1;
                              return (
                                 <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                                    {props.header.map((cabecera, id) => {
                                       const value = data[cabecera.name];
                                       return (
                                          <TableCell key={id} align="center">
                                             <Grid container direction="row"
                                                justifyContent="center"
                                                alignItems="center">
                                                <Grid sx={{ pr: 1 }}>
                                                   {cabecera.avatar &&
                                                      <Avatar
                                                         alt="Remy Sharp"
                                                         src="http://view.k-nela.cl/assets/images/avatars/avatar_8.jpg"
                                                         sx={{ width: 30, height: 30 }}
                                                      />
                                                   }
                                                </Grid>
                                                <Grid sx={{ pl: 1 }}>
                                                   <Typography sx={{ fontFamily: '"Public Sans", sans-serif !important' }} variant='inherit' fontWeight={500}>
                                                      {value || '---' }
                                                   </Typography>
                                                </Grid>
                                             </Grid>
                                          </TableCell>
                                       )
                                    })}

                                   { props.status_action && <TableCell align="center" >
                                       <ButtonGroup variant="contained" color="inherit" aria-label="button-group-custom-table" style={{padding: "0px 5px"}}>
                                          { 
                                             ! props?.btn_edit_disable && 
                                                <IconButton size="small" color="success" aria-label="view" 
                                                   onClick={() => {
                                                      props.setModalSave && props.setModalSave(true);
                                                      props.RecuperarData && props.RecuperarData({...data, action:'edit'}); 
                                                      props.actionSelect && props.actionSelect('edit');
                                                   }}
                                                >
                                                   <EditIcon fontSize='small' />
                                                </IconButton>
                                          }
                                          { 
                                             props?.btn_detail && 
                                                <IconButton size="small" color="primary" aria-label="view" 
                                                   onClick={() => {
                                                      props.setModalSave && props.setModalSave(true);
                                                      props.RecuperarData && props.RecuperarData({...data, action:'detail'}); 
                                                      props.actionSelect && props.actionSelect('detail');
                                                   }}
                                                >
                                                   <VisibilityIcon fontSize='small' />
                                                </IconButton>
                                          }

                                          {
                                             (pathname == `${ROUTE_ANNOUNCEMENT}` && props.currentView == 'jobOfert') && (
                                                <Tooltip title="ver especificaciones">
                                                   <IconButton size="small" color="primary" aria-label="view"
                                                      onClick={() => {
                                                         saveLocalStorage('newJobOffert', data);
                                                         props.actionSelect && props.actionSelect('especification')
                                                         props.setView && props.setView('specificOfert')
                                                         props.setShowSpecification && props.setShowSpecification(true);
                                                         props.setShowTeam && props.setShowTeam(true);
                                                      }}>
                                                         <FeedIcon fontSize="small" />
                                                      </IconButton>
                                                </Tooltip>
                                             )
                                          }

                                          {
                                             (props?.btn_helper) && (
                                                <Tooltip title={getTitleTooltip()}
                                                >
                                                   <IconButton size="small" color="primary" aria-label="view"
                                                      onClick={() => {
                                                         props.RecuperarData({...data, action:'helper'}); 
                                                         props.actionSelect && props.actionSelect('helper');
                                                         props.setModalHelper && props.setModalHelper(true); 
                                                      }}>
                                                         <FeedIcon fontSize="small" />
                                                      </IconButton>
                                                </Tooltip>
                                             )
                                          }

{
                                             (props?.btn_detail_view) && (
                                                <Tooltip title={getTitleTooltip()}
                                                >
                                                   <IconButton size="small" color="primary" aria-label="view"
                                                      onClick={() => {
                                                         props.RecuperarData({...data, action:'view-detail'}); 
                                                      }}>
                                                         <VisibilityIcon fontSize="small" />
                                                      </IconButton>
                                                </Tooltip>
                                             )
                                          }

                                         {
                                          !props?.btn_delete_disable &&  
                                             <IconButton size="small" color="error" aria-label="view" 
                                                onClick={() => { 
                                                   props.RecuperarData && props.RecuperarData({...data,action:'delete'});
                                                   props.actionSelect && props.actionSelect('delete');
                                                   props.setModalDelete && props.setModalDelete(true); 
                                                }}
                                             >
                                                <DeleteOutlineIcon fontSize='small' />
                                             </IconButton>
                                          }

                                          {
                                          props?.btn_add_person &&  (
                                             <Tooltip title={`cargas familiares`}>
                                                <IconButton size="small" color="secondary" aria-label="view" 
                                                   onClick={() => { 
                                                      props.RecuperarData && props.RecuperarData({...data,action:'add_person'});
                                                      props.actionSelect && props.actionSelect('add_person');
                                                      props.setModalDelete && props.setModalDelete(true); 
                                                   }}
                                                >
                                                   <GroupAddIcon fontSize='small'/>
                                                </IconButton>
                                             </Tooltip>
                                             )
                                          }

                                          {
                                          props?.btn_foolder &&  (
                                             <Tooltip title={`historial laboral`}>
                                                <IconButton size="small" color="primary" aria-label="view" 
                                                   onClick={() => { 
                                                      props.RecuperarData && props.RecuperarData({...data,action:'folder'});
                                                      props.actionSelect && props.actionSelect('folder');
                                                      props.setModalDelete && props.setModalDelete(true); 
                                                   }}
                                                >
                                                   <WorkIcon fontSize='small' />
                                                </IconButton>
                                             </Tooltip>
                                             )
                                          }

{
                                          props?.btn_contract &&  (
                                             <Tooltip title={`Ver Contrato`}>
                                                <IconButton size="small" color="primary" aria-label="view" 
                                                   onClick={() => { 
                                                      props.RecuperarData && props.RecuperarData({...data,action:'contract'});
                                                      props.actionSelect && props.actionSelect('contract');
                                                   }}
                                                >
                                                   <FilePresentIcon fontSize='small' />
                                                </IconButton>
                                             </Tooltip>
                                             )
                                          }


{
                                          props?.btn_reprocess &&  (
                                             <Tooltip title={`reprocesar Liquidación`}>
                                                <IconButton size="small" color="success" aria-label="view" 
                                                   onClick={() => { 
                                                      props.RecuperarData && props.RecuperarData({...data,action:'reprocess'});
                                                      props.actionSelect && props.actionSelect('reprocess');
                                                      props.setModalDelete && props.setModalDelete(true); 
                                                   }}
                                                >
                                                   <WifiProtectedSetupIcon fontSize='small' />
                                                </IconButton>
                                             </Tooltip>
                                             )
                                          }

{
                                          props?.btn_redirect &&  (
                                             <Tooltip title={`Redirigir`}>
                                                <IconButton size="small" color="success" aria-label="view" 
                                                   onClick={() => { 
                                                      props.RecuperarData && props.RecuperarData({...data,action:'redirect'});
                                                      props.actionSelect && props.actionSelect('redirect');
                                                      props.setModalDelete && props.setModalDelete(true); 
                                                   }}
                                                >
                                                   <MovingIcon fontSize='small' />
                                                </IconButton>
                                             </Tooltip>
                                             )
                                          }
{
                                          props?.btn_view_document &&  (
                                             <Tooltip title={`Ver documento`}>
                                                <IconButton size="small" color="success" aria-label="view" 
                                                   onClick={() => { 
                                                      props.RecuperarData && props.RecuperarData({...data,action:'view_document'});
                                                   }}
                                                >
                                                   <PictureAsPdfIcon fontSize='small' />
                                                </IconButton>
                                             </Tooltip>
                                             )
                                          }
                                       </ButtonGroup>
                                    </TableCell>}
                                 </TableRow>
                              );
                           })}
                     </TableBody>
                  </Table>
               </TableContainer>
               {/* </Scrollbar> */}

               <TablePagination
                  component="div"
                  count={props.total ? props.total : props.data.length}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5, 10, 15]}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Card>
         </Container>
      </>
   );
}

import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);
const rut_empresa = dataUser?.user?.rut_empresa;

export const TurnRepository = {

    getTurns: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/turn`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createTurn: async (dataTurn) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/turn`, {
            turn: dataTurn.turn
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    editTurn: async (id, dataTurn) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/turn/${id}`, {
            turn: dataTurn.turn,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    deleteTurn: async(id): Promise<any> => {
        const res = await http.delete<any>(`${API_URL_BASE}/v1/turn/${id}`)
        const {data, error, message} = res;
        return { data, error, message };
    }
}   
import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from '@/components/common/Loading';
import { Alert, Box, Tab, Button, Select, Card, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { ReportService } from '@/service/services/Report.service';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
// import { WorkerService } from '@/service/services/Worker.service';
import { readLocalStorage, saveLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_RUTA, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { PeriodService } from "@/service/services/Period.service";

const labels = [
    { label: 'Pendiente', id: '1' },
    { label: 'Aprobados', id: '2' },
    { label: 'Rechazados', id: '3' }
]

export const ReportExpenses: React.FC<any> = (props: any): JSX.Element => {

    const [data, setData] = useState<any>('')
    const [value, setValue] = useState('1');

    // data localstorage
    const data_user = readLocalStorage(KEY_USER_DATA);
    const id_company = data_user?.user?.id_company;

    const [periods, setPeriods] = useState<any>([]);
    const [years, setYears] = useState<any>([]);
    const [yearSelected, setYearSelected] = useState<any>('');
    const [monthSelected, setMonthSelected] = useState<any>('');

    const [rutasHeader, setRutasHeader] = React.useState([]);

    //data api solicitudes de gastos
    const [approvedRequests, setApprovedRequests] = React.useState([]);
    const [pendingRequests, setPendingRequests] = React.useState([]);
    const [rejectedRequests, setRejectedRequests] = React.useState([]);
    const [countEmployees_total, setCountEmployees_total] = React.useState(0);
    const [keysData, setKeysData] = React.useState([]);
    const [valuesData, setValuesData] = React.useState<any>([]);


    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });
    //ONCHANGE TABS
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    useEffect(() => {
        getPeriods();
    }, []);



    const getPeriods = async () => {
        setLoading(true);
        const resp: any = await PeriodService.getPeriods();
        const newData = [];
        const arrayYears = [];
        resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
            newData.push({
                id: item.id,
                month: item.month,
                year: item.year,
                alias_name: item.month + '-' + item.year,
            })
            const found = arrayYears.find(element => element == item.year);
            if (!found) { arrayYears.push(item.year); }

        });
        setYears(arrayYears);
        setPeriods(newData || []);
        setLoading(false);
    }
    const limpiarData = () => {
        setPendingRequests([]);
        setApprovedRequests([]);
        setRejectedRequests([]);
    }

   
    const showExpensReport = async () => {
        limpiarData();
        setLoading(true);
        if (!monthSelected || !yearSelected) {
            setLoading(false);
            return (
                setSnackBarConfig(prev => ({
                    ...prev, open: true,
                    severity: 'warning',
                    message: `Debe seleccionar Mes y Año.`
                }
                ))
            );
        }

        const periodSelected = monthSelected + '-' + yearSelected;
        const period = periods.find(element => element.alias_name == periodSelected);
        const dataPre = {
            idperiod: period.id,
            month: period.month,
            year: period.year
        }

        await ReportService.getReportExpens(id_company, dataPre.idperiod).then((data) => {


            const countEmployees = data?.data?.employee?.countEmployees || 0;
            const review_status = data?.data?.employee?.review_status || [];

            setCountEmployees_total(countEmployees);

            if ((review_status?.Pendiente).length > 0) {
                let newDataFilter = [];
                console.log('rewire: ', review_status?.Pendiente);
                for (let index = 0; index < (review_status?.Pendiente).length; index++) {
                    const key: any = Object.keys(review_status?.Pendiente[index]);
                    const values: any = Object.values(review_status?.Pendiente[index]);



                    values.length > 0 && values.map((arrayItem, i) => {
                        if (arrayItem.length > 0) {
                            const movilidad = Object.values(arrayItem[0]);
                            const alquiler = Object.values(arrayItem[1]);
                            const colicion = Object.values(arrayItem[2]);

                            let newData = {
                                employee: key[i],
                                movilidad: movilidad,
                                alquiler: alquiler,
                                colacion: colicion,
                            }
                            newDataFilter.push(newData)
                        }
                    });
                }

                console.log(newDataFilter);

                setPendingRequests(newDataFilter || []);
            }
            if ((review_status?.Aprobado).length > 0) {
                let newDataFilter = [];

                for (let index = 0; index < (review_status?.Aprobado).length; index++) {
                    const key: any = Object.keys(review_status?.Aprobado[index]);
                    const values: any = Object.values(review_status?.Aprobado[index]);

                    values.length > 0 && values.map((arrayItem, i) => {
                        if (arrayItem.length > 0) {
                            const movilidad = Object.values(arrayItem[0]);
                            const alquiler = Object.values(arrayItem[1]);
                            const colicion = Object.values(arrayItem[2]);

                            let newData = {
                                employee: key[i],
                                movilidad: movilidad,
                                alquiler: alquiler,
                                colacion: colicion,
                            }
                            newDataFilter.push(newData)
                        }
                    });
                }

                console.log(newDataFilter);

                setApprovedRequests(newDataFilter || []);
            }
            if ((review_status?.Rechazado).length > 0) {
                let newDataFilter = [];

                for (let index = 0; index < (review_status?.Rechazado).length; index++) {
                    const key: any = Object.keys(review_status?.Rechazado[index]);
                    const values: any = Object.values(review_status?.Rechazado[index]);

                    values.length > 0 && values.map((arrayItem, i) => {
                        if (arrayItem.length > 0) {
                            const movilidad = Object.values(arrayItem[0]);
                            const alquiler = Object.values(arrayItem[1]);
                            const colicion = Object.values(arrayItem[2]);

                            let newData = {
                                employee: key[i],
                                movilidad: movilidad,
                                alquiler: alquiler,
                                colacion: colicion,
                            }
                            newDataFilter.push(newData)
                        }
                    });
                }
                setRejectedRequests(newDataFilter || []);
            }

            setLoading(false);
        })
    }
    //     const review_status = data?.data?.employee?.review_status || [];

    //     // const approvedRequests = review_status?.Aprobado || [];
    //     setPendingRequests(review_status?.Pendiente || []);
    //     // const rejectedRequests = review_status?.Rechazado || [];

    //     const countEmployees = data?.data?.employee?.countEmployees || 0;

    //     // console.log('soy review_status: ', review_status);

    //     if (pendingRequests.length > 0) {
    //         let obj;

    //         const keys = Object.keys(pendingRequests[0]);
    //         const values = Object.values(pendingRequests[0]);

    //         setKeysData(keys);
    //         setValuesData(values)
    //     }
    //     // (pendingRequests[0]).map((array)=>{
    //     //     return console.log('array soy: ',array);
    //     // })
    //     // obj = {
    //     //     ...obj,
    //     //     name: keys[0],
    //     //     name2: keys[1],
    //     //     chargue_type: values[0],
    //     //     full_pay: values[1]
    //     // }
    //     // branch_office_obj.push(obj || {});
    //     // console.log('soy values: ', values)
    //     // console.log('soy keys: ', keys)


    //     //     values.map((name) => {
    //     //         return console.log('valores ', name);
    //     //     })

    //     // }


    //     // if (branch_office.length > 0) {
    //     //     let obj;
    //     //     for (let index = 0; index < branch_office.length; index++) {
    //     //         const keys = Object.keys(branch_office[index]);
    //     //         const values = Object.values(branch_office[index]);
    //     //         obj = {
    //     //             ...obj,
    //     //             name: keys[0],
    //     //             name2: keys[1],
    //     //             chargue_type: values[0],
    //     //             full_pay: values[1]
    //     //         }
    //     //         branch_office_obj.push(obj || {});
    //     //     }
    //     // }

    // }

    // console.log('soy keysData: ', keysData);
   const  getExcelReport = async() =>{
        limpiarData();
        setLoading(true);
        if (!monthSelected || !yearSelected) {
            setLoading(false);
            return (
                setSnackBarConfig(prev => ({
                    ...prev, open: true,
                    severity: 'warning',
                    message: `Debe seleccionar Mes y Año.`
                }
                ))
            );
        }

        const periodSelected = monthSelected + '-' + yearSelected;
        const period = periods.find(element => element.alias_name == periodSelected);
        const dataPre = {
            idperiod: period.id,
            month: period.month,
            year: period.year}
        
        const respExcel = await ReportService.getReportExpensExcel(id_company,dataPre?.idperiod);
       
        const btn_excel = document.createElement("a")
        btn_excel.href = `${respExcel.data.link}`
        btn_excel.download = 'Reporte.xlsx'

        document.body.appendChild(btn_excel);
        btn_excel.click();
        document.body.removeChild(btn_excel)
        setLoading(false);
   }

    return (
        <Protected>
            {loading && <Loading />}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>
            <Container>
                <Typography variant='h6' component='h1' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                    RENDICIÓN DE GASTOS
                </Typography>
                <Grid item xs={12} spacing={2} container direction={'row'} justifyContent='center' alignItems={'center'} marginTop='15px'>
                    <Grid item xs={12} md={4} display='flex' alignItems="center" spacing={2}>
                        <Typography variant="body1" style={{ marginRight: '10px' }}><b>Período:</b> </Typography>
                        <Select
                            id="id_education_level"
                            variant="standard"
                            value={yearSelected}
                            onChange={(event) => {
                                setYearSelected(event.target.value);
                            }}
                            fullWidth
                        >
                            {
                                years.map((year, i) => {
                                    return <MenuItem key={i} value={year}>{year}</MenuItem>;
                                })
                            }
                        </Select>
                    </Grid>
                    <Grid item xs={12} md={4} display='flex' alignItems="center">
                        <Typography style={{ marginRight: '10px' }} variant="body1"><b>Mes:</b> </Typography>
                        <Select
                            id="id_education_level"
                            variant="standard"
                            value={monthSelected}
                            onChange={(event) => { setMonthSelected(event.target.value) }}
                            fullWidth
                        >
                            <MenuItem value={'01'}>Enero</MenuItem>
                            <MenuItem value={'02'}>Febrero</MenuItem>
                            <MenuItem value={'03'}>Marzo</MenuItem>
                            <MenuItem value={'04'}>Abril</MenuItem>
                            <MenuItem value={'05'}>Mayo</MenuItem>
                            <MenuItem value={'06'}>Junio</MenuItem>
                            <MenuItem value={'07'}>Julio</MenuItem>
                            <MenuItem value={'08'}>Agosto</MenuItem>
                            <MenuItem value={'09'}>Septiembre</MenuItem>
                            <MenuItem value={'10'}>Octubre</MenuItem>
                            <MenuItem value={'11'}>Noviembre</MenuItem>
                            <MenuItem value={'12'}>Diciembre</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} md={4} >
                        <Button variant="contained" onClick={showExpensReport}>
                            Filtrar
                        </Button>
                    </Grid>
                </Grid>


                    <Typography variant='subtitle1' component='h4' gutterBottom sx={{ m: 1, fontWeight: 600, width: "100%", marginTop: '20px' }}>
                        Se encontraron {(countEmployees_total && countEmployees_total != 0) ? countEmployees_total : 0} empleados registrados
                    </Typography>

                    <Button variant="contained" onClick={()=>{getExcelReport()}}>
                        Descargar Excel
                    </Button>


                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>

                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList
                                onChange={handleChange}
                                aria-label="lab API tabs example">
                                {labels.map((value, i) => {
                                    return (
                                        <Tab key={i} label={value.label} value={value.id} />
                                    )
                                })}
                            </TabList>
                        </Box>

                        <TabPanel value="1">
                            <TableContainer sx={{ maxHeight: 440 }}>
                                <Typography variant="h5" sx={{ color: "#16556b" }}> Solicitudes Gastos Pendientes</Typography>
                                {
                                    pendingRequests.length > 0 && pendingRequests.map((valueData) => (
                                        <Card sx={{ width: "100%", marginTop: '20px' }}>

                                            <Typography variant='h6' component='h2' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                                                {valueData.employee}
                                            </Typography>
                                            <TableContainer>
                                                <Table>
                                                    <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                                        <TableRow >
                                                            <TableCell >
                                                                <Typography>Gastos</Typography>
                                                            </TableCell>
                                                            <TableCell >
                                                                <Typography>Gasto Empresa</Typography>
                                                            </TableCell>
                                                            <TableCell >
                                                                <Typography>Gasto Empleado</Typography>
                                                            </TableCell>
                                                            <TableCell >
                                                                <Typography>Costo Total</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow >
                                                            <TableCell size={'small'}>
                                                                Alquiler
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {valueData.alquiler[0].employee_expense}
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {valueData.alquiler[0].company_expense}
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {valueData.alquiler[0].total_cost}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow >
                                                            <TableCell size={'small'}>
                                                                Colación
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {valueData.colacion[0].employee_expense}
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {valueData.colacion[0].company_expense}
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {valueData.colacion[0].total_cost}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow >
                                                            <TableCell size={'small'}>
                                                                Mobilidad
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {valueData.movilidad[0].employee_expense}
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {valueData.movilidad[0].company_expense}
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {valueData.movilidad[0].total_cost}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Card>
                                    ))
                                }
                            </TableContainer>
                        </TabPanel>
                        <TabPanel value="2">
                            <TableContainer sx={{ maxHeight: 440 }}>
                                <Typography variant="h5" sx={{ color: "#16556b" }}> Solicitudes Gastos Aprobados </Typography>
                                {
                                    approvedRequests.length > 0 && approvedRequests.map((valueData) => (
                                        <Card sx={{ width: "100%", marginTop: '20px' }}>

                                            <Typography variant='h6' component='h2' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                                                {valueData.employee}
                                            </Typography>
                                            <TableContainer>
                                                <Table>
                                                    <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                                        <TableRow >
                                                            <TableCell >
                                                                <Typography>Gastos</Typography>
                                                            </TableCell>
                                                            <TableCell >
                                                                <Typography>Gasto Empresa</Typography>
                                                            </TableCell>
                                                            <TableCell >
                                                                <Typography>Gasto Empleado</Typography>
                                                            </TableCell>
                                                            <TableCell >
                                                                <Typography>Costo Total</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow >
                                                            <TableCell size={'small'}>
                                                                Alquiler
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {valueData.alquiler[0].employee_expense}
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {valueData.alquiler[0].company_expense}
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {valueData.alquiler[0].total_cost}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow >
                                                            <TableCell size={'small'}>
                                                                Colación
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {valueData.colacion[0].employee_expense}
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {valueData.colacion[0].company_expense}
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {valueData.colacion[0].total_cost}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow >
                                                            <TableCell size={'small'}>
                                                                Mobilidad
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {valueData.movilidad[0].employee_expense}
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {valueData.movilidad[0].company_expense}
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {valueData.movilidad[0].total_cost}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Card>
                                    ))
                                }
                            </TableContainer>
                        </TabPanel>
                        <TabPanel value="3">
                            <TableContainer sx={{ maxHeight: 440 }}>
                                <Typography variant="h5" sx={{ color: "#16556b" }}> Solicitudes Gastos Rechazados </Typography>
                                {
                                    rejectedRequests.length > 0 && rejectedRequests.map((valueData) => (
                                        <Card sx={{ width: "100%", marginTop: '20px' }}>

                                            <Typography variant='h6' component='h2' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                                                {valueData.employee}
                                            </Typography>
                                            <TableContainer>
                                                <Table>
                                                    <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                                        <TableRow >
                                                            <TableCell >
                                                                <Typography>Gastos</Typography>
                                                            </TableCell>
                                                            <TableCell >
                                                                <Typography>Gasto Empresa</Typography>
                                                            </TableCell>
                                                            <TableCell >
                                                                <Typography>Gasto Empleado</Typography>
                                                            </TableCell>
                                                            <TableCell >
                                                                <Typography>Costo Total</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow >
                                                            <TableCell size={'small'}>
                                                                Alquiler
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {valueData.alquiler[0].employee_expense}
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {valueData.alquiler[0].company_expense}
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {valueData.alquiler[0].total_cost}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow >
                                                            <TableCell size={'small'}>
                                                                Colación
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {valueData.colacion[0].employee_expense}
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {valueData.colacion[0].company_expense}
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {valueData.colacion[0].total_cost}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow >
                                                            <TableCell size={'small'}>
                                                                Mobilidad
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {valueData.movilidad[0].employee_expense}
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {valueData.movilidad[0].company_expense}
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {valueData.movilidad[0].total_cost}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Card>
                                    ))
                                }
                            </TableContainer>
                        </TabPanel>
                    </TabContext>
                </Box>
            </Container>
        </Protected >
    )
};
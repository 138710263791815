import { useEffect, useState } from "react"
import { TableDataV2 } from "@/components/common/Tablev2"
import { Loading } from "@/components/common/Loading"
import { Props } from "./JobOffert.type"
import { JobOffertService } from "@/service/services/JobOffert.service"
import { ModalJobOffert } from "@/components/Modal/ModalJobOffert"

export const JobOffert: React.FC<Props> = (props:any): JSX.Element => {

    const { setShowSpecification, setShowTeam, setView, currentView } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [jobOffert, setJobOffert] = useState([]);
    const [showJobOffertModal, setShowJobOffertModal] = useState<boolean>(false);
    const [actionSelected, setActionSelected] = useState<String>('');
    const [jobOffertSelected, setJobOffertSelected] = useState<String>('');

    useEffect(() => {
        getJobOffert();
    }, []);

    const getJobOffert = async() => {
        setLoading(true);
        await JobOffertService.getJobOffert().then((data) => {
            const oldData = data.data || [];
            let newData = [];
            oldData.length > 0 && oldData.map((item) => {
                data = {
                    id: item.id,
                    id_company: item.id_company,
                    start_postulation: item.start_postulation,
                    end_postulation: item.end_postulation,
                    n_available_positions: item.n_available_positions,
                    type_of_day: item.type_of_day,
                    minimum_salary: item.profile.minimum_salary,
                    maximum_salary: item.profile.maximum_salary,
                    mode: item.mode,
                    id_profile: item.profile.id,
                    profile: item.profile.name,
                    charge: item.charge.name,
                    id_charge: item.charge.id,
                    specification: item.specification
                }
                newData.push(data);
            });
            setJobOffert(newData || []);
            setLoading(false);
        })
    }

    function RecuperarData(value){
        setJobOffertSelected(value);
    }

    return (
        <>
            {loading && <Loading/>}

            <TableDataV2
                title={'Ofertas laborales'}
                data={jobOffert || []}
                header={[
                    { name: 'profile', label: 'Perfil', filter: false, Chip: false },
                    { name: 'charge', label: 'Cargo', filter: false, Chip: false }, 
                    { name: 'start_postulation', label: 'Fecha inicio', filter: false, Chip: false },
                    { name: 'end_postulation', label: 'Fecha fin', filter: false, Chip: true },
                    { name: 'mode', label: 'Modo', filter: false, Chip: false },
                    { name: 'type_of_day', label: 'Tipo de día', filter: false, Chip: false },
                ]}
                actionSelect={setActionSelected}
                setModalSave={setShowJobOffertModal}
                status_action
                // checkbox
                RecuperarData={RecuperarData}

                setShowSpecification={setShowSpecification}
                setShowTeam={setShowTeam}
                setView={setView}
                currentView={currentView}
            />

            { 
                showJobOffertModal && (
                    <ModalJobOffert
                        open={showJobOffertModal}
                        closeModal={()=>{setShowJobOffertModal(false)}}
                        actionSelected={actionSelected}
                        onListJobOfferts={getJobOffert}
                        jobOffert={jobOffertSelected}
                        setShowSpecification={setShowSpecification}
                        setView={setView}
                        clearState={()=>{setJobOffertSelected('')}}
                    />
                )
            }
            {
                // actionSelected === 'delete' && (
                //     <ModalPhaseDelete
                //         open={true}
                //         closeModal={()=>{setShowJobOffertModal(false)}}
                //         actionSelected={actionSelected}
                //         onListJobOfferts={getJobOffert}
                //         jobOffert={jobOffertSelected}
                //     />
                // )
            }
        </>
    )
}
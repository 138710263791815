import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from '@/components/common/Loading';
import { Alert, Autocomplete, Backdrop, Button, Card, CircularProgress, Container, Divider, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { ReportService } from '@/service/services/Report.service';
import { WorkerService } from '@/service/services/Worker.service';
import { PeriodService } from '@/service/services/Period.service';
import { API_URL_BASE } from '@/toolbox/defaults/app';


export const ReportEmployeesView: React.FC<any> = (props: any): JSX.Element => {
   
    const [data, setData] = useState<any>('')
    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });
    const [excelDissable, setExcelDissable]= useState<any>(false);
    
    const [periods, setPeriods] = useState<any>([]);
    const [years, setYears] = useState<any>([]);
    const [yearSelected, setYearSelected] = useState<any>('');
    const [monthSelected, setMonthSelected] = useState<any>('');

    const [contractType, setContractType] = useState<any>([]);
    const [gratificationType, setGratificationType] = useState<any>([]);
    const [familyStech, setFamilyStech] = useState<any>([]);
    const [countEmployees, setCountEmployees] = useState<any>('');

    useEffect(()=> {
        getPeriods();
     },[]);
  
     const getPeriods = async() => {
        setLoading(true);
        const resp:any = await PeriodService.getPeriods();
        const newData = [];
        const arrayYears = [];
        resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
           newData.push({
              id: item.id,
              month: item.month,
              year: item.year,
              alias_name: item.month + '-' +  item.year,
           })
           const found = arrayYears.find(element => element == item.year);
           if(!found) { arrayYears.push(item.year); }
           
        });
        setYears(arrayYears);
        setPeriods(newData || []);
        setLoading(false);
    }

    const getReportEmployee = async () => {
        const periodSelected = monthSelected + '-' + yearSelected;
        const period = periods.find(element => element.alias_name == periodSelected);

        setLoading(true);
        if(!monthSelected || !yearSelected){
            setLoading(false);
            return (
               setSnackBarConfig(prev => ({ ...prev, open: true, 
                  severity:'warning', 
                  message: `Debe seleccionar Mes y Año.` }
               ))
            );
        }

        await ReportService.getReportEmployee(period.id).then((data) => {

            const countEmployees = data?.data?.employee?.countEmployees || 0;
            const contract_type = data?.data?.employee?.contract_type || [] ;
            const family_strech = data?.data?.employee?.family_strech || [] ;
            const gratification_type = data?.data?.employee?.gratification_type || [] ;

            let contractTypeFilter = [];
            let familyStrechFilter = [];
            let gratificationTypeFilter = [];

            if(contract_type.length > 0){
                let obj;
                for (let index = 0; index < contract_type.length; index++) {
                    const keys =  Object.keys(contract_type[index]);
                    const values = Object.values(contract_type[index]);
                    obj = {
                        ...obj,
                        name: keys[0],
                        countEmployees: values[0],
                        FullPay: values[1]
                    }
                    contractTypeFilter.push(obj);
                }
            }

            if(family_strech.length > 0){
                let obj;
                for (let index = 0; index < family_strech.length; index++) {
                    const keys =  Object.keys(family_strech[index]);
                    const values = Object.values(family_strech[index]);
                    obj = {
                        ...obj,
                        name: keys[0],
                        countEmployees: values[0],
                        FullPay: values[1]
                    }
                    familyStrechFilter.push(obj);
                }
            }

            if(gratification_type.length > 0){
                let obj;
                for (let index = 0; index < gratification_type.length; index++) {
                    const keys =  Object.keys(gratification_type[index]);
                    const values = Object.values(gratification_type[index]);
                    obj = {
                        ...obj,
                        name: keys[0],
                        countEmployees: values[0],
                        FullPay: values[1]
                    }
                    gratificationTypeFilter.push(obj);
                }
            }
            setCountEmployees(countEmployees);
            setContractType(contractTypeFilter || []);
            setFamilyStech(familyStrechFilter || []);
            setGratificationType(gratificationTypeFilter || []);

            setLoading(false);
            setExcelDissable(true);
        })
    }
    const getReportExcelAbsencePDF = async (typeDownload) => {
        const periodSelected = monthSelected + '-' + yearSelected;
        const period = periods.find(element => element.alias_name == periodSelected);

        if (typeDownload == 'excel') {

            const respExcel = await ReportService.getReportExcelEmployees(period.id);
            const btn_excel = document.createElement("a")
            btn_excel.href = `${API_URL_BASE}${respExcel.data.link}`
            console.log('RUTAA: ', respExcel.data.link)
            btn_excel.download = 'EmployeeReport.xlsx'

            document.body.appendChild(btn_excel);
            btn_excel.click();
            document.body.removeChild(btn_excel)
        } else {
            const respCSV = await ReportService.getReportExcelEmployees(period.id);
            const btn_csv = document.createElement("a")
            btn_csv.href = `${API_URL_BASE}${respCSV.data.link}`

            btn_csv.download = 'EmployeeReport.csv'

            document.body.appendChild(btn_csv);
            btn_csv.click();
            document.body.removeChild(btn_csv)

        }
    }
    return (
        <Protected>
            {loading && <Loading/>}
            <Snackbar
                open={snackBarConfig.open} 
                autoHideDuration={snackBarConfig.autoHideDuration} 
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>
            <Container>
                <Typography variant='h6' component='h1' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                    REPORTE DE EMPLEADOS
                </Typography>

                <Grid container spacing={3} sx={{marginTop: '10px', marginBottom: '20px'}}>
                    <Grid item xs={12} md={12} display='flex'>
                        <FormControl variant="standard" sx={{width:'30%'}}>
                            <InputLabel id="demo-simple-select-standard-label">Año</InputLabel>
                            <Select
                                id="id_education_level"
                                variant="standard"
                                value={yearSelected}
                                onChange={(event) => {
                                setYearSelected(event.target.value);
                                }}
                                sx={{ width: '200px' }}
                            >
                                {
                                years.map((year, i) => {
                                    return <MenuItem key={i} value={year}>{year}</MenuItem>;
                                })
                                }
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" sx={{width:'30%'}}>
                            <InputLabel id="demo-simple-select-standard-label">Mes</InputLabel>
                            <Select
                                id="id_education_level"
                                variant="standard"
                                value={monthSelected}
                                onChange={(event) => {setMonthSelected(event.target.value)}}
                                sx={{ width: '200px' }}
                            >
                                <MenuItem value={'01'}>Enero</MenuItem>
                                <MenuItem value={'02'}>Febrero</MenuItem>
                                <MenuItem value={'03'}>Marzo</MenuItem>
                                <MenuItem value={'04'}>Abril</MenuItem>
                                <MenuItem value={'05'}>Mayo</MenuItem>
                                <MenuItem value={'06'}>Junio</MenuItem>
                                <MenuItem value={'07'}>Julio</MenuItem>
                                <MenuItem value={'08'}>Agosto</MenuItem>
                                <MenuItem value={'09'}>Septiembre</MenuItem>
                                <MenuItem value={'10'}>Octubre</MenuItem>
                                <MenuItem value={'11'}>Noviembre</MenuItem>
                                <MenuItem value={'12'}>Diciembre</MenuItem>
                            </Select>
                        </FormControl>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={getReportEmployee}
                        >
                        Filtrar
                        </Button>
                    </Grid>
                </Grid>
                {excelDissable &&
                    <Grid container spacing={3} sx={{ marginTop: '10px', marginBottom: '20px' }}>
                        <Grid item xs={12} md={12} display='flex' justifyContent='flex-end'>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => getReportExcelAbsencePDF('excel')}
                            >
                                Descargar excel
                            </Button>
                        </Grid>
                    </Grid>
                }
                <Typography variant='subtitle1' component='h4' gutterBottom  sx={{ m: 1, fontWeight: 600, width: "100%", marginTop: '20px' }}>
                    Se encontraron {countEmployees} empleados registrados
                </Typography>

                <Card sx={{ width: "100%", marginTop: '20px' }}>
                    <Typography variant='h6' component='h2' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                        Tipos de contrato
                    </Typography>
                    <TableContainer>
                        <Table>
                            <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                <TableRow >
                                    <TableCell >
                                        <Typography>Nombre</Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography>Cantidad Empleados</Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography>Total pago</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    contractType.length > 0 && contractType.map((item) => (
                                        <TableRow >
                                            <TableCell size={'small'}>
                                                {item.name}
                                            </TableCell>
                                            <TableCell size={'small'}>
                                                {item.countEmployees}
                                            </TableCell>
                                            <TableCell size={'small'}>
                                                {item.FullPay}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>

                <Card sx={{ width: "100%", marginTop: '20px' }}>
                    <Typography variant='h6' component='h2' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                        Tipos de gratificaciones
                    </Typography>
                    <TableContainer>
                        <Table>
                            <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                <TableRow >
                                    <TableCell >
                                        <Typography>Nombre</Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography>Cantidad Empleados</Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography>Total pago</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    gratificationType.length > 0 && gratificationType.map((item) => (
                                        <TableRow >
                                            <TableCell size={'small'}>
                                                {item.name}
                                            </TableCell>
                                            <TableCell size={'small'}>
                                                {item.countEmployees}
                                            </TableCell>
                                            <TableCell size={'small'}>
                                                {item.FullPay}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>

                <Card sx={{ width: "100%", marginTop: '20px' }}>
                    <Typography variant='h6' component='h2' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                        Tramos familiares
                    </Typography>
                    <TableContainer>
                        <Table>
                            <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                <TableRow >
                                    <TableCell >
                                        <Typography>Nombre</Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography>Cantidad Empleados</Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography>Total pago</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    familyStech.length > 0 && familyStech.map((item) => (
                                        <TableRow >
                                            <TableCell size={'small'}>
                                                {item.name}
                                            </TableCell>
                                            <TableCell size={'small'}>
                                                {item.countEmployees}
                                            </TableCell>
                                            <TableCell size={'small'}>
                                                {item.FullPay}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>

            </Container>
        </Protected>
    )
};
import { http } from '../http/http';
import { UserDetailsDTO,UserDTO,UserPost,UserRequestPost, UsersDTO} from '../http/dto/UserDTO';
import { UserDetails, Users } from '../models/User';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { User } from '../models/User';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { WorkersDTO } from '../http/dto/WorkerDTO';
import { Worker } from '../models/Worker';
import { BranchOffice } from '../models/BranchOffice';
// import { CostCenter, CostCenters } from '../models/CostCenter';

const valiteStatusRev = (review) => {
   switch (review) {
      case '0':
         return 'Pendiente'
         break;
      case '1':
         return 'Aceptado'
         break;
      default:
         return 'Rechazado'
         break;
   }
}

export const expenseAdminRepository = {

   getExpenseAdmin: async (review_status, status) : Promise<any>=> {
      const users = await http.get<any>(`${API_URL_BASE}/v1/expenseReports/adminList?review_status=${review_status}&status=${status}`);
      const { data, error, message } = users;
      // return {
      //    data,
      //    error,
      //    message
      // };
      return {
         data: (data||[]).map((raw) => ({
            id: raw?.id,
            idemployee: raw?.idemployee,
            name: raw?.name,
            surname: raw?.name,
            second_surname:raw?.second_surname,
            name_all:raw?.name +', '+ raw?.surname +' '+raw?.second_surname,
            idcompany: raw?.idcompany,
            n_document:raw?.n_document,
            detail: raw?.detail,
            idperiod: raw?.idperiod,
            period: raw?.month+'-'+raw?.year,
            // review_status: raw?.review_status,
            // review_status_name: valiteStatusRev( raw?.review_status),
            iduserreviewed: raw?.iduserreviewed,
            status: raw?.status,
         })),
         error,
         message
      }
   },
   editExpenseAdmin: async (dataExpense, idReport: number) : Promise<any> => {
      const res = await http.patch<any>(`${API_URL_BASE}/v1/expenseReports/editReport/${idReport}`, {      
         review_status: dataExpense.review_status,       
      });
      const {data, error, message} = res;
      return { data, error, message };
   },

   
}



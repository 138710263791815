import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Alert, Backdrop, Box, CircularProgress, Tab, Container, Card, Divider, Grid, Table, TableRow, IconButton, TableHead, TableCell, Typography, TableBody, Menu, MenuItem, TableContainer, Snackbar, Breadcrumbs } from "@mui/material";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { TableDataV2 } from '@/components/common/Tablev2';
import { Loading } from '@/components/common/Loading';
import { Props } from './AdminExpense.type';
import { ExpenseAdminService } from '@/service/services/ExpenseAdmin.service';
import { ModalAdminExpense } from '@/components/Modal/ModalAdminExpense';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import GroupsIcon from '@mui/icons-material/Groups';
import { EmployeeExpenseService } from '@/service/services/EmployeeExpense.service';
import { ModalExpenseEmployeeDetail } from '@/components/Modal/ModalExpenseEmployeeDetail/ModalExpenseEmployeeDetail';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
   const backgroundColor =
       theme.palette.mode === 'light'
           ? theme.palette.grey[100]
           : theme.palette.grey[800];
   return {
       backgroundColor,
       height: theme.spacing(3),
       color: theme.palette.text.primary,
       fontWeight: theme.typography.fontWeightRegular,
       '&:hover, &:focus': {
           backgroundColor: emphasize(backgroundColor, 0.06),
       },
       '&:active': {
           boxShadow: theme.shadows[1],
           backgroundColor: emphasize(backgroundColor, 0.12),
       },
   };
});


export const AdminExpenseView: React.FC<Props> = (props: any): JSX.Element => {

   const [loading, setLoading] = useState<boolean>(false);
   const [value, setValue] = useState('1');

   const [requestExpense, setRequestExpense] = useState([]);
   const [actionSelected, setActionSelected] = useState<String>('');
   const [review_status, setReview_status] = useState(0);
   const [status, setStatus] = useState(1);
   const [showAdminExpenseModal, setShowAdminExpenseModal] = useState<boolean>(false);
   const [expenseSelected, setExpenseSelected] = useState<any>('');

   const [view, setView] = useState<String>('expense');
   const [showDetails, setShowDetails] = useState<boolean>(false);
   const [details, setDetails] = useState([]);
   const [detailSelected, setDetailSelected] = useState<any>('');
   const [showDetailModal, setShowDetailModal] = useState<boolean>(false);

   useEffect(() => {
      getExpenseRequests()
   }, []);

   useEffect(()=> {
      if(expenseSelected && !showAdminExpenseModal){
          getDetailsExpense(expenseSelected.id);
      }
  },[expenseSelected, !showAdminExpenseModal]);

  
   const cabeceraDetail = [
      { name: 'reportTypeName', label: 'Tipo de Reporte' },
      { name: 'detail', label: 'Concepto de Detalle' },
      { name: 'amount', label: 'Monto' },
      { name: 'review_status', label: 'Estado de Revisión' },
  ];

   const handleChange = (event: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
      const valorTabs = parseInt(newValue) - 1;
      setReview_status(valorTabs);

   };
   const getExpenseRequests = async () => {
      setLoading(true);
      const response = await ExpenseAdminService.getExpenseAdmin('', '');
      if (response.data) {
         setRequestExpense(response.data || [])
      } else {
         console.log('error')
      }
      setLoading(false);
   }

   const getDetailsExpense = async(idExpense) => {
      setLoading(true);
      await EmployeeExpenseService.getDetailsExpense(idExpense, '', '', '').then((data) => {
         const oldData = data.data || [];
         let newData = [];
         oldData.length > 0 && oldData.map((item) => {
             data = {
                 id: item.id,
                 amount: item.amount,
                 coverage_company: item.coverage_company,
                 detail: item.detail,
                 doc: item.doc,
                 idexpense_report_type: item.idexpense_report_type,
                 idexpensereport: item.idexpensereport,
                 observation: item.observation,
                 reportTypeName: item.reportTypeName,
                 review_status: item.review_status == '0' ? 'pendiente' : (item.review_status == '1' ? 'aceptada' : 'rechazada'),
                 status: item.status,
             }
             newData.push(data);
         });
         setDetails(newData || []);
         setLoading(false);
      })
   }

   function RecuperarData(value){
      !expenseSelected && setExpenseSelected(value);
      expenseSelected && setDetailSelected(value);
      if(value.action != 'edit'){
         setShowDetails(true);
         setView('details');
     }
   }

   const valiteStatusRev = (review) => {
      switch (review) {
         case '0':
            return 'No Revisado'
            break;
         case '1':
            return 'Revisado'
            break;
         default:
            return 'Rechazado'
            break;
      }
   }

   return (
      <Protected>
         {loading && <Loading />}
         <Container>

            <Breadcrumbs aria-label="breadcrumb">
               <StyledBreadcrumb
                  label="Solicitudes de Gastos"
                  onClick={() => {
                     setView('expense');
                     setExpenseSelected('');
                     setShowDetails(false);
                  }}
                  icon={<GroupsIcon fontSize="small" />}
               />
               {
                  showDetails && (
                     <StyledBreadcrumb
                           label={`Detalles de Gastos`}
                           onClick={() => {setView('details')}}
                           icon={<GroupsIcon fontSize="small" />}
                     />
                  )
               }
            </Breadcrumbs>

            {
               view == 'expense' && (
                  <>
                     <TableDataV2
                        data={requestExpense}
                        header={[
                           { name: 'name_all', label: 'Empleado', filter: false, Chip: false },
                           { name: 'n_document', label: 'Documento', filter: false, Chip: false },
                           { name: 'detail', label: 'Concepto de Detalle', filter: false, Chip: false },
                           { name: 'period', label: 'Periodo', filter: false, Chip: true },
                           // { name: 'review_status_name', label: 'Estado de Revisión', filter: false, Chip: true },                                                              
                        ]}
                        actionSelect={setActionSelected}
                        setModalSave={setShowAdminExpenseModal}
                        status_action
                        btn_helper
                        RecuperarData={RecuperarData}
                        btn_disabled={true}
                        btn_delete_disable={true}
                        btn_edit_disable={true}
                     />  
                  </>
               )
            }    

            {
               view == 'details' && (
                  <TableDataV2
                        title={`Detalles `}
                        data={details || []}
                        header={cabeceraDetail || []}
                        actionSelect={setActionSelected}
                        setModalSave={setShowDetailModal}
                        status_action
                        btn_disabled={true}
                        disabled_title={false}
                        btn_edit_disable={false}
                        btn_delete_disable={true}
                        currentView={view}
                        RecuperarData={RecuperarData}
                  />
               )
            }

         </Container>
         { 
            showAdminExpenseModal && (
               <ModalAdminExpense
                  open={showAdminExpenseModal}
                  closeModal={()=>{setShowAdminExpenseModal(false)}}
                  actionSelected={actionSelected}
                  onListExpense={getExpenseRequests}
                  expense={expenseSelected}
                  clearState={()=>{setExpenseSelected('')}}
               />
            )
         }
         { 
                showDetailModal && (
                    <ModalExpenseEmployeeDetail
                        open={showDetailModal}
                        closeModal={()=>{setShowDetailModal(false)}}
                        actionSelected={actionSelected}
                        onListDetails={getDetailsExpense}
                        detail={detailSelected}
                        expenseSelected={expenseSelected}
                        clearState={()=>{setDetailSelected('')}}
                    />
                )
            }
      </Protected>
   )
};
import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Alert, Backdrop, Box, Breadcrumbs, Button, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar, Typography } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { WorkerService } from '@/service/services/Worker.service';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import GroupsIcon from '@mui/icons-material/Groups';
import { AssistanceService } from '@/service/services/Assistance.service';
import { ModalExtraHour } from '@/components/Modal/ModalExtraHour';
import { ModalExtraHourDelete } from '@/components/Modal/ModalExtraHourDelete';
import { ModalAbsence } from '@/components/Modal/ModalAbsence';
import { ModalAbsenceDelete } from '@/components/Modal/ModalAbsenceDelete';
import { Props } from './Absence.type';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

export const AbsenceView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [workers, setWorkers] = useState<any>([]);
    const [workerSelected, setWorkerSelected] = useState<any>('');
    const [Absences, setAbsences] = useState<any>([]);
    const [showAbsenceModal, setShowAbsenceModal] = useState<boolean>(false);
    const [showAbsenceDeleteModal, setShowAbsenceDeleteModal] = useState<boolean>(false);
    const [absenceSelected, setAbsenceSelected] = useState<any>('');
    const [actionSelected, setActionSelected] = useState<any>('');
    const [view, setView] = useState<String>('workers');
    const [showAbsences, setShowAbsences] = useState<boolean>(false);
    const [messageRequest, setMessageRequest] = useState<any>(null)
    const cabecera = [
        { name: 'n_document', label: 'RUT TRABAJADOR' },
        { name: 'surname', label: 'APELLIDO PATERNO' },
        { name: 'second_surname', label: 'APELLIDO MATERNO' },
        { name: 'name', label: 'NOMBRES' }];

    const cabeceraAbsence = [
    { name: 'type_absence_name', label: 'TIPO DE INASISTENCIA' },
    { name: 'type_discount_name', label: 'TIPO DE DESCUENTO' },
    { name: 'observation', label: 'OBSERVACION' },
    { name: 'date_registration', label: 'FECHA' },
    { name: 'total_absence', label: 'TOTAL INASISTENCIAS' }];


    const openEmployeeRequest = async(dataRequest) =>{
        console.log(dataRequest?.idEmployee)
        const respWorker = await WorkerService.getWorkers();
        const workersAll = respWorker.data;
        console.log(workersAll)
        const findEmployee = workersAll.find(value => value.id == dataRequest?.idEmployee);
        console.log(findEmployee)
        setWorkerSelected(findEmployee);
        setAbsenceSelected(findEmployee);
        setShowAbsences(true);
        setView('absences');
        setMessageRequest(dataRequest.detail)
        // const dataRequestProps = {
        //    workerSelected: findEmployee,
        //    messageDetail: dataRequest.detail,
        //    idRemuneration: dataRequest.idRemuneration,
        //    idPeriod: dataRequest.idPeriod
        // }
        // setDataRequest(dataRequestProps)
        // setOpenModal(true)
     }


    useEffect(()=> {
        getWorkers()
        if(props?.location.state){
            console.log(props?.location.state)
            openEmployeeRequest(props?.location.state)
         }
    },[]);

    useEffect(()=> {
        if(workerSelected){
         getAbsencesByEmployee(workerSelected.id);
        }
    },[workerSelected]);

    const getWorkers = async() => {
        setLoading(true);
        const resp:any = await WorkerService.getWorkers();
        setWorkers(resp.data || []);
        setLoading(false);
    }

    const getAbsencesByEmployee = async(idEmployee) => {
        setLoading(true);
        await AssistanceService.getAbsencesByEmployee(idEmployee).then((data) => {
            setAbsences(data.data || []);
            setLoading(false);
        })
    }

    function RecuperarData(value){
        console.log(value);
        !workerSelected && setWorkerSelected(value);
        workerSelected && setAbsenceSelected(value);
        setShowAbsences(true);
        setView('absences');
        // setOpenModal(true)
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Container>
            <Breadcrumbs aria-label="breadcrumb">
                <StyledBreadcrumb
                    label="Lista de Empleados"
                    onClick={() => {
                        setView('workers');
                        setWorkerSelected('');
                        setShowAbsences(false);
                    }}
                    icon={<GroupsIcon fontSize="small" />}
                />
                {
                    showAbsences && (
                        <StyledBreadcrumb
                            label={`Inasistencias`}
                            onClick={() => {setView('absences')}}
                            icon={<GroupsIcon fontSize="small" />}
                        />
                    )
                }
            </Breadcrumbs>
            {
                view == 'workers' && (
                    <TableDataV2
                        title={'Inasistencias por empleado'}
                        data={workers}
                        header={cabecera}
                        actionSelect={setActionSelected}
                        status_action
                        disabled_title={false}
                        btn_disabled
                        btn_edit_disable={true}
                        btn_delete_disable={true}
                        btn_helper
                        currentView={view}
                        RecuperarData={RecuperarData}
                        />
                )
            }
            {
                view == 'absences' && (

                    <>
                            {messageRequest && 
                                <Grid px={3} pt={3}>
                                <Chip label={`Mensaje de la solicitud: ${messageRequest}`}></Chip>
                                </Grid>
                             }
                     <TableDataV2
                        title={`Inasistencias de "${workerSelected?.name}"`}
                        data={Absences}
                        header={cabeceraAbsence}
                        actionSelect={setActionSelected}
                        setModalSave={setShowAbsenceModal}
                        setModalDelete={setShowAbsenceDeleteModal}
                        status_action
                        disabled_title={false}
                        btn_disabled={false}
                        btn_edit_disable={false}
                        btn_delete_disable={false}
                        currentView={view}
                        RecuperarData={RecuperarData}
                    />
                    </>
                   
                )
            }
            </Container>

            { 
                showAbsenceModal && (
                    <ModalAbsence
                        open={showAbsenceModal}
                        closeModal={()=>{setShowAbsenceModal(false)}}
                        actionSelected={actionSelected}
                        onListAbsences={getAbsencesByEmployee}
                        absence={absenceSelected}
                        workerSelected={workerSelected}
                        clearState={()=>{setAbsenceSelected('')}}
                    />
                )
            }
            {
                actionSelected === 'delete' && (
                    <ModalAbsenceDelete
                        open={showAbsenceDeleteModal}
                        closeModal={()=>{setShowAbsenceDeleteModal(false)}}
                        actionSelected={actionSelected}
                        onListAbsences={getAbsencesByEmployee}
                        absence={absenceSelected}
                        workerSelected={workerSelected}
                        clearState={()=>{setAbsenceSelected('')}}
                    />
                )
            }
        
        </Protected>
    )
};
import { useMemo, useEffect, useRef, useState } from "react";
import { Button, InputAdornment, IconButton, Grid, CircularProgress, Snackbar, Alert } from '@mui/material';
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Icon } from '@components/common/Icon';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import { Loading } from "@components/common/Loading";
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logoLogin from "@assets/img/logo-login.jpg";
import { VisibilityIcon, VisibilityOffIcon } from '@toolbox/constants/icons';
import * as qs from 'qs'
import { ROUTE_COMPANIES, ROUTE_HOME, ROUTE_MY_VALIDATIONS } from "@/toolbox/constants/route-map";
import { authenticationService } from '@service/services/Authentication.service';
import { useHistory } from 'react-router-dom'
import { Props } from './login.type'
import "./Login.sass";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_TRABAJADOR } from "@/toolbox/defaults/static-roles";
import { Toaster, toast } from 'react-hot-toast';

const theme = createTheme();

export const LoginView: React.FC<Props> = (props: any): JSX.Element => {
   const history = useHistory();
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loadData, setLoadData] = useState<boolean>(false);
   const [open, setOpen] = useState<boolean>(false)
   const [tokenNotify, setTokenNotify] = useState<any>();
   const [loading, setLoading] = useState(false);
   const [data, setData] = useState({
      user_nick: '',
      rut_empresa: '',
      password: '',
      textError: '',
      showPassword: false
   });
   // const msg = firebase.messaging();
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   })
   const [verify, setVerify] = useState(false);

   const ruta = props.location.search + '.';
   const { rut_empresa, usuario, password } = props.location && qs.parse(ruta.slice(1, -1));
   useEffect(() => {
      if (rut_empresa && usuario && password) {
         var decodeRutEmpresa;
         var decodeUser;
         var decodePassword;

         try {
            decodeRutEmpresa = atob(rut_empresa);
            decodeUser = atob(usuario);
            decodePassword = atob(password);
            authSubmit(decodeUser, decodeRutEmpresa, decodePassword, 'erp');
         } catch (error) {
         }
      } 
   }, []);

   const rules = useMemo(() => ({
      user_nick: [
         VALIDATORS.REQUIRED,
         VALIDATORS.ONLY_LOWERCASE,
      ],
      password: [
         VALIDATORS.REQUIRED,
         VALIDATORS.PASSWORD.SIZE,
         VALIDATORS.PASSWORD.ALLOWED_CHARACTERS,
         VALIDATORS.PASSWORD.VALID,
      ],
      rut_empresa: [
         VALIDATORS.REQUIRED,
         // VALIDATORS.ONLY_LOWERCASE,
      ]
   }), []);

   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);

      return valid;
   }

   const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      const name = event.target.name;
      const value = event.target.value;

      switch (name) {
         case 'user_nick':
            // if ((/^[\w@.-]{0,86}$/).test(value)) {

            // }
            setVerify(false)
            setData(prev => ({ ...prev, user_nick: value, textError: '' }));
            break;
         case 'password':
            if (/^.{0,15}$/.test(value)) {
               setData(prev => ({ ...prev, password: value, textError: '' }));
            }
            break;
         case 'rut_empresa':
            setVerify(false)
            var Fn = {
               // Valida el rut con su cadena completa "XXXXXXXX-X"
               validaRut: function (rutCompleto) {
                  if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto))
                     return false;
                  var tmp = rutCompleto.split('-');
                  var digv = tmp[1];
                  var rut = tmp[0];
                  if (digv == 'K') digv = 'k';
                  return (Fn.dv(rut) == digv);
               },
               dv: function (T) {
                  var M = 0, S = 1;
                  for (; T; T = Math.floor(T / 10))
                     S = (S + T % 10 * (9 - M++ % 6)) % 11;
                  return S ? S - 1 : 'k';
               }
            }

            var foo = value.split("-").join("")
            if (foo.length > 0 && foo.length < 10) {
               foo = foo.match(new RegExp('.{1,8}', 'g')).join("-");
               setData(prev => ({ ...prev, rut_empresa: foo, textError: '' }))
            } else if (foo.length == 0) {
               setData(prev => ({ ...prev, rut_empresa: "", textError: '' }))
            }

            break;
         default:
            break;
      }
   };

   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      setData(prev => ({ ...prev, [name]: newVal }));
   }

   const handleSubmit = (event: React.SyntheticEvent) => {
      event.preventDefault();

      const { user_nick, rut_empresa, password } = data;
      try {
         if (validate()) {
            authSubmit(user_nick, rut_empresa, password, '');
         }
      } catch (error) {
         setData(prev => ({ ...prev, textError: 'Lo sentimos, ocurrió un error inesperado.' }));
      } finally {

      }
   }

   const authSubmit = async (user_nick, rut_empresa, password, from) => {
      setLoading(true);
      const response = await authenticationService.login(user_nick, rut_empresa, password, from);
      console.log(response)
      if (response.data?.token == '') {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: response.data.message,
         }));
      } else {
         const data = readLocalStorage(KEY_USER_DATA)
         if (data.user.role == ROLE_SUPER_ADMIN) {
            history.push(ROUTE_HOME);
         }
         if (data.user.role == ROLE_ADMIN) {
            history.push(ROUTE_COMPANIES)
         }
         if (data.user.role == ROLE_TRABAJADOR) {
            history.push(ROUTE_MY_VALIDATIONS)
         }
      }

      setLoading(false);
   }
   const handleClickShowPassword = () => {
      setData({ ...data, showPassword: !data.showPassword });
   };

   const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
   };

   return (
      <>
         <ThemeProvider theme={theme}>
            <Toaster
               position="bottom-right"
               reverseOrder={false}

            />
            <Grid container component="main" sx={{ height: '100vh' }}>
               <CssBaseline />
               <Grid
                  item
                  xs={false}
                  sm={6}
                  md={7}
                  sx={{
                     backgroundImage: `url(${logoLogin})`,
                     backgroundRepeat: 'no-repeat',
                     backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                     backgroundSize: 'cover',
                     backgroundPosition: 'center',
                  }}
               />
               <Grid item xs={12} sm={6} md={5} component={Paper} elevation={6} square >
                  <Box
                     sx={{
                        my: 5,
                        mx: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                     }}>

                     <Grid sx={{ border: '1px solid #155172', borderTop: '8px solid #155172', borderRadius: '7px', width: '80%' }}>
                        <form className="LoginForm" onSubmit={handleSubmit}>
                           <Grid container spacing={2} className={`e-mb-lg`}>
                              <Grid item xs={12}>
                                 <Typography textAlign={'center'} sx={{ color: '#155172', fontWeight: '700', fontSize: '1.6em' }} >Sesión de Usuario</Typography>
                              </Grid>
                              <Grid item xs={12}>
                                 <Input
                                    ref={ref => inputRefs.current[0] = ref}
                                    label="Nombre de Usuario"
                                    name="user_nick"
                                    placeholder="Ingrese su nombre de usuario"
                                    value={data.user_nick}
                                    onChange={handleInput}
                                    onBlur={handleInputBlur}
                                    backgroundColor="#eeeff1"
                                    rules={rules.user_nick}
                                    disableElevation
                                    validateOnBlur
                                    dense
                                 />
                              </Grid>
                              <Grid item xs={12}>
                                 <Input
                                    ref={ref => inputRefs.current[1] = ref}
                                    label="RUT Empresa"
                                    name="rut_empresa"
                                    type="text"
                                    placeholder="Ingrese su RUT Empresa"
                                    value={data.rut_empresa}
                                    onChange={handleInput}
                                    onBlur={handleInputBlur}
                                    backgroundColor="#eeeff1"
                                    rules={rules.rut_empresa}
                                    disableElevation
                                    validateOnBlur
                                    dense
                                 />
                              </Grid>
                              <Grid item xs={12}>
                                 <Input
                                    ref={ref => inputRefs.current[2] = ref}
                                    label="Contraseña"
                                    name="password"
                                    type={data.showPassword ? 'text' : 'password'}
                                    placeholder="Ingrese su contraseña"
                                    value={data.password}
                                    onChange={handleInput}
                                    onBlur={handleInputBlur}
                                    backgroundColor="#eeeff1"
                                    //rules={rules.password}
                                    appendAdornment={
                                       <InputAdornment position="end">
                                          <IconButton
                                             aria-label="toggle password visibility"
                                             onClick={handleClickShowPassword}
                                             onMouseDown={handleMouseDownPassword}
                                          >
                                             {data.showPassword ? <Icon Svg={VisibilityIcon} /> : <Icon Svg={VisibilityOffIcon} />}
                                          </IconButton>
                                       </InputAdornment>
                                    }
                                    disableElevation
                                    validateOnBlur
                                    dense
                                 />
                              </Grid>
                              <Grid item xs={12}>

                                 {verify ? (<span style={{ color: 'red' }}>(*) Ingresa el Usuario y Rut</span>) : (<></>)}
                                 <Box sx={{ m: 1, position: 'relative' }}>
                                    <Button
                                       fullWidth={true}
                                       type='submit'
                                       variant='contained'
                                       onClick={handleSubmit}
                                       disabled={loading}
                                       sx={{
                                          background: '#E4980D', color: '#fff', fontWeight: '700', '&:hover': {
                                             bgcolor: '#E4980D'
                                          }
                                       }}
                                       disableElevation
                                    >
                                       <span>Iniciar sesión</span>
                                    </Button>
                                    {loading && (
                                       <CircularProgress
                                          size={24}
                                          sx={{
                                             position: 'absolute',
                                             top: '50%',
                                             left: '50%',
                                             marginTop: '-12px',
                                             marginLeft: '-12px',
                                          }}
                                       />
                                    )}
                                 </Box>
                              </Grid>
                           </Grid>
                           <span className="TextError">{data.textError}</span>
                        </form>
                     </Grid>
                     <Snackbar
                        open={snackBarConfig.open}
                        autoHideDuration={snackBarConfig.autoHideDuration}
                        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                     >
                        <Alert
                           onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                           severity={snackBarConfig.severity}
                           variant="filled"
                        >
                           {snackBarConfig.message}
                        </Alert>
                     </Snackbar>
                  </Box>
               </Grid>
            </Grid>
           
         </ThemeProvider>

         {loadData && <Loading title="Iniciando Sesión" />}
      </>
   )
}

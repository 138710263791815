import { ContractRepository } from "../repositories/Contract.repository";

export const ContractService = {
    getDocumentContract,
    getValidationSignature,
    getTypeSignature,
    createSignature,
    editSignatureAdmin,
    getDetailSignature,
    getAnnexed,
    getDocumentAnnexed,
    documentContractText,
    documentContractClauses,
    documentClausesByIdContract,
    variablesClause,
    getEmployeeVariables,
    createClauses,
    editClause,
    deleteClause,
    createDocument,
}

async function getDocumentContract(idEmployee:number){
    const res = await ContractRepository.getDocumentContract(idEmployee);
    return res;
}
// para obtener el estado de la validacion del contrato
async function getValidationSignature(){
    const res = await ContractRepository.getValidationSignature();
    return res;
}
// los tipos de firmas 
async function getTypeSignature(){
    const res = await ContractRepository.getTypeSignature();
    return res;
}
// para insertar la firma por parte del trabajador en el data solicita un idtypesignature y img
async function createSignature(data){
    const res = await ContractRepository.createSignature(data);
    return res;
}

async function editSignatureAdmin(idDetailSignature,data){
    const res = await ContractRepository.editSignatureAdmin(idDetailSignature,data);
    return res;
}

async function getDetailSignature(idEmplyee){
    const res = await ContractRepository.getDetailSignatureAdmin();
    const respSignature = res?.data.filter((item)=>{return item.idemployee == idEmplyee })
    return respSignature;
}

async function getAnnexed(idContract){
    const res = await ContractRepository.getAnnexed(idContract);
    return res;
}

async function getDocumentAnnexed(id){
    const res = await ContractRepository.getDocumentAnnexed(id);
    return res;
}

//GET DOCUMENT CONTRACT TEXT
async function documentContractText(){
    const res = await ContractRepository.documentContractText();
    return res;
}

async function documentContractClauses(){
    const res = await ContractRepository.documentContractClauses();
    return res;
}

async function documentClausesByIdContract(idContract){
    const res = await ContractRepository.documentClausesByIdContract(idContract);
    return res;
}

async function variablesClause(){
    const res = await ContractRepository.variablesClause();
    return res;
}

async function getEmployeeVariables(idEmployee: number){
    const res = await ContractRepository.getEmployeeVariables(idEmployee);
    return res;
}

async function createClauses(arrayClauses){
    let ArrayClausesId = [];

    for (let i = 0; i < arrayClauses.length; i++) {
        let dataClause = arrayClauses[i];
        const respClause = await ContractRepository.createClauses(dataClause);
        if(respClause?.data?.detail?.id) ArrayClausesId.push(respClause.data.detail.id);
    }

    return ArrayClausesId;
}

async function editClause(data, idClause ){
    const res = await ContractRepository.editClause(data, idClause);
    return res;
}

async function deleteClause( idClause){
    const res = await ContractRepository.deleteClause(idClause);
    return res;
}

async function createDocument(dataDocument){
    const res = await ContractRepository.createDocument(dataDocument);
    return res;
}





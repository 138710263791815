import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Breadcrumbs } from '@mui/material'
import { Props } from "./SettlementActive.type"
import './SettlementActive_styles.css'
import { TableDataV2 } from '@components/common/Tablev2';
import { WorkerService } from '@/service/services/Worker.service';
import { Loading } from '@/components/common/Loading';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import GroupsIcon from '@mui/icons-material/Groups';
import { LiquidationWorkers } from './LiquidationWorkers/LiquidationWorkers';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
   const backgroundColor =
       theme.palette.mode === 'light'
           ? theme.palette.grey[100]
           : theme.palette.grey[800];
   return {
       backgroundColor,
       height: theme.spacing(3),
       color: theme.palette.text.primary,
       fontWeight: theme.typography.fontWeightRegular,
       '&:hover, &:focus': {
           backgroundColor: emphasize(backgroundColor, 0.06),
       },
       '&:active': {
           boxShadow: theme.shadows[1],
           backgroundColor: emphasize(backgroundColor, 0.12),
       },
   };
});

const cabecera = [
   { name: 'n_document', label: 'RUT TRABAJADOR' },
   { name: 'surname', label: 'APELLIDO PATERNO' },
   { name: 'second_surname', label: 'APELLIDO MATERNO' },
   { name: 'name', label: 'NOMBRES' }
];
   
interface Data {
   codigo: string;
   nombreDefault: string;
   nombre: string;
}

function createDataCharges(
   codigo: string,
   nombreDefault: string,
   nombre: string,
): Data {
   return { codigo, nombreDefault, nombre };
}

const rowsDataSettkement = [
   createDataCharges('H|1', 'Sueldo Mes', 'Sueldo Base'),
   createDataCharges('H|2', 'Sobresueldo1 (Hrs. Extras)', 'Sobresueldo1 (Hrs. Extras)'),
   createDataCharges('H|3', 'Sobresueldo2 (Hrs. Extras)', 'Sobresueldo2 (Hrs. Extras)'),
   createDataCharges('H|4', 'Sobresueldo3 (Hrs. Extras)', 'Sobresueldo3 (Hrs. Extras)'),
   createDataCharges('H|5', 'Comision', 'Comision'),
   createDataCharges('H|6', 'Bono por Meta', 'Bono por Meta'),
]

const labels = [{ label: 'Estructuras Obligatorias', id: '1' }, { label: 'Estructuras Opcionales', id: '2' }]


export const SettlementActive: React.FC<Props> = (props: Props): JSX.Element => {

   const [loading, setLoading] = useState(false);
   const [workers, setWorkers] = useState<any>([])
   const [openModal, setOpenModal] = useState<boolean>(false);
   const [workerSelect, setWorkerSelect] = useState<any>({});
   const [actionSelected, setActionSelected] = useState<any>('');
   const [view, setView] = useState<String>('workers');
   const [showLiquidation, setShowLiquidation] = useState<boolean>(false);

   useEffect(() => {
      getWorkers()
   }, [])

   const getWorkers = async () => {
      setLoading(true);
      const resp:any = await WorkerService.getWorkers();
      setWorkers(resp.data);
      setLoading(false);
   }

   const recuperarData = (data) =>{
      // setOpenModal(true);
      setWorkerSelect(data);
      setShowLiquidation(true);
      setView('liquidation');
   }

   return (
      <Protected>
         {loading && <Loading/>}
         <Container>

            <Breadcrumbs aria-label="breadcrumb">
               <StyledBreadcrumb
                  label="Lista de Empleados"
                  onClick={() => {
                     setView('workers');
                     setWorkerSelect('');
                     setShowLiquidation(false);
                  }}
                  icon={<GroupsIcon fontSize="small" />}
               />
               {
                  showLiquidation && (
                     <StyledBreadcrumb
                           label={`Liquidaciones`}
                           onClick={() => {setView('liquidation')}}
                           icon={<GroupsIcon fontSize="small" />}
                     />
                  )
               }
            </Breadcrumbs>

            {
               view == 'workers' && (
                  <TableDataV2
                     data={workers}
                     header={cabecera}
                     actionSelect={setActionSelected}
                     status_action
                     btn_disabled
                     btn_helper
                     btn_edit_disable={true}
                     btn_delete_disable={true}
                     RecuperarData={recuperarData}
                  />
               )
            }

            {
               view == 'liquidation' && (
                  <LiquidationWorkers
                     open={openModal}
                     setOpen={setOpenModal}
                     data={workerSelect}
                     setData={setWorkerSelect}
                  />
               )
            }

         </Container>
      </Protected>
   );
};


import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useRef, useState } from "react";
import { Alert, Autocomplete, Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import { Loading } from "@/components/common/Loading";

export const ModalRequestLiquidationError = (props:any): JSX.Element => {

   const { open, closeModal, requestRemuneration } = props;

   const [loading, setLoading] = useState<boolean>(false);
   const [btnLiquitadion, setBtnLiquitadion] = useState<boolean>(false);
   const [dataForm, setDataForm] = useState<any>({
    detail: '',
    from_date_request: '',
    to_date_request:''
   })
   const [error,setError] = useState<any>(null)
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   });

   const onSubmit = async(e) =>{
     e.preventDefault()
    const res = await  requestRemuneration(dataForm);
    console.log(res)
     
   }

   const handleInput = (e) => {
    const changedFormValues = {
      ...dataForm,
      [e.target.name]: e.target.value
    }
    changedFormValues.detail.trim() !== "" && setError("");
    changedFormValues.from_date_request.trim() !== "" && setError("");
    changedFormValues.to_date_request.trim() !== "" && setError("");
    setDataForm(changedFormValues);
  }

   return(
      <>
      {loading && <Loading/>}
      <Snackbar
         open={snackBarConfig.open} 
         autoHideDuration={snackBarConfig.autoHideDuration} 
         onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
         anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
         <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
               {snackBarConfig.message || ""}
         </Alert>
      </Snackbar>

      <Modal open={open} handleClose={closeModal} handle size="sm">
         <ModalHeader
               text={"Generar Solicitud de Revisión"}
               className='positionElements'
               onCancel={closeModal}
         >
         </ModalHeader>
         <ModalBody>
            <Grid container >
               <Grid container item xs={12} alignItems="flex-start" justifyContent="center" marginBottom='25px'>
                  <Grid item xs={4}>
                     <Typography variant="body1"><b>Motivo:</b> </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                    value={dataForm?.detail}
                    type='text'
                    multiline
                    maxRows={10}
                    placeholder='Especifique detallamente el incoveniente con su liquidación'
                    fullWidth
                    onChange={handleInput}
                    name={'detail'}
                    />
                  </Grid>
               </Grid>
               <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                  <Grid item xs={4}>
                     <Typography variant="body1"><b>Fecha Inicial:</b> </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                    value={dataForm?.from_date_request}
                    variant="standard"
                    type="date"
                    fullWidth
                    onChange={handleInput}
                    name={'from_date_request'}
                    />
                  </Grid>
               </Grid>
               <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                  <Grid item xs={4}>
                     <Typography variant="body1"><b>Fecha Final:</b> </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                    value={dataForm?.to_date_request}
                    type="date"
                    variant="standard"
                    fullWidth
                    onChange={handleInput}
                    name={'to_date_request'}
                    />
                  </Grid>
               </Grid>

            </Grid>
         </ModalBody>
         <ModalFooter 
            buttonType="submit"
            confirmText={" CREAR NUEVA" }
            cancelText="CANCELAR" 
            onCancel={closeModal}
            onConfirm={(e)=>{onSubmit(e)}}
         />
      </Modal>
      </>
   );
}
import { Loading } from "@/components/common/Loading";
import { TableDataV2 } from "@/components/common/Tablev2";
import { Protected } from "@/components/layout/Protected";
import { ModalRequest } from "@/components/Modal/ModalRequest";
import { ModalRequestLiquidationError } from "@/components/Modal/ModalRequestLiquidationError";
import { PeriodService } from "@/service/services/Period.service";
import { RemunerationService } from "@/service/services/Remuneration.service";
import { RequestService } from "@/service/services/Request.service";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { ROUTE_ARREARS, ROUTE_ASSISTANCES, ROUTE_LIQUIDATION_SALARY } from "@/toolbox/constants/route-map";
import { ROLE_ADMIN } from "@/toolbox/defaults/static-roles";
import { readLocalStorage, saveLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { Alert, Button, Grid, MenuItem, Select, Snackbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export const MyRequestView: React.FC<any> = (props: any): JSX.Element => {
   const history = useHistory();
   const cabeceraworker = [
    { name: 'requestTypeName', label: 'Tipo de Solicitud' },
    { name: 'date_creation', label: 'Fecha de Solicitud' },
    { name: 'detail', label:'Detalle'},
    { name: 'observations', label:'Observacion'},
    { name: 'name_status_review', label:'Estado'}
   ]

   const cabeceraAdmin = [
    { name: 'requestTypeName', label: 'Tipo de Solicitud' },
    { name: 'n_document_employee', label: 'RUT Trabajador' },
    { name: 'name_employee', label: 'Nombre' },
    { name: 'surname_employee', label: 'Primer Apellido' },
    { name: 'second_surname_employee', label: 'Segundo Apellido' },
    { name: 'date_creation', label: 'Fecha de Solicitud' },
    { name: 'detail', label:'Detalle'},
    { name: 'observations', label:'Observacion'},
    { name: 'name_status_review', label:'Estado'}
   ]


   const [years, setYears] = useState<any>([]);
   const [periods, setPeriods] = useState<any>([]);
   const [yearSelected, setYearSelected] = useState<any>(null);
   const [loading, setLoading] = useState<boolean>(false);
   const [monthSelected, setMonthSelected] = useState<any>(null);
   const [liquidation, setLiquidation] = useState<any>('');
   const [dataPDF, setDataPDF] = useState<any>(null)
   const userData = readLocalStorage(KEY_USER_DATA);
   console.log(userData)
   const name_role = userData?.user.role;

   const [openModal, setOpenModal] = useState<boolean>(false)
   const [myRequest, setMyRequest] = useState<any>(null || []);
   const [requestSelected, setRequestSelected] = useState<any>(null)
   const [openTable, setOpenTable] = useState<boolean>(false);
   const [actionSelected, setActionSelected] = useState<String>('');
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   });



   const getPeriods = async () => {
      setLoading(true);
      const resp: any = await PeriodService.getPeriods();
      const newData = [];
      const arrayYears = [];
      resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
         newData.push({
            id: item.id,
            month: item.month,
            year: item.year,
            alias_name: item.month + '-' + item.year,
         })
         const found = arrayYears.find(element => element == item.year);
         if (!found) { arrayYears.push(item.year); }

      });
      setYears(arrayYears);
      setPeriods(newData || []);
      setLoading(false);
   }

   const onSubmit = async () => {
      setLoading(true);
      if (!monthSelected || !yearSelected) {
         setLoading(false);
         return (
            setSnackBarConfig(prev => ({
               ...prev, open: true,
               severity: 'warning',
               message: `Debe llenar todos los campos`
            }
            ))
         );
      }

     if(name_role == ROLE_ADMIN){
        const respMyRequest = await RequestService.getRequestForAdmin(yearSelected,monthSelected);
        console.log(respMyRequest)
        setMyRequest(respMyRequest?.data || []);
     }else{
        const respMyRequest = await RequestService.getRequestWorker(yearSelected,monthSelected);
        console.log(respMyRequest)
        setMyRequest(respMyRequest?.data || []);
     }
    
     setOpenTable(true)

      setLoading(false);
   }

   const requestCreate = async (data) =>{
      const dataRequest = {
         detail: data.detail,
         from_date_request: data.from_date_request,
         to_date_request: data.to_date_request,
         idrequests_type: data.idrequests_type
      }
      const res = await RequestService.createRequestWorker(dataRequest);
      // return res
   }

   const goRedirectRequest = async(request) =>  // UNA VEZ YA EDITO, EVALUAMOS SI RECHAZO O ACEPTO, EN CASO DE ACEPTAR EVALUAR EL TIPO PARA REDIRIGIRLO A DONDE SEA CONVENIENTE
   {
        switch(request.idrequests_type){
            case 1 :
                history.push(ROUTE_ASSISTANCES,  {idEmployee: request.idemployee, detail: request.detail, idTypeRequest: request.idrequests_type});
                break;
            case 2 :
                history.push(ROUTE_ARREARS,  {idEmployee: request.idemployee, detail: request.detail, idTypeRequest: request.idrequests_type});
                break;
            case 3 :
                history.push(ROUTE_ASSISTANCES,  {idEmployee: request.idemployee, detail: request.detail, idTypeRequest: request.idrequests_type});
                break;
            case 4 :
                history.push(ROUTE_ASSISTANCES,  {idEmployee: request.idemployee, detail: request.detail, idTypeRequest: request.idrequests_type});
                break;  
            case 6:
                const requestLiquidation = await RequestService.getRequestLiquidation(request?.id)
                console.log(requestLiquidation)
                history.push(ROUTE_LIQUIDATION_SALARY, {idEmployee: request.idemployee, detail: request.detail, idRemuneration:requestLiquidation.data.idremuneration , idPeriod: requestLiquidation.data.idperiod});
                break;
            default:
                console.log('hsd');
        }
    }

   const RecuperarData = (data) => {
    console.log(data)
   //  switch(data?.action)
   //  {
   //    case 'delete':

   //       break;
   //  }
   setRequestSelected(data)
   if(data.action == "redirect"){
      goRedirectRequest(data)
   }
    
   }
   useEffect(() => {
      if (periods.length == 0) {
         getPeriods();
      }
   }, []);

   return (
      <Protected>
         {loading && <Loading />}
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
               {snackBarConfig.message || ""}
            </Alert>
         </Snackbar>
         <Grid container p={5} >
            <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
               <Grid item xs={4}>
                  <Typography variant="body1"><b>Período:</b> </Typography>
               </Grid>
               <Grid item xs={8}>
                  <Select
                     id="id_education_level"
                     variant="standard"
                     value={yearSelected}
                     onChange={(event) => {
                        setYearSelected(event.target.value);
                     }}
                     fullWidth
                  >
                     {
                        years.map((year, i) => {
                           return <MenuItem key={i} value={year}>{year}</MenuItem>;
                        })
                     }
                  </Select>
               </Grid>
            </Grid>

            <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
               <Grid item xs={4}>
                  <Typography variant="body1"><b>Mes:</b> </Typography>
               </Grid>
               <Grid item xs={8}>
                  <Select
                     id="id_education_level"
                     variant="standard"
                     value={monthSelected}
                     onChange={(event) => { setMonthSelected(event.target.value) }}
                     fullWidth
                  >
                     <MenuItem value={'01'}>Enero</MenuItem>
                     <MenuItem value={'02'}>Febrero</MenuItem>
                     <MenuItem value={'03'}>Marzo</MenuItem>
                     <MenuItem value={'04'}>Abril</MenuItem>
                     <MenuItem value={'05'}>Mayo</MenuItem>
                     <MenuItem value={'06'}>Junio</MenuItem>
                     <MenuItem value={'07'}>Julio</MenuItem>
                     <MenuItem value={'08'}>Agosto</MenuItem>
                     <MenuItem value={'09'}>Septiembre</MenuItem>
                     <MenuItem value={'10'}>Octubre</MenuItem>
                     <MenuItem value={'11'}>Noviembre</MenuItem>
                     <MenuItem value={'12'}>Diciembre</MenuItem>
                  </Select>
               </Grid>
            </Grid>

            <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
               <Button onClick={() => { onSubmit() }} type={'submit'} size="small" color={"primary"} variant="contained" >
                  {name_role == ROLE_ADMIN  ? 'VER SOLICITUDES':`Ver Mis Solicitudes`}
               </Button>
            </Grid>

            {
                openTable && 
                <TableDataV2
                title={name_role == ROLE_ADMIN  ? 'SOLICITUDES DE EMPLEADOS':` MIS SOLICITUDES`}
                data={myRequest || []}
                header={name_role == ROLE_ADMIN? cabeceraAdmin: cabeceraworker}
                status_action
                btn_disabled={name_role == ROLE_ADMIN? true :false}
                btn_edit_disable={name_role == ROLE_ADMIN? false:true}
                btn_delete_disable={true}
               //  btn_helper
                btn_redirect
                setModalSave={setOpenModal}
                actionSelect={setActionSelected}
                // checkbox oh bandida no guardes oe, toy solucionando bug
                RecuperarData={RecuperarData}
                />
            }
         

          <ModalRequest
          open={openModal}
          closeModal={()=>setOpenModal(false)}
          requestCreate={requestCreate}
          actionSelected={actionSelected}
          request={actionSelected == 'edit' && requestSelected}
          />
         </Grid>
      </Protected>
   )
}
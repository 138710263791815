import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { async } from 'rxjs';

export const responsabilityFamilyRepository = {
   getResponsabilityFamily: async (id_employee): Promise<any> => {
      const resp = await http.get<any>(`${API_URL_BASE}/v1/responsabilityFamily/${id_employee}` )
      const {data,error,message} = resp
      return {
         data: (data||[]).map((dt) => ({
            id: dt.id,
            name: dt.name,
            birth_date: dt.birth_date,
            valid_since: dt.valid_since,
            valid_until: dt.valid_until,
            idrelationship: dt.idrelationship,
            RelationShip: dt.RelationShip,
            sexo: dt.sexo,
            idtyperesponsabilityfamily: dt.idtyperesponsabilityfamily,
            typeFamily: dt.typeFamily,
            idfamilyburden: dt.idfamilyburden,
            load_number: dt.load_number,
            idemployee: dt.idemployee,
            n_document: dt.n_document,
            name_employee: dt.name_employee,
            surname_employee: dt.surname_employee,
            second_surname_employee: dt.second_surname_employee,
            status: dt.status
         })),
         error,
         message
      };
   },
   postCreateResponsabilityFamily: async(dt): Promise<any> => {
    const resp = await http.post<any>(`${API_URL_BASE}/v1/responsabilityFamily`,{
        name: dt.name,
        birth_date: dt.birth_date,
        valid_since: dt.valid_since,
        valid_until: dt.valid_until,
        idrelationship: dt.idrelationship,
        sexo: dt.sexo,
        idtyperesponsabilityfamily : dt.idtyperesponsabilityfamily ,
        idfamilyburden: dt.idfamilyburden,
        idemployee: dt.idemployee,
    })
    const {data,error,message} = resp
    return {
        data: data,
        error: error,
        message: message
    }
   },

   getDataInitial: async (): Promise<any> => {
      const resp = await http.get<any>(`${API_URL_BASE}/v1/responsabilityFamily/typeResponsabilityFamily` )
      const {data,error,message} = resp
      return {
         data: 
         {
            type_responsability_family: (data.type_responsability_family||[]).map((dt) => ({
               id: dt.id,
               name: dt.name,
               status: dt.status
            })),
            relation_ship:  (data.relation_ship||[]).map((dt) => ({
               id: dt.id,
               name: dt.name,
               status: dt.status
            })),
         },
         error,
         message
      };
   },
}

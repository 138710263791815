import { VariableGlobalRepository } from "../repositories/VariableGlobal.repository";

export const VariableGlobalsService = {
    getVaribaleGlobal,
    createVariableGlobal,
    editVariableGlobal,
    deleteVariableGlobal
}

async function getVaribaleGlobal(){
    const res = await VariableGlobalRepository.getVaribaleGlobal();
    return res;
}

async function createVariableGlobal(data:{}){
    const res = await VariableGlobalRepository.createVariableGlobal(data);
    return res;
}

async function editVariableGlobal(id, data:{}){
    const res = await VariableGlobalRepository.editVariableGlobal(id, data);
    return res;
}

async function deleteVariableGlobal(id){
    const res = await VariableGlobalRepository.deleteVariableGlobal(id);
    return res;
}

import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);
const idCompany = dataUser?.user?.id_company;

export const ContractRepository = {

    getDocumentContract: async(idEmployee:number): Promise<any> => {
        const res = await http.getPDF<any>(`${API_URL_BASE}/v1/documentContract/downloadContract?idemployee=${idEmployee}`);
        return res;
    },
    getValidationSignature: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/validationSignature/statusValidation`);
        return res;
    },
    getTypeSignature: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/validationSignature/typeSignature`);
        return res;
    },
    createSignature: async(dataValidation): Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/validationSignature/created`,dataValidation);
        return res;
    },
    editSignatureAdmin: async(idDetailSignature, dtaSignature): Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/validationSignature/adminEdit/${idDetailSignature}`,{
            status: dtaSignature?.status,
            observations: dtaSignature?.observations
        });
        return res;
    },
    getDetailSignatureAdmin : async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/validationSignature/adminList?status=[0,1,2,3,4]`);
        return res;
    },
    getAnnexed : async(id): Promise<any> =>{
        const res = await http.get<any>(`${API_URL_BASE}/v1/annexed/getAnnexed/${id}`)
        return res
    },
    getDocumentAnnexed: async(id): Promise<any> => {
        const res = await http.getPDF<any>(`${API_URL_BASE}/v1/annexed/viewDocument/${id}`);
        return res;
    },

    //DOCUMENT CONTRACT 
    documentContractText : async(): Promise<any> =>{
        const res = await http.get<any>(`${API_URL_BASE}/v1/documentContract`)
        return res
    },

    documentContractClauses : async(): Promise<any> =>{
        const res = await http.get<any>(`${API_URL_BASE}/v1/documentContract/clauses`)
        return res
    },

    documentClausesByIdContract : async(idContract): Promise<any> =>{
        const res = await http.get<any>(`${API_URL_BASE}/v1/documentContract/clausesByIdContract/${idContract}`)
        return res
    },

    variablesClause : async(): Promise<any> =>{
        const res = await http.get<any>(`${API_URL_BASE}/v1/documentContract/variablesClause`)
        return res
    },

    getEmployeeVariables : async(idEmployee: number): Promise<any> =>{
        const res = await http.get<any>(`${API_URL_BASE}/v1/documentContract/getEmployeeVariables?idemployee=${idEmployee}`)
        return res
    },

    createClauses: async(dataClauses): Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/documentContract/clauses`, dataClauses);
        return res;
    },

    createDocument: async(dataDocument): Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/documentContract`, dataDocument);
        return res;
    },

    editClause: async(dataClauses, idClause: number): Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/documentContract/clauses/${idClause}`, dataClauses);
        const {data, error, message} = res;
        return { data, error, message };
    },

    deleteClause: async(idClause:number): Promise<any> => {
        const res= await http.delete<any>(`${API_URL_BASE}/v1/documentContract/clauses/${idClause}`)
        return res;
    },
}   

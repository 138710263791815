import { costCentersRepository } from "../repositories/CostCenters.repository";

export const CostCenterService = {
    getCostCenters,
    createCostCenter,
    updateCostCenter,
    deleteCostCenter,
}

async function getCostCenters(){
    const res = await costCentersRepository.getCostCenters();
    return res;
}
async function createCostCenter(data){
    const res = await costCentersRepository.createCostCenter(data);
    return res;
}

async function updateCostCenter( idCostCenter ,data:{}){
    const res = await costCentersRepository.updateCostCenter(idCostCenter, data);
    return res;
}

async function deleteCostCenter( idCostCenter){
    const res = await costCentersRepository.deleteCostCenter(idCostCenter);
    return res;
}

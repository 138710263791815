import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Autocomplete, Button, Divider, Grid, MenuItem, Select, TextField, Typography, Snackbar, Alert } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { PhasesService } from "@/service/services/Phases.service";
import { Loading } from "@/components/common/Loading";
import { AssistanceService } from "@/service/services/Assistance.service";
import { Props } from "./ModalAdvance.type";
import { PeriodService } from "@/service/services/Period.service";
import { AdvanceService } from "@/service/services/Advance.service";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { RleStructureService } from "@/service/services/RleStructure.service";
import { ROLE_ADMIN } from "@/toolbox/defaults/static-roles";

export const ModalAdvance: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, advance, onListAdvances, workerSelected, clearState, setPeriodMonth } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [periods, setPeriods] = useState<any>([]);

    const [years, setYears] = useState<any>([]);
    const [yearSelected, setYearSelected] = useState<any>('');
    const [monthSelected, setMonthSelected] = useState<any>('');

    const [statusEmployee, setStatusEmployee] = useState<any>('');

    const [data, setData] = useState({
        id: advance ? advance.id : '',
        idperiod: advance ? advance.idperiod : '',
        value: advance ? advance.value : '',
    });

    const dataUser = readLocalStorage(KEY_USER_DATA);
    const idUserSession= dataUser?.user?.idemployee;

    const role = dataUser?.user?.role;

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
     })

    useEffect(()=> {
        getPeriodsActually()
        if(!advance){
            setData({
                id: '',
                idperiod: '',
                value: '',
            })
        }
    },[]);

    useEffect(() => {
        if(advance && periods){
            const dataPEriod = setCustomPeriod(advance.periodo, periods);
            if(dataPEriod){
                setYearSelected(dataPEriod.year)
                setMonthSelected(dataPEriod.month)
            }
        }

    },[advance, periods]);

    const getPeriodsActually = async() => {
        setLoading(true);
        const resp:any = await PeriodService.getPeriodsActually();
        const newData = [];
        const arrayYears = [];
        resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
            newData.push({
                id: item.id,
                month: item.month,
                year: item.year,
                alias_name: item.month + '-' +  item.year,
            })
            const found = arrayYears.find(element => element == item.year);
            if(!found) { arrayYears.push(item.year); }
        });
        setYears(arrayYears);
        setPeriods(newData || []);
        setLoading(false);
    }

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.value) errors.value = "valor requerido";
        return errors;
    }

    const acceptAdvance = async(value)  => {
        const data = {
            request_status: value,
        }
        await AdvanceService.acceptAdvance(data, advance.id).then((data) => {
            setPeriodMonth({monthSelected: monthSelected, yearSelected: yearSelected})
            onListAdvances && onListAdvances(workerSelected.idemployee || workerSelected.id);
            closeModal && closeModal();
        })
    }
    useEffect(() => {
        getDetailEmployeeSO(idUserSession);
     },[])
    const getDetailEmployeeSO = async (id_employee) => {
        setLoading(true);
        const resp: any = await RleStructureService.getDetailEmployeeSO(id_employee);
        if (resp.data) {
            const registro_dv4 = resp?.data.filter(code_dv4 => code_dv4.code == 'DV4');
            registro_dv4?.map((item_code) => {
                return setStatusEmployee(item_code.status)
            })
        }
        setLoading(false);
     }


    const onSubmit = async(values, {resetForm})  => {

        const periodSelect = monthSelected + '-' + yearSelected;
        const period = periods.find(element => element.alias_name == periodSelect);

        const formData = {
            id: values.id,
            idperiod : period.id,
            value : values.value,
        }
        setLoading(true);
        if(advance && actionSelected=='edit'){
            await AdvanceService.editAdvance(formData, advance.id).then((data) => {
                onListAdvances && onListAdvances(workerSelected.idemployee || workerSelected.id);
                closeModal && closeModal();
            })
        }else{
            (statusEmployee && statusEmployee=='1')?
            await AdvanceService.createAdvance(formData, workerSelected.idemployee || workerSelected.id).then((data) => {
                onListAdvances && onListAdvances(workerSelected.idemployee || workerSelected.id);
                closeModal && closeModal();
            })
            :
            setSnackBarConfig(prev => ({ ...prev, open: true, severity:'warning', message: 'No tienes permiso para solicitar anticipos, comuníquese con su administrador' }))
            
        }
        setLoading(false);
    }

    function setCustomPeriod(periodo, periods): any {
        const periodoFilter = periodo.replace(/\s+/g, '')
        const data = periods && periods.find(period => period.alias_name == periodoFilter);
        return data;
    }

    return(
        <>
        {loading && <Loading/>}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    severity={snackBarConfig.severity}
                    variant="filled"
                >
                    {snackBarConfig.message}
                </Alert>
            </Snackbar>

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={(advance && actionSelected=='edit') ? "Editar Anticipo" : "Agregar Anticipo"}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >
                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>Período:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="id_education_level"
                                                variant="standard"
                                                value={yearSelected}
                                                onChange={(event) => {
                                                setYearSelected(event.target.value);
                                                }}
                                                fullWidth
                                            >
                                                {
                                                years.map((year, i) => {
                                                    return <MenuItem key={i} value={year}>{year}</MenuItem>;
                                                })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>Mes:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="id_education_level"
                                                variant="standard"
                                                value={monthSelected}
                                                onChange={(event) => {setMonthSelected(event.target.value)}}
                                                fullWidth
                                            >
                                                <MenuItem value={'01'}>Enero</MenuItem>
                                                <MenuItem value={'02'}>Febrero</MenuItem>
                                                <MenuItem value={'03'}>Marzo</MenuItem>
                                                <MenuItem value={'04'}>Abril</MenuItem>
                                                <MenuItem value={'05'}>Mayo</MenuItem>
                                                <MenuItem value={'06'}>Junio</MenuItem>
                                                <MenuItem value={'07'}>Julio</MenuItem>
                                                <MenuItem value={'08'}>Agosto</MenuItem>
                                                <MenuItem value={'09'}>Septiembre</MenuItem>
                                                <MenuItem value={'10'}>Octubre</MenuItem>
                                                <MenuItem value={'11'}>Noviembre</MenuItem>
                                                <MenuItem value={'12'}>Diciembre</MenuItem>
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Valor:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="value"
                                            variant="standard"
                                            type="number" 
                                            name="value"
                                            fullWidth
                                            value={values.value}
                                            onChange={handleChange}
                                            placeholder="valor de anticipo"
                                            error={errors.value && touched.value ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                    
                                    {
                                        (advance && role == ROLE_ADMIN) && (
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                
                                                {
                                                    (advance.request_status == 'aceptada' || advance.request_status == 'rechazada') && (
                                                        <>
                                                        <Grid item xs={4}>
                                                        <Typography variant="subtitle1" className="custom-input"><b>Estado de petición:</b></Typography>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                        <TextField
                                                            id="request_status"
                                                            variant="standard"
                                                            type="text" 
                                                            name="request_status"
                                                            fullWidth
                                                            value={advance.request_status}
                                                            disabled
                                                        />
                                                        </Grid>
                                                        </>
                                                    )
                                                }
                                                {
                                                    advance.request_status == 'pendiente' && (
                                                        <>
                                                        <Grid item xs={5}>
                                                            <Typography variant="subtitle1" className="custom-input"><b>{`Petición "${advance.request_status}": `}</b></Typography>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <Button onClick={()=>acceptAdvance(1)} size="small" color={"success"}variant="contained" style={{marginRight:'3px'}}>
                                                                {'aceptar petición'}
                                                            </Button>
                                                            
                                                            <Button onClick={()=>acceptAdvance(2)}size="small" color={"error"} variant="contained">
                                                                {'rechazar petición'}
                                                            </Button>
                                                        </Grid>
                                                        </>
                                                    )
                                                }
                                                
                                                
                                            </Grid>
                                        )
                                    }
                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(advance && actionSelected=='edit') ? "ACTUALIZAR" : "REGISTRAR"}
                                    cancelText="CANCELAR" 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}
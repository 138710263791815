import { userService } from "@/service/services/User.service";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, Divider, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import { Snackbar, Alert } from '@mui/material';
import { Props } from "./ModalUpdatePassword.type";
import { Loading } from "@/components/common/Loading";
import { ModalHeader } from "../ModalHeader";
import { ModalBody } from "../ModalBody";
import { Modal } from "../Modal";

const CssTextField = withStyles({
root: {
    '& .MuiInput-underline:after': {
    // borderBottomColor: pColor,
    },
    '& .MuiOutlinedInput-root': {
    '& fieldset': {
        borderColor: '#e3e1e1',
    },
    '&:hover fieldset': {
        borderColor: '#e3e1e1',
    },
    '&.Mui-focused fieldset': {
        borderColor: '#fafafa',
    },
    },
},
})(TextField);

export const ModalUpdatePassword: React.FC<Props> = (props:Props):JSX.Element => {

    const { closeModal, handleSessionClose } = props;

    const [loading, setLoading] = React.useState<boolean>(false);

    const dataUser = readLocalStorage(KEY_USER_DATA);
    const user = dataUser?.user || {};

    const [oldPassword, setOldPassword] = React.useState("");
    const [newPassword, setNewPassword] = React.useState("");
    const [verifyPassword, setVerifyPassword] = React.useState("");
    const [errors, setErrors] = React.useState({});

    const [showPassword, setShowPassword] = React.useState(false);
    const [showNewPassword, setShowNewPassword] = React.useState(false);
    const [showVerifyPassword, setShowVerifyPassword] = React.useState(false);

    const [snackBarConfig, setSnackBarConfig] = React.useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const onChangeOldPassword = (e) => {
        const oldPassword = e.target.value;
        setOldPassword(oldPassword);
    };

    const onChangeNewPassword = (e) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);
    };

    const onChangeVerifyPassword = (e) => {
    const verifyPassword = e.target.value;
    setVerifyPassword(verifyPassword);
    };

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        //Password
        if (!oldPassword) {
          formIsValid = false;
          errors["oldPassword"] = "Contraseña requerida";
        }

        //new Password
        if (!newPassword) {
          formIsValid = false;
          errors["newPassword"] = "Contraseña requerida";
        }

        if (!verifyPassword) {
          formIsValid = false;
          errors["verifyPassword"] = "Contraseña requerida";
        }
        if (newPassword !== verifyPassword) {
          formIsValid = false;
          errors["verifyPassword"] = "Las contraseñas no coinciden";
        }
        setErrors(errors);
        return formIsValid;
    }

    const body = {
        password: oldPassword,
        new_password: newPassword,
        iduser: user?.iduser,
    }

    const clearModal = () => {
        setOldPassword('');
        setNewPassword('');
        setVerifyPassword('');
        setShowPassword(false);
        setShowNewPassword(false);
        setShowVerifyPassword(false);
        closeModal();
    }

    const handleLogin = async (e) => {
        e.preventDefault();

        if (handleValidation()) {
          setLoading(true);
            await userService.updatePassword(body).then((data) => {
                console.log(data.data);
                if(data?.data?.status == true){
                  clearModal();
                  closeModal && closeModal();
                  //   handleSessionClose && handleSessionClose();
                  return (
                    setSnackBarConfig(prev => ({
                        ...prev,
                        open: true,
                        severity: 'success',
                        message: `${data?.data?.message}`,
                      }))
                  )
                }else{
                    return (
                        setSnackBarConfig(prev => ({
                            ...prev,
                            open: true,
                            severity: 'error',
                            message: `${data?.data?.message || 'Ocurrio un error, vuelve a intentarlo'}`,
                        }))
                    )
                }
            })
        }
        setLoading(false);
    }

    return (
      <>
          {loading && <Loading/>}
            <Snackbar
                open={snackBarConfig.open} 
                autoHideDuration={snackBarConfig.autoHideDuration} 
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>

            <Modal open={open} handleClose={closeModal} handle size="sm">

                    <ModalHeader
                        text={"Cambiar Contraseña"}
                        className='positionElements'
                        onCancel={closeModal}
                    >
                    </ModalHeader>

                    <ModalBody>

                        <Grid container>

                        <Grid item xs={12} style={{ margin: '15px 0px' }}>
                            <Box textAlign="center" style={{ marginTop: '5px' }} className={'input-password-content'}>
                            <CssTextField
                                style={{ width: '350px' }}
                                size='small'
                                id="oldPassword"
                                placeholder={"Ingrese actual contraseña"}
                                value={oldPassword}
                                required
                                fullWidth
                                autoComplete="new-password"
                                type={showPassword ? "text" : "password"}
                                onChange={onChangeOldPassword}
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => { setShowPassword(!showPassword) }}
                                        edge="end"
                                        style={{ color: 'rgb(76, 148, 182)' }}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                    </InputAdornment>
                                )
                                }}
                            />
                            </Box>
                            <span style={{
                            color: "red", display: 'flex', flexDirection: 'column',
                            alignItems: 'center'
                            }}>{errors["oldPassword"]}</span>
                        </Grid>

                        <Grid item xs={12} style={{ margin: '15px 0px' }}>
                            <Box textAlign="center" style={{ marginTop: '5px' }} className={'input-password-content'}>
                            <CssTextField
                                style={{ width: '350px' }}
                                size='small'
                                id="newPassword"
                                placeholder={"Ingrese nueva contraseña"}
                                value={newPassword}
                                required
                                fullWidth
                                autoComplete="new-password"
                                type={showNewPassword ? "text" : "password"}
                                onChange={onChangeNewPassword}
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => { setShowNewPassword(!showNewPassword) }}
                                        edge="end"
                                        style={{ color: 'rgb(76, 148, 182)' }}
                                    >
                                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                    </InputAdornment>
                                )
                                }}
                            />
                            </Box>
                            <span style={{
                            color: "red", display: 'flex', flexDirection: 'column',
                            alignItems: 'center'
                            }}>{errors["newPassword"]}</span>
                        </Grid>

                        <Grid item xs={12} style={{ margin: '15px 0px' }}>
                            <Box textAlign="center" style={{ marginTop: '5px' }} className={'input-password-content'}>
                            <CssTextField
                                style={{ width: '350px' }}
                                size='small'
                                id="verifyNewPassword"
                                placeholder={"Vuelva a ingresar nueva contraseña"}
                                value={verifyPassword}
                                required
                                fullWidth
                                autoComplete="new-password"
                                type={showVerifyPassword ? "text" : "password"}
                                onChange={onChangeVerifyPassword}
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => { setShowVerifyPassword(!showVerifyPassword) }}
                                        edge="end"
                                        style={{ color: 'rgb(76, 148, 182)' }}
                                    >
                                        {showVerifyPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                    </InputAdornment>
                                )
                                }}
                            />
                            </Box>
                            <span style={{
                            color: "red", display: 'flex', flexDirection: 'column',
                            alignItems: 'center'
                            }}>{errors["verifyPassword"]}</span>
                        </Grid>

                        <div style={{borderTop: "1px solid #e3e1e1", width: "100%", paddingTop:'15px', justifyContent:'end', textAlign:'end'}}>
                            <Button onClick={(e) => {handleLogin(e)}} size="small" color={"primary"} variant="contained">
                            Cambiar
                            </Button>
                        </div>
                    </Grid>
                    </ModalBody>
            </Modal>

          
        </>
    );
}

import { useEffect, useState } from "react"
import { TableDataV2 } from "@/components/common/Tablev2"
import { Protected } from "@/components/layout/Protected"
import { Alert, Box, Button, Card, Container, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { PhasesService } from "@/service/services/Phases.service"
import { Loading } from "@/components/common/Loading"
import { ModalPhases } from "@/components/Modal/ModalPhases"
import { ModalPhaseDelete } from "@/components/Modal/ModalPhaseDelete"
import { Props } from "./AssistanceWorker.type"
import moment from "moment"
import { AssistsWorkerService } from "@/service/services/AssistanceWorker.service"
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper"
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage"

export const AssistanceWorkerView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [assistances, setAssistances] = useState([]);
    const [assistanceSelected, setAssistanceSelected] = useState<any>('');
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const dataUser = readLocalStorage(KEY_USER_DATA);
    const idEmployee = dataUser?.user?.idemployee;

    const previousDate = moment().subtract(7, 'days').format("YYYY-MM-DD");
    const nexDate = moment().add(1, 'days').format("YYYY-MM-DD");

    useEffect(() => {
        getAssitsCalendar();
    }, [idEmployee, previousDate, nexDate]);

    const getAssitsCalendar = async() => {
        setLoading(true);
        await AssistsWorkerService.getAssitsCalendar(idEmployee, previousDate, nexDate).then((data) => {
            setAssistances(data.data || []);
            setLoading(false);
        })
    }

    const createAssists = async() => {
        setLoading(true);

        const currentHour = moment().format("HH:mm");
        const currentDate = moment().format("YYYY-MM-DD");

        const dataAssists = {
            idemployee: idEmployee,
            date: currentDate,
            start_time: currentHour,
        }

        await AssistsWorkerService.createAssists(dataAssists).then((data) => {
            if(data.data.status == true){
                getAssitsCalendar();
                setLoading(false);
                return (
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'success', 
                        message: `${data.data.message}` }
                    ))
                );
            }else{
                setLoading(false);
                return (
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'error', 
                        message: `${data.data.message || 'Ocurrió algún error.'}` }
                    ))
                );
            }
        })
    }

    const updateAssists = async(item) => {

        setLoading(true);
        
        const currentHour = moment().format("HH:mm");

        const dataAssists = {
            idemployee: idEmployee,
            start_refreshment: ((item.start_time != null ) && 
                                (item.start_refreshment == null || item.start_refreshment == "")) ? currentHour : item.start_refreshment,

            end_refreshment: ((item.start_refreshment != null) && 
                                (item.end_refreshment == null)) ? currentHour : item.end_refreshment,

            end_time: ((item.end_refreshment != null) && 
                        (item.end_time == null)) ? currentHour : item.end_time,
        }

        await AssistsWorkerService.updateAssists(item.id, dataAssists).then((data) => {
            if(data.data.status == true){
                getAssitsCalendar();
                setLoading(false);
                return (
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'success', 
                        message: `${data.data.message}` }
                    ))
                );
            }else{
                setLoading(false);
                return (
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'error', 
                        message: `${data.data.message || 'Ocurrió algún error.'}` }
                    ))
                );
            }
            
        })
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Snackbar
                open={snackBarConfig.open} 
                autoHideDuration={snackBarConfig.autoHideDuration} 
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>
            <Container>
            <Box
               sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  m: -1,
                  py: 3
               }}
            >
               <Typography variant='h6' component='h1' gutterBottom className="tittle" sx={{ m: 1 , fontWeight:600}}
               >
                  CONTROL DE ASISTENCIAS
               </Typography>
               <Box sx={{ m: 1 }}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={createAssists}
                    >
                    Marcar Ingreso
                    </Button>
               </Box>
            </Box>
            <Card sx={{width:"100%"}}>
            <TableContainer>
                <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align={'center'} sx={{ bgcolor: '#F4F6F8' }} width={'230px'}>
                            <Typography sx={{fontFamily:'"Roboto","Helvetica","Arial",sans-serif !important',fontWeight:500}}>
                                Fecha
                            </Typography>
                        </TableCell> 
                        <TableCell align={'center'} sx={{ bgcolor: '#F4F6F8' }} width={'230px'}>
                            <Typography sx={{fontFamily:'"Roboto","Helvetica","Arial",sans-serif !important',fontWeight:500}}>
                                Hora Ingreso
                            </Typography>
                        </TableCell> 
                        <TableCell align={'center'} sx={{ bgcolor: '#F4F6F8' }} width={'230px'}>
                            <Typography sx={{fontFamily:'"Roboto","Helvetica","Arial",sans-serif !important',fontWeight:500}}>
                                Inicio Refrigerio
                            </Typography>
                        </TableCell> 
                        <TableCell align={'center'} sx={{ bgcolor: '#F4F6F8' }} width={'230px'}>
                            <Typography sx={{fontFamily:'"Roboto","Helvetica","Arial",sans-serif !important',fontWeight:500}}>
                                Fin Refrigerio
                            </Typography>
                        </TableCell> 
                        <TableCell align={'center'} sx={{ bgcolor: '#F4F6F8' }} width={'230px'}>
                            <Typography sx={{fontFamily:'"Roboto","Helvetica","Arial",sans-serif !important',fontWeight:500}}>
                                Hora Salida
                            </Typography>
                        </TableCell> 
                        <TableCell align={'center'} sx={{ bgcolor: '#F4F6F8' }} width={'230px'}>
                            <Typography sx={{fontFamily:'"Roboto","Helvetica","Arial",sans-serif !important',fontWeight:500}}>
                                Horas Extras (m)
                            </Typography>
                        </TableCell> 
                        <TableCell align={'center'} sx={{ bgcolor: '#F4F6F8' }} width={'230px'}>
                            <Typography sx={{fontFamily:'"Roboto","Helvetica","Arial",sans-serif !important',fontWeight:500}}>
                                Tardanzas (m)
                            </Typography>
                        </TableCell> 
                        <TableCell align={'center'} sx={{ bgcolor: '#F4F6F8' }} width={'230px'}>
                            <Typography sx={{fontFamily:'"Roboto","Helvetica","Arial",sans-serif !important',fontWeight:500}}>
                                Acciones
                            </Typography>
                        </TableCell> 
                    </TableRow>
                </TableHead>
                <TableBody>
                        {
                            assistances.length > 0 && assistances.map((item)=>(
                            <TableRow key={item.id} >
                                <TableCell align={'center'} >
                                    <Typography sx={{ fontFamily: '"Public Sans", sans-serif !important' }} variant='inherit' fontWeight={500}>
                                        {item.date || '---' }
                                    </Typography>
                                </TableCell> 
                                <TableCell align={'center'} >
                                    <Typography sx={{ fontFamily: '"Public Sans", sans-serif !important' }} variant='inherit' fontWeight={500}>
                                        {item.start_time || '---' }
                                    </Typography>
                                </TableCell> 
                                <TableCell align={'center'} >
                                    <Typography sx={{ fontFamily: '"Public Sans", sans-serif !important' }} variant='inherit' fontWeight={500}>
                                        {item.start_refreshment || '---' }
                                    </Typography>
                                </TableCell> 
                                <TableCell align={'center'} >
                                    <Typography sx={{ fontFamily: '"Public Sans", sans-serif !important' }} variant='inherit' fontWeight={500}>
                                        {item.end_refreshment || '---' }
                                    </Typography>
                                </TableCell> 
                                <TableCell align={'center'} >
                                    <Typography sx={{ fontFamily: '"Public Sans", sans-serif !important' }} variant='inherit' fontWeight={500}>
                                        {item.end_time || '---' }
                                    </Typography>
                                </TableCell> 
                                <TableCell align={'center'} >
                                    <Typography sx={{ fontFamily: '"Public Sans", sans-serif !important' }} variant='inherit' fontWeight={500}>
                                        {item.extra_hours || '---' }
                                    </Typography>
                                </TableCell> 
                                <TableCell align={'center'} >
                                    <Typography sx={{ fontFamily: '"Public Sans", sans-serif !important' }} variant='inherit' fontWeight={500}>
                                        {item.delay || '---' }
                                    </Typography>
                                </TableCell> 
                                <TableCell align={'center'} >
                                <Button onClick={()=>{updateAssists(item)}} disabled={item.end_time ? true : false}>
                                    Marcar
                                </Button>
                                </TableCell> 
                            </TableRow>
                            ))
                        }
                </TableBody>
                </Table>
            </TableContainer>
            </Card>
            </Container>
        </Protected>
    )
}
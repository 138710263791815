import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { PhasesService } from "@/service/services/Phases.service";
import { Loading } from "@/components/common/Loading";
import { AssistanceService } from "@/service/services/Assistance.service";
import { Props } from "./ModalMovements.type";
import { MovementsService } from "@/service/services/Movements.service";

export const ModalMovements: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, movement, onListMovements, workerSelected, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);

    const [movTypes, SetMovTypes] = useState<any>([]);
    const [movTypeSelected, SetMovTypeSelected] = useState<any>('');
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [data, setData] = useState({
        id: movement ? movement.id : '',
        idemployee: movement ? movement.idemployee : '',
        idmovstaff: movement ? movement.idmovstaff : '',
        date_from: movement ? movement.date_from : '',
        date_until: movement ? movement.date_until : '',
        observation: movement ? movement.observation : '',
    });

    useEffect(() => {
        getMovementsTypes();
    }, []);

    useEffect(() => {
        if(movement && movTypes){
            setCustomMovementType(movement.idmovstaff, movTypes);
        }

    },[movement, movTypes]);

    const getMovementsTypes = async () => {
        setLoading(true);
        await MovementsService.getMovementsTypes().then((data) => {
            SetMovTypes(data.data || [])
           setLoading(false);
        });
    }

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.date_from) errors.date_from = "total tardanzas requerido";
        if(!values.date_until) errors.date_until = "total tardanzas requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {
        setLoading(true);
        if(!movTypeSelected){
            setLoading(false);
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: `Debe seleccionar un tipo de movimiento` }
                ))
            );
        }
        const formData = {
            id: values.id,
            idemployee: workerSelected.id,
            idmovstaff: movTypeSelected.id,
            date_from : values.date_from,
            date_until : values.date_until,
            observation : values.observation,
        }
        await MovementsService.createMovement(formData).then((data) => {
            onListMovements && onListMovements(workerSelected.id);
            closeModal && closeModal();
        })
        setLoading(false);
    }

    function setCustomMovementType(idMovType, movTypes): any {
        const data = movTypes && movTypes.find(movType => movType.id === idMovType);
        const newData = {
            id: data?.id,
            code: data?.code,
            detail: data?.detail
        }
        return SetMovTypeSelected(newData);
    }

    return(
        <>
        {loading && <Loading/>}
        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={(movement && actionSelected=='edit') ? "Editar Movimiento" : "Agregar Movimiento"}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>Tipo de movimiento:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                id="disable-portal"
                                                disablePortal
                                                value={movTypeSelected}
                                                options={movTypes || []}
                                                getOptionLabel={(option) => option.detail || ""}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} variant="standard" label="Escriba para buscar" />}
                                                onChange={(e, newValue) => { SetMovTypeSelected(newValue) }}
                                                disabled={actionSelected == 'detail' ? true : false}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Fecha Inicio:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="date_from"
                                            variant="standard"
                                            fullWidth
                                            name="date_from"
                                            type="date"
                                            onChange={handleChange}
                                            value={values.date_from}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={errors.date_from && touched.date_from ? true : false}
                                            disabled={actionSelected == 'detail' ? true : false}
                                        />
                                        {/* <TextField
                                            id="date_from"
                                            variant="standard"
                                            type="month" 
                                            name="date_from"
                                            fullWidth
                                            value={values.date_from}
                                            onChange={handleChange}
                                            placeholder="fecha inicio"
                                            error={errors.date_from && touched.date_from ? true : false}
                                        /> */}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Fecha Fin:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="date_until"
                                            variant="standard"
                                            fullWidth
                                            name="date_until"
                                            type="date"
                                            onChange={handleChange}
                                            value={values.date_until}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={errors.date_until && touched.date_until ? true : false}
                                            disabled={actionSelected == 'detail' ? true : false}
                                        />
                                        {/* <TextField
                                            id="date_until"
                                            variant="standard"
                                            type="month" 
                                            name="date_until"
                                            fullWidth
                                            value={values.date_until}
                                            onChange={handleChange}
                                            placeholder="fecha fin"
                                            error={errors.date_until && touched.date_until ? true : false}
                                        /> */}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Observación:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="observation"
                                            variant="standard"
                                            type="text" 
                                            name="observation"
                                            multiline
                                            maxRows={4}
                                            fullWidth
                                            value={values.observation}
                                            onChange={handleChange}
                                            placeholder="observacion"
                                            error={errors.observation && touched.observation ? true : false}
                                            disabled={actionSelected == 'detail' ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(movement && actionSelected=='edit') ? "ACTUALIZAR" : "REGISTRAR"}
                                    cancelText="CANCELAR" 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}
import { CountryService } from "@/service/services/Country.service";
import { Typography } from "@mui/material";
import { useState } from "react";
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { ModalHeader } from "../ModalHeader";
import { Props } from "./ModalCountryProvinceDelete.type";

export const ModalCountryProvinceDelete: React.FC<Props> = (props:any): JSX.Element => {
    const { open, closeModal, province, onListProvince, clearState, departamentSelected } = props;
    const [loading, setLoading] = useState<boolean>(false);

    const onDeleted= async()=>{
        setLoading(true);
        await CountryService.deleteProvince(province.id).then((data) => {
            onListProvince && onListProvince(departamentSelected.id);
            closeModal && closeModal();
        })
        setLoading(false);
    }
  
  return (
    <>
    <Modal
      open={open}
      handleClose={closeModal}
      size="xs"
    >
      <ModalHeader
        text="ELIMINAR PROVINCIA"
        className='positionElements'
        onCancel={closeModal}
        clearState={clearState}
      >
      </ModalHeader>

      <ModalBody>
        <Typography variant="subtitle1" className="textColor"> 
          ¿Está seguro de eliminar esta Provincia?
        </Typography>
      </ModalBody>

      <ModalFooter 
        confirmText="Eliminar"
        cancelText="CANCELAR"
        onCancel={closeModal}
        className="footerRight"
        onConfirm={onDeleted}
        clearState={clearState}
      />
    </Modal>
    </>
  )
}
import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from '@/components/common/Loading';
import { Alert, Autocomplete, Backdrop, Button, Card, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { ReportService } from '@/service/services/Report.service';
import { WorkerService } from '@/service/services/Worker.service';
import { API_URL_BASE } from '@/toolbox/defaults/app';


export const DelayAbsenceExtraHourView: React.FC<any> = (props: any): JSX.Element => {
   
    const [data, setData] = useState<any>('')
    // console.log(data);
    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [workerOptions, setWorkerOptions] = useState<any>([]);
    const [workerSelected, setWorkerSelected] = useState<any>(null)
    const [startDateSelected, setStartDateSelected] = useState<any>('')
    const [endDateSelected, setEndDateSelected] = useState<any>('')
    const [excelDissable, setExcelDissable]= useState<any>(false);
    useEffect(() => {
        getEmployees();
    }, []);

    const getEmployees = async  () => {
        const resp = await WorkerService.getWorkers();
        setWorkerOptions(resp?.data || [])
    }

    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        console.log(e)
        setStartDateSelected(value)
    }

    const getReportAbsenceDelay = async () => {
        setLoading(true);
        if(!startDateSelected || !endDateSelected || !workerSelected){
            setLoading(false);
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: `Debe completar todos los campos para realizar el filtrado` }
                ))
            );
        }

        await ReportService.getReportAbsenceDelay(startDateSelected, endDateSelected, workerSelected?.id).then((data) => {
            // console.log(data);
            setData(data.data || []);
            setLoading(false);
            setExcelDissable(true);
        })
    }
    const getReportExcelAbsencePDF = async (typeDownload) => {
        setLoading(true);
        if(!startDateSelected || !endDateSelected || !workerSelected){
            setLoading(false);
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: `Debe completar todos los campos para realizar el filtrado` }
                ))
            );
        }

        if (typeDownload == 'excel') {
            
            const respExcel = await ReportService.getReportExcelAbsence(startDateSelected, endDateSelected, workerSelected?.id);
            const btn_excel = document.createElement("a")
            // btn_excel.href = `${respExcel.data.link}`
            btn_excel.href = `${API_URL_BASE}${respExcel.data.link}`
            btn_excel.download = 'AbsenceDelayExport.xlsx'

            document.body.appendChild(btn_excel);
            btn_excel.click();
            document.body.removeChild(btn_excel)
        }else{
            const respCSV = await ReportService.getReportExcelAbsence(startDateSelected, endDateSelected, workerSelected?.id);
            const btn_csv = document.createElement("a")
            btn_csv.href = `${API_URL_BASE}${respCSV.data.link}`
            
            btn_csv.download = 'AbsenceDelayExport.csv'

            document.body.appendChild(btn_csv);
            btn_csv.click();
            document.body.removeChild(btn_csv)
    
        }
        setLoading(false);
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Snackbar
                open={snackBarConfig.open} 
                autoHideDuration={snackBarConfig.autoHideDuration} 
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>
            <Container>
                <Typography variant='h6' component='h1' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                    REPORTE DE TARDANZAS, INASISTENCIAS Y HORAS EXTRAS
                </Typography>

                <Grid container spacing={3} sx={{marginTop: '10px', marginBottom: '20px'}}>
                    
                    <Grid item xs={12} md={12} display='flex' justifyContent='space-between'>
                        <Autocomplete
                            id="disable-portal"
                            disablePortal
                            value={workerSelected}
                            options={workerOptions || []}
                            getOptionLabel={(option) => option.name+' '+option.surname || ""}
                            onChange={(e, newValue) => { setWorkerSelected(newValue) }}
                            sx={{ width: '250px' }}
                            renderInput={(params) => <TextField {...params} variant="standard" label="Seleccione un trabajador" />}
                        />
                        <TextField
                            id="start_date"
                            variant="standard"
                            name="start_date"
                            sx={{ width: '200px' }}
                            label="desde"
                            type="date"
                            value={startDateSelected}
                            onChange={(e)=>{setStartDateSelected(e.target.value)}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            id="end_date"
                            variant="standard"
                            sx={{ width: '200px' }}
                            name="end_date"
                            label="hasta"
                            type="date"
                            value={endDateSelected}
                            onChange={(e)=>{setEndDateSelected(e.target.value)}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                        <Button
                            color="primary"
                            variant="contained"
                            onClick={getReportAbsenceDelay}
                        >
                        Filtrar
                        </Button>
                    </Grid>
                    
                </Grid>
                {excelDissable &&
                    <Grid container spacing={3} sx={{ marginTop: '10px', marginBottom: '20px' }}>
                        <Grid item xs={12} md={12} display='flex' justifyContent='flex-end'>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => getReportExcelAbsencePDF('excel')}
                            >
                                Descargar excel
                            </Button>
                        </Grid>
                    </Grid>
                }
                
                <Card sx={{ width: "100%", marginTop: '20px' }}>
                    <TableContainer>
                    <Table>
                        <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                            <TableRow >
                                <TableCell >
                                    <Typography>Empleado</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography>Tardanzas</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography>Fechas Inasistencias</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography>Total Inasistencias</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography>Horas Extras</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow >
                                {
                                    data && (<>
                                        <TableCell size={'small'} >
                                            {data.employee}
                                        </TableCell>
                                        <TableCell size={'small'} >
                                            {data.delay}
                                        </TableCell>
                                        <TableCell size={'small'} >
                                            {
                                               data.missing_date?.length > 0 && data.missing_date.map((date, key)=>(
                                                <>
                                                    <div key={key}>{date}</div>
                                                </>
                                               )) || '---'
                                            }
                                        </TableCell>
                                        <TableCell size={'small'} >
                                            {data.absence}
                                        </TableCell>
                                        <TableCell size={'small'} >
                                            {data.extra_hour}
                                        </TableCell>
                                    </>)
                                }
                            </TableRow >
                        </TableBody>
                    </Table>
                    </TableContainer>
                </Card>
            </Container>
        </Protected>
    )
};
import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';

export const BusinessAreaRepository = {

    getBusinessArea: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/businessArea`);
        const {data, error, message} = res;
        return { data, error, message };
    },
} 
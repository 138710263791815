import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Props } from "./ModalCharges.type";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Autocomplete, Divider, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { ChargeService } from "@/service/services/Charge.service";
import { Loading } from "@/components/common/Loading";
import { BusinessAreaService } from "@/service/services/BusinessArea.service";

export const ModalCharges: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, charge, onListCharges, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);

    //bussinesArea
    const [businessArea, setBusinessArea] = useState<any>([]);

    const [data, setData] = useState({
        id: charge ? charge.id : '',
        name: charge ? charge.name : '',
        charge_description: charge ? charge.charge_description : '',
        // production: charge ? charge.production : '',
        idbussinesarea: charge ? charge.idbussinesarea : '',
        // id_company: charge ? charge.id_company : '',
        status: charge ? charge.status : '',
    });


    const getBusinessArea = async () => {
        setLoading(true);
        await BusinessAreaService.getBusinessArea().then((data) => {
            setBusinessArea(data.data || [])
            setLoading(false);
        });
    }


    const validateForm = (values) => {
        let errors: any = {};
        if(!values.name) errors.name = "nombre requerido";
        if(!values.charge_description) errors.charge_description = "descripción requerido";
        // if(!values.production) errors.production = "produccion requerido";
        if(!values.idbussinesarea) errors.idbussinesarea = "negocio requerido";
        // if(!values.id_company) errors.id_company = "compañía requerido";
        if(!values.status) errors.status = "estado requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            id: values.id,
            name: values.name,
            charge_description : values.charge_description,
            // production: values.production,
            idbussinesarea : values.idbussinesarea,
            // id_company: values.id_company,
            status : values.status
        }
        setLoading(true);
        if(charge && actionSelected=='edit'){
            // await ChargeService.edit(formData).then((data) => {
            //     onListCharges && onListCharges();
            //     closeModal && closeModal();
            // })
            console.log('falta editar');
        }else{
            await ChargeService.createCharge(formData).then((data) => {
                onListCharges && onListCharges();
                closeModal && closeModal();
            })
        }
        setLoading(false);
    }
    useEffect(() => {
        getBusinessArea();
    }, []);
    return(
        <>
        {loading && <Loading/>}

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={(charge && actionSelected=='edit') ? "Editar cargo" : "Agregar cargo"}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Nombre:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="name"
                                            variant="standard"
                                            type="text" 
                                            name="name"
                                            fullWidth
                                            value={values.name}
                                            onChange={handleChange}
                                            placeholder="Nombre"
                                            error={errors.name && touched.name ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Descripción:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="charge_description"
                                            variant="standard"
                                            type="text" 
                                            name="charge_description"
                                            multiline
                                            maxRows={4}
                                            fullWidth
                                            value={values.charge_description}
                                            onChange={handleChange}
                                            placeholder="Descripcion"
                                            error={errors.charge_description && touched.charge_description ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1"><b>Área de Negocio:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="idbussinesarea"
                                                variant="standard"
                                                value={values.idbussinesarea}
                                                onChange={(event) => {
                                                    setFieldValue("idbussinesarea", event.target.value);
                                                }}
                                                error={errors.idbussinesarea && touched.idbussinesarea ? true : false}
                                                fullWidth
                                            >
                                                {
                                                    businessArea.map((busArea, i) => {
                                                        // var formatRole = {...role, name: getCustomRoleName(role.name)};
                                                        return <MenuItem key={i} value={busArea.id}>{busArea.name}</MenuItem>;
                                                    })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >                
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1"><b>Estado:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="status"
                                                variant="standard"
                                                value={values.status}
                                                onChange={(event) => {
                                                    setFieldValue("status", event.target.value)
                                                }}
                                                fullWidth
                                            >
                                                <MenuItem value="1">Activo</MenuItem>
                                                <MenuItem value="2">No activo</MenuItem>
                                            </Select>
                                        </Grid> 
                                    </Grid>
                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(charge && actionSelected=='edit') ? "ACTUALIZAR" : "REGISTRAR"}
                                    cancelText="CANCELAR" 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}
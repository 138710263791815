import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Button, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { PhasesService } from "@/service/services/Phases.service";
import { Loading } from "@/components/common/Loading";
import { AssistanceService } from "@/service/services/Assistance.service";
import { Props } from "./ModalExpenseEmployeeDetail.type";
import { EmployeeExpenseService } from "@/service/services/EmployeeExpense.service";
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { ModalExpenseConfirm } from "../ModalExpenseConfirm";
import { ROLE_SUPER_ADMIN } from "@/toolbox/constants/role-type";
import { ROLE_ADMIN } from "@/toolbox/defaults/static-roles";

export const ModalExpenseEmployeeDetail: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, detail, onListDetails, expenseSelected, clearState } = props;

    const dataUser = readLocalStorage(KEY_USER_DATA);
    const role = dataUser?.user?.role;

    const [loading, setLoading] = useState<boolean>(false);
    const [expenseReportType, setExpenseReportType] = useState<any>([]);
    const [currentCv, setCurrentCv] = useState<any>(null); 
    const [reviewStatus, setReviewStatus] = useState<any>(''); 
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
    const [coverageCompany, setCoverageCompany] = useState<boolean>(false);

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [data, setData] = useState<any>({
        id: detail ? detail.id : '',
        idexpense_report_type: detail ? detail.idexpense_report_type : '',
        detail: detail ? detail.detail : '',
        amount: detail ? detail.amount : '',
        coverage_company: detail ? detail.coverage_company : 100,
        observation: detail ? detail.observation : '',
        review_status: detail ? detail.review_status : '',
        status: detail ? detail.status : '',
        doc: detail ? detail.doc : '',
    });

    useEffect(() => {
        // if(detail){
            listReportType();
        // }
    }, [detail]);

    const listReportType = async () => {
        setLoading(true);
        await EmployeeExpenseService.listReportType().then((data) => {
            setExpenseReportType(data.data || [])
           setLoading(false);
        });
    }
    const setNameFile = (event) => {
        const files = event.target && event.target.files || [];
        const currentCv = files[0];
        
        if(currentCv){
            setCurrentCv(currentCv);

            const nameAsArray = currentCv.name.split(".") || [];
            const nameWithoutExtension = nameAsArray.filter((word, i) => i != nameAsArray.length-1);
            const nameFile = nameWithoutExtension.join(".") || "";
            return currentCv;
        }
        return "";
    };

    const showDocument = async() => {
        setLoading(true);

        if(detail && detail.doc){
            const route = `${API_URL_BASE}/${detail.doc}`;
            window.open(route, '_blank');
        }

        setLoading(false);
    }

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.idexpense_report_type) errors.idexpense_report_type = "tipo de reporte requerido";
        if(!values.detail) errors.detail = "detalle requerido";
        if(!values.amount) errors.amount = "monto requerido";
        if(role == ROLE_ADMIN && !values.observation) errors.observation = "observacion requerido";
        if(role == 'TRABAJADOR' && !values.status && detail) errors.status = "estado requerido";
        if(role == ROLE_ADMIN && !values.coverage_company) errors.coverage_company = "procentaje requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        if(!detail && !currentCv){
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: `Seleccione un archivo` }
                ))
            );
        }

        const formData = new FormData();
        formData.append("id", values.id);
        formData.append("idexpense_report_type", values.idexpense_report_type);
        formData.append("detail", values.detail);
        formData.append("amount", values.amount);
        formData.append("coverage_company", values.coverage_company);

        // if(role == ROLE_ADMIN) formData.append("coverage_company", values.coverage_company);
        // if(role == 'TRABAJADOR') formData.append("coverage_company", '100');

        if(role == ROLE_ADMIN)  formData.append("observation", values.observation);
        if(role == 'TRABAJADOR')  formData.append("observation", '');

        if(role == ROLE_ADMIN) formData.append("status", detail?.status || '1');
        if(role == 'TRABAJADOR') formData.append("status", values.status || '1');

        if(role == ROLE_ADMIN && reviewStatus=='aceptar') formData.append("review_status", '1');
        if(role == ROLE_ADMIN && reviewStatus=='rechazar') formData.append("review_status", '2');
        if(role == 'TRABAJADOR') formData.append("review_status", '0');

        if(currentCv) formData.append("doc", values.doc);


        setLoading(true);
        

        if(detail && actionSelected=='edit'){
            await EmployeeExpenseService.editReportDetail(detail.id, formData).then((data) => {
                if(data?.data){
                    if(reviewStatus == 'aceptar'){
                        setShowModalConfirm(true);
                        setCoverageCompany(values.coverage_company);
                    }else{
                        closeModal && closeModal();
                        onListDetails && onListDetails(expenseSelected.id);
                    }
                }
                // onListDetails && onListDetails(expenseSelected.id);
            })
        }else{
            await EmployeeExpenseService.createDetailExpenseEmployee(expenseSelected.id, formData).then((data) => {
                onListDetails && onListDetails(expenseSelected.id);
                closeModal && closeModal();
            })
        }

        setLoading(false);
    }

    return(
        <>
        {loading && <Loading/>}

        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={role == ROLE_ADMIN ? "Aceptar o Rechazar Solicitud" : (detail ? "Editar Detalle" : "Agregar Detalle")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    <Grid item xs={12} container alignItems="center" justifyContent="center"  marginTop='25px' marginBottom='25px' >                
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1"><b>Tipo de reporte:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="idexpense_report_type"
                                                variant="standard"
                                                value={values.idexpense_report_type}
                                                onChange={(event) => {
                                                    setFieldValue("idexpense_report_type", event.target.value)
                                                }}
                                                fullWidth
                                                error={errors.idexpense_report_type && touched.idexpense_report_type ? true : false}
                                            >
                                                {
                                                    expenseReportType.length > 0 && expenseReportType.map((type, i) => {
                                                        return <MenuItem key={i} value={type.id}>{type.name}</MenuItem>;
                                                    })
                                                }
                                            </Select>
                                        </Grid> 
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Detalle:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="detail"
                                            variant="standard"
                                            type="text" 
                                            name="detail"
                                            multiline
                                            maxRows={4}
                                            fullWidth
                                            value={values.detail}
                                            onChange={handleChange}
                                            placeholder="detalle"
                                            error={errors.detail && touched.detail ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Monto:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="amount"
                                            variant="standard"
                                            type="number" 
                                            name="amount"
                                            fullWidth
                                            value={values.amount}
                                            onChange={handleChange}
                                            placeholder="monto"
                                            error={errors.amount && touched.amount ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    {
                                        role == ROLE_ADMIN && (
                                            <>
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>Cobertura Empresa (%):</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                <TextField
                                                    id="coverage_company"
                                                    variant="standard"
                                                    type="number" 
                                                    name="coverage_company"
                                                    fullWidth
                                                    value={values.coverage_company}
                                                    onChange={handleChange}
                                                    placeholder="cobertura empresa"
                                                    error={errors.coverage_company && touched.coverage_company ? true : false}
                                                />
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>Observación:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                <TextField
                                                    id="observation"
                                                    variant="standard"
                                                    type="text" 
                                                    name="observation"
                                                    multiline
                                                    maxRows={4}
                                                    fullWidth
                                                    value={values.observation}
                                                    onChange={handleChange}
                                                    placeholder="observacion"
                                                    error={errors.observation && touched.observation ? true : false}
                                                />
                                                </Grid>
                                            </Grid>
                                            {/* <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >                
                                                <Grid item xs={4}>
                                                    <Typography variant="subtitle1"><b>Estado de Revisión:</b> </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Select
                                                        id="review_status"
                                                        name="review_status"
                                                        variant="standard"
                                                        value={values.review_status}
                                                        onChange={(event) => {
                                                            setFieldValue("review_status", event.target.value);
                                                        }}
                                                        fullWidth
                                                        error={errors.review_status && touched.review_status ? true : false}
                                                    >
                                                        <MenuItem value="1">Aceptar</MenuItem>
                                                        <MenuItem value="2">Rechazar</MenuItem>
                                                    </Select>
                                                </Grid> 
                                            </Grid> */}
                                            </>
                                        )
                                    }

                                    {
                                        (role == 'TRABAJADOR' && detail) && (
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >                
                                                <Grid item xs={4}>
                                                    <Typography variant="subtitle1"><b>Estado del detalle:</b> </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Select
                                                        id="status"
                                                        name="status"
                                                        variant="standard"
                                                        value={values.status}
                                                        onChange={(event) => {
                                                            setFieldValue("status", event.target.value);
                                                        }}
                                                        fullWidth
                                                        error={errors.status && touched.status ? true : false}
                                                    >
                                                        <MenuItem value="1">Habilitado</MenuItem>
                                                        <MenuItem value="2">Deshabilitado</MenuItem>
                                                    </Select>
                                                </Grid> 
                                            </Grid>
                                        )
                                    }

                                    {
                                        detail && (
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>Actual Documento:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                <Button onClick={showDocument} size="small" color={"primary"} variant="contained" >
                                                    {`ver documento`}
                                                </Button>
                                                </Grid>
                                            </Grid>
                                        )
                                    }

                                    {
                                        role == 'TRABAJADOR' && (
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                <Grid item xs={4}>
                                                    <Typography variant="subtitle1" className="custom-input"><b>Nuevo Documento:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Button variant="contained" component="label" fullWidth>
                                                        <input
                                                            id="doc"
                                                            type="file"
                                                            name="doc"
                                                            onChange={(event) => {
                                                                setFieldValue("doc", setNameFile(event));
                                                            }}
                                                        />
                                                    </Button>
                                                </Grid>
                                            </Grid> 
                                        )
                                    }
                                </Grid>
                                <Divider />
                                {
                                    role == 'TRABAJADOR' && (
                                        <ModalFooter 
                                            buttonType="submit"
                                            confirmText={"ACEPTAR"}
                                            onConfirm={handleSubmit}
                                            cancelText="CANCELAR" 
                                            onCancel={closeModal}
                                            clearState={clearState}
                                        />
                                    )
                                }
                                {
                                    role == ROLE_ADMIN && (
                                        <ModalFooter 
                                            buttonType="submit"
                                            confirmText={"ACEPTAR SOLICITUD"}
                                            secondaryText={"RECHAZAR SOLICITUD"}
                                            onConfirm={()=> {setReviewStatus('aceptar'); handleSubmit}}
                                            onSecondaryConfirm={()=> {setReviewStatus('rechazar'); handleSubmit}}
                                            clearState={clearState}
                                        />
                                    )
                                }
                                
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        {
            showModalConfirm && (
                <ModalExpenseConfirm
                    open={showModalConfirm}
                    closeModal={()=>{setShowModalConfirm(false)}}
                    closeModalMain={closeModal}
                    onListDetails={onListDetails}
                    actionSelected={actionSelected}
                    expenseSelected={expenseSelected}
                    detail={detail}
                    coverageCompany={coverageCompany}
                />
            )
        }
        </>
    );
}
import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Alert, Backdrop, CircularProgress, Container, Divider, Grid, IconButton, Menu, Snackbar, Typography,Button, Box, Card} from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { TableBody, Select, FormControl, InputLabel, MenuItem, TextField, TableCell, TableContainer, TableHead, TablePagination, TableRow, Table, createTheme, ThemeProvider } from '@mui/material';
import {useHistory} from 'react-router-dom'
import * as Routes from '@constants/route-map';
import { PeriodService } from '@/service/services/Period.service';
import { RemunerationService } from '@/service/services/Remuneration.service';
import { Props } from './PreviewPrevired.type';
import { TableData } from '@/components/common/Table';
import { templateRemunerationMonthlyService } from '@/service/services/TemplateRemunerationMonthly.service';
import { API_URL_BASE } from '@/toolbox/defaults/app';
import { ModalAmountGeneral } from '@/components/Modal/ModalAmountGeneral';

const cabecera = [
    { name: 'RUT Trabajador', label: 'RUT Trabajador', width: '200px' },
    { name: 'DV Trabajador', label: 'DV Trabajador', width: '200px'  },
    { name: 'Apellido Paterno', label: 'Apellido Paterno' , width: '200px' },
    { name: 'Apellido Materno', label: 'Apellido Materno' , width: '200px' },
    { name: 'Nombres', label: 'Nombres' , width: '200px' },
    { name: 'Sexo', label: 'Sexo' , width: '200px' },
    { name: 'Nacionalidad', label: 'Nacionalidad' , width: '200px' },
    { name: 'Tipo Pago', label: 'Tipo Pago', width: '200px'  },
    { name: 'Período (Desde)', label: 'Período (Desde)' , width: '200px' },
    { name: 'Período (Hasta)', label: 'Período (Hasta)' , width: '200px' },
    { name: 'Régimen Previsional', label: 'Régimen Previsional' , width: '200px' },
    { name: 'Tipo Trabajador', label: 'Tipo Trabajador' , width: '200px' },
    { name: 'Días Trabajados', label: 'Días Trabajados' , width: '200px' },
    { name: 'Tipo de Línea', label: 'Tipo de Línea' , width: '200px' },
    { name: 'Código Movimiento de Personal', label: 'Código Movimiento de Personal', width: '200px'  },
    { name: 'Fecha Desde', label: 'Fecha Desde', width: '200px'  },
    { name: 'Fecha Hasta', label: 'Fecha Hasta' , width: '200px' },
    { name: 'Tramo Asignación Familiar', label: 'Tramo Asignación Familiar' , width: '200px' },
    { name: 'N°Cargas Simples', label: 'N°Cargas Simples', width: '200px'  },
    { name: 'N° Cargas Maternales', label: 'N° Cargas Maternales' , width: '200px' },
    { name: 'N°Cargas Inválidas', label: 'N°Cargas Inválidas' , width: '200px' },
    { name: 'Asignación Familiar', label: 'Asignación Familiar' , width: '200px' },
    { name: 'Asignación Familiar Retroactiva', label: 'Asignación Familiar Retroactiva' , width: '200px' },
    { name: 'Reintegro Cargas Familiares', label: 'Reintegro Cargas Familiares', width: '200px'  },
    { name: 'Solicitud Trabajador Joven', label: 'Solicitud Trabajador Joven', width: '200px'  },
    { name: 'Código de la AFP', label: 'Código de la AFP' , width: '200px' },
    { name: 'Renta Imponible AFP', label: 'Renta Imponible AFP' , width: '200px' },
    { name: 'Cotización Obligatoria AFP', label: 'Cotización Obligatoria AFP', width: '200px'  },
    { name: 'Cotización Seguro de Invalidez y Sobrevivencia (SIS)', label: 'Cotización Seguro de Invalidez y Sobrevivencia (SIS)', width: '200px'  },
    { name: 'Cuenta de Ahorro Voluntario AFP', label: 'Cuenta de Ahorro Voluntario AFP' , width: '200px' },
    { name: 'Renta Imp. Sust.AFP', label: 'Renta Imp. Sust.AFP' , width: '200px' },
    { name: 'Tasa Pactada (Sustit.)', label: 'Tasa Pactada (Sustit.)' , width: '200px' },
    { name: 'Aporte Indemn. (Sustit.)', label: 'Aporte Indemn. (Sustit.)', width: '200px'  },
    { name: 'N° Períodos (Sustit.)', label: 'N° Períodos (Sustit.)' , width: '200px' },
    { name: 'Período desde (Sustit.)', label: 'Período desde (Sustit.)' , width: '200px' },
    { name: 'Período Hasta (Sustit.)', label: 'Período Hasta (Sustit.)' , width: '200px' },
    { name: 'Puesto de Trabajo Pesado', label: 'Puesto de Trabajo Pesado' , width: '200px' },
    { name: '% Cotización Trabajo Pesado', label: '% Cotización Trabajo Pesado' , width: '200px' },
    { name: 'Cotización Trabajo Pesado', label: 'Cotización Trabajo Pesado', width: '200px'  },
    { name: 'Código de la Institución APVI', label: 'Código de la Institución APVI', width: '200px'  },
    { name: 'Número de Contrato APVI', label: 'Número de Contrato APVI' , width: '200px' },
    { name: 'Forma de Pago APVI', label: 'Forma de Pago APVI', width: '200px'  },
    { name: 'Cotización APVI', label: 'Cotización APVI' , width: '200px' },
    { name: 'Cotización Depósitos Convenidos', label: 'Cotización Depósitos Convenidos' , width: '200px' },
    { name: 'Código Institución Autorizada APVC', label: 'Código Institución Autorizada APVC', width: '200px'  },
    { name: 'Número de Contrato APVC', label: 'Número de Contrato APVC' , width: '200px' },
    { name: 'Forma de Pago APVC', label: 'Forma de Pago APVC', width: '200px'  },
    { name: 'Cotización Trabajador APVC', label: 'Cotización Trabajador APVC' , width: '200px' },
    { name: 'Cotización Empleador APVC', label: 'Cotización Empleador APVC' , width: '200px' },
    { name: 'RUT Afiliado Voluntario', label: 'RUT Afiliado Voluntario' , width: '200px' },
    { name: 'DV Afiliado Voluntario', label: 'DV Afiliado Voluntario' , width: '200px' },
    { name: 'Monto Capitalización Voluntaria', label: 'Monto Capitalización Voluntaria', width: '200px'  },
    { name: 'Monto Ahorro Voluntario', label: 'Monto Ahorro Voluntario' , width: '200px' },
    { name: 'Número de periodos de cotización', label: 'Número de periodos de cotización' , width: '200px' },
    { name: 'Código EX-Caja Régimen', label: 'Código EX-Caja Régimen' , width: '200px' },
    { name: 'Tasa Cotización Ex-Caja Previsión', label: 'Tasa Cotización Ex-Caja Previsión', width: '200px'  },
    { name: 'Renta Imponible IPS', label: 'Renta Imponible IPS', width: '200px'  },
    { name: 'Cotización Obligatoria IPS', label: 'Cotización Obligatoria IPS' , width: '200px' },
    { name: 'Renta Imponible Desahucio', label: 'Renta Imponible Desahucio' , width: '200px' },
    { name: 'Código Ex-Caja Régimen Desahucio', label: 'Código Ex-Caja Régimen Desahucio' , width: '200px' },
    { name: 'Tasa Cotización Desahucio Ex-Cajas de Previsión', label: 'Tasa Cotización Desahucio Ex-Cajas de Previsión', width: '200px'  },
    { name: 'Cotización Desahucio', label: 'Cotización Desahucio' , width: '200px' },
    { name: 'Cotización Fonasa', label: 'Cotización Fonasa' , width: '200px' },
    { name: 'Cotización Acc. Trabajo (ISL)', label: 'Cotización Acc. Trabajo (ISL)' , width: '200px' },
    { name: 'Bonificación Ley 15.386', label: 'Bonificación Ley 15.386' , width: '200px' },
    { name: 'Descuento por cargas familiares de IPS (ex INP)', label: 'Descuento por cargas familiares de IPS (ex INP)', width: '200px'  },
    { name: 'Bonos Gobierno', label: 'Bonos Gobierno' , width: '200px' },
    { name: 'Código Institución de Salud', label: 'Código Institución de Salud' , width: '200px' },
    { name: 'Número del FUN', label: 'Número del FUN', width: '200px' },
    { name: 'Renta Imponible Isapre', label: 'Renta Imponible Isapre', width: '200px'  },
    { name: 'Moneda del plan pactado Isapre', label: 'Moneda del plan pactado Isapre' , width: '200px' },
    { name: 'Cotización Pactada', label: 'Cotización Pactada' , width: '200px' },
    { name: 'Cotización Obligatoria Isapre', label: 'Cotización Obligatoria Isapre', width: '200px'  },
    { name: 'Cotización Adicional Voluntaria', label: 'Cotización Adicional Voluntaria' , width: '200px' },
    { name: 'Monto Garantía Explícita de Salud GES (Uso Futuro)', label: 'Monto Garantía Explícita de Salud GES (Uso Futuro)' , width: '200px' },
    { name: 'Código CCAF', label: 'Código CCAF' , width: '200px' },
    { name: 'Renta Imponible CCAF', label: 'Renta Imponible CCAF' , width: '200px' },
    { name: 'Créditos Personales CCAF', label: 'Créditos Personales CCAF' , width: '200px' },
    { name: 'Descuento Dental CCAF', label: 'Descuento Dental CCAF', width: '200px'  },
    { name: 'Descuentos por Leasing (Programa Ahorro)', label: 'Descuentos por Leasing (Programa Ahorro)', width: '200px'  },
    { name: 'Descuentos por seguro de vida CCAF', label: 'Descuentos por seguro de vida CCAF', width: '200px'  },
    { name: 'Otros descuentos CCAF', label: 'Otros descuentos CCAF' , width: '200px' },
    { name: 'Cotización a CCAF de no afiliados a Isapres', label: 'Cotización a CCAF de no afiliados a Isapres', width: '200px'  },
    { name: 'Descuento Cargas Familiares CCAF', label: 'Descuento Cargas Familiares CCAF', width: '200px'  },
    { name: 'Otros descuentos CCAF 1 (Uso Futuro)', label: 'Otros descuentos CCAF 1 (Uso Futuro)' , width: '200px' },
    { name: 'Otros descuentos CCAF 2 (Uso Futuro)', label: 'Otros descuentos CCAF 2 (Uso Futuro)' , width: '200px' },
    { name: 'Bonos Gobierno (Uso Futuro)', label: 'Bonos Gobierno (Uso Futuro)' , width: '200px' },
    { name: 'Código de Sucursal (Uso Futuro)', label: 'Código de Sucursal (Uso Futuro)' , width: '200px' },
    { name: 'Código Mutualidad', label: 'Código Mutualidad' , width: '200px' },
    { name: 'Renta Imponible Mutual', label: 'Renta Imponible Mutual' , width: '200px' },
    { name: 'Cotización Accidente del Trabajo (MUTUAL)', label: 'Cotización Accidente del Trabajo (MUTUAL)' , width: '200px' },
    { name: 'Sucursal para pago Mutual', label: 'Sucursal para pago Mutual', width: '200px'  },
    { name: 'Renta Imponible Seguro Cesantía (Informar Renta Total Imponible)', label: 'Renta Imponible Seguro Cesantía (Informar Renta Total Imponible)' , width: '200px' },
    { name: 'Aporte Trabajador Seguro Cesantía', label: 'Aporte Trabajador Seguro Cesantía' , width: '200px' },
    { name: 'Aporte Empleador Seguro Cesantía', label: 'Aporte Empleador Seguro Cesantía', width: '200px'  },
    { name: 'Rut Pagadora Subsidio', label: 'Rut Pagadora Subsidio', width: '200px'  },
    { name: 'DV Pagadora Subsidio', label: 'DV Pagadora Subsidio' , width: '200px' },
    { name: 'Centro de Costos, Sucursal, Agencia,Obra, Región', label: 'Centro de Costos, Sucursal, Agencia,Obra, Región' , width: '200px' },
];


export const PreviewPrevired: React.FC<Props> = (props: any): JSX.Element => {
    const { data, setShowPrevired, setShowMain, period, costCenter } = props;

    useEffect(()=> {
    },[]);
    const [showUserModal, setShowUserModal] = useState<boolean>(false);

    const previredDocument = async(typeDownload) => {
        
         if( typeDownload == 'excel'){
            const respExcel = await templateRemunerationMonthlyService.createPreviredExcel(period.id, costCenter?.id || "");
            // window.open(`${respExcel.data.link}`, '_blank');
            console.log(respExcel.data.link);
            const btn_excel = document.createElement("a")
            btn_excel.href = `${respExcel.data.link}`
            btn_excel.download = 'previred.xlsx'

            document.body.appendChild(btn_excel);
            btn_excel.click();
            document.body.removeChild(btn_excel)

         }else{
            const respCSV = await templateRemunerationMonthlyService.createPreviredCsv(period.id, costCenter?.id || "");
            const btn_csv = document.createElement("a")
            btn_csv.href = `${respCSV.data.link}`
            btn_csv.download = 'previred.csv'

            document.body.appendChild(btn_csv);
            btn_csv.click();
            document.body.removeChild(btn_csv)
    
         }
    }
    

    return (
        <>

        <Container>
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} display='flex' justifyContent='space-between'>
                <Typography variant='h6' component='h1' gutterBottom className="tittle">
                    PREVIRED
                </Typography>
                <Button
                     color="error"
                     variant="contained"
                     onClick={()=>{
                        setShowPrevired(false)
                        setShowMain(true)
                     }}
                  >
                   regresar
                  </Button>
            </Grid>
        </Grid>

        <Grid item xs={12} spacing={2} container direction={'row'} justifyContent='center' alignItems={'center'} marginTop='15px'>

            <Grid item xs={12} container alignItems="center" justifyContent="start" marginBottom='25px'>
                <Grid item xs={4}>
                <Typography variant="subtitle1" className="custom-input"><b>Previred formato excel:</b></Typography>
                </Grid>
                <Grid item xs={8} justifyContent='start'>
                    <Button onClick={()=>{previredDocument('excel')}} type={'submit'} size="medium" color={"primary"} variant="contained" disableElevation sx={{width:'220px'}}>
                        Descargar
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                <Grid item xs={4}>
                <Typography variant="subtitle1" className="custom-input"><b>Previred formato csv:</b></Typography>
                </Grid>
                <Grid item xs={8}>
                    <Button onClick={()=>{previredDocument('csv')}} type={'submit'} size="medium" color={"primary"} variant="contained" disableElevation sx={{width:'220px'}}>
                        Descargar
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                <Grid item xs={4}>
                <Typography variant="subtitle1" className="custom-input"><b>Previred montos generales:</b></Typography>
                </Grid>
                <Grid item xs={8}>
                    <Button onClick={()=>setShowUserModal(true)} type={'submit'} size="medium" color={"primary"} variant="contained" disableElevation sx={{width:'220px'}}>
                        ver montos Previred
                    </Button>
                </Grid>
            </Grid>

        </Grid>
        <TableData
            header={cabecera}
            data={data || []}
        />
        {/* <TableDataV2
            title={'Valor de hora extras por empleado'}
            data={data}
            header={cabecera}
            disabled_title={true}
            btn_disabled
            btn_edit_disable={true}
            btn_delete_disable={true}
        /> */}
        </Container>
        { 
            showUserModal && (
               <ModalAmountGeneral
                  open={showUserModal}
                  closeModal={()=>{setShowUserModal(false)}}
                  idperiod={period.id}
                  
               />
            )
         }
        </>
    )
    
};
import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);
const idCompany = dataUser?.user?.id_company;

export const ReportRepository = {

    getReportAbsenceDelay: async(start_date:any, end_date:any, idEmployee:number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/markedAssists/absenceDelay?start_date=${start_date}&end_date=${end_date}&idemployee=${idEmployee}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getReportEmployee: async(idPeriod:number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/dashboard/employees/${idCompany}?idperiod=${idPeriod}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getReportRequest: async(idPeriod:number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/dashboard/request/${idCompany}?idperiod=${idPeriod}`);
        const {data, error, message} = res;
        return { data, error, message };
    },
    getReportLiquidation:async(idCompany:number, idperiod:number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/dashboard/settlements/${idCompany}?idperiod=${idperiod}`);
        const {data, error, message} = res;
        return { data, error, message };
    },
    getReportExpens:async(idCompany:number, idperiod:number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/dashboard/expenseReport/${idCompany}?idperiod=${idperiod}`);
        const {data, error, message} = res;
        return { data, error, message };
    },
    getReportliquidationByProjects:async(idperiod:number, month:any='', year:any=''): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/dashboard/liquidationByProjects/${idCompany}?idperiod=${idperiod}&month=${month}&year=${year}`);
        const {data, error, message} = res;
        return { data, error, message };
    },
    getReportGroupCompany:async(idperiod:number, id): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/dashboard/multi-company/${id}?idperiod=${idperiod}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getReportGroupCompanyExcel:async(idperiod:number, id): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/dashboard/excelMultiCompany/${id}?idperiod=${idperiod}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getReportExpensExcel:async(idCompany:number, idperiod:number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/dashboard/excelExpense/${idCompany}?idperiod=${idperiod}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getReportRequestExcel: async(idPeriod:number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/dashboard/excelRequest/${idCompany}?idperiod=${idPeriod}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getReportLiquidationExcel:async(idCompany:number, idperiod:number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/dashboard/excelSettlements/${idCompany}?idperiod=${idperiod}`);
        const {data, error, message} = res;
        return { data, error, message };
    },
   getReportExcelAbsence:async(start_date:any, end_date:any, idEmployee:number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/dashboard/excelAbsence?start_date=${start_date}&end_date=${end_date}&idemployee=${idEmployee}`);
        const {data, error, message} = res;
        return { data, error, message };
    },
    
    getReportExcelEmployees: async (idperiod: number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/dashboard/excelEmployees/${idCompany}?idperiod=${idperiod}`);
        const { data, error, message } = res;
        return { data, error, message };
    },
}   

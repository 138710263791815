import { AssistsWorkerRepository } from "../repositories/AssistanceWorker.repository";
import { chargesRepository } from "../repositories/Charge.repository";

export const AssistsWorkerService = {
    getAssitsCalendar,
    createAssists,
    createAssistMasive,
    updateAssists,
    deleteAssists,
}

async function getAssitsCalendar(idEmployee: number, start_date: any, end_date: any){
    const res = await AssistsWorkerRepository.getAssitsCalendar(idEmployee, start_date, end_date);
    return res;
}

async function createAssists(data){
    const res = await AssistsWorkerRepository.createAssists(data);
    return res;
}

async function createAssistMasive(data){
    const res = await AssistsWorkerRepository.createAssistMasive(data);
    return res;
}

async function updateAssists( idAssists ,data){
    const res = await AssistsWorkerRepository.updateAssists(idAssists, data);
    return res;
}

async function deleteAssists( idAssists){
    const res = await AssistsWorkerRepository.deleteAssists(idAssists);
    return res;
}


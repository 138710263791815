import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { PhasesService } from "@/service/services/Phases.service";
import { Loading } from "@/components/common/Loading";
import { AssistanceService } from "@/service/services/Assistance.service";
import { Props } from "./ModalExpenseConfirm.type";
import { RleStructureService } from "@/service/services/RleStructure.service";

export const ModalExpenseConfirm: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, extraHour, onListDetails, closeModalMain, detail, expenseSelected, coverageCompany, clearState } = props;

    const periodExpense = (expenseSelected?.period).split('-');
    const filterPeriod = periodExpense[1]+'-'+periodExpense[0];
    const amount = (parseFloat(detail?.amount) * parseFloat(coverageCompany)) / 100

    const [loading, setLoading] = useState<boolean>(false);
    const [structures, setStructures] = useState<any>([]);
    const [structureSelected, setStructureSelected] = useState<any>(null);

    console.log(structures)

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [data, setData] = useState({
        id: extraHour ? extraHour.id : '',
        idemployee: extraHour ? extraHour.idemployee : '',
        iddetstructureemployeeopt: extraHour ? extraHour.iddetstructureemployeeopt : '',
        hour: extraHour ? extraHour.hour : '',
        date: extraHour ? extraHour.date : '',
        code: extraHour ? extraHour.code : '',
    });

    useEffect(()=> {
        if(expenseSelected.idemployee){
            getDetailEmployeeSO(expenseSelected.idemployee);
        }
    },[expenseSelected.idemployee]);

    const getDetailEmployeeSO = async (id_employee) => {
        setLoading(true);
        const resp:any = await RleStructureService.getDetailEmployeeSO(id_employee);
        const arrayStructure = [];
        if(resp.data){
            (resp.data).map((item)=>{
                if(item.code != 'HIT2'){
                    arrayStructure.push(item);
                }
            })
            setStructures(arrayStructure || [])
        }
        setLoading(false);
    }

    const validateForm = (values) => {
        let errors: any = {};
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        if(!structureSelected){
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: `Seleccione una estructura` }
                ))
            );
        }

        const formData = {
            id: structureSelected.id,
            value: parseFloat(structureSelected.value) + amount,
            validity: filterPeriod,
            status: '1'
        }
        setLoading(true);

        await RleStructureService.editDetailEmployeeSO(formData.id, formData).then((data) => {
            console.log(data);
            if(data?.data){
                closeModalMain && closeModalMain();
                closeModal && closeModal();
                onListDetails && onListDetails(expenseSelected.id);
                return (
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'success', 
                        message: `La operación se realizó con éxito.` }
                    ))
                );
            }
            
        })

        setLoading(false);
    }

    return(
        <>
        {loading && <Loading/>}

        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={"Agregar Monto a Estructura"}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Nombre Empleado:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            variant="standard"
                                            type="text" 
                                            fullWidth
                                            value={expenseSelected?.name_all}
                                            disabled
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Periodo:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            variant="standard"
                                            type='month'
                                            fullWidth
                                            value={filterPeriod || expenseSelected?.period}
                                            disabled
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Monto:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                variant="standard"
                                                fullWidth
                                                type="number"
                                                value={amount}
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>Estructura:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                id="disable-portal"
                                                disablePortal
                                                value={structureSelected}
                                                options={structures || []}
                                                getOptionLabel={(option) => option.code +' - '+ option.alias_name || ""}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} variant="standard" label="Escriba para buscar" />}
                                                onChange={(e, newValue) => { setStructureSelected(newValue) }}
                                            />
                                        </Grid>
                                    </Grid>


                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={"CONFIRMAR"}
                                    // cancelText="CANCELAR" 
                                    // onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}
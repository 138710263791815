import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';

export const timeZoneRepository = {

    getTimeZone: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/timeZone`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createTimeZone: async (dataTimeZone) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/timeZone`, {
            description: dataTimeZone.description,
            operation: dataTimeZone.operation,
            time_zone: dataTimeZone.time_zone,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    editTimeZone: async (dataTimeZone, idTimeZone: number) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/timeZone/${idTimeZone}`, {
            description: dataTimeZone.description,
            operation: dataTimeZone.operation,
            time_zone: dataTimeZone.time_zone,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    deleteTimeZone: async(idTimeZone:number): Promise<any> => {
        const res= await http.delete<any>(`${API_URL_BASE}/v1/timeZone/${idTimeZone}`)
        return res;
    },
}



import { Props } from './Assistances.type';
import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Alert, Backdrop, Box, Breadcrumbs, Button, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar, TextField, Typography } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { WorkerService } from '@/service/services/Worker.service';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import GroupsIcon from '@mui/icons-material/Groups';
import moment from 'moment';
import { AssistsWorkerService } from '@/service/services/AssistanceWorker.service';
import { ModalAssistance } from '@/components/Modal/ModalAssistance';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

export const AssistancesView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [workers, setWorkers] = useState<any>([]);
    const [workerSelected, setWorkerSelected] = useState<any>('');

    const [assistances, setAssistances] = useState([]);
    const [showAssistanceModal, setShowAssistanceModal] = useState<boolean>(false);
    const [showAssistanceDeleteModal, setShowAssistanceDeleteModal] = useState<boolean>(false);
    const [assistanceSelected, setAssistanceSelected] = useState<any>('');
    const [actionSelected, setActionSelected] = useState<any>('');

    const [view, setView] = useState<String>('workers');
    const [showAbsences, setShowAbsences] = useState<boolean>(false);
    const [messageRequest, setMessageRequest] = useState<any>(null);

    const [startDateSelected, setStartDateSelected] = useState<any>('')
    const [endDateSelected, setEndDateSelected] = useState<any>('')

    const cabecera = [
        { name: 'n_document', label: 'RUT TRABAJADOR' },
        { name: 'surname', label: 'APELLIDO PATERNO' },
        { name: 'second_surname', label: 'APELLIDO MATERNO' },
        { name: 'name', label: 'NOMBRES' }
    ];

    const cabeceraAsistence = [
        { name: 'date', label: 'Fecha' },
        { name: 'start_time', label: 'Hora Ingreso' },
        { name: 'start_refreshment', label: 'Inicio Refrigerio' },
        { name: 'end_refreshment', label: 'Fin Refrigerio' },
        { name: 'end_time', label: 'Hora Salida' },
        { name: 'extra_hours', label: 'Horas Extras (m)' },
        { name: 'delay', label: 'Tardanzas (m)' }
    ];


    useEffect(()=> {
        getWorkers();
    },[]);

    useEffect(()=> {
        const previousDate = moment().subtract(7, 'days').format("YYYY-MM-DD");
        const nexDate = moment().add(1, 'days').format("YYYY-MM-DD");

        if(workerSelected ){
            getAssitsCalendar(workerSelected.id, previousDate, nexDate);
        }
    },[workerSelected]);

    const getWorkers = async() => {
        setLoading(true);
        const resp:any = await WorkerService.getWorkers();

        const oldData = resp.data || [];
        let newData = [];
        oldData.length > 0 && oldData.map((item) => {
            let obj = {
                ...item,
                name_complete: item.name+' '+item.surname+' '+item.second_surname,
            }
            newData.push(obj);
        });
        setWorkers(newData || []);
        setLoading(false);
    }

    const getAssitsCalendar = async(idEmployee, start_date, end_date) => {
        setLoading(true);
        await AssistsWorkerService.getAssitsCalendar(idEmployee, start_date, end_date).then((data) => {
            setAssistances(data.data || []);
            setLoading(false);
        })
    }

    function RecuperarData(value){
        !workerSelected && setWorkerSelected(value);
        workerSelected && setAssistanceSelected(value);
        setShowAbsences(true);
        setView('assistance');
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Container>
            <Breadcrumbs aria-label="breadcrumb">
                <StyledBreadcrumb
                    label="Lista de Empleados"
                    onClick={() => {
                        setView('workers');
                        setWorkerSelected('');
                        setShowAbsences(false);
                    }}
                    icon={<GroupsIcon fontSize="small" />}
                />
                {
                    showAbsences && (
                        <StyledBreadcrumb
                            label={`Asistencias`}
                            onClick={() => {setView('assistance')}}
                            icon={<GroupsIcon fontSize="small" />}
                        />
                    )
                }
            </Breadcrumbs>
            {
                view == 'workers' && (
                    <TableDataV2
                        title={'Asistencias por empleado'}
                        data={workers}
                        header={cabecera}
                        actionSelect={setActionSelected}
                        setModalSave={setShowAssistanceModal}
                        status_action
                        disabled_title={false}
                        // btn_disabled
                        btn_edit_disable={true}
                        btn_delete_disable={true}
                        btn_helper
                        currentView={view}
                        RecuperarData={RecuperarData}
                        />
                )
            }
            {
                view == 'assistance' && (

                    <>
                    <Grid container spacing={3} sx={{marginTop: '10px', marginBottom: '20px', alignItems: 'center', marginLeft: '5px'}}>
                    
                        <Grid item spacing={3} xs={12} md={12} display='flex' sx={{alignItems: 'center'}}>
                            <TextField
                                id="start_date"
                                variant="standard"
                                name="start_date"
                                sx={{ width: '200px', marginRight: '30px'}}
                                label="desde"
                                type="date"
                                value={startDateSelected}
                                onChange={(e)=>{setStartDateSelected(e.target.value)}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                id="end_date"
                                variant="standard"
                                sx={{ width: '200px', marginRight: '30px'}}
                                name="end_date"
                                label="hasta"
                                type="date"
                                value={endDateSelected}
                                onChange={(e)=>{setEndDateSelected(e.target.value)}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                            <Button
                                sx={{marginTop:'20px'}}
                                onClick={()=>{getAssitsCalendar(workerSelected.id, startDateSelected, endDateSelected)}}
                            >
                                Filtrar
                            </Button>
                        </Grid>
                        
                    </Grid>
                     <TableDataV2
                        title={`Asistencias de "${workerSelected?.name}"`}
                        data={assistances}
                        header={cabeceraAsistence}
                        actionSelect={setActionSelected}
                        setModalSave={setShowAssistanceModal}
                        setModalDelete={setShowAssistanceDeleteModal}
                        status_action
                        disabled_title={false}
                        btn_disabled={false}
                        btn_edit_disable={false}
                        btn_delete_disable={true}
                        currentView={view}
                        RecuperarData={RecuperarData}
                    />
                    </>
                   
                )
            }
            </Container>

            { 
                view =='workers' && showAssistanceModal && (
                    <ModalAssistance
                        open={showAssistanceModal}
                        closeModal={()=>{setShowAssistanceModal(false)}}
                        actionSelected={actionSelected}
                        onListAssistances={getAssitsCalendar}
                        assistance={assistanceSelected}
                        workerSelected={workerSelected}
                        clearState={()=>{setAssistanceSelected('')}}
                        workers={workers}
                        massive
                    />
                )
            }
            { 
                view =='assistance' && showAssistanceModal && (
                    <ModalAssistance
                        open={showAssistanceModal}
                        closeModal={()=>{setShowAssistanceModal(false)}}
                        actionSelected={actionSelected}
                        onListAssistances={getAssitsCalendar}
                        assistance={assistanceSelected}
                        workerSelected={workerSelected}
                        clearState={()=>{setAssistanceSelected('')}}
                    />
                )
            }
            {/* {
                actionSelected === 'delete' && (
                    <ModalAbsenceDelete
                        open={showAbsenceDeleteModal}
                        closeModal={()=>{setShowAbsenceDeleteModal(false)}}
                        actionSelected={actionSelected}
                        onListAssistances={getAssitsCalendar}
                        assistance={assistanceSelected}
                        workerSelected={workerSelected}
                        clearState={()=>{setAssistanceSelected('')}}
                    />
                )
            } */}
        
        </Protected>
    )
};
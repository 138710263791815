import { reminderTypeRepository } from "../repositories/TypeReminder.repository";

export const ReminderTypeService = {
    getReminderTypes,
    createReminderType,
    editReminderType,
    deleteReminderType,
}

async function getReminderTypes(){
    const res = await reminderTypeRepository.getReminderTypes();
    return res;
}

async function createReminderType(data){
    const res = await reminderTypeRepository.createReminderType(data);
    return res;
}

async function editReminderType(data, idRequestType: number){
    const res = await reminderTypeRepository.editReminderType(data, idRequestType);
    return res;
}

async function deleteReminderType(idRequestType:number){
    const res = await reminderTypeRepository.deleteReminderType(idRequestType);
    return res;
 }
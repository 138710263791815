import { ApplicantsRepository } from "../repositories/Applicants.repository";

export const ApplicantsService = {
    getApplicants,
    createApplicant,
    editApplicant,
}

async function getApplicants(){
    const res = await ApplicantsRepository.getApplicants();
    return res;
}

async function createApplicant(data){
    const res = await ApplicantsRepository.createApplicants(data);
    return res;
}

async function editApplicant(data:{}){
    const res = await ApplicantsRepository.editApplicants(data);
    return res;
}

// async function getCocinaById(idCocina: number){
//     const res = await CocinaRepository.getCocinaById(idCocina);
//     return res;
// }

import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Button, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { CountryService } from "@/service/services/Country.service";
import { Props } from "./ModalCompany.type";
import { companiesService } from "@/service/services/Companies.service";

export const ModalCompany: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, company, onListCompanies, clearState } = props;

    const entry = [
        {id: 1, name: 'Privada'},
    ]

    const [loading, setLoading] = useState<boolean>(false);
    const [compensationBox, setCompensationBox] = useState<any>([]);
    const [imageDoc, setImageDoc] = useState<any>(null);
    //for get idDistrict
    const [countries, setCountries] = useState<any>([]);
    const [countrySelected, setCountrySelected] = useState<any>('');
    const [departaments, setDepartaments] = useState<any>([]);
    const [departamentSelected, setDepartamentSelected] = useState<any>('');
    const [provinces, setProvinces] = useState<any>([]);
    const [provinceSelected, setProvinceSelected] = useState<any>('');
    const [districts, setDistricts] = useState<any>([]);
    const [districtSelected, setDistrictSelected] = useState<any>('');

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [data, setData] = useState({
        id: company ? company.id : '',
        registry_number: company ? company.registry_number : '',
        business_name: company ? company.business_name : '',
        phone: company ? company.phone : '',
        email: company ? company.email : '',
        contact_name: company ? company.contact_name : '',
        contact_email: company ? company.contact_email : '',
        contact_phone: company ? company.contact_phone : '',
        website: company ? company.website : '',
        fax: company ? company.fax : '',
        main_address: company ? company.main_address : '',
        iddistrict: company ? company.iddistrict : '',
        idcompensationbox: company ? company.idcompensationbox : '',
        identry: company ? company.identry : '',
        image: company ? company.image : '',
    });

    useEffect(() => {
        getCajas();
        getCountries();
    }, []);

    const getCajas = async() => {
        setLoading(true);
        await companiesService.getCajas().then((data) => {
            setCompensationBox(data || []);
            setLoading(false);
        })
    }

    const getCountries = async () => {
        setLoading(true);
        await CountryService.getCountries().then((data) => {
            setCountries(data.data || [])
           setLoading(false);
        });
    }

    const getDepartments = async (idCountry) => {
        setLoading(true);
        await CountryService.getDepartments(idCountry).then((data) => {
            setDepartaments(data.data || [])
           setLoading(false);
        });
    }

    const getProvinces = async (idDepartament) => {
        setLoading(true);
        await CountryService.getProvinces(idDepartament).then((data) => {
            setProvinces(data.data || [])
           setLoading(false);
        });
    }

    const getDistricts = async (idProvince) => {
        setLoading(true);
        await CountryService.getDistricts(idProvince).then((data) => {
            setDistricts(data.data || [])
           setLoading(false);
        });
    }

    const setNameFile = (event) => {
        const files = event.target && event.target.files || [];
        const fileSignature = files[0];

        if (fileSignature) {

            const nameAsArray = fileSignature.name.split(".") || [];
            console.log(nameAsArray)
            const extension = nameAsArray.slice(-1)
            console.log(extension)
            const nameWithoutExtension = nameAsArray.filter((word, i) => i != nameAsArray.length - 1);

            if (extension == 'jpg' || extension == 'JPG' || extension == 'jpeg' ||
                extension == 'JPEG' || extension == 'png' || extension == 'PNG') {
                setImageDoc(fileSignature) 
                return fileSignature
            } else {
                return (
                    setSnackBarConfig(prev => ({
                        ...prev, open: true,
                        severity: 'warning',
                        message: `El formato de imagen no es correcta`
                    }
                    ))
                );
            }
        }
        return "";
    };

    const validateForm = (values) => {
        let errors: any = {};

        if(!values.registry_number) errors.registry_number = "numero de registro requerido";
        if(!values.business_name) errors.business_name = "nombre requerido";
        if(!values.phone) errors.phone = "telefono phone";
        if(!values.email) errors.email = "correo requerido";
        if(!values.contact_name) errors.contact_name = "nombre de contacto requerido";
        if(!values.contact_email) errors.contact_email = "correo de contacto requerido";
        if(!values.contact_phone) errors.contact_phone = "telefono de contacto requerido";
        if(!values.website) errors.website = "sitio web requerido";
        if(!values.fax) errors.fax = "fax requerido";
        if(!values.main_address) errors.main_address = "direccion requerido";
        if(!values.identry) errors.identry = "tipo de empresa requerido";
        if(!values.idcompensationbox) errors.idcompensationbox = "compensacion requerido";

        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        if(!districtSelected){
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: `Debe seleccionar un Distrito` }
                ))
            );
        }

        const formData = new FormData();
        formData.append("id", values.id);
        formData.append("registry_number", values.registry_number);
        formData.append("business_name", values.business_name);
        formData.append("phone", values.phone);
        formData.append("email", values.email);
        formData.append("contact_name", values.contact_name);
        formData.append("contact_email", values.contact_email);
        formData.append("contact_phone", values.contact_phone);
        formData.append("website", values.website);
        formData.append("fax", values.fax);
        formData.append("main_address", values.main_address);
        formData.append("iddistrict", districtSelected?.id);
        formData.append("idcompensationbox", values.idcompensationbox);
        formData.append("identry", values.identry);
        if(imageDoc)  formData.append("image", values.image);

        const formDataEdit = {
            id: values.id,
            registry_number: values.registry_number,
            business_name: values.business_name,
            phone: values.phone,
            email: values.email,
            contact_name: values.contact_name,
            contact_email: values.contact_email,
            contact_phone: values.contact_phone,
            website: values.website,
            fax: values.fax,
            main_address: values.main_address,
            iddistrict: districtSelected?.id,
            idcompensationbox: values.idcompensationbox,
            identry: values.identry,
        }

        setLoading(true);
        if(company && actionSelected=='edit'){
            await companiesService.editCompany(company.id, formDataEdit).then((data) => {
                onListCompanies && onListCompanies();
                closeModal && closeModal();
            })
        }else{
            await companiesService.createCompany(formData).then((data) => {
                onListCompanies && onListCompanies();
                closeModal && closeModal();
            })
        }
        setLoading(false);
    }

    return(
        <>
        {loading && <Loading/>}
        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={(company && actionSelected=='edit') ? "Editar Empresa" : "Agregar Empresa"}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Número de RUT:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="registry_number"
                                            variant="standard"
                                            type="text" 
                                            name="registry_number"
                                            fullWidth
                                            value={values.registry_number}
                                            onChange={handleChange}
                                            placeholder="numero de registro"
                                            error={errors.registry_number && touched.registry_number ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>Nombre de Negocio:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="business_name"
                                            variant="standard"
                                            type="text" 
                                            name="business_name"
                                            fullWidth
                                            multiline
                                            value={values.business_name}
                                            onChange={handleChange}
                                            placeholder="nombre de negocio"
                                            error={errors.business_name && touched.business_name ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Teléfono:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="phone"
                                            variant="standard"
                                            type="text" 
                                            name="phone"
                                            multiline
                                            maxRows={4}
                                            fullWidth
                                            value={values.phone}
                                            onChange={handleChange}
                                            placeholder="telefono"
                                            error={errors.phone && touched.phone ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Correo:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="email"
                                            variant="standard"
                                            type="email" 
                                            name="email"
                                            multiline
                                            maxRows={4}
                                            fullWidth
                                            value={values.email}
                                            onChange={handleChange}
                                            placeholder="email"
                                            error={errors.email && touched.email ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Nombre de Contacto:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="contact_name"
                                            variant="standard"
                                            type="text" 
                                            name="contact_name"
                                            multiline
                                            fullWidth
                                            value={values.contact_name}
                                            onChange={handleChange}
                                            placeholder="nombre de contacto"
                                            error={errors.contact_name && touched.contact_name ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Correo de Contacto:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="contact_email"
                                            variant="standard"
                                            type="email" 
                                            name="contact_email"
                                            multiline
                                            fullWidth
                                            value={values.contact_email}
                                            onChange={handleChange}
                                            placeholder="correo de contacto"
                                            error={errors.contact_email && touched.contact_email ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Teléfono de Contacto:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="contact_phone"
                                            variant="standard"
                                            type="text" 
                                            name="contact_phone"
                                            multiline
                                            fullWidth
                                            value={values.contact_phone}
                                            onChange={handleChange}
                                            placeholder="telefono de contacto"
                                            error={errors.contact_phone && touched.contact_phone ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Sitio Web:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="website"
                                            variant="standard"
                                            type="text" 
                                            name="website"
                                            multiline
                                            fullWidth
                                            value={values.website}
                                            onChange={handleChange}
                                            placeholder="sitio web"
                                            error={errors.website && touched.website ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Fax:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="fax"
                                            variant="standard"
                                            type="text" 
                                            name="fax"
                                            multiline
                                            fullWidth
                                            value={values.fax}
                                            onChange={handleChange}
                                            placeholder="fax"
                                            error={errors.fax && touched.fax ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>Pais:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="id_nationality"
                                                variant="standard"
                                                value={countrySelected}
                                                onChange={(event) => { 
                                                    setCountrySelected(event.target.value);
                                                }}
                                                fullWidth
                                            >
                                                {
                                                countries.map((country, i) => {
                                                    return <MenuItem key={i} value={country.id} onClick={ () => {getDepartments(country.id)}}>{country.name}</MenuItem>;
                                                })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>Departamento:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                id="disable-portal"
                                                disablePortal
                                                value={departamentSelected}
                                                options={departaments || []}
                                                getOptionLabel={(option) => option.name || ""}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} variant="standard" label="Escriba para buscar" />}
                                                onChange={(e, newValue) => { 
                                                    setDepartamentSelected(newValue); 
                                                    newValue && getProvinces(newValue?.id);
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>Provincia:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                id="disable-portal"
                                                disablePortal
                                                value={provinceSelected}
                                                options={provinces || []}
                                                getOptionLabel={(option) => option.name || ""}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} variant="standard" label="Escriba para buscar" />}
                                                onChange={(e, newValue) => { 
                                                    setProvinceSelected(newValue);
                                                    newValue && getDistricts(newValue?.id); 
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>Distrito:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                id="disable-portal"
                                                disablePortal
                                                value={districtSelected}
                                                options={districts || []}
                                                getOptionLabel={(option) => option.name || ""}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} variant="standard" label="Escriba para buscar" />}
                                                onChange={(e, newValue) => { setDistrictSelected(newValue) }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Dirección Principal:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="main_address"
                                            variant="standard"
                                            type="text" 
                                            name="main_address"
                                            multiline
                                            fullWidth
                                            value={values.main_address}
                                            onChange={handleChange}
                                            placeholder="direccion"
                                            error={errors.main_address && touched.main_address ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1"><b>Tipo de Empresa:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="identry"
                                                variant="standard"
                                                value={values.identry}
                                                onChange={(event) => {
                                                    setFieldValue("identry", event.target.value);
                                                }}
                                                error={errors.identry && touched.identry ? true : false}
                                                fullWidth
                                            >
                                                {
                                                    entry.map((item, i) => {
                                                        return <MenuItem key={i} value={item.id}>{item.name}</MenuItem>;
                                                    })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1"><b>Caja de Compensación:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="idcompensationbox"
                                                variant="standard"
                                                value={values.idcompensationbox}
                                                onChange={(event) => {
                                                    setFieldValue("idcompensationbox", event.target.value);
                                                }}
                                                error={errors.idcompensationbox && touched.idcompensationbox ? true : false}
                                                fullWidth
                                            >
                                                {
                                                    compensationBox.map((box, i) => {
                                                        return <MenuItem key={i} value={box.id}>{box.name}</MenuItem>;
                                                    })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    {
                                        !company && (
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                    <Typography variant="subtitle1" className="custom-input"><b>Logo:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Button variant="contained" component="label" fullWidth>
                                                        <input
                                                            id="image"
                                                            type="file"
                                                            name="image"
                                                            onChange={(event) => {
                                                                setFieldValue("image", setNameFile(event));
                                                            }}
                                                        />
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        )
                                    }

                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(company && actionSelected=='edit') ? "ACTUALIZAR" : "REGISTRAR"}
                                    cancelText="CANCELAR" 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}
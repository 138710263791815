import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Alert, Backdrop, Box, Breadcrumbs, Button, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar, Typography } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { WorkerService } from '@/service/services/Worker.service';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import GroupsIcon from '@mui/icons-material/Groups';
import { AssistanceService } from '@/service/services/Assistance.service';
import { Props } from './Delay.type';
import { ModalDelay } from '@/components/Modal/ModalDelay';
import { ModalDelayDelete } from '@/components/Modal/ModalDelayDelete';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

export const DelayView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [workers, setWorkers] = useState<any>([]);
    const [workerSelected, setWorkerSelected] = useState<any>('');
    const [delays, setDelays] = useState<any>([]);
    const [showDelayModal, setShowDelayModal] = useState<boolean>(false);
    const [showDelayDeleteModal, setShowDelayDeleteModal] = useState<boolean>(false);
    const [delaySelected, setDelaySelected] = useState<any>('');
    const [actionSelected, setActionSelected] = useState<any>('');
    const [view, setView] = useState<String>('workers');
    const [showDelays, setShowDelays] = useState<boolean>(false);

    const cabecera = [
        { name: 'n_document', label: 'RUT TRABAJADOR' },
        { name: 'surname', label: 'APELLIDO PATERNO' },
        { name: 'second_surname', label: 'APELLIDO MATERNO' },
        { name: 'name', label: 'NOMBRES' }];

    const cabeceraDelay = [
        { name: 'date', label: 'FECHA' },
        { name: 'total_delay', label: 'TOTAL DE HORAS' },
        { name: 'observation', label: 'OBSERVACION' },
    ];

    useEffect(()=> {
        getWorkers()
    },[]);

    useEffect(()=> {
        if(workerSelected){
            getDelaysByEmployee(workerSelected.id);
        }
    },[workerSelected]);

    const getWorkers = async() => {
        setLoading(true);
        const resp:any = await WorkerService.getWorkers();
        setWorkers(resp.data || []);
        setLoading(false);
    }

    const getDelaysByEmployee = async(idEmployee) => {
        setLoading(true);
        await AssistanceService.getDelaysByEmployee(idEmployee).then((data) => {
            setDelays(data.data || []);
            setLoading(false);
        })
    }

    function RecuperarData(value){
        console.log(value);
        !workerSelected && setWorkerSelected(value);
        workerSelected && setDelaySelected(value);
        setShowDelays(true);
        setView('delays');
        // setOpenModal(true)
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Container>
            <Breadcrumbs aria-label="breadcrumb">
                <StyledBreadcrumb
                    label="Lista de Empleados"
                    onClick={() => {
                        setView('workers');
                        setWorkerSelected('');
                        setShowDelays(false);
                    }}
                    icon={<GroupsIcon fontSize="small" />}
                />
                {
                    showDelays && (
                        <StyledBreadcrumb
                            label={`Tardanzas`}
                            onClick={() => {setView('delays')}}
                            icon={<GroupsIcon fontSize="small" />}
                        />
                    )
                }
            </Breadcrumbs>
            {
                view == 'workers' && (
                    <TableDataV2
                        title={'Tardanzas por empleado'}
                        data={workers}
                        header={cabecera}
                        actionSelect={setActionSelected}
                        status_action
                        disabled_title={false}
                        btn_disabled
                        btn_edit_disable={true}
                        btn_delete_disable={true}
                        btn_helper
                        currentView={view}
                        RecuperarData={RecuperarData}
                        />
                )
            }
            {
                view == 'delays' && (
                    <TableDataV2
                        title={`Tardanzas de "${workerSelected?.name}"`}
                        data={delays}
                        header={cabeceraDelay}
                        actionSelect={setActionSelected}
                        setModalSave={setShowDelayModal}
                        setModalDelete={setShowDelayDeleteModal}
                        status_action
                        disabled_title={false}
                        btn_disabled={false}
                        btn_edit_disable={false}
                        btn_delete_disable={false}
                        currentView={view}
                        RecuperarData={RecuperarData}
                    />
                )
            }
            </Container>

            { 
                showDelayModal && (
                    <ModalDelay
                        open={showDelayModal}
                        closeModal={()=>{setShowDelayModal(false)}}
                        actionSelected={actionSelected}
                        onListDelays={getDelaysByEmployee}
                        delay={delaySelected}
                        workerSelected={workerSelected}
                        clearState={()=>{setDelaySelected('')}}
                    />
                )
            }
            {
                actionSelected === 'delete' && (
                    <ModalDelayDelete
                        open={showDelayDeleteModal}
                        closeModal={()=>{setShowDelayDeleteModal(false)}}
                        actionSelected={actionSelected}
                        onListDelays={getDelaysByEmployee}
                        delay={delaySelected}
                        workerSelected={workerSelected}
                        clearState={()=>{setDelaySelected('')}}
                    />
                )
            }
        
        </Protected>
    )
};
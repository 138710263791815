import { notificationsTypeRepository } from "../repositories/NotificationsType.repository";

export const notificationsTypeService = {
    getNotificationsTypes,
    createNotificationsType,
    editNotificationsType,
    deleteNotificationsType,
}

async function getNotificationsTypes(){
    const res = await notificationsTypeRepository.getNotificationsTypes();
    return res;
}

async function createNotificationsType(data){
    const res = await notificationsTypeRepository.createNotificationsType(data);
    return res;
}

async function editNotificationsType(data, idRequestType: number){
    const res = await notificationsTypeRepository.editNotificationsType(data, idRequestType);
    return res;
}

async function deleteNotificationsType(idRequestType:number){
    const res = await notificationsTypeRepository.deleteNotificationsType(idRequestType);
    return res;
 }
import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);
const rut_empresa = dataUser?.user?.rut_empresa;

export const ScheduleRepository = {

    //Jornadas
    getWorkingTime: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/scheduleCompany/workingTime`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    //Turnos
    getWorkingShift: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/scheduleCompany/workShift`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getScheduleCompany: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/scheduleCompany`);
        const {data, error, message} = res;
        return { data: (data||[]).map((raw) => ({
            id: raw?.id,
            check_in_times: raw?.check_in_times,
            closing_hour: raw?.closing_hour,
            idworkshift: raw?.idworkshift,
            idworkingtime: raw?.idworkingtime,
            idworkshift_name: raw?.work_shift?.name,
            idworkingtime_name: raw?.working_time?.name,
         }))
            , error, message };
    },

    deleteScheduleCompany: async(id): Promise<any> => {
        const res = await http.delete<any>(`${API_URL_BASE}/v1/scheduleCompany/${id}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    editScheduleCompany: async(id, dataEdit): Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/scheduleCompany/${id}`,{
            check_in_times: dataEdit.check_in_times,
            closing_hour: dataEdit.closing_hour,
            idworkshift: dataEdit.idworkshift,
            idworkingtime:  dataEdit.idworkingtime 
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    createScheduleCompany: async(dataEdit): Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/scheduleCompany`,{
            check_in_times: dataEdit.check_in_times,
            closing_hour: dataEdit.closing_hour,
            idworkshift: dataEdit.idworkshift,
            idworkingtime:  dataEdit.idworkingtime 
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    //HORARIOS POR TRABAJADOR

    getScheduleEmployee: async(id): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/workerSchedule/${id}`);
        const {data, error, message} = res;
        return { data , error, message };
    },

    deleteScheduleEmployee: async(id): Promise<any> => {
        const res = await http.delete<any>(`${API_URL_BASE}/v1/workerSchedule/${id}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    editScheduleEmployee: async(id, dataEdit): Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/workerSchedule/${id}`,{
            start_collation: dataEdit.start_collation,
            end_collation: dataEdit.end_collation
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    createScheduleEmployee: async(dataCreate): Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/workerSchedule`,{
            start_collation: dataCreate.start_collation,
            end_collation: dataCreate.end_collation,
            idworkschedulecompany: dataCreate.idworkschedulecompany,
            idemployee:  dataCreate.idemployee,
            start_date: dataCreate.start_date,
            end_date: dataCreate.end_date,
            weekly: dataCreate.weekly,
            observations: dataCreate.observations,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },
 
    getScheduleDetailEmployee: async(idEmployee, idSchedule): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/detailWorkerSchedule/schedules?employees=${idEmployee}&idworker_schedule=${idSchedule}`);
        const {data, error, message} = res;
        return { data , error, message };
    },


}   
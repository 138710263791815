import { RecluitingTeamRepository } from "../repositories/RecluitingTeam.repository";

export const RecluitingTeamService = {
    getRecluitingTeam,
    createRecluitingTeam,
    editRecluitingTeam
}

async function getRecluitingTeam(idJobOffert:number){
    const res = await RecluitingTeamRepository.getRecluitingTeam(idJobOffert);
    return res;
}

async function createRecluitingTeam(data){
    if(data.id_user && (data.id_user).length > 0){
        for(let i = 0; i < (data.id_user).length; i++){
            const dataFilter = {
                id_user: data.id_user[i].iduser,
                id_job_offer: data.id_job_offer,
                role: data.role
            } 
            const res = await RecluitingTeamRepository.createRecluitingTeam(dataFilter);
        }
    }
    // return res;
}

async function editRecluitingTeam(data:{}){
    const res = await RecluitingTeamRepository.editRecluitingTeam(data);
    return res;
}

import { RemunerationRepository } from "../repositories/Remuneration.repository";

export const RemunerationService = {
    verfifyRemuneration,
    preRemuneration,
    generatePDFPreRemuneration,
    createRemuneration,
    preRemunerationStoreMassive,
    generatePreRemunerationPDFMassive,
    createRemunerationMassive,
    requestRemunerationError,
    generatePDFRemuneration,
    changeEmployeeStatus,
    changeStatusRemuneration
}

async function verfifyRemuneration(idEmploye: number, idPeriodo: number){
    const res = await RemunerationRepository.verfifyRemuneration(idEmploye, idPeriodo);
    return res;
}

async function preRemuneration(data:{}, idEmploye: number){
    const res = await RemunerationRepository.preRemuneration(data, idEmploye);
    return res;
}

async function generatePDFPreRemuneration(data:{}){
    const res = await RemunerationRepository.generatePDFPreRemuneration(data);
    return res;
}

async function generatePDFRemuneration(data:{}){
    const res = await RemunerationRepository.generatePDFRemuneration(data);
    return res;
}

async function createRemuneration(data:{}, idEmploye: number){
    const res = await RemunerationRepository.createRemuneration(data, idEmploye);
    return res;
}

async function preRemunerationStoreMassive(data:{}){
    const res = await RemunerationRepository.preRemunerationStoreMassive(data);
    return res;
}

async function generatePreRemunerationPDFMassive(data:{}){
    const res = await RemunerationRepository.generatePreRemunerationPDFMassive(data);
    return res;
}

async function createRemunerationMassive(data){
    const res = await RemunerationRepository.createRemunerationMassive(data);
    return res;
}

async function requestRemunerationError(data){
    const res = await RemunerationRepository.requestRemunerationError(data);
    return res;
}

async function changeEmployeeStatus(id_remuneration, status_confirmation)
{
    const res = await RemunerationRepository.changeEmployeeStatus(id_remuneration, status_confirmation);
    return res;   
}

async function changeStatusRemuneration(id_employee)
{
    const res = await RemunerationRepository.changeStatusRemuneration(id_employee);
    return res;   
}

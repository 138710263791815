import React from 'react'
import { DialogContent } from '@mui/material';
import '../../assets/styles/modals.scss';

export const ModalBody = (props) => {

    const { children } = props;

    return (
        <DialogContent  dividers>
            {children}
        </DialogContent>
    )
}

import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Button, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { AssistanceService } from "@/service/services/Assistance.service";
import { Props } from "./ModalSignatureDetail.type";
import { WorkerValidationService } from "@/service/services/WorkerValidation.service";
import { ContractService } from "@/service/services/Contract.service";
import { API_URL_BASE } from "@/toolbox/defaults/app";

export const ModalSignatureDetail: React.FC<Props> = (props: Props): JSX.Element => {

    const { open, setOpen , idEmployee, getContractStatus, getContract} = props;

    const [loading, setLoading] = useState<boolean>(false);
    // const [imageDoc, setImageDoc] = useState<any>("" || undefined);
    const [detail, setDetail] = useState<any>(
        {
            "id": 1,
            "idtypesignature": 1,
            "typeSignatureName": "Firma electrónica simple",
            "typeSignatureUrl": "https://www.e-certchile.cl/productos/firmas-electronicas/firma-electronica-simple",
            "idemployee": 15,
            "employeeName": "carreño romero pierre",
            "EmployeeN_document": "71530135-1",
            "img": "images/company/76001228-9/71530135-1/1671608278 - Firma.jpg",
            "date_created": "2022-12-21 07:37:58",
            "date_update": null,
            "observations": null,
            "status": "0"
        }
    )
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [typeSignature, setTypeSignature] = useState<any>([])
    const [typeSignatureSelect, setTypeSignatureSelect] = useState<any>({})
  
    const handleSubmit = async (e, status) => {
        status == 1 && e.preventDefault();
        console.log(status)
        setLoading(true);
        const objData = {
            status: status,
            observations: ""
        }
       const res =  await ContractService.editSignatureAdmin(detail.id, objData);
       console.log(res)
       getContract();
       getContractStatus();
       setOpen(false);

    }

    const getTypeSignature = async() => {
        const res = await ContractService.getTypeSignature();
        setTypeSignature(res?.data || [])
        return res?.data
    }

    const verImagen = async() => {
        setLoading(true);

        if(detail && detail.img){
            const route = `${API_URL_BASE}/${detail.img}`;
            window.open(route, '_blank');
        }

        setLoading(false);
    }

    const getDetailSignature = async()=>{
        const resTypeSignature = await getTypeSignature();
        const res = await ContractService.getDetailSignature(idEmployee);
        console.log(res)
        if(res.length == 0){
            setSnackBarConfig({...snackBarConfig, open:true, severity:'warning', message:'El usuario esta pendiente de enviar su firma'})
            setTimeout(()=>{
                setOpen(false)
            },1000)
            return
        }
        console.log(res.slice(-1)[0])
        setDetail(res.slice(-1)[0]);
        console.log(resTypeSignature)
        const resType = resTypeSignature.find((value) => value.id == res.slice(-1)[0].idtypesignature)
        console.log(resType)
        setTypeSignatureSelect(resType)
    }

    useEffect(()=>{
        getDetailSignature();

    },[])


    return (
        <>
            {loading && <Loading />}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>

            <Modal open={open} handleClose={() => setOpen(false)} handle size="sm">
                <ModalHeader
                    text={"Validar Firma"}
                    className='positionElements'
                    onCancel={() => setOpen(false)}
                >
                </ModalHeader>
                <ModalBody>

                    <form onSubmit={(e)=>handleSubmit(e,1)}>
                        <Grid container >
                        <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                                <Typography variant="body1"><b>Tipo de Firma:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Autocomplete
                                                    id="idtypesignature"
                                                    value={typeSignatureSelect}
                                                    disabled
                                                    options={typeSignature || []}
                                                    getOptionLabel={(option:any) => option.name || ""}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} variant="standard" label="Escriba para buscar (*)" />}
                                                    // onChange={(e:any, newValue) => {console.log(newValue); setTypeSignatureSelect(newValue.id) }}
                                                />
                                            </Grid>
                                        </Grid>

                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1" className="custom-input"><b>Imagen:</b></Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Button variant="contained" component="label" fullWidth onClick={()=>{verImagen()}}>
                                        Ver Imagen
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1" className="custom-input"><b>Estado:</b></Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                    disabled
                                    fullWidth
                                    multiline
                                    value={detail.status == 0 ? 'La verificación de la firma esta en proceso': detail.status == 1 ? 'Firma verificada': 
                                    detail.status == 2 ?'Solicitud de verificación eliminada':'Verificación rechazada' }
                                    />
                                </Grid>
                            </Grid>
                            


                        </Grid>
                        <Divider />

                        <ModalFooter
                            buttonType="submit"
                            confirmText={"CONFIRMAR"}
                            cancelText="RECHAZAR" 
                            onCancel={(e)=>handleSubmit(e,3)}
                            onConfirm={(e)=>handleSubmit(e,1)}
                        />


                    </form>


                </ModalBody>
            </Modal>
        </>
    );
}
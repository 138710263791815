
import { Protected } from "@/components/layout/Protected"
import { Container, Card, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Typography, Snackbar, Alert, Grid, Select, MenuItem, Button } from "@mui/material"
import { Props } from "./Request.type"
import { Loading } from "@/components/common/Loading";
import TableContainer from '@mui/material/TableContainer';
import { useEffect, useRef, useState } from "react";
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage"
import { PeriodService } from "@/service/services/Period.service";
import { RequestService } from "@/service/services/Request.service"
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";

export const RequestView: React.FC<Props> = (props: any): JSX.Element => {

    const sessionUSer = readLocalStorage(KEY_USER_DATA);
    const idCompany = sessionUSer?.user?.id_company;

    const [loading, setLoading] = useState<boolean>(false);
    const [periods, setPeriods] = useState<any>([]);
    const [years, setYears] = useState<any>([]);
    const [yearSelected, setYearSelected] = useState<any>('');
    const [monthSelected, setMonthSelected] = useState<any>('');

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    useEffect(() => {
        if(years.length == 0) {
            getPeriods();
        }
    }, []);
   
    const [request, setRequest] = useState<any>();
    const [countEmployees, setCountEmployees] = useState<any>('');
    const [countRequest, setCountRequest] = useState<any>('');
    const [open, setOpen] = useState(false);


    const getPeriods = async () => {
        setLoading(true);
        const resp: any = await PeriodService.getPeriods();
        const newData = [];
        const arrayYears = [];
        resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
           newData.push({
              id: item.id,
              month: item.month,
              year: item.year,
              alias_name: item.month + '-' + item.year,
           })
           const found = arrayYears.find(element => element == item.year);
           if (!found) { arrayYears.push(item.year); }
  
        });
        setYears(arrayYears);
        setPeriods(newData || []);
        setLoading(false);
    }

    const onSubmit = async () => {
        setRequest('');
        setLoading(true);
        if (!monthSelected || !yearSelected) {
           setLoading(false);
           return (
              setSnackBarConfig(prev => ({
                 ...prev, open: true,
                 severity: 'warning',
                 message: `Debe llenar todos los campos`
              }
              ))
           );
        }
  
          const periodSelected = monthSelected + '-' + yearSelected;
          const period = periods.find(element => element.alias_name == periodSelected);
  
        await RequestService.getRequestReport(idCompany, period.id).then((data)=> {
          const countEmployees = data?.data?.employee?.countEmployees || 0;
          const countRequest = data?.data?.employee?.countRequests || 0;
          const request = data?.data?.employee?.request || [] ;
  
          setCountEmployees(countEmployees);
          setCountRequest(countRequest);
  
          let newData = [];
  
          if(request.length > 0){
              for (let index = 0; index < request.length; index++) {
                  if(!Array.isArray(request[index])){
                      newData.push(request[index])
                  }
              }
          }
          if (newData.length > 0) {
              let newDataFilter = [];
              for (let index = 0; index < (newData).length; index++) {
                  const key: any = Object.keys(newData[index]);
                  const values: any = Object.values(newData[index]);
  
                  values.length > 0 && values.map((arrayItem, i) => {
                      if (arrayItem.length > 0) {
                          const Slope = (arrayItem[0]);
                          const Accepted = (arrayItem[1]);
                          const Rejected = (arrayItem[2]);
  
                          let newData = {
                             requestName: key[i],
                             slope: Slope,
                             accepted: Accepted,
                             rejected: Rejected,
                          }
                          newDataFilter.push(newData)
                      }
                  });
              }
              setRequest(newDataFilter);
          }
  
        })
  
        setLoading(false);
     }

    return (
        <Protected>
            {loading && <Loading />}
            <Snackbar
                open={snackBarConfig.open} 
                autoHideDuration={snackBarConfig.autoHideDuration} 
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>

            <Container>
                <Typography variant='h6' component='h1' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                    REPORTE DE SOLICITUDES
                </Typography>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center" >

                    <Grid item xs={1}>
                        <Typography variant="body1"><b>Período:</b> </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            id="id_education_level"
                            variant="standard"
                            value={yearSelected}
                            onChange={(event) => {
                                setYearSelected(event.target.value);
                            }}
                            fullWidth
                        >
                            {
                                years.map((year, i) => {
                                    return <MenuItem key={i} value={year}>{year}</MenuItem>;
                                })
                            }
                        </Select>
                    </Grid>

                    <Grid item xs={1}></Grid>

                    <Grid item xs={1}>
                        <Typography variant="body1"><b>Mes:</b> </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            id="id_education_level"
                            variant="standard"
                            value={monthSelected}
                            onChange={(event) => { setMonthSelected(event.target.value) }}
                            fullWidth
                        >
                            <MenuItem value={'01'}>Enero</MenuItem>
                            <MenuItem value={'02'}>Febrero</MenuItem>
                            <MenuItem value={'03'}>Marzo</MenuItem>
                            <MenuItem value={'04'}>Abril</MenuItem>
                            <MenuItem value={'05'}>Mayo</MenuItem>
                            <MenuItem value={'06'}>Junio</MenuItem>
                            <MenuItem value={'07'}>Julio</MenuItem>
                            <MenuItem value={'08'}>Agosto</MenuItem>
                            <MenuItem value={'09'}>Septiembre</MenuItem>
                            <MenuItem value={'10'}>Octubre</MenuItem>
                            <MenuItem value={'11'}>Noviembre</MenuItem>
                            <MenuItem value={'12'}>Diciembre</MenuItem>
                        </Select>
                    </Grid>

                    {
                    <Grid container item xs={2} alignItems="center" justifyContent="center" marginBottom='25px'>
                        <Button onClick={onSubmit} type={'submit'} size="small" color={"primary"} variant="contained" >
                            {`FILTRAR`}
                        </Button>
                    </Grid>
                    }
                </Grid>

                {/* <FilterRequest setRequest={setRequest} setCountEmployees={setCountEmployees} setCountRequest={setCountRequest} /> */}

                {
                    countEmployees && (
                        <Typography variant='subtitle1' component='h4' gutterBottom  sx={{ m: 1, fontWeight: 600, width: "100%", marginTop: '20px' }}>
                            Se encontraron {countEmployees} empleados registrados
                        </Typography>
                    )
                }
                {
                    (countRequest && countRequest > 0) && (
                        <Typography variant='subtitle1' component='h4' gutterBottom  sx={{ m: 1, fontWeight: 600, width: "100%", marginTop: '20px' }}>
                            Se encontraron {countRequest} solicitudes registrados
                        </Typography>
                    ) || <div></div>
                }
                

                {
                    request && request.length > 0 && request.map((item, index)=> (
                        <Card sx={{ width: "100%", marginTop: '20px' }}>
                            <Typography variant='h6' component='h2' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                                {item.requestName}
                            </Typography>
                            <TableContainer>
                                <Table>
                                    <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                        <TableRow >
                                            <TableCell >
                                                <Typography>Estado de solicitud</Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography>Cantidad Empleados</Typography>
                                            </TableCell>
                                            <TableCell sx={{width:'500px'}}>
                                                <Typography>Empleados</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        <TableRow >
                                            <TableCell size={'small'}>
                                                Pendientes
                                            </TableCell>
                                            <TableCell size={'small'}>
                                                {item.slope.Slope}
                                            </TableCell>
                                            <TableCell size={'small'} component="th" scope="row">
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="small"
                                                    onClick={() => request.indexOf(item) == index && setOpen(!open)}
                                                >
                                                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </IconButton>
                                                <Collapse in={open} timeout="auto" unmountOnExit>
                                                        <List component="div" disablePadding>
                                                            {
                                                                (item.slope.detail).map((employee)=>(
                                                                    <ListItemButton>
                                                                        {employee}
                                                                    </ListItemButton>
                                                                ))
                                                            }
                                                        </List>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell size={'small'}>
                                                Aceptados
                                            </TableCell>
                                            <TableCell size={'small'}>
                                                {item.accepted.Accepted}
                                            </TableCell>
                                            <TableCell size={'small'} component="th" scope="row">
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="small"
                                                    onClick={() => request.indexOf(item) == index && setOpen(!open)}
                                                >
                                                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </IconButton>
                                                <Collapse in={open} timeout="auto" unmountOnExit>
                                                    <List component="div" disablePadding>
                                                        {
                                                            (item.accepted.detail).map((employee)=>(
                                                                <ListItemButton>
                                                                    {employee}
                                                                </ListItemButton>
                                                            ))
                                                        }
                                                    </List>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell size={'small'}>
                                                Rechazados
                                            </TableCell>
                                            <TableCell size={'small'}>
                                                {item.rejected.Rejected}
                                            </TableCell>
                                            <TableCell size={'small'}  component="th" scope="row">
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="small"
                                                    onClick={() => request.indexOf(item) == index && setOpen(!open)}
                                                >
                                                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </IconButton>
                                                <Collapse in={open} timeout="auto" unmountOnExit>
                                                    <List component="div" disablePadding>
                                                        {
                                                            (item.rejected.detail).map((employee)=>(
                                                                <ListItemButton>
                                                                    {employee}
                                                                </ListItemButton>
                                                            ))
                                                        }
                                                    </List>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card>
                    ))
                }
                

            </Container>

        </Protected>
    )
}
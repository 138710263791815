import { deparmentRepository } from "../repositories/Deparment.repository";

export const departmentService = {
    getDeparments,
    createDepartment,
    editDepartment,
    deleteDepartment,
    getResponsibilities,
    createResponsibilities,
    editResponsibilities,
    deleteResponsibilities,
}

async function getDeparments(){
   const res = await deparmentRepository.getDeparments();
   return res;
}

async function createDepartment(data:{}){
   const res = await deparmentRepository.createDepartment(data);
   return res;
}

async function editDepartment(data:{}, idDepartment: number){
   const res = await deparmentRepository.editDepartment(data, idDepartment);
   return res;
}

async function deleteDepartment(idDepartment:number){
   const res = await deparmentRepository.deleteDepartment(idDepartment);
   return res;
}

async function getResponsibilities(idArea: number){
   const res = await deparmentRepository.getResponsibilities(idArea);
   return res;
}

async function createResponsibilities(data:{}){
   const res = await deparmentRepository.createResponsibilities(data);
   return res;
}

async function editResponsibilities(data:{}, idArea: number){
   const res = await deparmentRepository.editResponsibilities(data, idArea);
   return res;
}

async function deleteResponsibilities(idArea:number){
   const res = await deparmentRepository.deleteResponsibilities(idArea);
   return res;
}

import { requestTypeRepository } from "../repositories/RequestType.repository";

export const requestTypeService = {
    getRequestTypes,
    createRequestType,
    editRequestType,
    deleteRequestType,
}

async function getRequestTypes(){
    const res = await requestTypeRepository.getRequestTypes();
    return res;
}

async function createRequestType(data){
    const res = await requestTypeRepository.createRequestType(data);
    return res;
}

async function editRequestType(data, idRequestType: number){
    const res = await requestTypeRepository.editRequestType(data, idRequestType);
    return res;
}

async function deleteRequestType(idRequestType:number){
    const res = await requestTypeRepository.deleteRequestType(idRequestType);
    return res;
 }
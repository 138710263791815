import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Autocomplete, Divider, Grid, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalStructureManteiner.type";
import { TimeZoneService } from "@/service/services/TimeZone.service";
import { RleStructureService } from "@/service/services/RleStructure.service";
import { CountryService } from "@/service/services/Country.service";

export const ModalStructureManteiner: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, structure, onListStructure, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);

    const [data, setData] = useState({
        id: structure ? structure.id : '',
        code: structure ? structure.code : '',
        name: structure ? structure.name : '',
        idtypestructure: structure ? structure.idtypestructure : '',
        required: structure ? structure.required : '',
        percentage: 1,
        gratification: structure ? structure.gratification : '',
        concept_DT: structure ? structure.concept_DT : '',
        idcountry: structure ? structure.idcountry : '',
        
    });

    const [typeStructureSelected, setTypeStructureSelected] = useState<any>(null);
    const [typeStructure,setTypeStructure] = useState<any>([])
    const requiredOptions = [
        {id:"1", name:'Si'},
        {id:"0", name:'No'}
    ]
    const [requiredSelected, setRequiredSelected] = useState<any>(null)
    const gratificationOptions = [
        {id:"1", name:'Si'},
        {id:"0", name:'No'}
    ]
    const [gratificationSelected, setGratificationSelected] = useState<any>(null)
    const [countries,setCountries] = useState<any>([])
    const [countrySelected, setCountrySelected] = useState<any>(null)
    const validateForm = (values) => {
        let errors: any = {};
        if(!values.code) errors.code = "nombre requerido";
        if(!values.name) errors.name = "nombre requerido";
        if(!typeStructureSelected) errors.idtypestructure = "nombre requerido";
        if(!requiredSelected) errors.required = "nombre requerido";
        if(!values.percentage) errors.percentage = "nombre requerido";
        if(!values.gratification) errors.gratification = "nombre requerido";
        if(!values.concept_DT) errors.concept_DT = "nombre requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            id: values.id,
            code: values.code,
            name: values.name,
            idtypestructure: typeStructureSelected.id,
            required: requiredSelected.id,
            percentage: values.percentage,
            gratification: gratificationSelected.id,
            concept_DT: values.concept_DT,
            idcountry: countrySelected.id,
        }
        setLoading(true);
        if(structure && actionSelected=='edit'){
            await RleStructureService.editRLEStructure(structure.id, formData).then((data) => {
                onListStructure && onListStructure();
                closeModal && closeModal();
            })
        }else{
            await RleStructureService.createStructure(formData).then((data) => {
                onListStructure && onListStructure();
                closeModal && closeModal();
            })
        }
        setLoading(false);
    }

    const getTypeStructure = async () =>{
        const resp:any = await RleStructureService.getRLEStructureType();
        setTypeStructure(resp.data)
        if(actionSelected == 'edit'){
            const objTypeStructure = resp.data.find((value)=> value.id == structure.idtypestructure)
            setTypeStructureSelected(objTypeStructure)
        }
    }

    const getCountry = async () => {
        const resp:any = await CountryService.getCountries();
        setCountries(resp.data)
        if(actionSelected == 'edit'){
            const objCountry = resp.data.find((value)=> value.id == structure.idcountry)
            setCountrySelected(objCountry)
        }
    }

    const getDataEdit = () => {
        const objRequired =  requiredOptions.find((value) => value.id == structure.required)
        setRequiredSelected(objRequired)
        const objGrati =  gratificationOptions.find((value) => value.id == structure.gratification)
        setGratificationSelected(objGrati)
    }
    useEffect(()=>{
        getTypeStructure();
        getCountry();
        if(actionSelected == 'edit'){getDataEdit();}
    },[])
    return(
        <>
        {loading && <Loading/>}

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={(structure ? "Editar Estructura de Liquidación" : "Agregar Estructura de Liquidación")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Codigo:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="code"
                                            variant="standard"
                                            type="text" 
                                            name="code"
                                            fullWidth
                                            value={values.code}
                                            onChange={handleChange}
                                            placeholder="Código"
                                            error={errors.code && touched.code ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Nombre:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="name"
                                            variant="standard"
                                            type="text" 
                                            name="name"
                                            fullWidth
                                            value={values.name}
                                            onChange={handleChange}
                                            placeholder="Nombre"
                                            error={errors.name && touched.name ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                                <Typography variant="body1"><b>Tipo de Estructura:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Autocomplete
                                                    id="disable-portal"
                                                    disablePortal
                                                    value={typeStructureSelected}
                                                    options={typeStructure || []}
                                                    getOptionLabel={(option) => option.name || ""}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} variant="standard" label="Escriba para buscar" />}
                                                    onChange={(e, newValue) => { setTypeStructureSelected(newValue) }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                                <Typography variant="body1"><b>Obligatorio:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Autocomplete
                                                    id="disable-portal"
                                                    disablePortal
                                                    value={requiredSelected}
                                                    options={requiredOptions || []}
                                                    getOptionLabel={(option) => option.name || ""}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} variant="standard" label="Escriba para buscar" />}
                                                    onChange={(e, newValue) => { setRequiredSelected(newValue) }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                                <Typography variant="body1"><b>Gratificación:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Autocomplete
                                                    id="disable-portal"
                                                    disablePortal
                                                    value={gratificationSelected}
                                                    options={gratificationOptions || []}
                                                    getOptionLabel={(option) => option.name || ""}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} variant="standard" label="Escriba para buscar" />}
                                                    onChange={(e, newValue) => { setGratificationSelected(newValue) }}
                                                />
                                            </Grid>
                                        </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Concepto DT:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="concept_DT"
                                            variant="standard"
                                            type="text" 
                                            name="concept_DT"
                                            fullWidth
                                            value={values.concept_DT}
                                            onChange={handleChange}
                                            placeholder="Concepto DT"
                                            error={errors.concept_DT && touched.concept_DT ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                                <Typography variant="body1"><b>Pais:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Autocomplete
                                                    id="disable-portal"
                                                    disablePortal
                                                    value={countrySelected}
                                                    options={countries || []}
                                                    getOptionLabel={(option) => option.name || ""}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} variant="standard" label="Escriba para buscar" />}
                                                    onChange={(e, newValue) => { setCountrySelected(newValue) }}
                                                />
                                            </Grid>
                                        </Grid>

                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(structure && actionSelected=='edit') ? "ACTUALIZAR" : "REGISTRAR"}
                                    cancelText="CANCELAR" 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { userRepository } from "@/service/repositories/User.repository";
import { ConfigurationIPService } from "@/service/services/ConfigurationIP.service";
import { Props } from "./ModalIpDenied.type";

export const ModalIpDenied: React.FC<Props> = (props:any): JSX.Element => {
    const { open, closeModal, actionSelected, userIp, onListIps, clearState } = props;

    console.log(userIp)

    const [loading, setLoading] = useState<boolean>(false);

    const [users, setUsers] = useState([]);
    const [userSelected, setUserSelected] = useState<any>('');

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });
    
    const [data, setData] = useState<any>({
        id: userIp ? userIp.id : '',
        ip: userIp ? userIp.ip : '',
        iduser:  userIp ? userIp.iduser : '',
        permission:  userIp ? userIp.permission : '',
    });

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        if(userIp && users){
            setCustomUser(userIp.iduser, users);
        }

    },[userIp, users]);

    const getUsers = async () => {
        setLoading(true);
        await userRepository.getUsers().then((data) => {
            setUsers(data.data || [])
           setLoading(false);
        });
    }

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.ip ) errors.ip = "ip requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        if(!userSelected && !userIp){
            return (
               setSnackBarConfig(prev => ({ ...prev, open: true, 
                  severity:'warning', 
                  message: `Debe seleccionar Usuario.` }
               ))
            );
        }

        const formData = {
            id: values.id,
            ip: values.ip,
            iduser: userSelected.iduser,
            permission : values.permission
        }

        setLoading(true);
        if(userIp && actionSelected=='edit'){
            await ConfigurationIPService.editDeniedIP(formData, userIp.id).then((data) => {
                onListIps && onListIps();
                closeModal && closeModal();
            })
        }else{
            await ConfigurationIPService.deniedIP(formData).then((data) => {
                onListIps && onListIps();
                closeModal && closeModal();
            })
        }
        setLoading(false);
    }

    function setCustomUser(idUser, users): any {
        const data = users && users.find(user => user.iduser === idUser);
        const newData = {
            id: data?.id,
            iduser: data?.iduser,
            email: data?.email,
            user_name: data?.user_name,
            role: data?.role,
            n_document: data?.n_document,
        }
        return setUserSelected(newData);
    }

    return(
        <>

        {loading && <Loading/>}

        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={(userIp && actionSelected=='edit') ? "Editar IP" : "Agregar IP"}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    <Grid item xs={12} container  marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>IP:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="ip"
                                            variant="standard"
                                            type="text" 
                                            name="ip"
                                            multiline
                                            maxRows={4}
                                            fullWidth
                                            value={values.ip}
                                            onChange={handleChange}
                                            placeholder="escribir IP"
                                            error={errors.ip && touched.ip ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    {
                                        !userIp && (
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>Usuario:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Autocomplete
                                                        id="disable-portal"
                                                        disablePortal
                                                        value={userSelected}
                                                        options={users || []}
                                                        getOptionLabel={(option) => option.user_name || ""}
                                                        fullWidth
                                                        renderInput={(params) => <TextField {...params} variant="standard" label="Escriba para buscar" />}
                                                        onChange={(e, newValue) => {  setUserSelected(newValue) }}
                                                    />
                                                </Grid>
                                            </Grid> 
                                        )
                                    }                                  

                                    {
                                        userIp && (
                                            <Grid container item xs={12} alignItems="center" justifyContent="center">
                                                <Grid item xs={4}>
                                                    <Typography variant="body1"><b>Permiso:</b> </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Select
                                                        id="permission"
                                                        name='permission'
                                                        variant="standard"
                                                        value={values.permission}
                                                        onChange={(event) => { 
                                                        setFieldValue("permission", event.target.value);
                                                        }}
                                                        fullWidth
                                                    >
                                                    <MenuItem key={1} value={1}>Aceptado</MenuItem>;
                                                    <MenuItem key={2} value={0}>Denegado</MenuItem>;
                                                        
                                                    </Select>
                                                </Grid>
                                            </Grid> 
                                        )
                                    }

                                    
                                 
                                </Grid>
                                <Divider style={{margin: '30px 0px 10px 0px'}}/>
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(userIp && actionSelected=='edit') ? "ACTUALIZAR" : "REGISTRAR"}
                                    cancelText="CANCELAR" 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}
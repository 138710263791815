import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Autocomplete, Divider, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalScheduleCompany.type";
import { ScheduleService } from "@/service/services/Schedule.service";

export const ModalScheduleCompany: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, schedule, onList, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [workShiftOptions,setWorkShiftOptions] = useState<string[]>([]);
    const [workTimeOptions, setWorkTimeOptions] = useState<string[]>([]);
    const [workShiftSelected, setWorkShiftSelectede] = useState(null);
    const [workTimeSelected, setWorkTimeSelected] = useState(null);
    const [data, setData] = useState({
        id:  schedule ? schedule.id : '',
        check_in_times: schedule ? schedule.check_in_times : '',
        closing_hour: schedule ? schedule.closing_hour : '',
    });

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.name) errors.name = "nombre requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            id: values.id,
            check_in_times: values.check_in_times,
            closing_hour : values.closing_hour,
            idworkshift: workShiftSelected.id,
            idworkingtime: workTimeSelected.id
        }
        setLoading(true);
        if(schedule && actionSelected=='edit'){
            await ScheduleService.editScheduleCompany(formData, schedule.id).then((data) => {
                onList && onList();
                closeModal && closeModal();
            })
        }else{
            await ScheduleService.createScheduleCompany(formData).then((data) => {
                onList && onList();
                closeModal && closeModal();
            })
        }
        setLoading(false);
    }

    const getWorkShift = async() => {
        await ScheduleService.getWorkingShift().then((data) => {
            setWorkShiftOptions(data.data)
            if(actionSelected=='edit'){
                const newObj = data.data.find( item => item.id == schedule.idworkshift  )
                setWorkShiftSelectede(newObj)
            }
        })
    }

    const getWorkTime = async() => {
        await ScheduleService.getWorkingTime().then((data) => {
            setWorkTimeOptions(data.data)
            if(actionSelected=='edit'){
                const newObj = data.data.find( item => item.id == schedule.idworkingtime  )
                setWorkTimeSelected(newObj)
            }
        })
    }

    useEffect(()=>{
        getWorkShift();
        getWorkTime();
    },[])

    return(
        <>
        {loading && <Loading/>}

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={(schedule ? "editar horario" : "agregar horario")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Horario de Entrada:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="check_in_times"
                                            variant="standard"
                                            type="time" 
                                            name="check_in_times"
                                            fullWidth
                                            value={values.check_in_times}
                                            onChange={handleChange}
                                            placeholder="Horario de Entrada"
                                            error={errors.check_in_times && touched.check_in_times ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Horario de Salida:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="closing_hour"
                                            variant="standard"
                                            type="time" 
                                            name="closing_hour"
                                            fullWidth
                                            value={values.closing_hour}
                                            onChange={handleChange}
                                            placeholder="Horario de Entrada"
                                            error={errors.closing_hour && touched.closing_hour ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="body1"><b>Jornada:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                id="disable-portal"
                                                disablePortal
                                                value={workShiftSelected}
                                                options={workShiftOptions || []}
                                                getOptionLabel={(option) => option.name || ""}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} variant="standard" label="Escriba para buscar" />}
                                                onChange={(e, newValue) => {  setWorkShiftSelectede(newValue) }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="body1"><b>Turno:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                id="disable-portal"
                                                disablePortal
                                                value={workTimeSelected}
                                                options={workTimeOptions || []}
                                                getOptionLabel={(option) => option.name || ""}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} variant="standard" label="Escriba para buscar" />}
                                                onChange={(e, newValue) => {  setWorkTimeSelected(newValue) }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(schedule && actionSelected=='edit') ? "ACTUALIZAR" : "REGISTRAR"}
                                    cancelText="CANCELAR" 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { ProjectService } from "@/service/services/Project.service";
import { Props } from "./ModalProjectParticipation.type";
import { PeriodService } from "@/service/services/Period.service";

export const ModalProjectParticipation: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, participation, onListParticipations, detailSelected, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);

    const [periods, setPeriods] = useState<any>([]);
    const [years, setYears] = useState<any>([]);
    const [yearSelected, setYearSelected] = useState<any>('');
    const [monthSelected, setMonthSelected] = useState<any>('');

    const [data, setData] = useState({
        id: participation ? participation.id : '',
        idperiod: participation ? participation.idperiod : '',
        idemployee: participation ? participation.idemployee : '',
        participation_percentage: participation ? participation.participation_percentage : '',
        total: participation ? participation.total : '',
        iddetailproject: participation ? participation.iddetailproject : '',
    });

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    })

    useEffect(()=> {
        getPeriodsActually()
    },[]);

    const getPeriodsActually = async() => {
        setLoading(true);
        const resp:any = await PeriodService.getPeriods();
        const newData = [];
        const arrayYears = [];
        resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
            newData.push({
                id: item.id,
                month: item.month,
                year: item.year,
                alias_name: item.month + '-' +  item.year,
            })
            const found = arrayYears.find(element => element == item.year);
            if(!found) { arrayYears.push(item.year); }
        });
        setYears(arrayYears);
        setPeriods(newData || []);
        setLoading(false);
    }

    const validateForm = (values) => {
        let errors: any = {};
        // if(!values.participation_percentage) errors.participation_percentage = "porcentaje requerido";
        // if(!values.total) errors.total = "total requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        if(!monthSelected || !yearSelected){
            return (
               setSnackBarConfig(prev => ({ ...prev, open: true, 
                  severity:'warning', 
                  message: `Debe seleccionar Mes y Año.` }
               ))
            );
        }

        const periodSelect = monthSelected + '-' + yearSelected;
        const period = periods.find(element => element.alias_name == periodSelect);

        const startProyect = new Date(detailSelected.start_date);
        const endProyect = new Date(detailSelected.end_date);
        const dateSelected = new Date(yearSelected + '-' + monthSelected + '-' + '01');
        console.log(startProyect);
        console.log(endProyect);
        console.log(dateSelected);
        if(dateSelected >= endProyect || dateSelected <= startProyect){
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                   severity:'warning', 
                   message: `El periodo seleccionado no se encuentra en el rango de Inicio y Fin del Proyecto.` }
                ))
             );
        }

        const formData = {
            id: values.id,
            idperiod : period.id,
            idemployee : detailSelected.idemployee,
            participation_percentage : values.participation_percentage && (values.participation_percentage / 100),
            total : values.total,
            iddetailproject : detailSelected.iddetailproject,
        }

        setLoading(true);
        let verifyQuantity;
        let verifyPorcentage;

        if(values.total != ''){
            await ProjectService.verifyTotal(detailSelected.idemployee, period.id, values.total).then((data) => {
                if(data.data.status == true) {
                    verifyQuantity = true;
                }else{
                    verifyQuantity = false;
                    return (
                        setSnackBarConfig(prev => ({ ...prev, open: true, 
                            severity:'warning', 
                            message: `${data.data.message || 'Ocurrió algun error, vuelva a intentarlo.'}` }
                        ))
                    )
                }
            })
        }

        if(values.participation_percentage != ''){
            const particpation = (values.participation_percentage / 100)
            await ProjectService.verifyParticipation(detailSelected.idemployee, period.id, particpation).then((data) => {
                if(data.data.status == true) {
                    verifyPorcentage = true;
                }else{
                    verifyPorcentage = false;
                    return (
                        setSnackBarConfig(prev => ({ ...prev, open: true, 
                            severity:'warning', 
                            message: `${data.data.message || 'Ocurrió algun error, vuelva a intentarlo.'}` }
                        ))
                    )
                }
            })
        }

        if(verifyPorcentage == true || verifyQuantity == true){
            await ProjectService.createDetailParticipation(formData).then((data) => {
                if(data.data.status == false) {
                    setLoading(false);
                    return (
                        setSnackBarConfig(prev => ({ ...prev, open: true, 
                            severity:'warning', 
                            message: `${data.data.message || 'Ocurrió algun error, vuelva a intentarlo.'}` }
                        ))
                    )
                }
                onListParticipations && onListParticipations(detailSelected.iddetailproject);
                closeModal && closeModal();
            })
        }
        
        setLoading(false);
    }

    return(
        <>
        {loading && <Loading/>}

        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={"Agregar Participación"}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>Período:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="id_education_level"
                                                variant="standard"
                                                value={yearSelected}
                                                onChange={(event) => {
                                                setYearSelected(event.target.value);
                                                }}
                                                fullWidth
                                            >
                                                {
                                                years.map((year, i) => {
                                                    return <MenuItem key={i} value={year}>{year}</MenuItem>;
                                                })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>Mes:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="id_education_level"
                                                variant="standard"
                                                value={monthSelected}
                                                onChange={(event) => {setMonthSelected(event.target.value)}}
                                                fullWidth
                                            >
                                                <MenuItem value={'01'}>Enero</MenuItem>
                                                <MenuItem value={'02'}>Febrero</MenuItem>
                                                <MenuItem value={'03'}>Marzo</MenuItem>
                                                <MenuItem value={'04'}>Abril</MenuItem>
                                                <MenuItem value={'05'}>Mayo</MenuItem>
                                                <MenuItem value={'06'}>Junio</MenuItem>
                                                <MenuItem value={'07'}>Julio</MenuItem>
                                                <MenuItem value={'08'}>Agosto</MenuItem>
                                                <MenuItem value={'09'}>Septiembre</MenuItem>
                                                <MenuItem value={'10'}>Octubre</MenuItem>
                                                <MenuItem value={'11'}>Noviembre</MenuItem>
                                                <MenuItem value={'12'}>Diciembre</MenuItem>
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Participación (%):</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                id="participation_percentage"
                                                variant="standard"
                                                type="number" 
                                                fullWidth
                                                name="participation_percentage"
                                                onChange={handleChange}
                                                placeholder="porcentaje participacion"
                                                value={values.participation_percentage}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={errors.participation_percentage && touched.participation_percentage ? true : false}
                                                disabled={values.total!='' ?  true : false}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Total:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="total"
                                            variant="standard"
                                            type="number" 
                                            name="total"
                                            fullWidth
                                            value={values.total}
                                            onChange={handleChange}
                                            placeholder="total participacion"
                                            error={errors.total && touched.total ? true : false}
                                            disabled={values.participation_percentage!='' ?  true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={"REGISTRAR"}
                                    cancelText="CANCELAR" 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}
import { companiesRepository } from "../repositories/Companies.repository";

export const companiesService = {
    getCompaniesAdmin,
    getCompanies,
    getGroupCompanies,
    getGroupCompaniesById,
    companiesByUser,
    createCompany,
    createRequestGroupCompany,
    editCompany,
    editDecisionGroupCompany,
    deleteCompany,
    getCajas,
    getMutualityByCompany,
    getDataCompany,
    updateMutual,
    updateExtraHours,
    updateCloseDay,
    deleteGroupCompany
}

async function getCompaniesAdmin(){
    const res = await companiesRepository.getCompaniesAdmin();
    return res;
}

async function getCompanies(){
    const res = await companiesRepository.getCompanies();
    return res;
}

async function getGroupCompanies(){
    const res = await companiesRepository.getGroupCompanies();
    return res;
}

async function getGroupCompaniesById(id){
    const res = await companiesRepository.getGroupCompaniesById(id);
    return res;
}

async function editDecisionGroupCompany(id, decision){
    const res = await companiesRepository.editDecisionGroupCompany(id, {status: decision});
    return res;
}

async function companiesByUser(idUser:number){
    const res = await companiesRepository.companiesByUser(idUser);
    return res;
}

async function createCompany(data){
    const res = await companiesRepository.createCompany(data);
    return res;
}

async function createRequestGroupCompany(data){
    const res = await companiesRepository.createRequestGroupCompany(data);
    return res;
}

async function editCompany( idCompany ,data){
    const res = await companiesRepository.editCompany(idCompany, data);
    return res;
}

async function deleteCompany( idCompany){
    const res = await companiesRepository.deleteCompany(idCompany);
    return res;
}

async function getCajas(){
    const res = await companiesRepository.getCajas();
    return res;
}

async function getMutualityByCompany(){
    const res = await companiesRepository.getMutualityByCompany();
    return res;
}

async function getDataCompany(){
    const res = await companiesRepository.getDataCompany();
    return res;
}

async function updateMutual(dataCompany){
    const res = await companiesRepository.updateMutual(dataCompany);
    return res;
}

async function updateExtraHours(dataCompany){
    const res = await companiesRepository.updateExtraHours(dataCompany);
    return res;
}

async function updateCloseDay(dataCompany){
    const res = await companiesRepository.updateCloseDay(dataCompany);
    return res;
}

async function deleteGroupCompany(dataCompany){
    const res = await companiesRepository.deleteGroupCompany(dataCompany);
    return res;
}

import { PhasesRepository } from "../repositories/Phases.repository";

export const PhasesService = {
    getPhases,
    createPhase,
    editPhase,
}

async function getPhases(){
    const res = await PhasesRepository.getPhases();
    return res;
}

async function createPhase(data:{}){
    const res = await PhasesRepository.createPhase(data);
    return res;
}

async function editPhase(data:{}){
    const res = await PhasesRepository.editPhase(data);
    return res;
}


import { AdvanceRepository } from "../repositories/Advance.repository";

export const AdvanceService = {
    getAdvancesByEmployee,
    createAdvance,
    editAdvance,
    deleteAdvance,
    acceptAdvance,
}

async function getAdvancesByEmployee(idEmployee: number){
    const res = await AdvanceRepository.getAdvancesByEmployee(idEmployee);
    return res;
}

async function createAdvance(data, idEmployee: number){
    const res = await AdvanceRepository.createAdvance(data, idEmployee);
    return res;
}

async function editAdvance(data, idAdvance: number){
    const res = await AdvanceRepository.editAdvance(data, idAdvance);
    return res;
}

async function acceptAdvance(data, idAdvance: number){
    const res = await AdvanceRepository.acceptAdvance(data, idAdvance);
    return res;
}

async function deleteAdvance(idAdvance:number){
    const res = await AdvanceRepository.deleteAdvance(idAdvance);
    return res;
}

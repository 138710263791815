import { useEffect, useState } from "react"
import { TableDataV2 } from "@/components/common/Tablev2"
import { Protected } from "@/components/layout/Protected"
import { Alert, Autocomplete, Box, Button, Container, Grid, Snackbar, TextField, Typography } from "@mui/material"
import { Loading } from "@/components/common/Loading"
import { CountryService } from "@/service/services/Country.service"
import { Props } from "./Province.type"
import { ModalCountryProvince } from "@/components/Modal/ModalCountryProvince/ModalCountryProvince"
import { ModalCountryProvinceDelete } from "@/components/Modal/ModalCountryProvinceDelete/ModalCountryProvinceDelete"

export const ProvinceView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [provinces, setProvinces] = useState<any>([]);
    const [showProvinceModal, setShowProvinceModal] = useState<boolean>(false);
    const [showProvinceDeleteModal, setShowProvinceDeleteModal] = useState<boolean>(false);
    const [provinceSelected, setProvinceSelected] = useState<any>('');
    const [actionSelected, setActionSelected] = useState<any>('');

    const [countries, setCountries] = useState<any>([]);
    const [countrySelected, setCountrySelected] = useState<any>('');
    const [departaments, setDepartaments] = useState<any>([]);
    const [departamentSelected, setDepartamentSelected] = useState<any>('');

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });
    

    useEffect(() => {
        getCountries();
    }, []);

    useEffect(() => {
        if(countrySelected) getDepartments(countrySelected.id);
    }, [countrySelected]);

    const getCountries = async () => {
        setLoading(true);
        await CountryService.getCountries().then((data) => {
            setCountries(data.data || []);
            setCountrySelected(data.data[0] || '');
           setLoading(false);
        });
    }

    const getDepartments = async (idCountry) => {
        setLoading(true);
        await CountryService.getDepartments(idCountry).then((data) => {
            setDepartaments(data.data || [])
           setLoading(false);
        });
    }

    const getProvinces = async (idDepartament) => {
        setLoading(true);
        await CountryService.getProvinces(idDepartament).then((data) => {
            setProvinces(data.data || [])
           setLoading(false);
        });
    }

    function RecuperarData(value){
        setProvinceSelected(value)
        console.log(value)
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Snackbar
                open={snackBarConfig.open} 
                autoHideDuration={snackBarConfig.autoHideDuration} 
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>

            <Container>
                <Grid item xs={12} container direction={'row'} justifyContent='space-between' alignItems={'center'} sx={{marginLeft:'25px'}}>
                    <Grid item container direction={'row'} xs={10} spacing={2} >
                        <Grid item xs={12} md={3}>
                            <Autocomplete
                            id="disable-portal"
                            disablePortal
                            value={countrySelected}
                            options={countries || []}
                            getOptionLabel={(option) => option.name || ""}
                            fullWidth
                            renderInput={(params) => <TextField {...params} variant="standard" label="filtrar por países" />}
                            onChange={(e, newValue) => {
                                if (newValue) {
                                    setCountrySelected(newValue);
                                    getDepartments(newValue.id);
                                }
                            }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Autocomplete
                                id="disable-portal"
                                disablePortal
                                value={departamentSelected}
                                options={departaments || []}
                                getOptionLabel={(option) => option.name || ""}
                                fullWidth
                                renderInput={(params) => <TextField {...params} variant="standard" label="filtrar por departamentos" />}
                                onChange={(e, newValue) => { 
                                    setDepartamentSelected(newValue); 
                                    newValue && getProvinces(newValue?.id);
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <TableDataV2
                    title={'Provincias'}
                    data={provinces || []}
                    header={[
                        { name: 'code', label: 'Código', filter: false, Chip: false },
                        { name: 'name', label: 'Nombre', filter: false, Chip: false },
                    ]}
                    actionSelect={setActionSelected}
                    setModalSave={setShowProvinceModal}
                    setModalDelete={setShowProvinceDeleteModal}
                    status_action
                    RecuperarData={RecuperarData}
                />
            </Container>

            { 
                showProvinceModal && (
                    <ModalCountryProvince
                        open={showProvinceModal}
                        closeModal={()=>{setShowProvinceModal(false)}}
                        actionSelected={actionSelected}
                        onListProvince={getProvinces}
                        province={provinceSelected}
                        clearState={()=>{setProvinceSelected('')}}
                        departamentSelected={departamentSelected}
                    />
                )
            }
            {
                actionSelected === 'delete' && (
                    <ModalCountryProvinceDelete
                        open={showProvinceDeleteModal}
                        closeModal={()=>{setShowProvinceDeleteModal(false)}}
                        actionSelected={actionSelected}
                        onListProvince={getProvinces}
                        province={provinceSelected}
                        clearState={()=>{setProvinceSelected('')}}
                        departamentSelected={departamentSelected}
                    />
                )
            }
        </Protected>
    )
}
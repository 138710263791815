import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Autocomplete, Divider, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { PhasesService } from "@/service/services/Phases.service";
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalProject.type";
import { ProjectService } from "@/service/services/Project.service";

export const ModalProject: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, project, onListProjects, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [projectTypes, SetProjectTypes] = useState<any>([]);

    //type projects data
    const arrayProjectTypes = [
        {id: 1, name: 'De ingeniería'},
        {id: 2, name: 'Económicos'},
        {id: 3, name: 'Fiscales'},
        {id: 4, name: 'Legales'},
        {id: 5, name: 'Artísticos'},
        {id: 6, name: 'Tecnológicos'},
        {id: 7, name: 'Productivos'},
        {id: 8, name: 'Otros'},
    ]

    const [data, setData] = useState({
        id: project ? project.id : '',
        name: project ? project.name : '',
        description: project ? project.description : '',
        start_date: project ? project.start_date : '',
        end_date: project ? project.end_date : '',
        projected_total: project ? project.projected_total : '',
        idtypeproject: project ? project.idtypeproject : 1,
    });

    useEffect(() => {
        getProjectTypes();
    }, []);

    useEffect(() => {
        if(open){
            if(project){
                setData({
                    id: project ? project.id : '',
                    name: project ? project.name : '',
                    description: project ? project.description : '',
                    start_date: project ? project.start_date : '',
                    end_date: project ? project.end_date : '',
                    projected_total: project ? project.projected_total : '',
                    idtypeproject: project ? getCustomProjectType(project.idtypeproject, projectTypes) : 1,
                })
            }
        }
    }, [open, projectTypes]);

    const getProjectTypes = () => {
        setLoading(true);
        SetProjectTypes(arrayProjectTypes || []);
        // await PhasesService.getPhases().then((data) => {
        //     setPhases(data.data || []);
        // })
        setLoading(false);
    }

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.name) errors.name = "nombre requerido";
        if(!values.description) errors.description = "descripción requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            id: values.id,
            name: values.name,
            description : values.description,
            start_date : values.start_date,
            end_date : values.end_date,
            projected_total : values.projected_total,
            idtypeproject : values.idtypeproject,
        }
        setLoading(true);
        if(project && actionSelected=='edit'){
            await ProjectService.editProject(formData, project.id).then((data) => {
                onListProjects && onListProjects();
                closeModal && closeModal();
            })
        }else{
            await ProjectService.createProject(formData).then((data) => {
                onListProjects && onListProjects();
                closeModal && closeModal();
            })
        }
        setLoading(false);
    }

    const getCustomProjectType = (oldIdtypeproject, projectTypes) => {
        const customProjectType = projectTypes.find(projectType => projectType.id == oldIdtypeproject);
        return  customProjectType ? customProjectType.id : ''
    }

    return(
        <>
        {loading && <Loading/>}

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={(project && actionSelected=='edit') ? "Editar proyecto" : "Agregar proyecto"}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Nombre:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="name"
                                            variant="standard"
                                            type="text" 
                                            name="name"
                                            fullWidth
                                            value={values.name}
                                            onChange={handleChange}
                                            placeholder="Nombre"
                                            error={errors.name && touched.name ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Descripción:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="description"
                                            variant="standard"
                                            type="text" 
                                            name="description"
                                            multiline
                                            maxRows={4}
                                            fullWidth
                                            value={values.description}
                                            onChange={handleChange}
                                            placeholder="Descripcion"
                                            error={errors.description && touched.description ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Inicio del proyecto:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                id="start_date"
                                                variant="standard"
                                                fullWidth
                                                name="start_date"
                                                type="date"
                                                onChange={handleChange}
                                                value={values.start_date}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Fin del proyecto:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                id="end_date"
                                                variant="standard"
                                                fullWidth
                                                name="end_date"
                                                type="date"
                                                onChange={handleChange}
                                                value={values.end_date}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Total proyectado:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="projected_total"
                                            variant="standard"
                                            type="number" 
                                            name="projected_total"
                                            fullWidth
                                            value={values.projected_total}
                                            onChange={handleChange}
                                            placeholder="total proyectado"
                                            error={errors.projected_total && touched.projected_total ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>Tipo de proyecto:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="idtypeproject"
                                                variant="standard"
                                                value={values.idtypeproject}
                                                onChange={(event) => { 
                                                setFieldValue("idtypeproject", event.target.value);
                                                }}
                                                error={ errors.idtypeproject && touched.idtypeproject ? true : false }
                                                fullWidth
                                            >
                                                {
                                                projectTypes.map((projectType, i) => {
                                                    // var formatRole = {...role, name: getCustomRoleName(role.name)};
                                                    return <MenuItem key={i} value={projectType.id}>{projectType.name}</MenuItem>;
                                                })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(project && actionSelected=='edit') ? "ACTUALIZAR" : "REGISTRAR"}
                                    cancelText="CANCELAR" 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Autocomplete, Divider, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalScheduleWorker.type";
import { ScheduleService } from "@/service/services/Schedule.service";

export const ModalScheduleWorker: React.FC<Props> = (props:any): JSX.Element => {

    const arrayDays = [
        {id: 1, name: 'Lunes'},
        {id: 2, name: 'Martes'},
        {id: 3, name: 'Miercoles'},
        {id: 4, name: 'Jueves'},
        {id: 5, name: 'Viernes'},
        {id: 6, name: 'Sabado'},
        {id: 7, name: 'Domingo'},
    ]

    const { open, closeModal, actionSelected, schedule, onList, clearState, workerSelected } = props;
    console.log(schedule)
    const [loading, setLoading] = useState<boolean>(false);
    const [scheduleCompany, setScheduleCompany] = useState<string[]>([]);
    const [scheduleCompanySelected, setScheduleCompanySelected] = useState(null);
    const [arrayDaysSelected, setArrayDaysSelected] = useState([])
    const [data, setData] = useState({
        id:  schedule ? schedule.id : '',
        start_collation: schedule ? schedule.start_collation : '',
        end_collation: schedule ? schedule.end_collation : '', 
        weekly: '',
        observations: '',
        start_date: '',
        end_date: ''
    });

    const validateForm = (values) => {
        let errors: any = {};
        // if(!values.start_collation) errors.start_collation = "nombre requerido";
        // if(!values.end_collation) errors.end_collation = "nombre requerido";
        return errors;
    }

    const structureDays = (dataArray) => {
        const newArray = dataArray.map(item => item.id)
        console.log(newArray)
        return JSON.stringify(newArray)
    }

    const onSubmit = async(values, {resetForm})  => {

        const formDataEdit = {
            start_collation: values.start_collation,
            end_collation : values.end_collation,
        }

        const formData = {
            start_collation: values.start_collation,
            end_collation: values.end_collation,
            idworkschedulecompany: scheduleCompanySelected?.id,
            idemployee:  workerSelected.id,
            start_date: values.start_date,
            end_date: values.end_date,
            weekly: structureDays(arrayDaysSelected),
            observations: values.observations,
        }
        setLoading(true);
        console.log(formDataEdit)
        console.log(formData)
        if(schedule && actionSelected=='edit'){
            await ScheduleService.editScheduleEmployee(schedule.id, formDataEdit).then((data) => {
                onList && onList(workerSelected.id);
                closeModal && closeModal();
            })
        }else{
            await ScheduleService.createScheduleEmployee(formData).then((data) => {
                onList && onList();
                closeModal && closeModal(workerSelected.id);
            })
        }
        setLoading(false);
    }

    const getScheduleCompany = async() => {
        setLoading(true)
        const response = await ScheduleService.getScheduleCompany();
        if (response.data) {
            setScheduleCompany(response.data || [])
        } else {
            console.log('error')
        }
        setLoading(false);
    }

    useEffect(()=>{
        if(actionSelected != 'edit'){getScheduleCompany();}
    },[])

    return(
        <>
        {loading && <Loading/>}

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={(schedule ? "Editar Horario" : "Agregar Horario")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >
                                    { actionSelected != 'edit' && <>
                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="body1"><b>Horarios de la Empresa:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                id="disable-portal"
                                                disablePortal
                                                value={scheduleCompanySelected}
                                                options={scheduleCompany || []}
                                                getOptionLabel={(option) => `${option.check_in_times} - ${option.closing_hour} ` || ""}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} variant="standard" label="Escriba para buscar" />}
                                                onChange={(e, newValue) => {  setScheduleCompanySelected(newValue) }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="body1"><b>Dias Laborables:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                id="disable-portal"
                                                disablePortal
                                                value={arrayDaysSelected}
                                                options={arrayDays || []}
                                                getOptionLabel={(option) => `${option.name}` || ""}
                                                fullWidth
                                                multiple
                                                renderInput={(params) => <TextField {...params} variant="standard" label="Escriba para buscar" />}
                                                onChange={(e, newValue) => {  setArrayDaysSelected(newValue) }}
                                            />
                                        </Grid>
                                    </Grid></>}
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Inicio de Colación:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="start_collation"
                                            variant="standard"
                                            type="time" 
                                            name="start_collation"
                                            fullWidth
                                            value={values.start_collation}
                                            onChange={handleChange}
                                            placeholder="Inicio de la Colación"
                                            error={errors.start_collation && touched.start_collation ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Fin de Colación:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="end_collation"
                                            variant="standard"
                                            type="time" 
                                            name="end_collation"
                                            fullWidth
                                            value={values.end_collation}
                                            onChange={handleChange}
                                            placeholder="Horario de Entrada"
                                            error={errors.end_collation && touched.end_collation ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                    { actionSelected != 'edit' && <><Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Fecha de Inicio:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="start_date"
                                            variant="standard"
                                            type="date" 
                                            name="start_date"
                                            fullWidth
                                            value={values.start_date}
                                            onChange={handleChange}
                                            placeholder="Fecha de Inicio"
                                            error={errors.start_date && touched.start_date ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Fecha de Fin :</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="end_date"
                                            variant="standard"
                                            type="date" 
                                            name="end_date"
                                            fullWidth
                                            value={values.end_date}
                                            onChange={handleChange}
                                            placeholder="Fecha de Fin"
                                            error={errors.end_date && touched.end_date ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Observaciones :</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="observations"
                                            variant="standard"
                                            type="text" 
                                            name="observations"
                                            fullWidth
                                            value={values.observations}
                                            onChange={handleChange}
                                            placeholder="Observaciones"
                                            error={errors.observations && touched.observations ? true : false}
                                        />
                                        </Grid>
                                    </Grid></>}
                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(schedule && actionSelected=='edit') ? "ACTUALIZAR" : "REGISTRAR"}
                                    cancelText="CANCELAR" 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}
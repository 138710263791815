import { useState } from 'react';
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { authenticationService } from '@/service/services/Authentication.service';
import { ROUTE_COMPANY_SETTING } from '@/toolbox/constants/route-map';
import { useHistory } from 'react-router-dom';
import { ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_TRABAJADOR } from '@/toolbox/constants/role-type';

// ----------------------------------------------------------------------

const MENU_OPTIONS_SUPER_ADMIN = [
  {
    label: 'Inicio',
    icon: 'eva:home-fill',
  },
  {
    label: 'Perfil',
    icon: 'eva:person-fill',
  },
];

const MENU_OPTIONS_ADMIN = [
  {
    label: 'Inicio',
    icon: 'eva:home-fill',
  },
  {
    label: 'Perfil',
    icon: 'eva:person-fill',
  },
  {
    label: 'Configuración Empresa',
    icon: 'eva:settings-2-fill',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const data_user = readLocalStorage(KEY_USER_DATA);
  const role = data_user?.user?.role;
  const history= useHistory();

  const account = {
    photoURL: '',
    displayName: data_user.user.name + ' '+ data_user.user.last_name,
    role: data_user.user.role
  }
  
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = (option) => {
    setOpen(null);
    if (option) {
      switch(option.label)
      {
          case 'Configuración Empresa':
            history.push(ROUTE_COMPANY_SETTING);
            break;
      }
    }
  };

  const logout = () => {
    handleClose('');
    authenticationService.logout().then(res => {
        if (!res.error) {
            switch(data_user?.user?.role)
            {
                case 'DOCTOR':
                    window.location.replace('/login/doctor');
                    break;
                case 'PACIENTE':
                    window.location.replace('/login/paciente');
                    break;
                case 'TUTOR':
                    window.location.replace('/login/tutor');
                    break;
                case ROLE_ADMIN:
                    window.location.replace('/login/admin-centro-medico');
                    break;
                case ROLE_SUPER_ADMIN:
                    window.location.replace('/login/admin');
                    break;
                case ROLE_TRABAJADOR:
                    window.location.replace('/login/worker');
            }
           
        }
    }
    )
  }

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {account.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {/* {account.email} */}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {
            role == ROLE_SUPER_ADMIN && (
              MENU_OPTIONS_SUPER_ADMIN.map((option) => (
                <MenuItem key={option.label} onClick={()=>handleClose(option)}>
                  {option.label}
                </MenuItem>
              ))
            )
          }
          {
            role == ROLE_ADMIN && (
              MENU_OPTIONS_ADMIN.map((option) => (
                <MenuItem key={option.label} onClick={()=>handleClose(option)}>
                  {option.label}
                </MenuItem>
              ))
            )
          }
          
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={()=>{logout()}} sx={{ m: 1 }}>
          Salir
        </MenuItem>
      </Popover>
    </>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import { Loading } from "@components/common/Loading";
import { Alert, Backdrop, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar, Breadcrumbs, Typography, TextField, Select, Autocomplete, Button, Input } from "@mui/material";
import { Protected } from '@/components/layout/Protected';
import { ModalContractVariables } from '@/components/Modal/ModalContractVariables/ModalContractVariables';
import { ContractService } from '@/service/services/Contract.service';
import '../ContractEditStyle.css';

export const ContractEdit: React.FC<any> = (props: any): JSX.Element => {

    const idEmployee = props?.location?.state?.idEmployee || 13;
    const idContractDocument = props?.location?.state?.idContractDocument;

    const [document, setDocument] = useState([])
    const [dataVariables, setDataVariables] = useState([]);
    const [documentClausulas, setDocumentClausulas] = useState([])

    const [loading, setLoading] = useState(false);
    const [cursorActive, setCursorActive] = useState(null)
    const [idActive, setIdActive] = useState(null)
    const [openModal, setOpenModal] = useState(false);
    const [dataCompanySelected, setDataCompanySelected] = useState(null)
    const [clausulaSelected, setClausulaSelected] = useState(0)

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    useEffect(() => {
        documentContractClauses();
    }, []);

    useEffect(() => {
        if(idEmployee) getEmployeeVariables(idEmployee);
    }, [idEmployee]);

    useEffect(() => {
        if(dataVariables.length > 0 && document.length > 0) {
            setDocumentClausulas(EstructurarData(document, true))
        }
    }, [dataVariables.length > 0 && document.length > 0])

    const getEmployeeVariables = async(employee) => {
        setLoading(true);
        await ContractService.getEmployeeVariables(employee).then((data) => {
            const dataFilter = data.data[0] || [];
            let newArray = [
                { key: `{{district_company}}`, name: `Distrito de la Empresa`, value: dataFilter.district_company},
                { key: `{{signature_date_contract}}`, name: `Dato Firma Contrato`, value: dataFilter.signature_date_contract},
                { key: `{{company}}`, name: `Nombre de la Empresa`, value: dataFilter.company},
                { key: `{{rut_company}}`, name: `Rut de la Empresa`, value: dataFilter.rut_company},
                { key: `{{manager}}`, name: `Administrador de la Empresa`, value: dataFilter.manager},
                { key: `{{rut_manager}}`, name: `Rut Administrador de la Empresa`, value: dataFilter.rut_manager},
                { key: `{{address_manager}}`, name: `Dirección Administrador de la Empresa`, value: dataFilter.address_manager},
                { key: `{{employee}}`, name: `Nombre del Empleado`, value: dataFilter.employee},
                { key: `{{rut_employee}}`, name: `Rut del Empleado`, value: dataFilter.rut_employee},
                { key: `{{nationality_employee}}`, name: `Nacionalidad del Empleado`, value: dataFilter.nationality_employee},
                { key: `{{date_of_birth}}`, name: `Fecha Nacimiento del Empleado`, value: dataFilter.date_of_birth},
                { key: `{{address_employee}}`, name: `Dirección del Empleado`, value: dataFilter.address_employee},
                { key: `{{district_employee}}`, name: `Distrito del Empleado`, value: dataFilter.district_employee},

                { key: `{{charge_employee}}`, name: `Cargo del Empleado`, value: dataFilter.charge_employee},
                { key: `{{address_branchOffice}}`, name: `Dirección de la Sucursal`, value: dataFilter.address_branchOffice},
                { key: `{{district_branchOffice}}`, name: `Distrito de la Sucursal`, value: dataFilter.district_branchOffice},

                { key: `{{weekly_hour_employee}}`, name: `Horario Semanal del Empleado`, value: dataFilter.weekly_hour_employee},
                { key: `{{start_hour_employee}}`, name: `Horario Inicio del Empleado`, value: dataFilter.start_hour_employee},
                { key: `{{end_hour_employee}}`, name: `Horario Fin del Empleado`, value: dataFilter.end_hour_employee},
                { key: `{{hour_collation}}`, name: `Colación Hora del Empleado`, value: dataFilter.hour_collation},

                { key: `{{base_salary_employee}}`, name: `Salario base del Empleado`, value: dataFilter.base_salary_employee},
                { key: `{{salary_letters_employee}}`, name: `Cartas Salariales del Empleado`, value: dataFilter.salary_letters_employee},

            ];
            setDataVariables(newArray || []);
            setLoading(false);
        })
    }

    const documentContractClauses = async() => {
        setLoading(true);
        if(idContractDocument){
            await ContractService.documentClausesByIdContract(idContractDocument).then((data) => {
                setDocument(data.data || []);
                setLoading(false);
            })
        }else{
            await ContractService.documentContractClauses().then((data) => {
                setDocument(data.data || []);
                setLoading(false);
            })
        }
    }

    const handleInput = (e) => {
        const value = e.target.value
        if (value[e.target.selectionEnd - 1] == "@") {
            setCursorActive(e.target.selectionEnd);
            setIdActive(e.target.name)
            setOpenModal(true);
            return
        } else {
            let changedForm;
            let indice = 0;
            documentClausulas.map((item, i) => {
                if (item.id == e.target.name) {
                    changedForm = {
                        ...item,
                        text: e.target.value
                    }
                    indice = i
                }
            })
            documentClausulas.splice(indice, 1, changedForm)
            setDocumentClausulas([...documentClausulas]);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const evaluateVar = (e, obj) => {
        setOpenModal(false)
        let changedForm;
        let indice = 0;
        documentClausulas.map((item, i) => {
            if (item.id == idActive) {
                let newText = item.text.split('');
                newText.splice(cursorActive, 0, ' ' + obj.value + ' ')
                changedForm = {
                    ...item,
                    text: newText.join('')
                }
                indice = i
            }
        })
        documentClausulas.splice(indice, 1, changedForm)
        setDocumentClausulas([...documentClausulas]);
    }

    const TextToVariable = (text) => {
        let textChange = text;
        dataVariables && dataVariables.map((item) => {
            textChange = textChange.replace(item.name, item.key)
        })
        return textChange
    }

    const VariableToText = (textVariable) => {
        let newText = textVariable
        dataVariables && dataVariables.map((item) => {
            newText = newText.replace(item.key, item.name)
        })
        return newText
    }

    const Enviar = () => {
        let textSend = EstructurarData(documentClausulas, false)
        console.log(textSend)
    }

    const EstructurarData = (documentData, toText) => {
        let newArrayEstructura = [];
        documentData.map((item, i) => {
            let objNew = {
                id: item.id,
                text: toText ? VariableToText(item.text) : TextToVariable(item.text),
                position: i,
                headboard: item.headboard,
                status: item.status
            }
            newArrayEstructura.push(objNew);
        })

        return newArrayEstructura
    }

    const editClause = async(clause) => {
        setLoading(true);
        let textSend = EstructurarData(documentClausulas, false)
        const clauseCurrent = textSend.find(item => item.id == clause.id);
        if(!clauseCurrent) return alert('ocurrio algun error');
        await ContractService.editClause(clauseCurrent, clause.id).then((data) => {
            console.log(data?.data || '');
            if(data.data.status == true){
                setLoading(false);
                documentContractClauses();
                return (
                    setSnackBarConfig(prev => ({
                        ...prev, 
                        open: true,
                        severity: 'success',
                        message: `Clausula editada con éxito`
                    }
                    ))
                );
            }else{
                setLoading(false);
                return (
                    setSnackBarConfig(prev => ({
                        ...prev, 
                        open: true,
                        severity: 'error',
                        message: `${data.data.status || ''}`
                    }
                    ))
                );
            }
            setLoading(false);

        })
    }

    const deleteClause = async(clause) => {
        console.log(clause)
        setLoading(true);
        await ContractService.deleteClause(clause.id).then((data) => {
            if(data.data.status == false) {
                setLoading(false);
                return (
                    setSnackBarConfig(prev => ({
                        ...prev, 
                        open: true,
                        severity: 'error',
                        message: `${data.data.message}`
                    }
                    ))
                );
            }else{
                documentContractClauses();
                setLoading(false);
                return (
                    setSnackBarConfig(prev => ({
                        ...prev, 
                        open: true,
                        severity: 'success',
                        message: `Clausula eliminada con éxito`
                    }
                    ))
                );
            }
        })
    }

    const NewClausula = async() =>{
        console.log(documentClausulas)
        const newObj = {
            headboard: 'Nombre de la Clausula',
            id: 'new',
            position:documentClausulas.length +1 ,
            status: 1,
            text:'',
        }
        setDocumentClausulas([...documentClausulas,newObj ]);
    }

    const bodyForm = <form onSubmit={handleSubmit}>
        {
            documentClausulas.map((item) => {
                return (
                    <Grid container style={{minHeight: "auto", maxHeight: "auto", overflow: "hidden", width:'100%'}} sx={{ py: 2 }}>

                        <Button onClick={()=>{ setClausulaSelected(item.id) }}>Editar</Button>
                        {/* <Button onClick={()=>{ }}>Subir</Button>
                        <Button onClick={()=>{  }}>Bajar</Button> */}
                        <Button onClick={()=>{ deleteClause(item) }}>Eliminar</Button>

                        <Grid sx={{width: '100%'}} className='clauses-container-text'>
                            {
                                item.headboard && (
                                    <Grid item className='clauses-container-title'>
                                        <TextField
                                            className='name-clauses'
                                            type="text"
                                            variant="standard"
                                            value={item.headboard}
                                            fullWidth
                                            onChange={()=>{}}
                                            placeholder='nombre de la clausula'
                                            disabled={clausulaSelected!=item.id}
                                            
                                        /><span >:</span>
                                    </Grid>
                                )
                            }
                            <Grid item >
                                <TextField
                                    fullWidth
                                    id="text-clausula"
                                    className='text-field'
                                    variant="standard"
                                    name={item.id}
                                    disabled={clausulaSelected!=item.id}
                                    type="text"
                                    multiline
                                    value={item.text}
                                    onChange={handleInput}
                                />
                            </Grid>
                        </Grid>
                        <Grid sx={{width: '100%', textAlign: 'end'}}>
                            <Button disabled={clausulaSelected!=item.id} onClick={()=>{ editClause(item) }}>Guardar Cambios</Button>
                        </Grid>
                    </Grid>
                )
            })
        }

    </form>

    return (
        <>
            <Protected>
                {loading && <Loading />}
                <Snackbar
                    open={snackBarConfig.open} 
                    autoHideDuration={snackBarConfig.autoHideDuration} 
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
                    <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                        {snackBarConfig.message || ""}
                    </Alert>
                </Snackbar>

                {/* <Button onClick={() => { Enviar() }} >Enviar</Button>
                <Button onClick={() => { NewClausula() }} >Nueva Clausula</Button> */}
                {bodyForm}
                
            </Protected>
            {
                openModal &&
                <ModalContractVariables
                    open={openModal}
                    closeModal={() => { setOpenModal(false) }}
                    dataCompany={dataVariables}
                    dataCompanySelected={dataCompanySelected}
                    setDataCompanySelected={setDataCompanySelected}
                    evaluateVar={evaluateVar}
                />
            }
        </>
    )
};
import React, { useEffect, useRef, useState } from 'react';

import { Protected } from '@components/layout/Protected';
import { authenticationService } from '@service/services/Authentication.service';
import { Loading } from "@components/common/Loading";
import Card from "@mui/material/Card";
import { Props } from './Home.type'
import Contactos from "@assets/img/clientes.png";
import Productos from "@assets/img/productos.png";
import Negocios from "@assets/img/negocios.png";
import Vacio from "@assets/img/vacio.png";
import Typography from "@mui/material/Typography";
import { Alert, Backdrop, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar } from "@mui/material";
import { Link } from "react-router-dom";
import { Box } from "@mui/system";
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_ARRAY_MY_MENU, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { SpinnerGrow } from '@/components/common/Spinner';
import { Chart } from "react-google-charts";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Fade from '@mui/material/Fade';
import { moneyFormatInt } from '@/toolbox/helpers/money.helper';
import logokyte from "@assets/img/vacio.png";
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { TableDataV2 } from '@/components/common/Tablev2';
import { authenticationRepository } from '@/service/repositories/Authentication.repository';
import { userRepository } from '@/service/repositories/User.repository';


export const HomeView: React.FC<Props> = (props:any): JSX.Element => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElLeads, setAnchorElLeads] = React.useState(null);
  const [anchorElLeadsChannel, setAnchorElLeadsChannel] = React.useState(null);
  const [openView, setOpenView] = useState(false);
  const dataUsers=[{}]
  const [users, setUsers]=useState([])
  const [snackBarConfig, setSnackBarConfig] = useState<any>({
   open: false,
   severity: 'error',
   message: 'Error',
   autoHideDuration: 3000,
})
  const getUsers = async () => {
   const response = await userRepository.getUsers();
   console.log(response)
   if (response.data) {
      setSnackBarConfig(prev => ({
         ...prev,
         open: true,
         message: response.data,
      }));
      setUsers(response.data)
   } else {
     console.log('error')
   }
   // setLoading(false);
}

function RecuperarData(value){
console.log(value)
}

useEffect(() => {
   getUsers()
},[])

  return (
      <Protected>
         {/* HOME */}
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openView}
         >
            <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <SpinnerGrow/>
                     <h1 style={{marginLeft:'10px'}}><strong>Cargando...</strong></h1>
               </Grid>
         </Backdrop>
         <Container>
            <TableDataV2
                data={users}
                header={[
                  //   { name: 'id', label: 'RUT', filter: false, Chip: false },
                    { name: 'user_name', label: 'Nombre', filter: false, Chip: false },
                    { name: 'n_document', label: 'N Documento', filter: false, Chip: true },
                    //   { name: 'last_name', label: 'Apellido', filter: false, Chip: false },
                  //   { name: 'date_birth', label: 'Fecha de Nacimiento', filter: false, Chip: false },
                    { name: 'email', label: 'Correo', filter: false, Chip: false },
                  //   { name: 'doctorTypeName', label: 'Tipo ', filter: false, Chip: true },
                    { name: 'role', label: 'Rol', filter: false, Chip: true },
                  //   { name: 'districtName', label: 'Distrito', filter: false, Chip: true },
                  //   { name: 'address', label: 'Direccion', filter: false, Chip: true },
                  //   { name: 'status', label: 'Estado', filter: false, Chip: true },
                ]}
                status_action
               //  checkbox
                title={'Usuarios'}
                RecuperarData={RecuperarData}
               //  setModalSave={setOpen}
               //  actionSelect={setActionSelect}
            />
         </Container>
      
      </Protected>
   )
};
import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);
const rut_empresa = dataUser?.user?.rut_empresa;

export const WorkerContactRepository = {

    getEmployeeContact: async(idEmployee:number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/employee/contact/${idEmployee}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createEmployeeContact: async (dataContact, idEmployee:number) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/employee/contact/${idEmployee}`, {
            email: dataContact.email,
            landline: dataContact.landline,
            personal_phone: dataContact.personal_phone,
            emergency_phone: dataContact.emergency_phone,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    editEmployeeContact: async (dataContact, idContact:number) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/employee/contact/${idContact}`, {
            email: dataContact.email,
            landline: dataContact.landline,
            personal_phone: dataContact.personal_phone,
            emergency_phone: dataContact.emergency_phone,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    deleteEmployeeContact: async(idContact:number): Promise<any> => {
        const res= await http.delete<any>(`${API_URL_BASE}/v1/employee/contact/${idContact}`)
        return res;
     },
}   
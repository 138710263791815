import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Autocomplete, Divider, Grid, Alert, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { AssistanceService } from "@/service/services/Assistance.service";
import { Props } from "./ModalAssistance.type";
import { AssistsWorkerService } from "@/service/services/AssistanceWorker.service";
import moment from "moment";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { ROLE_ADMIN, ROLE_TRABAJADOR } from "@/toolbox/constants/role-type";

export const ModalAssistance: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, assistance, onListAssistances, workerSelected, clearState, massive, workers } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [workersSelected, setWorkersSelected] = useState<any>('');

    const previousDate = moment().subtract(7, 'days').format("YYYY-MM-DD");
    const nexDate = moment().add(1, 'days').format("YYYY-MM-DD");

    const dataUser = readLocalStorage(KEY_USER_DATA);
    const role = dataUser?.user?.role;

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [data, setData] = useState({
        id: assistance ? assistance.id : '',
        idemployee: assistance ? assistance.idemployee : '',
        date: assistance ? assistance.date : '',
        start_time: assistance ? assistance.start_time : '',
        start_refreshment: assistance ? assistance.start_refreshment : '',
        end_refreshment: assistance ? assistance.end_refreshment : '',
        end_time: assistance ? assistance.end_time : '',
        observations: assistance ? assistance.observations : '',
        start_date: '',
        end_date: '',
    });

    const validateForm = (values) => {
        let errors: any = {};
        if(massive && !values.start_date) errors.start_date = "fecha requerido";
        if(massive && !values.end_date) errors.end_date = "fecha requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        let arrayIdsEmployees= [];

        const formData = {
            id: values.id,
            idemployee: workerSelected.id,
            date : values.date,
            start_time : values.start_time,
            start_refreshment : values.start_refreshment,
            end_refreshment : values.end_refreshment,
            end_time : values.end_time,
            observations : values.observations,
        }

        workersSelected.length > 0 && workersSelected.map((item) => {
            arrayIdsEmployees.push(item.id);
        });

        const formDataMassive = {
            id: values.id,
            idemployees: JSON.stringify(arrayIdsEmployees),
            start_date : values.start_date,
            end_date : values.end_date,
            start_time : values.start_time,
            start_refreshment : values.start_refreshment,
            end_refreshment : values.end_refreshment,
            end_time : values.end_time,
            observations : values.observations,
        }

        setLoading(true);
        if(assistance && actionSelected=='edit'){
            await AssistsWorkerService.updateAssists(assistance.id, formData).then((data) => {
                if(data.data.status == true){
                    onListAssistances && onListAssistances(workerSelected.id, previousDate, nexDate);
                    clearState && clearState();
                    closeModal && closeModal();
                    setLoading(false);
                    return (
                        setSnackBarConfig(prev => ({ ...prev, open: true, 
                            severity:'success', 
                            message: `${data.data.message}` }
                        ))
                    );
                }else{
                    setLoading(false);
                    return (
                        setSnackBarConfig(prev => ({ ...prev, open: true, 
                            severity:'error', 
                            message: `${data.data.message || 'Ocurrió algún error.'}` }
                        ))
                    );
                }
            })
        }else{
            if(massive){
                await AssistsWorkerService.createAssistMasive(formDataMassive).then((data) => {
                    if(data.data.status == true){
                        closeModal && closeModal();
                        setLoading(false);
                        return (
                            setSnackBarConfig(prev => ({ ...prev, open: true, 
                                severity:'success', 
                                message: `${data.data.message}` }
                            ))
                        );
                    }else{
                        setLoading(false);
                        return (
                            setSnackBarConfig(prev => ({ ...prev, open: true, 
                                severity:'error', 
                                message: `${data.data.message || 'Ocurrió algún error.'}` }
                            ))
                        );
                    }
                })
            }else{
                await AssistsWorkerService.createAssists(formData).then((data) => {
                    if(data.data.status == true){
                        onListAssistances && onListAssistances(workerSelected.id, previousDate, nexDate);
                        closeModal && closeModal();
                        setLoading(false);
                        return (
                            setSnackBarConfig(prev => ({ ...prev, open: true, 
                                severity:'success', 
                                message: `${data.data.message}` }
                            ))
                        );
                    }else{
                        setLoading(false);
                        return (
                            setSnackBarConfig(prev => ({ ...prev, open: true, 
                                severity:'error', 
                                message: role == ROLE_TRABAJADOR ? `${ data.data.message || 'Ocurrió algún error.'}` : `La Asistencia para la fecha seleccionada ya ha sido registrada.`} 
                            ))
                        );
                    }
                })
            }
            
        }
        setLoading(false);
    }
    
    return(
        <>
        {loading && <Loading/>}

        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={assistance ? "Editar Asistencia" : "Agregar Asistencia"}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    {
                                        !massive && (
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>Fecha:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <TextField
                                                        id="date"
                                                        variant="standard"
                                                        fullWidth
                                                        name="date"
                                                        type="date"
                                                        onChange={handleChange}
                                                        value={values.date}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={errors.date && touched.date ? true : false}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )
                                    }

                                    

                                    {
                                        massive && (
                                            <>
                                            <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                    <Typography variant="body1"><b>Empleados:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Autocomplete
                                                        multiple={true}
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        value={workersSelected|| []}
                                                        options={workers || []}
                                                        getOptionLabel={(option) => option.name_complete || ""}
                                                        fullWidth
                                                        renderInput={(params) => <TextField {...params} variant="standard" label="Escriba para buscar" />}
                                                        onChange={(e, newValue) => {
                                                            setWorkersSelected(newValue);
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>Fecha Inicio:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <TextField
                                                        id="start_date"
                                                        variant="standard"
                                                        fullWidth
                                                        name="start_date"
                                                        type="date"
                                                        onChange={handleChange}
                                                        value={values.start_date}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={errors.start_date && touched.start_date ? true : false}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>Fecha Fin:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <TextField
                                                        id="end_date"
                                                        variant="standard"
                                                        fullWidth
                                                        name="end_date"
                                                        type="date"
                                                        onChange={handleChange}
                                                        value={values.end_date}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={errors.end_date && touched.end_date ? true : false}
                                                    />
                                                </Grid>
                                            </Grid>
                                            </>
                                        )
                                    }

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Hora Ingreso:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                id="start_time"
                                                variant="standard"
                                                fullWidth
                                                name="start_time"
                                                type="time"
                                                onChange={handleChange}
                                                value={values.start_time}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={errors.start_time && touched.start_time ? true : false}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Inicio Refrigerio:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                id="start_refreshment"
                                                variant="standard"
                                                fullWidth
                                                name="start_refreshment"
                                                type="time"
                                                onChange={handleChange}
                                                value={values.start_refreshment}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={errors.start_refreshment && touched.start_refreshment ? true : false}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Fin Refrigerio:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                id="end_refreshment"
                                                variant="standard"
                                                fullWidth
                                                name="end_refreshment"
                                                type="time"
                                                onChange={handleChange}
                                                value={values.end_refreshment}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={errors.end_refreshment && touched.end_refreshment ? true : false}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Hora Salida:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                id="end_time"
                                                variant="standard"
                                                fullWidth
                                                name="end_time"
                                                type="time"
                                                onChange={handleChange}
                                                value={values.end_time}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={errors.end_time && touched.end_time ? true : false}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Observación:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="observations"
                                            variant="standard"
                                            type="text" 
                                            name="observations"
                                            multiline
                                            maxRows={4}
                                            fullWidth
                                            value={values.observations}
                                            onChange={handleChange}
                                            placeholder="observacion"
                                            error={errors.observations && touched.observations ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(assistance && actionSelected=='edit') ? "ACTUALIZAR" : "REGISTRAR"}
                                    cancelText="CANCELAR" 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}
import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from '@/components/common/Loading';
import { Alert, Autocomplete, Backdrop, Button, Card, CircularProgress, Container, Divider, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { ReportService } from '@/service/services/Report.service';
import { WorkerService } from '@/service/services/Worker.service';
import { PeriodService } from '@/service/services/Period.service';


export const ReportLiquidationByProject: React.FC<any> = (props: any): JSX.Element => {
   
    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });
    
    const [periods, setPeriods] = useState<any>([]);
    const [years, setYears] = useState<any>([]);
    const [yearSelected, setYearSelected] = useState<any>('');
    const [monthSelected, setMonthSelected] = useState<any>('');

    const [reports, setReports] = useState<any>([]);

    useEffect(()=> {
        getPeriods();
     },[]);
  
     const getPeriods = async() => {
        setLoading(true);
        const resp:any = await PeriodService.getPeriods();
        const newData = [];
        const arrayYears = [];
        resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
           newData.push({
              id: item.id,
              month: item.month,
              year: item.year,
              alias_name: item.month + '-' +  item.year,
           })
           const found = arrayYears.find(element => element == item.year);
           if(!found) { arrayYears.push(item.year); }
           
        });
        setYears(arrayYears);
        setPeriods(newData || []);
        setLoading(false);
    }

    const getReportEmployee = async () => {
        const periodSelected = monthSelected + '-' + yearSelected;
        const period = periods.find(element => element.alias_name == periodSelected);

        setLoading(true);

        if(!monthSelected || !yearSelected){
            setLoading(false);
            return (
               setSnackBarConfig(prev => ({ ...prev, open: true, 
                  severity:'warning', 
                  message: `Debe seleccionar Mes y Año.` }
               ))
            );
        }

        await ReportService.getReportliquidationByProjects(period.id, '', '').then((data) => {
            if(data.data.status == false){
                setLoading(false);
                return (
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'warning', 
                        message: `${data.data.message || 'Ocurrió algún error'}` }
                    ))
                );
            }
            setReports(data.data || []);
            setLoading(false);
        })
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Snackbar
                open={snackBarConfig.open} 
                autoHideDuration={snackBarConfig.autoHideDuration} 
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>
            <Container>
                <Typography variant='h6' component='h1' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                    REPORTE DE LIQUIDACIONES POR PROYECTO
                </Typography>

                <Grid container spacing={3} sx={{marginTop: '10px', marginBottom: '20px'}}>
                    <Grid item xs={12} md={12} display='flex'>
                        <FormControl variant="standard" sx={{width:'30%'}}>
                            <InputLabel id="demo-simple-select-standard-label">Año</InputLabel>
                            <Select
                                id="id_education_level"
                                variant="standard"
                                value={yearSelected}
                                onChange={(event) => {
                                setYearSelected(event.target.value);
                                }}
                                sx={{ width: '200px' }}
                            >
                                {
                                years.map((year, i) => {
                                    return <MenuItem key={i} value={year}>{year}</MenuItem>;
                                })
                                }
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" sx={{width:'30%'}}>
                            <InputLabel id="demo-simple-select-standard-label">Mes</InputLabel>
                            <Select
                                id="id_education_level"
                                variant="standard"
                                value={monthSelected}
                                onChange={(event) => {setMonthSelected(event.target.value)}}
                                sx={{ width: '200px' }}
                            >
                                <MenuItem value={'01'}>Enero</MenuItem>
                                <MenuItem value={'02'}>Febrero</MenuItem>
                                <MenuItem value={'03'}>Marzo</MenuItem>
                                <MenuItem value={'04'}>Abril</MenuItem>
                                <MenuItem value={'05'}>Mayo</MenuItem>
                                <MenuItem value={'06'}>Junio</MenuItem>
                                <MenuItem value={'07'}>Julio</MenuItem>
                                <MenuItem value={'08'}>Agosto</MenuItem>
                                <MenuItem value={'09'}>Septiembre</MenuItem>
                                <MenuItem value={'10'}>Octubre</MenuItem>
                                <MenuItem value={'11'}>Noviembre</MenuItem>
                                <MenuItem value={'12'}>Diciembre</MenuItem>
                            </Select>
                        </FormControl>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={getReportEmployee}
                        >
                        Filtrar
                        </Button>
                    </Grid>
                </Grid>
                
                {
                    reports.length > 0 && reports.map((item)=>(
                        <>
                        <Card sx={{ width: "100%", marginTop: '20px' }}>
                            <Grid sx={{ width: "100%", padding: '20px' }}>
                                <Typography variant="h5" sx={{ pt: 1 }}>
                                    Proyecto "{item.name}"
                                </Typography>
                                <Typography variant="subtitle1" sx={{ pt: 1 }}>
                                    Descripción del Proyecto: {item.description || '---'}
                                </Typography>
                                
                                <Typography variant="subtitle1" sx={{ pt: 1 }}>
                                    Costo Total del Proyecto: {item.projected_total}
                                </Typography>
                                <Grid display='flex' sx={{ width: "100%"}}>
                                    <Typography variant="subtitle1" sx={{ pt: 1, marginRight: '40px' }}>
                                        Fecha de Inicio: {item.start_date}
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ pt: 1 }}>
                                        Fecha Fin: {item.end_date}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>

                        {
                            (item.details).length > 0 ? (
                                <Card sx={{ width: "100%", marginTop: '20px' }}>
                                    <Typography variant='h6' component='h2' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                                        {`Detalle de participación en ${item.name}`}
                                    </Typography>

                                    <TableContainer>
                                        <Table>
                                            <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                                <TableRow >
                                                    <TableCell >
                                                        <Typography>Nombre Empleado</Typography>
                                                    </TableCell>
                                                    <TableCell >
                                                        <Typography>Porcentaje de Participación</Typography>
                                                    </TableCell>
                                                    <TableCell >
                                                        <Typography>Total</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {
                                                    (item.details).map((detail)=>(
                                                        <TableRow >
                                                            <TableCell size={'small'}>
                                                                {detail.name +' '+ detail.surname +' '+ detail.second_surname }
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {detail.participation_percentage}
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {detail.total_participation}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                                <TableRow>
                                                    <TableCell colSpan={2}>TOTAL</TableCell>
                                                    <TableCell align="left">{item.SumTotalEmployee}</TableCell>
                                                </TableRow>
                                                
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Card>
                            ) : (
                                <Typography variant='h6' component='h2' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                                        {`No se encontró Participación en ${item.name} para este periodo.`}
                                </Typography>
                            )
                        }

                        
                        </>
                    ))
                }

            </Container>
        </Protected>
    )
};
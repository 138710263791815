import { useEffect, useState } from "react"
import { TableDataV2 } from "@/components/common/Tablev2"
import { Protected } from "@/components/layout/Protected"
import { Autocomplete, Box, Button, Container, Grid, TextField, Typography } from "@mui/material"
import { Loading } from "@/components/common/Loading"
import { CountryService } from "@/service/services/Country.service"
import { Props } from "./Departament.type"
import { ModalCountryDepartament } from "@/components/Modal/ModalCountryDepartament/ModalCountryDepartament"
import { ModalCountryDepartamentDelete } from "@/components/Modal/ModalCountryDepartamentDelete/ModalCountryDepartamentDelete"

export const DepartamentView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [departaments, setDepartaments] = useState<any>([]);
    const [showDepartamentModal, setShowDepartamentModal] = useState<boolean>(false);
    const [showDepartamentDeleteModal, setShowDepartamentDeleteModal] = useState<boolean>(false);
    const [departamentSelected, setDepartamentSelected] = useState<any>('');
    const [actionSelected, setActionSelected] = useState<any>('');

    const [countries, setCountries] = useState<any>([]);
    const [countrySelected, setCountrySelected] = useState<any>('');
    

    useEffect(() => {
        getCountries();
    }, []);

    useEffect(() => {
        if(countrySelected) getDepartments(countrySelected.id);
    }, [countrySelected]);

    const getCountries = async () => {
        setLoading(true);
        await CountryService.getCountries().then((data) => {
            setCountries(data.data || []);
            setCountrySelected(data.data[0] || '');
           setLoading(false);
        });
    }

    const getDepartments = async (idCountry) => {
        setLoading(true);
        await CountryService.getDepartments(idCountry).then((data) => {
            setDepartaments(data.data || [])
           setLoading(false);
        });
    }

    function RecuperarData(value){
        setDepartamentSelected(value)
        console.log(value)
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Container>
                <Grid item xs={12} container direction={'row'} justifyContent='space-between' alignItems={'center'} sx={{marginLeft:'25px'}}>
                    <Grid item container direction={'row'} xs={10} spacing={2} >
                        <Grid item xs={12} md={3}>
                            <Autocomplete
                            id="disable-portal"
                            disablePortal
                            value={countrySelected}
                            options={countries || []}
                            getOptionLabel={(option) => option.name || ""}
                            fullWidth
                            renderInput={(params) => <TextField {...params} variant="standard" label="filtrar por países" />}
                            onChange={(e, newValue) => {
                                if (newValue) {
                                    setCountrySelected(newValue);
                                    getDepartments(newValue.id);
                                }
                            }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <TableDataV2
                    title={'Regiones'}
                    data={departaments || []}
                    header={[
                        { name: 'code', label: 'Código', filter: false, Chip: false },
                        { name: 'name', label: 'Nombre', filter: false, Chip: false },
                    ]}
                    actionSelect={setActionSelected}
                    setModalSave={setShowDepartamentModal}
                    setModalDelete={setShowDepartamentDeleteModal}
                    status_action
                    RecuperarData={RecuperarData}
                />
            </Container>

            { 
                showDepartamentModal && (
                    <ModalCountryDepartament
                        open={showDepartamentModal}
                        closeModal={()=>{setShowDepartamentModal(false)}}
                        actionSelected={actionSelected}
                        onListDepartament={getDepartments}
                        departament={departamentSelected}
                        clearState={()=>{setDepartamentSelected('')}}
                        countrySelected={countrySelected}
                    />
                )
            }
            {
                actionSelected === 'delete' && (
                    <ModalCountryDepartamentDelete
                        open={showDepartamentDeleteModal}
                        closeModal={()=>{setShowDepartamentDeleteModal(false)}}
                        actionSelected={actionSelected}
                        onListDepartament={getDepartments}
                        departament={departamentSelected}
                        clearState={()=>{setDepartamentSelected('')}}
                        countrySelected={countrySelected}
                    />
                )
            }
        </Protected>
    )
}
import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, } from '@mui/material';
import { ArrowBackIcon, ArrowDownIcon, ArrowRightIcon, MoreIcon } from "@toolbox/constants/icons";
import { Props } from './FormPersonalData.type'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import Entity from "@assets/img/entity.png";
import './style.sass'
import "./FormPersonalData_style.css";
import BadgeIcon from '@mui/icons-material/Badge';
import HeightIcon from '@mui/icons-material/Height';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
import { WorkerService } from "@/service/services/Worker.service";
import { dateFormat } from '@/toolbox/helpers/date.helper';


export const FormPersonalData: React.FC<Props> = (
   props: Props
): JSX.Element => {
   
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false)
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const [error, setError] = useState<any>('');
   const [estado, setEstado] = useState<any>({ rut: false, nombre_rz: false })
   

   const [valuePersonalData, setvaluePersonalData] = useState<any>(props?.valuePersonalData);
   const [valueRevalorizable, setValueRevalorizable] = React.useState('Si');
   const [valueDepreciable, setValueDepreciable] = React.useState('Si');
   const [valueTipoDepreciacion, setValueTipoDepreciacion] = React.useState('Lineal');
   const [valueUnidadMedina, setValueUnidadMedina] = React.useState('Mensual');

   useEffect(() => {
      if(props?.valuePersonalData){
         setvaluePersonalData(props.valuePersonalData)
      }
   }, [props?.valuePersonalData])
 
   const handleChangeRevalorizable = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueRevalorizable(event.target.value);
   };

   const handleChangeDepreciable = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueDepreciable(event.target.value);
   };
   const handleTipoDepreciacion = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueTipoDepreciacion(event.target.value);
   };
   const handleUnidadMedina = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueUnidadMedina(event.target.value);
   };

   const grupos = [
      { label: 'Grupo 1' },
      { label: 'Grupo 2' },
      { label: 'Grupo 3' },
      { label: 'Grupo 4' }
   ]
   const estadoCivil = [
      { id:1, label: 'Casado(a)' },
      { id:2, label: 'Divorciado(a)' },
      { id:3, label: 'Separado(a)' },
      { id:4, label: 'Soltero(a)' },
      { id:5, label: 'Viudo(a)' },
   ]
   const sexoValue = [
      { id:'1', label: 'Masculino' },
      { id:'2', label: 'Femenino' },
   ]
   const estadoTrabajador = [
      { label: 'ACTIVADO' },
      { label: 'DESACTIVADO' },
   ]
   const nacionalidadValue = [
      { label: 'CHILENO' },
      { label: 'EXTRANJERO' },
   ]
   const situacionMilitar = [
      {  id: 1, label: 'Efectuado' },
      {  id: 2, label: 'Eximido' },
      {  id: 3, label: 'Postergado' },
      {  id: 4, label: 'Inscrito Disponible' },
      {  id: 5, label: 'Sin Servicio Militar' }
   ]
   const nivelEstudios = [
      { id: 1, label: 'Certificaciones Profesionales' },
      { id: 2, label: 'Post Grado' },
      { id: 3, label: 'Universitaria' },
      { id: 4, label: 'Técnica' },
      { id: 5, label: 'Básica' }
   ]
   const comunaValue = [
      {  id: 1, label: 'Comuna 1' },
      {  id: 2, label: 'Comuna 2' },
      {  id: 3, label: 'Comuna 3' },
      {  id: 4, label: 'Comuna 4' },
      {  id: 5, label: 'Comuna 5' }
   ]
   const regionValue = [
      { id: 1, label: 'Región 1' },
      { id: 2, label: 'Región 2' },
      { id: 3, label: 'Región 3' },
      { id: 4, label: 'Región 4' },
      { id: 5, label: 'Región 5' }
   ]

   const rules = useMemo(() => ({
      codigoInsumo: [
         VALIDATORS.REQUIRED,
      ],
      nombreInsumo: [
         VALIDATORS.REQUIRED,
      ],
      detalleLargo: [
         VALIDATORS.REQUIRED
      ],
      grupo: [
         VALIDATORS.REQUIRED,
      ],
      estadoProducto: [
         VALIDATORS.REQUIRED,
      ],
      clasificacionCompra: [
         VALIDATORS.REQUIRED,
      ],

   }), []);

   useEffect(() => {
      if(props?.valuePersonalData){
         setvaluePersonalData(props.valuePersonalData)
      }
   }, [props?.valuePersonalData])
   
   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'n_document':
            props.setError('')
            setvaluePersonalData(prev => ({ ...prev, n_document: value }));
            break;
         case 'name':
            props.setError('')  
            setvaluePersonalData(prev => ({ ...prev, name: value }))
            break;
         case 'surname':
            props.setError('')  
            setvaluePersonalData(prev => ({ ...prev, surname: value }))
            break;
         case 'second_surname':
            props.setError('')  
            setvaluePersonalData(prev => ({ ...prev, second_surname: value }));
            break;
         case 'direccion':
            props.setError('')  
            setvaluePersonalData(prev => ({ ...prev, direccion: value }));
            break;
         case 'telefono':
            props.setError('')  
            setvaluePersonalData(prev => ({ ...prev, telefono: value }));
            break;
         // case 'ciudad':
         //    props.setError('')  
         //    setvaluePersonalData(prev => ({ ...prev, ciudad: value }));
         //    break;
         case 'celular':
            props.setError('')  
            setvaluePersonalData(prev => ({ ...prev, celular: value }));
            break;
         case 'emergency_phone':
         props.setError('')  
         setvaluePersonalData(prev => ({ ...prev, emergency_phone: value }));
         break;
         case 'email':
            props.setError('')  
            setvaluePersonalData(prev => ({ ...prev, email: value }));
            break;
         default:
            break;
      }  
   };

   const handleChangeBirthDate = (newValue) => {
      props.setError('')
      console.log("soy al fecha: ", newValue.target.value)
      setvaluePersonalData({...valuePersonalData, birth_date: newValue.target.value})
    };


   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      setvaluePersonalData(prev => ({ ...prev, [name]: newVal }));
   }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
      setError('');
      if(!valuePersonalData.n_document){
         return setError('n_document')
      }
      if(!valuePersonalData.name){
         return setError('name')
      }
      if(!valuePersonalData.surname){
         return setError('surname')
      }
      if(!valuePersonalData.second_surname){
         return setError('second_surname')
      }
      if(!valuePersonalData.idcivilstatus){
         return setError('idcivilstatus')
      }
      if(!valuePersonalData.idemployeetype){
         return setError('idemployeetype')
      }
      if(!valuePersonalData.sexo){
         return setError('sexo')
      }
      // if(!valuePersonalData.pensioner){
      //    return setError('pensioner')
      // }
      if(!valuePersonalData.state){
         return setError('state')
      }
      if(!dateFormat(valuePersonalData.birth_date)){
         return setError('birth_date')
      }
      if(!valuePersonalData.idmilitary){
         return setError('idmilitary')
      }
      if(!valuePersonalData.ideducation){
         return setError('ideducation')
      }
      if(!valuePersonalData.direccion){
         return setError('direccion')
      }
      if(!valuePersonalData.iddistrict){
         return setError('iddistrict')
      }
      if(!valuePersonalData.telefono){
         return setError('telefono')
      }
      if(!valuePersonalData.celular){
         return setError('celular')
      }
      if(!valuePersonalData.emergency_phone){
         return setError('emergency_phone')
      }
      if(!valuePersonalData.email){
         return setError('email')
      }
      
      const dataCreate = {
         ...valuePersonalData,
         birth_date: dateFormat(valuePersonalData.birth_date)
      }
      
      const resp =  props.createWorkers(dataCreate);
      
      if(!resp){
         console.log('error')
      }
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }

   return (
      <Container maxWidth="xl">
         <form >
            <Grid container spacing={3} className={`e-mb-lg`}>

               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="n_document"
                     type='text'
                     placeholder="Rut"
                     value={valuePersonalData.n_document}
                     onChange={handleInput}
                     error={props.error=='n_document'}
                     errorMessages={props.error=='n_document' && 'Campo Obligatorio'}
                     // backgroundColor="#F3F3F3"
                    
                     disableElevation
            
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />

               </Grid>

               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="name"
                     type='text'
                     placeholder="Nombres"
                     value={valuePersonalData.name}
                     onChange={handleInput}
                     error={props.error=='name'}
                     errorMessages={props.error=='name' && 'Campo Obligatorio'}
                     // backgroundColor="#F3F3F3"
                     
                     disableElevation
            
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />

               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="surname"
                     type='text'
                     placeholder="Apellido Paterno"
                     value={valuePersonalData.surname}
                     onChange={handleInput}
                     error={props.error=='surname'}
                     errorMessages={props.error=='surname' && 'Campo Obligatorio'}
                     
                     // backgroundColor="#F3F3F3"
                     
                     disableElevation
            
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />

               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="second_surname"
                     type='text'
                     placeholder="Apellido Materno"
                     value={valuePersonalData.second_surname}
                     onChange={handleInput}
                     error={props.error=='second_surname'}
                     errorMessages={props.error=='second_surname' && 'Campo Obligatorio'}
                     // backgroundColor="#F3F3F3"
                     
                     disableElevation
            
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />

               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={props.dataInitial.CivilStatus}
                     onChange={(e,value:any)=>{props.setError('');setvaluePersonalData({...valuePersonalData, idcivilstatus: value?value.id:null})}}
                     getOptionLabel={(option: any) => option.name ? option.name : ''}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     value={
                        // props.valuePersonalData?.idcivilstatus?(
                        //    props.dataInitial.CivilStatus.filter((value)=>{return value.id == props.valuePersonalData?.idcivilstatus})[0]
                        // ):(
                           valuePersonalData?.idcivilstatus?(
                              props.dataInitial.CivilStatus.filter((value)=>{return value.id == valuePersonalData?.idcivilstatus})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     renderInput={(params) => <TextField {...params} label="Estado Civil" 
                     error={props.error=='idcivilstatus'} 
                     helperText={props.error=='idcivilstatus' && 'Campo obligatorio'} />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={props.dataInitial.EmployeeType}
                     onChange={(e, value:any)=>{
                        const validpensioner = (value.code == 0 || value.code == 3 ) ? 0 : 1;
                        props.setError('');
                        setvaluePersonalData(prev => ({ ...valuePersonalData, idemployeetype: value?value.id:null, pensioner: validpensioner}));
                     }}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     value={
                        // props.valuePersonalData?.idemployeetype?(
                        //    props.dataInitial.EmployeeType.filter((value)=>{return value.id == props.valuePersonalData?.idemployeetype})[0]
                        // ):(
                           valuePersonalData?.idemployeetype?(
                              props.dataInitial.EmployeeType.filter((value)=>{return value.id == valuePersonalData?.idemployeetype})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     getOptionLabel={(option: any) => option.name ? option.name : ''}
                     renderInput={(params) => <TextField {...params} label="Tipo Trabajador" 
                     error={props.error=='idemployeetype'}
                     helperText={props.error== 'idemployeetype' && 'Campo obligatorio'}/>}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={sexoValue}
                     onChange={(e,value:any)=>{props.setError('');setvaluePersonalData({...valuePersonalData, sexo:value?value.id:null })}}
                     value={
                        // props.valuePersonalData?.sexo?(
                        //   sexoValue.filter((value)=>{return value.id == props.valuePersonalData?.sexo})[0]
                        // ):(
                           valuePersonalData?.sexo?(
                             sexoValue.filter((value)=>{return value.id == valuePersonalData?.sexo})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Sexo "
                     error={props.error=='sexo'}
                     helperText={props.error=='sexo' && 'Campo obligatorio'} />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  {/* <LocalizationProvider dateAdapter={AdapterDateFns} color="primary" focused>
                     <Stack>
                        <DatePicker
                            inputFormat="MM/dd/yyyy"
                           // views={['year', 'month', 'day']}
                           label="Fecha de Nacimiento"
                           value={valuePersonalData.birth_date}
                           onChange={handleChangeBirthDate}
                           renderInput={(params) => <TextField {...params} 
                           error={props.error=='birth_date'}
                           helperText={props.error=='birth_date' && 'Campo obligatorio'} />}
                        />
                     </Stack>                                               
                  </LocalizationProvider> */}
                  <TextField
                           variant="standard"
                           fullWidth
                           name="birth_date"
                           type="date"
                           onChange={handleChangeBirthDate}
                           value={valuePersonalData.birth_date}
                           InputLabelProps={{
                              shrink: true,
                           }}
                           error={props.error=='birth_date'}
                           helperText={props.error=='birth_date' && 'Campo obligatorio'}
                        />
               </Grid>

               {/* <Grid item xs={12} sm={3}>
                  <FormControl >
                     <FormControlLabel control={<Checkbox />} label="Pensionado" />                     
                  </FormControl>
               </Grid>  */}

               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={props.dataInitial.State}
                     onChange={(e, value:any)=>{props.setError('');setvaluePersonalData({...valuePersonalData, state: value?value.valueStatus:null})}}
                     getOptionLabel={(option: any) => option.status ? option.status : ''}
                     value={
                        // props.valuePersonalData?.state?(
                        //    props.dataInitial.State.filter((value)=>{return value.valueStatus == props.valuePersonalData?.state})[0]
                        // ):(
                           valuePersonalData?.state?(
                              props.dataInitial.State.filter((value)=>{return value.valueStatus == valuePersonalData?.state})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Estado del Trabajador"
                     error={props.error=='state'}
                     helperText={props.error=='state' && 'Campo obligatorio'} />}
                  />
               </Grid>
               {/* <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={props.dataInitial.Country}
                     getOptionLabel={(option: any) => option.name ? option.name : ''}
                     value={
                        props.valuePersonalData?.idmilitary?(
                           props.dataInitial.Country.filter((value)=>{return value.id == props.valuePersonalData?.idmilitary})[0]
                        ):(
                           valuePersonalData?.idmilitary?(
                              props.dataInitial.Country.filter((value)=>{return value.id == valuePersonalData?.idmilitary})[0]
                           ):(
                              null
                           )
                        )
                        }
                     
                     renderInput={(params) => <TextField {...params} label="Nacionalidad" 
                     error={props.error=='idnationality'}
                     helperText={props.error=='DataModalFamily' && 'Campo obligatorio'} />}
                  />
               </Grid> */}
               {/* <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={props.dataInitial.Military}
                     onChange={(e,value:any)=>{props.setError('');setvaluePersonalData({...valuePersonalData, idmilitary: value.id})}}
                     getOptionLabel={(option: any) => option.name ? option.name : ''}
                     value={
                        props.valuePersonalData?.idmilitary?(
                           props.dataInitial.Military.filter((value)=>{return value.id == props.valuePersonalData?.idmilitary})[0]
                        ):(
                           valuePersonalData?.idmilitary?(
                              props.dataInitial.Military.filter((value)=>{return value.id == valuePersonalData?.idmilitary})[0]
                           ):(
                              null
                           )
                        )
                        }
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Situación Militar"
                     error={props.error=='idmilitary'}
                     helperText={props.error=='idmilitary' && 'Campo obligatorio'} />}
                  />
               </Grid> */}
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={props.dataInitial.Education}
                     onChange={(e, value:any)=>{props.setError('');setvaluePersonalData({...valuePersonalData, ideducation: value?value.id:null})}}
                     getOptionLabel={(option: any) => option.name ? option.name : ''}
                     value={
                        // props.valuePersonalData?.ideducation?(
                        //    props.dataInitial.Education.filter((value)=>{return value.id == props.valuePersonalData?.ideducation})[0]
                        // ):(
                           valuePersonalData?.ideducation?(
                              props.dataInitial.Education.filter((value)=>{return value.id == valuePersonalData?.ideducation})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Último nivel de Estudios" 
                     error={props.error =='ideducation'} 
                     helperText={props.error=='ideducation' && 'Campo obligatorio'} />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     ref={ref => inputRefs.current[1] = ref}
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <HeightIcon />

                        </InputAdornment>
                     }
                     name="direccion"
                     placeholder="Dirección"
                     value={valuePersonalData.direccion}
                     onChange={handleInput}
                     error={props.error=='direccion'}
                     errorMessages={props.error=='direccion' && 'Campo obligatorio'}
                     // backgroundColor="#F3F3F3"
                     
                     disableElevation
            
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={props.dataInitial.District}
                     onChange={(e,value:any)=>{props.setError('');setvaluePersonalData({...valuePersonalData, iddistrict: value?value.id:null})}}
                     getOptionLabel={(option: any) => option.name ? option.name : ''}
                     value={
                        // props.valuePersonalData?.iddistrict?(
                        //    props.dataInitial.District.filter((value)=>{return value.id == props.valuePersonalData?.iddistrict})[0]
                        // ):(
                           valuePersonalData?.iddistrict?(
                              props.dataInitial.District.filter((value)=>{return value.id == valuePersonalData?.iddistrict})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Comuna" 
                     error={props.error=='iddistrict'}
                     helperText={props.error=='iddistrict' && 'Campo obligatorio'} />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     ref={ref => inputRefs.current[1] = ref}
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <PhoneIcon />

                        </InputAdornment>
                     }
                     name="telefono"
                     placeholder="Teléfono Fijo"
                     value={valuePersonalData.telefono}
                     onChange={handleInput}
                     error={props.error=='telefono'}
                     errorMessages={props.error=='telefono' && 'Campo obligatorio'}
                     // backgroundColor="#F3F3F3"
                     
                     disableElevation
            
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               {/* <Grid item xs={12} sm={3}>
                  <Input
                     ref={ref => inputRefs.current[1] = ref}
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <LocationCityIcon />
                        </InputAdornment>
                     }
                     name="ciudad"
                     placeholder="Ciudad"
                     value={valuePersonalData.ciudad}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     
                     disableElevation
            
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid> */}
               <Grid item xs={12} sm={3}>
                  <Input
                     ref={ref => inputRefs.current[1] = ref}
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <PhoneAndroidIcon />                           
                        </InputAdornment>
                     }
                     name="celular"
                     placeholder="Celular"
                     value={valuePersonalData.celular}
                     onChange={handleInput}
                     error={props.error=='celular'}
                     errorMessages={props.error=='celular' && 'Campo obligatorio'}
                     // backgroundColor="#F3F3F3"
                     
                     disableElevation
            
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     ref={ref => inputRefs.current[1] = ref}
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <PhoneAndroidIcon />                           
                        </InputAdornment>
                     }
                     name="emergency_phone"
                     placeholder="Celular de Emergencia"
                     value={valuePersonalData.emergency_phone}
                     onChange={handleInput}
                     error={props.error=='emergency_phone'}
                     errorMessages={props.error=='emergency_phone' && 'Campo obligatorio'}
                     // backgroundColor="#F3F3F3"
                     
                     disableElevation
            
                     dense    
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     ref={ref => inputRefs.current[1] = ref}
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <AlternateEmailIcon />                          
                        </InputAdornment>
                     }
                     name="email"
                     placeholder="Email"
                     value={valuePersonalData.email}
                     onChange={handleInput}
                     error={props.error=='email'}
                     errorMessages={props.error=='email' && 'Campo obligatorio'}
                     // backgroundColor="#F3F3F3"
                     
                     disableElevation
            
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               {/* <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={regionValue}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Región" />}
                  />
               </Grid>               */}

               <Grid item container
                  xs={12}
                  justifyContent='right'
               >
                  <Grid item>
                     {/* <Button
                        type='submit'
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>Guardar</span>
                        }
                     </Button> */}
                     <Button
                        type='submit'
                        variant='contained'
                        onClick={()=>{  props.setvaluePersonalData( {...valuePersonalData,
                           birth_date: dateFormat(valuePersonalData.birth_date)}); props.handleNext();}}
                        disabled={loading}
                        disableElevation
                        className='btn_save'
                        sx={{ml:2}}
                        endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>Siguiente</span>
                        }
                     </Button>
                     

                  </Grid>
               </Grid>
            </Grid>
         </form>
      </Container>
   );
}

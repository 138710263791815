import { AdvanceRepository } from "../repositories/Advance.repository";
import { EmploymentHistoryRepository } from "../repositories/EmploymentHistory.repository";

export const EmploymentHistoryService = {
    getEmploymentHistory,
    createEmploymentHistory,
    editEmploymentHistory,
    deleteEmploymentHistory,
}

async function getEmploymentHistory(idEmployee: number){
    const res = await EmploymentHistoryRepository.getEmploymentHistory(idEmployee);
    return res;
}

async function createEmploymentHistory(data){
    const res = await EmploymentHistoryRepository.createEmploymentHistory(data);
    return res;
}

async function editEmploymentHistory(data, idHistory: number){
    const res = await EmploymentHistoryRepository.editEmploymentHistory(data, idHistory);
    return res;
}

async function deleteEmploymentHistory(idHistory:number){
    const res = await EmploymentHistoryRepository.deleteEmploymentHistory(idHistory);
    return res;
}

import { http } from '../http/http';
import { UserDetailsDTO,UserDTO,UserPost,UserRequestPost, UsersDTO} from '../http/dto/UserDTO';
import { UserDetails, Users } from '../models/User';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { User } from '../models/User';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { WorkersDTO } from '../http/dto/WorkerDTO';
import { Worker } from '../models/Worker';
import { BranchOffice } from '../models/BranchOffice';
import { Departament } from '../models/Departaments';
import { DeparmentDTO } from '../http/dto/DepartamentsDTO';


export const deparmentRepository = {

   //`tbl_business_area`

   getDeparments: async () : Promise<Departament>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const users = await http.get<DeparmentDTO>(`${API_URL_BASE}/v1/businessArea`);
      const { data, error, message } = users;
      return {
         data: (data||[]).map((raw) => ({
            id: raw?.id,
            name: raw?.name,
            branchOffice: raw?.branchOffice,
            count_workers: raw?.count_workers,
            idbranchOffice: raw?.idbranchOffice,
         })),
         error,
         message
      }
   },

   createDepartment: async (dataDepartment) : Promise<any> => {
      const res = await http.post<any>(`${API_URL_BASE}/v1/businessArea`, {
         name: dataDepartment.name,
         idbranchoffice: dataDepartment.idbranchoffice,
      });
      const {data, error, message} = res;
      return { data, error, message };
   },

   editDepartment: async (dataDepartment, idDepartment: number) : Promise<any> => {
      const res = await http.patch<any>(`${API_URL_BASE}/v1/businessArea/${idDepartment}`, {
         name: dataDepartment.name,
         idbranchoffice: dataDepartment.idbranchoffice,
      });
      const {data, error, message} = res;
      return { data, error, message };
   },

   deleteDepartment: async(idDepartment:number): Promise<any> => {
      const res= await http.delete<any>(`${API_URL_BASE}/v1/businessArea/${idDepartment}`)
      return res;
   },

   //funtions

   getResponsibilities: async(idArea: number): Promise<any> => {
      const res = await http.get<any>(`${API_URL_BASE}/v1/businessArea/getFunction/${idArea}`);
      const {data, error, message} = res;
      return { data, error, message };
   },

   createResponsibilities: async (dataFunction) : Promise<any> => {
      const res = await http.post<any>(`${API_URL_BASE}/v1/businessArea/createFunction`, {
         description: dataFunction.description,
         idbusiness_area: dataFunction.idbusiness_area,
      });
      const {data, error, message} = res;
      return { data, error, message };
   },

   editResponsibilities: async (dataFunction, idFunction: number) : Promise<any> => {
      const res = await http.patch<any>(`${API_URL_BASE}/v1/businessArea/editarFunction/${idFunction}`, {
         description: dataFunction.description,
         idbusiness_area: dataFunction.idbusiness_area,
      });
      const {data, error, message} = res;
      return { data, error, message };
   },

   deleteResponsibilities: async(idFunction:number): Promise<any> => {
      const res= await http.delete<any>(`${API_URL_BASE}/v1/businessArea/deleteFunction/${idFunction}`)
      return res;
   }
}



import { ReportRepository } from "../repositories/Report.repository";

export const ReportService = {
    getReportAbsenceDelay,
    getReportEmployee,
    getReportRequest,
    getReportLiquidation,
    getReportGroupCompany,
    getReportGroupCompanyExcel,
    getReportExpens,
    getReportExpensExcel,
    getReportRequestExcel,
    getReportLiquidationExcel,
    getReportExcelAbsence,
    getReportExcelEmployees,
    getReportliquidationByProjects,
}

async function getReportAbsenceDelay(start_date:any, end_date:any, idEmployee:number){
    const res = await ReportRepository.getReportAbsenceDelay(start_date, end_date, idEmployee);
    return res;
}

async function getReportEmployee(idPeriod:number){
    const res = await ReportRepository.getReportEmployee(idPeriod);
    return res;
}

async function getReportRequest(idPeriod:number){
    const res = await ReportRepository.getReportRequest(idPeriod);
    return res;
}
async function  getReportLiquidation(idCompany:number, idperiod:number){
    const res = await ReportRepository.getReportLiquidation(idCompany, idperiod);
    return res;
}
async function  getReportExpens(idCompany:number, idperiod:number){
    const res = await ReportRepository.getReportExpens(idCompany, idperiod);
    return res;
}
async function  getReportExcelAbsence(start_date:any, end_date:any, idEmployee:number){
    const res = await ReportRepository.getReportExcelAbsence(start_date, end_date, idEmployee);
    return res;
}
async function getReportExcelEmployees(idperiod: number) {
    const res = await ReportRepository.getReportExcelEmployees(idperiod);
    return res;
}
async function getReportliquidationByProjects(idperiod:any, month:any, year:any){
    const res = await ReportRepository.getReportliquidationByProjects(idperiod, month, year);
    return res;
}
async function getReportGroupCompany(idperiod:any, id){
    const res = await ReportRepository.getReportGroupCompany(idperiod, id);
    return res;
}
async function getReportGroupCompanyExcel(idperiod:any, id){
    const res = await ReportRepository.getReportGroupCompanyExcel(idperiod, id);
    return res;
}
// 3 ULTIMAS RUTAS DE REPORTE

async function  getReportExpensExcel(idCompany:number, idperiod:number){
    const res = await ReportRepository.getReportExpensExcel(idCompany, idperiod);
    return res;
}
async function  getReportRequestExcel(idCompany:number){
    const res = await ReportRepository.getReportRequestExcel(idCompany);
    return res;
}
async function  getReportLiquidationExcel(idCompany:number, idperiod:number){
    const res = await ReportRepository.getReportLiquidationExcel(idCompany, idperiod);
    return res;
}





import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import { PhasesService } from "@/service/services/Phases.service";
import { branchOfficeService } from "@/service/services/BranchOffice.service";
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalDepartment.type";
import '../../../assets/styles/modals.scss';
import { departmentService } from "@/service/services/Deparment.service";

export const ModalDepartment: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, department, onListDepartments, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [branchOffices, setBranchOffices] = useState<any>([]);
    const [branchOfficeSelected, setBranchOfficeSelected] = useState<any>('');
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [data, setData] = useState({
        id: department ? department.id : '',
        name: department ? department.name : '',
        idbranchoffice: department ? department.idbranchoffice : '',
    });

    useEffect(() => {
        getBranchOffices()
    },[]);

    useEffect(() => {
        if(department && branchOffices){
            setCustomBranch(department.idbranchoffice, branchOffices);
        }

    },[department, branchOffices]);
  
    const getBranchOffices = async () => {
        setLoading(true);
        const response = await branchOfficeService.getBranchOffices();
        if (response.data) {
            setBranchOffices(response.data || [])
        } else {
        console.log('error')
        }
        setLoading(false);
    }

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.name) errors.name = "nombre requerido";
        // if(!values.description) errors.description = "descripción requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        if(!branchOfficeSelected){
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: `Debe seleccionar una Sucursal` }
                ))
            );
        }

        const formData = {
            id: values.id,
            name: values.name,
            idbranchoffice : branchOfficeSelected?.id
        }
        setLoading(true);
        if(department && actionSelected=='edit'){
            await departmentService.editDepartment(formData, department.id).then((data) => {
                onListDepartments && onListDepartments();
                closeModal && closeModal();
            })
        }else{
            await departmentService.createDepartment(formData).then((data) => {
                onListDepartments && onListDepartments();
                closeModal && closeModal();
            })
        }
        setLoading(false);
    }

    function setCustomBranch(idBranchoffice, branchOffices): any {
        console.log(idBranchoffice)
        console.log(branchOffices)
        const data = branchOffices && branchOffices.find(branchOffice => branchOffice.id === idBranchoffice);
        console.log(data);
        const newData = {
            id: data?.id,
            name: data?.name
        }
        return setBranchOfficeSelected(newData);
    }

    return(
        <>
        {loading && <Loading/>}
        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={(department && actionSelected=='edit') ? "Editar departamento" : "Agregar departamento"}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Nombre:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="name"
                                            variant="standard"
                                            type="text" 
                                            name="name"
                                            fullWidth
                                            value={values.name}
                                            onChange={handleChange}
                                            placeholder="Nombre"
                                            error={errors.name && touched.name ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>Sucursal:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                id="disable-portal"
                                                disablePortal
                                                value={branchOfficeSelected}
                                                options={branchOffices || []}
                                                getOptionLabel={(option) => option.address || ""}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} variant="standard" label="Escriba para buscar" />}
                                                onChange={(e, newValue) => { setBranchOfficeSelected(newValue) }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(department && actionSelected=='edit') ? "ACTUALIZAR" : "REGISTRAR"}
                                    cancelText="CANCELAR" 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}
import { ScheduleRepository } from "../repositories/Schedule.repository";

export const ScheduleService = {
    getWorkingTime,
    getWorkingShift,
    getScheduleCompany,
    deleteScheduleCompany,
    editScheduleCompany,
    createScheduleCompany,
    getScheduleEmployee,
    deleteScheduleEmployee,
    editScheduleEmployee,
    createScheduleEmployee,
    getScheduleDetailEmployee
}

async function getWorkingTime(){
    const res = await ScheduleRepository.getWorkingTime();
    return res;
}
async function getWorkingShift(){
    const res = await ScheduleRepository.getWorkingShift();
    return res;
}
async function getScheduleCompany(){
    const res = await ScheduleRepository.getScheduleCompany();
    return res;
}

async function deleteScheduleCompany(data:{}){
    const res = await ScheduleRepository.deleteScheduleCompany(data);
    return res;
}

async function editScheduleCompany(id, data:{}){
    const res = await ScheduleRepository.editScheduleCompany(id, data);
    return res;
}

async function createScheduleCompany(id){
    const res = await ScheduleRepository.createScheduleCompany(id);
    return res;
}


async function getScheduleEmployee(id){
    const res = await ScheduleRepository.getScheduleEmployee(id);
    return res;
}

async function deleteScheduleEmployee(data:{}){
    const res = await ScheduleRepository.deleteScheduleEmployee(data);
    return res;
}

async function editScheduleEmployee(id, data:{}){
    const res = await ScheduleRepository.editScheduleEmployee(id, data);
    return res;
}

async function createScheduleEmployee(data){
    const res = await ScheduleRepository.createScheduleEmployee(data);
    return res;
}

async function getScheduleDetailEmployee(idEmployee, idSchedule){
    const res = await ScheduleRepository.getScheduleDetailEmployee(idEmployee, idSchedule);
    return res;
}

import { AssistanceService } from "@/service/services/Assistance.service";
import { ChargeService } from "@/service/services/Charge.service";
import { CostCenterService } from "@/service/services/CostCenter.service";
import { TurnService } from "@/service/services/Turn.service";
import { Typography } from "@mui/material";
import { useState } from "react";
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { ModalHeader } from "../ModalHeader";
import { Props } from "./ModalTurnDelete.type";

export const ModalTurnDelete: React.FC<Props> = (props:any): JSX.Element => {
    const { open, closeModal, turn, onListTurns, clearState } = props;
    const [loading, setLoading] = useState<boolean>(false);

    const onDeleted= async()=>{
        setLoading(true);
        await TurnService.deleteTurn(turn.id).then((data) => {
            onListTurns && onListTurns();
            closeModal && closeModal();
        })
        setLoading(false);
    }
  
    return (
        <>
        <Modal
        open={open}
        handleClose={closeModal}
        size="xs"
        >
        <ModalHeader
            text="ELIMINAR GIRO DE LA EMPRESA"
            className='positionElements'
            onCancel={closeModal}
            clearState={clearState}
        >
        </ModalHeader>

        <ModalBody>
            <Typography variant="subtitle1" className="textColor"> 
            ¿Está seguro de eliminar este GIRO?
            </Typography>
        </ModalBody>

        <ModalFooter 
            confirmText="Eliminar"
            cancelText="CANCELAR"
            onCancel={closeModal}
            className="footerRight"
            onConfirm={onDeleted}
            clearState={clearState}
        />
        </Modal>
        </>
    )
}
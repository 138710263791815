import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, } from '@mui/material';
import { ArrowBackIcon, ArrowDownIcon, ArrowRightIcon, MoreIcon } from "@toolbox/constants/icons";
import { Props } from './FormBankInformation.type'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import './style.sass'
import "./FormBankInformation_style.css";
import BadgeIcon from '@mui/icons-material/Badge';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export const FormBankInformation: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false)
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
    );
   const [estado, setEstado] = useState<any>({ rut: false, nombre_rz: false })

   const [valueInitialSituation, setValueInitialSituation] = React.useState('Informacion de Compra');

    const handleInitialSituation = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueInitialSituation(event.target.value);
    };

    const [valueBankInformation, setValueBankInformation] = useState<any>(props.valueBankInformation)
    const [error, setError] = useState<any>('')
   const bancoValue = [
      {id:1, label: 'BANCO CHILE'},
      {id:2, label: 'SEGUNDO BANCO'},
      {id:3, label: 'TERCER BANCO'},
      {id:4, label: 'CUARTO BANCO'}
   ]
   const formaPagoValue = [
      {id:1, label: 'Efectivo'},
      {id:2, label: 'Cheque'},
      {id:3, label: 'Vale Vista'},
      {id:4, label: 'Transferencia'}
   ]
   const tipoCuenta = [
      {id:1, label: 'Cuenta Corriente'},
      {id:2, label: 'Cuenta de Ahorro'},
      {id:3, label: 'Cuenta Vista'},
      {id:4, label: 'Línea de Crédito'},
   ]   
   const rules = useMemo(() => ({
      unidadMedida: [
         VALIDATORS.REQUIRED,
      ],
      stockMinimo: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      stockMaximo: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      ubicacionFisica: [
         VALIDATORS.REQUIRED,
      ],

   }), []);
   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'account_number':
            props.setError('');
            setValueBankInformation(prev => ({ ...prev, account_number: value }));
            break;
         default:
            break;
      }
   };

   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      props.setValueBankInformation(prev => ({ ...prev, [name]: newVal }));
   }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
      const resp =  props.createWorkers(valueBankInformation);
      
      try{
         if(resp){
            console.log('sin errores')
         }
      }catch(exeption){
         console.log("Error de formulario: ",exeption)
      }
      
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }

   return (
      <Container maxWidth="xl">
         <form >
            <Grid container spacing={3} className={`e-mb-lg`} mt={2}>
                  
               <Grid item xs={12} sm={3} >
                  <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={props.dataInitial.Bank}
                        onChange={(e, value:any)=>{setValueBankInformation({...valueBankInformation, idbank: value?value.id:null}); props.setError('')}}
                        getOptionLabel={(option: any) => option.name ? option.name : ''}
                        value={
                           // props.valueBankInformation?.idbank?(
                           //    props.dataInitial.Bank.filter((value)=>{return value.id == props.valueBankInformation?.idbank})[0]
                           // ):(
                              valueBankInformation?.idbank?(
                                 props.dataInitial.Bank.filter((value)=>{return value.id == valueBankInformation?.idbank})[0]
                              ):(
                                 null
                              )
                           // )
                           }
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Banco"
                        error={props.error=='idbank'} 
                        helperText={props.error=='idbank' && 'Campo obligatorio'}/>}
                     />
               </Grid>
               <Grid item xs={12} sm={3} >
                  <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={props.dataInitial.WayPay}
                        onChange={(e, value:any)=>{setValueBankInformation({...valueBankInformation, idwaypay: value?value.id: null}); props.setError('')}}
                        getOptionLabel={(option: any) => option.name ? option.name : ''}
                        value={
                           // props.valueBankInformation?.idwaypay?(
                           //    props.dataInitial.WayPay.filter((value)=>{return value.id == props.valueBankInformation?.idwaypay})[0]
                           // ):(
                              valueBankInformation?.idwaypay?(
                                 props.dataInitial.WayPay.filter((value)=>{return value.id == valueBankInformation?.idwaypay})[0]
                              ):(
                                 null
                              )
                           // )
                           }
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Forma de Pago" 
                        error={props.error=='idwaypay'} 
                        helperText={props.error=='idwaypay' && 'Campo obligatorio'}/>}
                     />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={props.dataInitial.AccountType}
                        onChange={(e, value:any)=>{setValueBankInformation({...valueBankInformation, idaccounttype:value? value.id:null}); props.setError('')}}
                        getOptionLabel={(option: any) => option.name ? option.name : ''}
                        value={
                           // props.valueBankInformation?.idaccounttype?(
                           //    props.dataInitial.AccountType.filter((value)=>{return value.id == props.valueBankInformation?.idaccounttype})[0]
                           // ):(
                              valueBankInformation?.idaccounttype?(
                                 props.dataInitial.AccountType.filter((value)=>{return value.id == valueBankInformation?.idaccounttype})[0]
                              ):(
                                 null
                              )
                           // )
                           }
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Tipo Cuenta" 
                        error={props.error=='idaccounttype'} 
                        helperText={props.error=='idaccounttype' && 'Campo obligatorio'}/>}
                     />
               </Grid>                 
               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="account_number"
                     type='text'
                     placeholder="Número"
                     value={valueBankInformation.account_number}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                  
                     disableElevation
                   
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                     error={props.error=='account_number'}
                     errorMessages={props.error=='account_number' && 'Campo obligatorio'}
                  />
               </Grid>
             

               <Grid item container
                  justifyContent='space-between'
               >
                     <Grid item xs={6}>
                        <Button
                           variant='contained'
                           onClick={() => { props.setValueBankInformation(valueBankInformation); props.handleBack(); }}
                           disabled={loading}
                           disableElevation
                           className='btn_back'
                           startIcon={<ArrowBackIosIcon />}
                        >
                           {
                              loading ?
                                 <CircularProgress className="CircularProgress" size={24} /> :
                                 <span>Atrás</span>
                           }
                        </Button>
                     </Grid>
                  <Grid item>
                  <Button
                        type='submit'
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              props.isEdit? <span>Actualizar</span> : <span>Guardar</span>
                        }
                     </Button>
                     
                     

                  </Grid>
               </Grid>
            </Grid>
         </form>
      </Container>
   );
}

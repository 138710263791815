import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import axios from 'axios';

const dataUser = readLocalStorage(KEY_USER_DATA);
const idCompany = dataUser?.user?.id_company;

export const RemunerationRepository = {

    verfifyRemuneration: async(idEmploye: number, idPeriodo: number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/remuneration/verify/${idEmploye}?idperiod=${idPeriodo}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    preRemuneration: async (dataPre, idEmploye: number) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/preremuneration/${idEmploye}`, {
            idperiod: dataPre.idperiod,
            month: dataPre.month,
            year: dataPre.year
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    generatePDFPreRemuneration: async (dataPdf) : Promise<any> => {
        const res = await http.postPDF<any>(`${API_URL_BASE}/v1/preremuneration/generatePDF`, {
            idperiod: dataPdf.idperiod,
            idemployee: dataPdf.idemployee,
        });
        return res;
    },

    generatePDFRemuneration: async (dataPdf) : Promise<any> => {
        const res = await http.postPDF<any>(`${API_URL_BASE}/v1/remuneration/generatePDF`, {
            idperiod: dataPdf.idperiod,
            idemployee: dataPdf.idemployee,
        });
        return res;
    },

    createRemuneration: async (dataRemu, idEmploye: number) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/remuneration/${idEmploye}`, {
            idperiod: dataRemu.idperiod,
            month: dataRemu.month,
            year: dataRemu.year
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    preRemunerationStoreMassive: async (dataRemu) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/preremuneration/createMassive`, {
            idperiod: dataRemu.idperiod,
            month: dataRemu.month,
            year: dataRemu.year
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    generatePreRemunerationPDFMassive: async (dataPdf) : Promise<any> => {
        const res = await http.postPDF<any>(`${API_URL_BASE}/v1/preremuneration/generatePDFMassive`, {
            idperiod: dataPdf.idperiod,
            month: dataPdf.month,
            year: dataPdf.year,
            idproject: dataPdf.idproject,
            idbusinessarea: dataPdf.idbusinessarea,
            idcharge: dataPdf.idcharge,
            idcostcenter: dataPdf.idcostcenter,
            id_company: idCompany,
        });
        return res;
    },

    createRemunerationMassive: async (dataRemu) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/remuneration/createMassive`, {
            idperiod: dataRemu.idperiod,
            month: dataRemu.month,
            year: dataRemu.year,
            idproject: dataRemu.idproject,
            idbusinessarea: dataRemu.idbusinessarea,
            idcharge: dataRemu.idcharge,
            idcostcenter: dataRemu.idcostcenter,
            id_company: idCompany,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    requestRemunerationError: async(dataRemu): Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/requestManagement/create`, {
            idrequests_type: 6,
            detail: dataRemu.detail,
            from_date_request: dataRemu.from_date_request,
            to_date_request: dataRemu.to_date_request,
            idremuneration: dataRemu.idremuneration
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    changeEmployeeStatus: async(id_remuneration, status_confirmation): Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/remuneration/changeEmployeeStatus/${id_remuneration}`, {
            status_confirmation: status_confirmation,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    changeStatusRemuneration: async(id_employee): Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/remuneration/changeStatusRemuneration/${id_employee}`, {});
        const {data, error, message} = res;
        return { data, error, message };
    }

}   
import { RleStructureRepository } from "../repositories/RleStructure.repository";

export const RleStructureService = {
    getRLEStructure,
    getRLEStructureType,
    getRLEStructureFilter,
    getRLEStructureOptional,
    editRLEOptional,
    createStructureOptional,
    createStructure,
    getDetailEmployeeSO,
    editDetailEmployeeSO,
    editRLEStructure,
    deleteRLEStructure,
    editDetailEmployeeSOMultiple,
}

async function getRLEStructure(){
    const res = await RleStructureRepository.getRLEStructure();
    return res;
}

async function getRLEStructureType(){
    const res = await RleStructureRepository.getRLEStructureType();
    return res;
}

async function getRLEStructureFilter(value: number){
    const res = await RleStructureRepository.getRLEStructureFilter(value);
    return res;
}

async function getRLEStructureOptional(){
    const res = await RleStructureRepository.getRLEStructureOptional();
    return res;
}

async function createStructureOptional(data:{}){
    const res = await RleStructureRepository.createStructureOptional(data);
    return res;
}

async function createStructure(data:{}){
    const res = await RleStructureRepository.createStructure(data);
    return res;
}

async function editRLEOptional(id, change_value) {
    const resp = await RleStructureRepository.editRLEOptional(id, change_value);
    return resp;
}

async function editRLEStructure(id, change_value) {
    const resp = await RleStructureRepository.editRLEStructure(id, change_value);
    return resp;
}

async function deleteRLEStructure(id) {
    const resp = await RleStructureRepository.deleteRLEStructure(id);
    return resp;
}

async function getDetailEmployeeSO(id) {
    const resp = await RleStructureRepository.getDetailEmployeeSO(id);
    return resp;
}

async function editDetailEmployeeSO(id, change_value) {
    const resp = await RleStructureRepository.editDetailEmployeeSO(id, change_value);
    return resp;
}

async function editDetailEmployeeSOMultiple(data) {
    const resp = await RleStructureRepository.editDetailEmployeeSOMultiple(data);
    return resp;
}

import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Button, Card, Grid, MenuItem, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Loading } from "@/components/common/Loading";
import { companiesService } from '@/service/services/Companies.service';
import { Props } from './ExtraHourValue.type';

export const ExtraHourValue: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, dataCompany, onListDataCompany, mutuality, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);
    

    const [data, setData] = useState({
        id: dataCompany ? dataCompany.idcompany : '',
        extra_hours1: dataCompany ? dataCompany.extra_hours1 : '',
        extra_hours2: dataCompany ? dataCompany.extra_hours2 : '',
        extra_hours3: dataCompany ? dataCompany.extra_hours3 : '',
    });

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.extra_hours2) errors.extra_hours2 = "horas extras 1 requerido";
        if(!values.extra_hours3) errors.extra_hours3 = "horas extras 2 requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            extra_hours1 : dataCompany.extra_hours1,
            extra_hours2 : values.extra_hours2,
            extra_hours3 : values.extra_hours3,
        }
        setLoading(true);
        await companiesService.updateExtraHours(formData).then((data) => {
            onListDataCompany && onListDataCompany();
            closeModal && closeModal();
        })
        setLoading(false);
    }
    return(
        <>
        {loading && <Loading/>}

        
            <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                    return(
                        <form onSubmit={handleSubmit}>
                            <Card sx={{ width: "100%", marginTop: '20px' }}>
                                <TableContainer>
                                    <Table>
                                        <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                            <TableRow >
                                                <TableCell >
                                                    <Typography>Valor de Horas Extras</Typography>
                                                </TableCell>
                                                <TableCell >
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            <TableRow >
                                                <TableCell size={'small'} sx={{ bgcolor: '#F4F6F8', width: '30%' }}>
                                                    Horas Extras 1
                                                </TableCell>
                                                <TableCell >
                                                    <TextField
                                                        id="extra_hours1"
                                                        variant="standard"
                                                        sx={{ width: '400px' }}
                                                        name="extra_hours1"
                                                        type="number"
                                                        value={values.extra_hours1}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        disabled
                                                    />
                                                </TableCell>
                                            </TableRow>

                                            <TableRow >
                                                <TableCell size={'small'} sx={{ bgcolor: '#F4F6F8', width: '30%' }}>
                                                Horas Extras 2
                                                </TableCell>
                                                <TableCell >
                                                    <TextField
                                                        id="extra_hours2"
                                                        variant="standard"
                                                        sx={{ width: '400px' }}
                                                        name="extra_hours2"
                                                        type="number"
                                                        onChange={handleChange}
                                                        value={values.extra_hours2}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={errors.extra_hours2 && touched.extra_hours2 ? true : false}
                                                    />
                                                </TableCell>
                                            </TableRow>

                                            <TableRow >
                                                <TableCell size={'small'} sx={{ bgcolor: '#F4F6F8', width: '30%' }}>
                                                Horas Extras 3
                                                </TableCell>
                                                <TableCell >
                                                    <TextField
                                                        id="extra_hours3"
                                                        variant="standard"
                                                        sx={{ width: '400px' }}
                                                        name="extra_hours3"
                                                        type="number"
                                                        onChange={handleChange}
                                                        value={values.extra_hours3}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={errors.extra_hours3 && touched.extra_hours3 ? true : false}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Grid sx={{ textAlign: 'center', margin: '10px' }}>
                                    <Button color="primary" variant="contained" onClick={() => {handleSubmit()}}>
                                        ACTUALIZAR
                                    </Button>
                                </Grid>
                            </Card>
                        </form>
                    )
                }}
            </Formik>
        </>
    );
}
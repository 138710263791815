import { ConfigurationIPRepository } from "../repositories/ConfigurationIP.repository";

export const ConfigurationIPService = {
    getIPAccessByCompany,
    getIPAccessByUser,
    acceptIP,
    editAccessIP,
    deleteAccessIP,

    getIPDeniedByCompany,
    getIPDeniedByUser,
    deniedIP,
    editDeniedIP,
    deleteDeniedIP,
    assignPermissionIP,

    getCompanyIP,
    editSettingIP,
}
//IPS ACEPTADAS

async function getIPAccessByCompany(){
    const res = await ConfigurationIPRepository.getIPAccessByCompany();
    return res;
}

async function getIPAccessByUser(idUser: number){
    const res = await ConfigurationIPRepository.getIPAccessByUser(idUser);
    return res;
}

async function acceptIP(data:{}){
    const res = await ConfigurationIPRepository.acceptIP(data);
    return res;
}

async function editAccessIP(data:{}, idIP:number){
    const res = await ConfigurationIPRepository.editAccessIP(data, idIP);
    return res;
}

async function deleteAccessIP(id: number){
    const res = await ConfigurationIPRepository.deleteAccessIP(id);
    return res;
}

//IPS DENEGADAS

async function getIPDeniedByCompany(){
    const res = await ConfigurationIPRepository.getIPDeniedByCompany();
    return res;
}

async function getIPDeniedByUser(idUser: number){
    const res = await ConfigurationIPRepository.getIPDeniedByUser(idUser);
    return res;
}

async function deniedIP(data:{}){
    const res = await ConfigurationIPRepository.deniedIP(data);
    return res;
}

async function editDeniedIP(data:{}, idIP:number){
    const res = await ConfigurationIPRepository.editDeniedIP(data, idIP);
    return res;
}

async function deleteDeniedIP(id: number){
    const res = await ConfigurationIPRepository.deleteDeniedIP(id);
    return res;
}

async function assignPermissionIP(data:{}){
    const res = await ConfigurationIPRepository.assignPermissionIP(data);
    return res;
}

//SETTING IP BY COMPANY

async function getCompanyIP(){
    const res = await ConfigurationIPRepository.getCompanyIP();
    return res;
}

async function editSettingIP(data:{}){
    const res = await ConfigurationIPRepository.editSettingIP(data);
    return res;
}




import React, { useState, useMemo, useRef, useEffect  } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, InputLabel, Autocomplete, Button, Tabs, Tab} from "@mui/material";
import { Props } from './StructureLre.type';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { styled } from '@mui/material/styles';
import { RleStructureService } from '@/service/services/RleStructure.service';
import { ModalStructureLre } from '@/components/Modal/ModalStructureLre';
import { Input } from '@/components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import { InputChildren } from './children';

// import './SettkementStructure_styles.css'

const columnsRLEStructure = [
   { id: 'concept_DT', label: 'CONCEPTO DT', minWidth: 100 },
   { id: 'code', label: 'CODIGO', minWidth: 100 },
   { id: 'name', label: 'DESCRIPCION', minWidth: 100 },
   // { id: 'status', label: 'SELECCIONADO', minWidth: 100 },
];

const columnsRLEOptional = [
   { id: 'concept_DT', label: 'CONCEPTO DT', minWidth: 100 },
   { id: 'code', label: 'CODIGO', minWidth: 100 },
   { id: 'name', label: 'DESCRIPCION', minWidth: 100 },
   { id: 'alias_name', label: 'OTRA DESCRIPCION', minWidth: 150 },
   { id: 'check', label: 'VIGENCIA INDEFINIDA', minWidth: 100 },
   { id: 'status', label: 'ESTADO', minWidth: 100 },
];


const labels = [{ label: 'Estructuras Obligatorias', id: '1' }, { label: 'Estructuras Opcionales', id: '2' }]

export const StructureLreView: React.FC<Props> = (props:any): JSX.Element => {

   const [loading, setLoading] = useState<boolean>(false);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('1');
   const inputRefs = useRef<Array<InputRef | null>>([]);

   const [dataRLEStructure, setDataRLEStructure] = useState<any>([]);
   const [dataRLEOptional, setDataRLEOptional] = useState<any>([]);
   const [showStructureModal, setShowStructureModal] = useState<boolean>(false);

   const Android12Switch = styled(Switch)(({ theme }) => ({
   padding: 8,
   '& .MuiSwitch-track': {
     borderRadius: 22 / 2,
     '&:before, &:after': {
       content: '""',
       position: 'absolute',
       top: '50%',
       transform: 'translateY(-50%)',
       width: 16,
       height: 16,
     },
     '&:before': {
       backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
         theme.palette.getContrastText(theme.palette.primary.main),
       )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
       left: 12,
     },
     '&:after': {
       backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
         theme.palette.getContrastText(theme.palette.primary.main),
       )}" d="M19,13H5V11H19V13Z" /></svg>')`,
       right: 12,
     },
   },
   '& .MuiSwitch-thumb': {
     boxShadow: 'none',
     width: 16,
     height: 16,
     margin: 2,
   },
   }));

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const handleChange = (event: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
   };

   const changeSelected = (e, value) => {
      const nuevaData = {
         ...value,
         indefinite_validity: e.target.checked ? '1':'0'
         // status: e.target.checked ? '1':'0'
      }
      const changeStatus = dataRLEOptional.map((row) => 
            row.id === value.id ? nuevaData : row
      );
      editRLEOptional(nuevaData)
      setDataRLEOptional(changeStatus);
   }

   const changeOnOff = (e, value) => {
      const nuevaData = {
         ...value,
         // indefinite_validity: e.target.checked ? '1':'0'
         status: e.target.checked ? '1':'0'
      }
      const changeIndefiniteValidity = dataRLEOptional.map((row) => 
            row.id === value.id ? nuevaData : row
      );
      editRLEOptional(nuevaData)
      setDataRLEOptional(changeIndefiniteValidity);
   }

   const changeDescription = (e, value) => {
      const nuevaData = {
         ...value,
         alias_name: e.target.value
      }
      const changeIndefiniteValidity = dataRLEOptional.map((row) => 
            row.id === value.id ? nuevaData : row
      );
      setDataRLEOptional(changeIndefiniteValidity);
   }

   const onBlurDescription = (e, value) => {
      console.log('onBlur','aceptar');
      const nuevaData = {
         ...value,
         alias_name: e.target.value
      }
      const changeIndefiniteValidity = dataRLEOptional.map((row) => 
            row.id === value.id ? nuevaData : row
      );
      editRLEOptional(nuevaData)
      setDataRLEOptional(changeIndefiniteValidity);
   }

   useEffect(() => {
      getRLE()
   }, [])

   const getRLE = async () => {
      const requireStructure = 1; //values: 1 - 0
      setLoading(true);
      const respRLEStructure = await RleStructureService.getRLEStructureFilter(requireStructure);
      const RLEOptional = await RleStructureService.getRLEStructureOptional();
      setDataRLEStructure(respRLEStructure.data || [])
      setDataRLEOptional(RLEOptional.data || [])
      setLoading(false);
   }

   const editRLEOptional = async (value) => {
      setLoading(true);
      const changeValue = {
         alias_name: value.alias_name,
         indefinite_validity: value.indefinite_validity,
         status: value.status
      }
      const respRLEStructure = await RleStructureService.editRLEOptional(value.id, changeValue);
      setLoading(false);
   }

   return (
      <Protected>
         {loading && <Loading/>}

         <Container>

            <Grid container spacing={3}>
               <Grid item xs={12} md={12} display='flex' justifyContent='space-between'>
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        ESTRUCTURA DE LIQUIDACION
                     </Typography>
                  </Box>
                  <Button
                     color="primary"
                     variant="contained"
                     onClick={()=>{
                        setShowStructureModal(true);
                     }}
                  >
                   Agregar estructura opcional
                  </Button>
               </Grid>
            </Grid>

            <Box sx={{ width: '100%', typography: 'body1' }}>
               <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                     <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example">
                        {labels.map((value, i) => {
                           return (
                              <Tab key={i} label={value.label} value={value.id} />
                           )
                        })}
                     </TabList>
                  </Box>

                  <TabPanel value="1">
                     <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                           <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                 <TableRow>
                                    {columnsRLEStructure.map((column) => (
                                       <TableCell
                                          key={column.id}
                                          style={{ minWidth: column.minWidth }}
                                       >
                                          {column.label}
                                       </TableCell>
                                    ))}
                                 </TableRow>
                              </TableHead>
                              <TableBody>
                                 {dataRLEStructure.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    return (
                                       <TableRow hover role="checkbox" tabIndex={-1} key={row.codigo}>
                                          <TableCell>
                                             {row.code}
                                          </TableCell>
                                          <TableCell>
                                             {row.concept_DT}
                                          </TableCell>
                                          <TableCell>
                                             {row.name}
                                          </TableCell>
                                          {/* <TableCell>
                                             <Checkbox checked={row.status == '1' ? true : false} />
                                          </TableCell> */}
                                       </TableRow>
                                    );
                                 })}

                              </TableBody>
                           </Table>
                        </TableContainer>
                        <TablePagination
                           rowsPerPageOptions={[5, 10, 15]}
                           component="div"
                           count={dataRLEStructure.length}
                           rowsPerPage={rowsPerPage}
                           page={page}
                           onPageChange={handleChangePage}
                           onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                     </Paper>
                     {/* <Grid item xs={12} md={3} mt={1}>
                        <Button
                           type='submit'
                           variant='contained'
                           disableElevation
                           className='btn_next'
                           style={{ backgroundColor: '#ff9700' }}
                        >
                           <span>Guardar Cambios</span>
                        </Button>
                     </Grid> */}
                  </TabPanel>

                  <TabPanel value="2">
                     <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                           <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                 <TableRow>
                                    {columnsRLEOptional.map((column) => (
                                       <TableCell
                                          key={column.id}
                                          style={{ minWidth: column.minWidth }}
                                       >
                                          {column.label}
                                       </TableCell>
                                    ))}
                                 </TableRow>
                              </TableHead>
                              <TableBody>
                                 {dataRLEOptional.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    return (
                                       <TableRow hover role="checkbox" tabIndex={-1} key={row.codigo}>
                                            <TableCell>
                                             {row.concept_DT}
                                          </TableCell>
                                          <TableCell>
                                             {row.code}
                                          </TableCell>
                                          <TableCell>
                                             {row.name}
                                          </TableCell>
                                          <TableCell>
                                             <InputChildren
                                                row={row}
                                                dataOptional={dataRLEOptional}
                                                setDataOptional={setDataRLEOptional}
                                                editRLEOptional={editRLEOptional}
                                             />
                                          </TableCell>
                                          <TableCell>
                                             <Checkbox 
                                             // disabled = {row.indefinite_validity == '0' ? true : false}
                                             disabled = {row.status == '0' ? true : false}
                                             checked={row.indefinite_validity == '1' ? true : false} 
                                             onChange={(e)=>{changeSelected(e, row)}}/>
                                          </TableCell>
                                          <TableCell>
                                             <FormControlLabel
                                                control={
                                                // <Android12Switch  checked={row.indefinite_validity == '1' ? true : false}  
                                                <Android12Switch  checked={row.status == '1' ? true : false}  
                                                onChange={(e)=>{changeOnOff(e, row)}} />}
                                                label=""
                                             />
                                          </TableCell>
                                       </TableRow>
                                    );
                                 })}

                              </TableBody>
                           </Table>
                        </TableContainer>
                        <TablePagination
                           rowsPerPageOptions={[5, 10, 15]}
                           component="div"
                           count={dataRLEOptional.length}
                           rowsPerPage={rowsPerPage}
                           page={page}
                           onPageChange={handleChangePage}
                           onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                     </Paper>
                     <Grid item xs={12} md={3} mt={1}>
                        <Button
                           type='submit'
                           variant='contained'
                           // onClick={handleSubmit}
                           // disabled={loading}
                           disableElevation
                           className='btn_next'
                           style={{ backgroundColor: '#ff9700' }}
                        >
                           <span>Guardar Cambios</span>
                        </Button>
                     </Grid>
                  </TabPanel>

               </TabContext>
            </Box>
         </Container>

         { 
            showStructureModal && (
               <ModalStructureLre
                  open={showStructureModal}
                  closeModal={()=>{setShowStructureModal(false)}}
                  onListStructures={getRLE}
                  setValueTab={setValue}
                  // actionSelected={actionSelected}
                  // phase={phaseSelected}
               />
            )
         }
      
      </Protected>
   )
};
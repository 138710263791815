import { PeriodRepository } from "../repositories/Period.repository";

export const PeriodService = {
    getPeriods,
    getPeriodsActually,
    // createAdvance,
    // editAdvance,
    // deleteAdvance,
}

async function getPeriods(){
    const res = await PeriodRepository.getPeriods();
    return res;
}

async function getPeriodsActually(){
    const res = await PeriodRepository.getPeriodsActually();
    return res;
}

// async function createAdvance(data:{}, idEmployee: number){
//     const res = await PeriodRepository.createAdvance(data, idEmployee);
//     return res;
// }

// async function editAdvance(data:{}, idAdvance: number){
//     const res = await PeriodRepository.editAdvance(data, idAdvance);
//     return res;
// }

// async function deleteAdvance(idAdvance:number){
//     const res = await PeriodRepository.deleteAdvance(idAdvance);
//     return res;
// }

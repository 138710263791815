import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);
const rut_empresa = dataUser?.user?.rut_empresa;

export const HealthRepository = {

    getCodeHealth: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/codhealth`);
        const {data, error, message} = res;
        return { data, error, message };
    },
}  
import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Container } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { ModalProject } from '@/components/Modal/ModalProject';
import { ProjectService } from '@/service/services/Project.service';
import { ModalProjectDelete } from '@/components/Modal/ModalProjectDelete';
import { Loading } from '@/components/common/Loading';
import { Props } from './TypeNotifications.type';
import { requestTypeService } from '@/service/services/RequestType.service';
import { ModalRequestType } from '@/components/Modal/ModalRequestType';
import { ModalRequestTypeDelete } from '@/components/Modal/ModalRequestTypeDelete';
import { notificationsTypeService } from '@/service/services/NotificationsType.service';
import { ModalNotificationsType } from '@/components/Modal/ModalTypeNotifications';
import { ModalNotificationsTypeDelete } from '@/components/Modal/ModalTypeNotificationsDelete/ModalTypeNotificationsDelete';

export const NotificationsTypeView: React.FC<Props> = (props:any): JSX.Element => {

   const [loading, setLoading] = useState<boolean>(false);
   const [notificationsTypes, setNotificationsTypes] = useState([]);
   const [showNotificationsTypeModal, setShowNotificationsTypeModal] = useState<boolean>(false);
   const [showNotificationsTypeDeleteModal, setShowNotificationsTypeDeleteModal] = useState<boolean>(false);
   const [actionSelected, setActionSelected] = useState<String>('');
   const [notificationsTypeSelected, setNotificationsTypeSelected] = useState<String>('');

   useEffect(() => {
    getNotificationsTypes()
   },[]);

   const getNotificationsTypes = async () => {
      setLoading(true);
      const response = await notificationsTypeService.getNotificationsTypes();
      if (response.data) {
        setNotificationsTypes(response.data || [])
      } else {
         console.log('error')
      }
      setLoading(false);
   }

   function RecuperarData(value){
      console.log(value);
      setNotificationsTypeSelected(value);
   }

  return (
      <Protected>
         {loading && <Loading/>}
         <Container>
            <TableDataV2
               title={'Tipos de notificaciones'}
               data={notificationsTypes}
               header={[
                  { name: 'name', label: 'Nombre', filter: false, Chip: true },
                  { name: 'url', label: 'URL', filter: false, Chip: true },
               ]}
               actionSelect={setActionSelected}
               setModalSave={setShowNotificationsTypeModal}
               setModalDelete={setShowNotificationsTypeDeleteModal}
               status_action
               // checkbox
               RecuperarData={RecuperarData}
            />
         </Container>

         { 
            showNotificationsTypeModal && (
               <ModalNotificationsType
                  open={showNotificationsTypeModal}
                  closeModal={()=>{setShowNotificationsTypeModal(false)}}
                  actionSelected={actionSelected}
                  onListNotificationsType={getNotificationsTypes}
                  notificationsType={notificationsTypeSelected}
                  clearState={()=>{setNotificationsTypeSelected('')}}
               />
            )
         }
         {
            actionSelected === 'delete' && (
               <ModalNotificationsTypeDelete
                  open={showNotificationsTypeDeleteModal}
                  closeModal={()=>{setShowNotificationsTypeDeleteModal(false)}}
                  actionSelected={actionSelected}
                  onListNotificationsType={getNotificationsTypes}
                  notificationsType={notificationsTypeSelected}
                  clearState={()=>{setNotificationsTypeSelected('')}}
               />
            )
         }
      
      </Protected>
   )
};